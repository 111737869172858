import React from "react";
import OnlinePayment from "../../assets/Slides2_460.jpg";

const TechnicienSlide = () => {

  const imageStyle = {
    width: "100%",
    height: "400px",
    objectFit: "contain",
  };

  return (
      <div
          className="flex flex-row  justify-around p-2"
          style={{
            background: "inherit",
            // "linear-gradient(135deg, rgb(36, 145, 58) 0%, #1ec540 50%, #56ab2f 100%)",
            textAlign: "center",
            borderRadius: "10px",
          }}
      >
        <div className={`hidden md:inline`}>
          {/*<img src={OnlinePayment} alt="paiement en ligne" style={imageStyle} />*/}
          <img src={OnlinePayment} alt="paiement en ligne" style={imageStyle}/>
        </div>

      </div>
  );
};

export default TechnicienSlide;
