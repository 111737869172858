import React from "react";

import  DemandeMotif from "../../../components/demande/DemandeMotif";


const ResiliationPhysique = ({serverOffline}) => {
      return  <DemandeMotif titre="Résiliation personne physique" userId={localStorage.getItem("userId")} type="physique" serverOffline={serverOffline} />
};

export default ResiliationPhysique ;
