import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";

import {
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
  CircularProgress,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import NavbarSidebarLayout from "../../../components/Dashboard/Layouts/NavbarSidebar";
import ServerStatus from "../../../components/ServerStatus";

import { api } from "../../../config/config";
import TypePiece from "../../../shared/TypePiece";
import { MuiTelInput } from "mui-tel-input";
import { Breadcrumb } from "flowbite-react";
import { EmailRegex } from "../../../components/Validation/EmailRegex";
import { PhoneRegex } from "../../../components/Validation/PhoneRegex";
import { PieceValidation } from "../../../components/Validation/PieceValidation";
import { CloudUpload } from "@mui/icons-material";
import { HiHome } from "react-icons/hi";
import LogoSonabel from "../../../assets/Logo-site-SONABEL-def.png";
// import InfoService from "../../../components/InfoService/InfoService";
import CnibValid from "../../../components/Functions/CnibValid";
import StepActionComponent from "../components/StepActionComponent";

const PuissanceMonophase = [
  { key: "3A", value: "3A", label: "3A" },
  { key: "5A - 15A", value: "5A - 15A", label: "5A - 15A" },
  { key: "10A - 30A", value: "10A - 30A", label: "10A - 30A" },
];

const PuissanceTriphase = [
  { key: "10A - 30A", value: "10A - 30A", label: "10A - 30A" },
  { key: "DT", value: "DT", label: "Double tarif (> 30A)" },
];

const BrancheSanAbon = ({ serverOffline }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);

  //Controler la selction de la puissance
  const [showAlert, setShowAlert] = useState(false);
  const [puissances, setPuissances] = useState([]);

  const [formData, setFormData] = useState({
    typeClient: "particulier",
    //Initialiser avec les valeurs par défaut du formulaire
    service: "",
    exploitation: "",
    section: "",
    lot: "",
    parcelle: "",
    rang: "",
    nom: "",
    prenom: "",
    email: "",
    telephone: "+226",
    whatsApp: "+226",
    rccm: "",
    ifu: "",
    pb: "",
    profession: "",
    piece: "",
    lieu_piece: "",
    date_piece: "",
    numero_piece: "",
    latitude: "",
    longitude: "",
    puissance: "",
    compteur: "",
    type_amperage: "monophasé", // triphasé
    police: "",
    region: "",
    province: "",
    ville: "",
    village: "",
    nom_entreprise: "",
    prop_nom: "",
    prop_prenom: "",
    file: null,
  });
  const [errors, setErrors] = useState({});

  // La liste de professions
  const [professions, setProfessions] = useState([]);
  // liste de villes
  const [villes, setVilles] = useState([]);
  // liste des villages
  const [villages, setVillages] = useState([]);
  const [phone, setPhone] = React.useState("+226");
  const [whatsapp, setWhatsapp] = React.useState("+226");

  // deactiver le bouton submit lorsqu'on actionne le bouton soumettre
  const [stateSend, setStateSend] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const files = useState({
    cnib: "",
    parcelle: "",
  });

  const avertissement = <p className="text-red-600">Avertissement</p>;

  //Gérer les puissances en fonction du types d'ampérange
  useEffect(() => {
    if (formData.type_amperage === "monophasé") {
      setFormData((prevData) => ({
        ...prevData,
        puissance: PuissanceMonophase[0].key,
      }));
      setPuissances(PuissanceMonophase);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        puissance: PuissanceTriphase[0].key,
      }));
      setPuissances(PuissanceTriphase);
    }
  }, [formData.type_amperage]);
  useEffect(() => {
    // Récupération des professions
    api
        .get("/profession/all")
        .then((response) => {
          if (response.status === 200) {
            setProfessions(response.data.content);
          }
        })
        .catch(() => {});

    // Récupération des villes
    api
        .get("/ville/cd")
        .then((response) => {
          if (response.status === 200) {
            const trimmedVilles = response.data.content.map((ville) => ({
              id: ville.id,
              name: ville.agence.trim(),
            }));
            setVilles(trimmedVilles);
          }
        })
        .catch(() => {});
  }, []);

  const steps = [
    avertissement,
    "Type de client",
    "Informations du client",
    "Informations d'identité",
    "Localisation géographique",
    "Information cadastrale de la parcelle",
    "Information sur le branchement",
    "Validation",
  ];

  const handleNext = () => {
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setErrors(validationErrors);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setStateSend(false);
  };

  /**
   * Recuperer des les donnees des
   * champs du formulaire
   *
   */
  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleChangeVille = (event, value) => {
    setVillages([]); // met la liste des village a zero
    let valeur = value === "undefined" || value === null ? "" : value?.id;
    //setFormData((prevData) => ({ ...prevData, ville: value?.id }));
    setFormData((prevData) => ({ ...prevData, ville: valeur }));
    setIsLoading(true);
    api
        .get(`/village/ville/${value?.id}`)

        .then((response) => {
          if (response.status === 200) {
            const trimmedVillages = response.data.content.map((village) => ({
              id: village?.id,
              name: village?.localite.trim(),
            }));
            setVillages(trimmedVillages);
            setIsLoading(false);
          }
        })
        .catch(() => {});
  };

  const handleChangeProfession = (event, value) => {
    let valeur = value === "undefined" || value === null ? "" : value?.id;
    setFormData((prevData) => ({ ...prevData, profession: valeur }));
  };

  const handleChangePiece = (event, value) => {
    setFormData((prevData) => ({ ...prevData, piece: value?.name }));
  };

  const handleChangeVillage = (event, value) => {
    let valeur = value === "undefined" || value === null ? "" : value?.id;
    setFormData((prevData) => ({ ...prevData, village: valeur }));
  };

  const handleFieldFocus = (field) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  };

  // Validation des champs du formulaire
  const validateForm = (formData) => {
    const errors = {};
    if (activeStep === 2) {
      if (!formData.nom.trim()) {
        errors.nom = "Nom obligatoire";
      }
      if (!formData.prenom.trim()) {
        errors.prenom = "Prenom obligatoire";
      }
      // Validation de l'email
      if (!formData.email.trim()) {
        errors.email = "Email obligatoire";
      } else if (!EmailRegex(formData.email.trim())) {
        errors.email = "Format d'email invalide";
      }
      if (!formData.telephone.trim() && formData.telephone.length < 8) {
        errors.telephone = "Telephone obligatoire";
      } else if (!PhoneRegex(formData.telephone)) {
        errors.telephone =
            "Le numéro de téléphone doit être au format correct.";
      }
      if (!PhoneRegex(formData.whatsApp)) {
        errors.whatsApp = "Le numéro de téléphone doit être au format correct.";
      }

      if (!formData.profession) {
        errors.profession =
            "Vueillez choisir une profession ou secteur d'activité";
      }
      if (formData.typeClient === "entreprise" && !formData.rccm.trim()) {
        errors.rccm = "RCCM obligatoire";
      }
      if (
          formData.typeClient === "entreprise" &&
          !formData.nom_entreprise.trim()
      ) {
        errors.nom_entreprise = "Nom Entreprise obligatoire";
      }
      if (formData.typeClient === "entreprise" && !formData.ifu.trim()) {
        errors.ifu = "Numero IFU obligatoire";
      }
    } else if (activeStep === 3) {
      if (!formData.numero_piece.trim() && formData.numero_piece.length < 8) {
        errors.numero_piece = "Numéro piece obligatoire";
      }
      if (!formData.piece) {
        errors.piece = "Type piece obligatoire";
      }
      if (!formData.file) {
        errors.file = "Ce champ est obligatoire";
      }
      if (formData.file && formData.file.size > 2 * 1024 * 1024) {
        errors.file = "Le fichier sélectionné est trop volumineux.";
      }
      if (!formData.file || !formData.file.name || !formData.file.name.toLowerCase().endsWith('.pdf')) {
        errors.file = "Le fichier doit être un pdf";
      }
      if (!formData.date_piece.trim()) {
        errors.date_piece = "La date d'etablissement est  obligatoire";
        setErrorDate(true);
      } else if (!PieceValidation(formData.date_piece)) {
        errors.date_piece = "Cette date ne peut pas être acceptée.";
        setErrorDate(true);
      }
      // On verifie si la pièce d'identité est valide
      if (CnibValid(formData.date_piece) >= 10) {
        errors.date_piece = "Votre carte d'identité a expiré.";
        setErrorDate(true);
      }
      if (!formData.lieu_piece.trim()) {
        errors.lieu_piece = " Lieu d'etablissement de la piece obligatoire";
      }
    } else if (activeStep === 4) {
      if (!formData.ville) {
        errors.ville = "La ville est Obligatoire";
      }
      if (!formData.village) {
        errors.village = "La ville est Obligatoire";
      }
    } else if (activeStep === 5) {
      if (!formData.prop_nom.trim()) {
        errors.prop_nom = "Ce champ est obligatoire";
      }
      if (!formData.prop_prenom.trim()) {
        errors.prop_prenom = "Ce champ est obligatoire";
      }
      if (!formData.lot.trim()) {
        errors.lot = "Numero lot obligatoire";
      }
      if (!formData.parcelle.trim()) {
        errors.parcelle = "Numero parcelle Obligatoire";
      }
      if (!formData.section.trim()) {
        errors.section = "Numero section Obligatoire";
      }
      if (
          formData.document_parcelle &&
          formData.document_parcelle.size > 2 * 1024 * 1024
      ) {
        errors.document_parcelle =
            "Le fichier sélectionné est trop volumineux.";
      } else if (!formData.document_parcelle) {
        errors.document_parcelle = "Le document de la parcelle est obligatoire.";
      }
      // if (!formData.document_parcelle || !formData.file_branchement.name || !formData.file_branchement.name.toLowerCase().endsWith('.pdf')) {
      //   errors.document_parcelle = "Le fichier doit être un pdf";
      // }
    } else if (activeStep === 6) {
      if (!formData.puissance) {
        errors.puissance = "Ce champ est obligatoire";
        setShowAlert(true);
      }
    }
    return errors;
  };

  //envoie des fichiers avant l'envoie de la demande
  const handleSendFile = async () => {
    if (formData.file) {
      // create a new FormData object and append the file to it
      const formDataTransform = new FormData();
      formDataTransform.append("file", formData.file);

      try {
        setIsLoading(true);
        setStateSend(true);
        await api
            .post("/storage/upload", formDataTransform, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              if (response.status === 201) {
                files.cnib = response.data.content;
                // handleDemande(response.data.content);
              }
            })
            .catch(() => {
              //S'il n'a pas pu enregistrer les données on arrête le fonctionnement du spinner
              setIsLoading(false);
              setStateSend(false);
              //On envoie une notification disant qu'il y a eu echec
              toast.error("Demande non enregistré");
            });
      } catch (e) {}
    }
  };
  const handleSendFileDocument = async () => {
    if (formData.file) {
      // create a new FormData object and append the file to it
      const formDataTransform = new FormData();
      formDataTransform.append("file", formData.document_parcelle);

      try {
        setIsLoading(true);
        setStateSend(true);
        await api
            .post("/storage/upload", formDataTransform, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              if (response.status === 201) {
                files.parcelle = response.data.content;
              }
            })
            .catch(() => {
              //S'il n'a pas pu enregistrer les données on arrête le fonctionnement du spinner
              setIsLoading(false);
              setStateSend(false);
              //On envoie une notification disant qu'il y a eu echec
              toast.error("Demande non enregistré");
            });
      } catch (e) {}
    }
  };

  const handleDemande = async (cnib, parcelle) => {
    if (cnib) {
      const validationErrors = validateForm(formData);
      if (Object.keys(validationErrors).length === 0) {
        setErrors({});

        const demandeService = {
          lot: formData.lot,
          parcelle: formData.parcelle,
          type_sce: location.state.idBranchementAbon,
          nom: formData.nom,
          prenom: formData.prenom,
          tel1_whatsapp: formData.telephone.replace(/\s+/g, ""),
          tel2: formData.whatsApp.replace(/\s+/g, ""),
          email: formData.email,
          numero_ifu: formData.ifu,
          numero_rccm: formData.rccm,
          ville_id: formData.ville,
          village_id: formData.village,
          profession_id: formData.profession,
          type_amperage: formData.type_amperage,
          puissance: formData.puissance,
          numero_piece: formData.numero_piece,
          piece: formData.piece,
          date_etablissement: formData.date_piece,
          lieu_etablissement: formData.lieu_piece,
          section: formData.section,
          user_id: localStorage.getItem("userId"),
          nom_entreprise: formData.nom_entreprise,
          prop_nom: formData.prop_nom,
          prop_prenom: formData.prop_prenom,
          cat_cli_code: "",
          photo_piece: cnib,
          document_parcelle: parcelle,
        };
        if (formData.typeClient === "particulier") {
          demandeService.cat_cli_code = "00";
        } else {
          demandeService.cat_cli_code = "02";
        }

        const filteredDataBranchSansAbon = Object.fromEntries(
            Object.entries(demandeService).filter(
                ([key, value]) => value !== null
            )
        );

        // Soumettre la demande du client
        try {
          setIsLoading(true);
          await api
              .post("/demande", filteredDataBranchSansAbon)
              .then((response) => {
                if (response.status === 201) {
                  setFormData({
                    typeClient: "particulier",
                    //Initialiser avec les valeurs par défaut du formulaire
                    service: "",
                    exploitation: "",
                    section: "",
                    lot: "",
                    parcelle: "",
                    rang: "",
                    nom: "",
                    prenom: "",
                    email: "",
                    telephone: "+226",
                    whatsApp: "+226",
                    rccm: "",
                    ifu: "",
                    pb: "",
                    profession: "",
                    piece: "CNI",
                    lieu_piece: "",
                    date_piece: "",
                    numero_piece: "",
                    latitude: "",
                    longitude: "",
                    puissance: "3A",
                    compteur: "",
                    type_amperage: "monophasé", // triphasé
                    police: "",
                    region: "",
                    province: "",
                    ville: "",
                    village: "",
                    nom_entreprise: "",
                    prop_nom: "",
                    prop_prenom: "",
                    file: null,
                  });
                  toast.success(
                      "Demande enregistrée ! Nous vous enverrons des notifications pour le suivi de la demande par mail ou SMS."
                  );
                  setIsLoading(false);
                  setStateSend(true); // désactiver le bouton
                  setTimeout(() => {
                    navigate("/dashboard");
                  }, 2000);
                }
              })
              .catch((err) => {
                if (err.response?.status === 404) {
                  setIsLoading(false);
                  setStateSend(false);
                  toast.error("La section saisie n'est pas valide.");
                }else {
                  setIsLoading(false);
                  setStateSend(false);
                  toast.error("Demande non enregistré");
                }
              });
          // setErrorMessage("");
        } catch (erreur) {
        }
      } else {
        setErrors(validationErrors);
      }
    } else {
      toast.error("Demande non enregistré");
    }
  };

  // Controller les numero de téléphone
  const handleChangePhone = (value) => {
    setPhone(value);
    setFormData((prevData) => ({ ...prevData, telephone: value }));
  };

  //Whatsapp
  const handleChangePhoneWhatsapp = (valueWhatsapp) => {
    setWhatsapp(valueWhatsapp);
    setFormData((prevData) => ({ ...prevData, whatsApp: valueWhatsapp }));
  };
  const handleFileChange = (event) => {
    setFormData((prevData) => ({ ...prevData, file: event.target.files[0] }));
  };

  const handleFileDocumentChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      document_parcelle: event.target.files[0],
    }));
  };

  // Gestion synchrôme de l'envoie des données
  const handleManageDataSend = async () => {
    try {
      // On upload le fichier de la cnib
      if (formData.file) {
        await handleSendFile();
      }
      // On upload le fichier du document de la parcelle
      if (formData.document_parcelle) {
        await handleSendFileDocument();
      }

      // On verifie que l'un des deux existes
      if (files.cnib || files.parcelle) {
        // Si parcelle est n'existe pas on renvoie null
        await handleDemande(files.cnib, files.parcelle || null);
      }
    } catch (e) {}
  };

  const handleAcceptTermsAndCondition = async (event) => {
    setStateSend(!event.target.checked);
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
            <div>
              <div className="flex flex-col items-center">
                <div className={`text-center items-center mt-4`}>
                  <img src={LogoSonabel} alt="" width={200} />
                </div>
                <div className={`text-justify m-8`}>
                  <h2 className="mb-2 text-lg font-bold text-gray-900 dark:text-white">
                    Conditions pour cette demande :
                  </h2>
                  <ol className="max-w-md space-y-1 text-gray-500 list-decimal list-inside dark:text-gray-400">
                    <li>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      Assurez-vous de disposer de votre pièce d'identité, du
                      document relatif à votre parcelle, des informations
                      cadastrales de celle-ci, ainsi que de connaître le type de
                      branchement et l'ampérage souhaité.
                    </span>
                    </li>
                    <li>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      La SONABEL décline toute responsabilité pour toute
                      inexactitude des informations fournies par les demandeurs.
                    </span>
                    </li>
                    <li>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      Aucun remboursement ne sera accordé en cas de fausses
                      informations. La SONABEL se réserve le droit de poursuivre
                      en justice les cas de fraude.
                    </span>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
        );
      case 1:
        return (
            <div className="flex justify-center h-32 items-center">
              <FormControl>
                <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="mb-5"
                >
                  {/* Type de client */}
                </FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="type-client"
                    value={formData.typeClient}
                >
                  <FormControlLabel
                      value="particulier"
                      control={<Radio />}
                      label="Particulier"
                      onChange={(e) => handleChange("typeClient", e.target.value)}
                  />
                  <FormControlLabel
                      value="entreprise"
                      control={<Radio />}
                      label="Entreprise"
                      onChange={(e) => handleChange("typeClient", e.target.value)}
                  />
                </RadioGroup>
              </FormControl>
            </div>
        );
      case 2:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              <TextField
                  label="Nom du Demandeur"
                  fullWidth
                  size="small"
                  value={formData.nom}
                  onChange={(e) => handleChange("nom", e.target.value)}
                  onFocus={() => handleFieldFocus("nom")}
                  error={!!errors.nom}
                  helperText={errors.nom}
                  required
              />
              <TextField
                  label="Prenom du demandeur"
                  fullWidth
                  size="small"
                  value={formData.prenom}
                  onChange={(e) => handleChange("prenom", e.target.value)}
                  onFocus={() => handleFieldFocus("prenom")}
                  error={!!errors.prenom}
                  helperText={errors.prenom}
                  required
              />
              <TextField
                  label="Email"
                  fullWidth
                  size="small"
                  value={formData.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                  onFocus={() => handleFieldFocus("email")}
                  error={!!errors.email}
                  helperText={errors.email}
                  required
                  type="email"
              />
              <MuiTelInput
                  label="Telephone"
                  fullWidth
                  size="small"
                  value={phone}
                  onChange={handleChangePhone}
                  onFocus={() => handleFieldFocus("telephone")}
                  error={!!errors.telephone}
                  helperText={errors.telephone}
                  required
                  className="dark:text-white"
              />
              <MuiTelInput
                  label="WhatSApp"
                  fullWidth
                  size="small"
                  value={whatsapp}
                  onChange={handleChangePhoneWhatsapp}
                  onFocus={() => handleFieldFocus("whatsApp")}
                  error={!!errors.whatsApp}
                  helperText={errors.whatsApp}
                  className="dark:text-white"
              />
              <Autocomplete
                  // disablePortal
                  autoHighlight
                  id="profession"
                  options={professions}
                  size="small"
                  sx={{ width: "100%" }}
                  //onChange={handleProfessionChange}
                  onChange={handleChangeProfession}
                  onFocus={() => handleFieldFocus("profession")}
                  //value={services.find((service) => service.libelle === formData.service) || null}
                  value={
                      professions.find(
                          (profession) => profession.id === formData.profession
                      ) || null
                  }
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          label="Profession ou secteur d'activité"
                          error={!!errors.profession}
                          helperText={errors.profession}
                      />
                  )}
              />

              {/**
               * Si c'est  le client est une entreprise
               * on affiche rccm et ifu
               *
               */}

              {formData.typeClient === "entreprise" && (
                  <>
                    <TextField
                        label="Nom Entreprise"
                        fullWidth
                        size="small"
                        value={formData.nom_entreprise}
                        onChange={(e) =>
                            handleChange("nom_entreprise", e.target.value)
                        }
                        onFocus={() => handleFieldFocus("nom_entreprise")}
                        error={!!errors.nom_entreprise}
                        helperText={errors.nom_entreprise}
                        required
                    />
                    <TextField
                        label="Numero IFU"
                        fullWidth
                        size="small"
                        value={formData.ifu}
                        onChange={(e) => handleChange("ifu", e.target.value)}
                        onFocus={() => handleFieldFocus("ifu")}
                        error={!!errors.ifu}
                        helperText={errors.ifu}
                        required
                    />

                    <TextField
                        label="RCCM"
                        fullWidth
                        size="small"
                        value={formData.rccm}
                        onChange={(e) => handleChange("rccm", e.target.value)}
                        onFocus={() => handleFieldFocus("rccm")}
                        error={!!errors.rccm}
                        helperText={errors.rccm}
                        required
                    />
                  </>
              )}
            </div>
        );
      case 3:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              <Autocomplete
                  // disablePortal
                  autoHighlight
                  id="typepiece"
                  options={TypePiece}
                  size="small"
                  sx={{ width: "100%" }}
                  //onChange={handleProfessionChange}
                  onChange={handleChangePiece}
                  onFocus={() => handleFieldFocus("piece")}
                  value={
                      TypePiece.find((piece) => piece.name === formData.piece) || null
                  }
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          label="Type de piece"
                          error={!!errors.piece}
                          helperText={errors.piece}
                      />
                  )}
              />

              <TextField
                  label="Numero de la piece"
                  fullWidth
                  size="small"
                  value={formData.numero_piece}
                  onChange={(e) => handleChange("numero_piece", e.target.value)}
                  onFocus={() => handleFieldFocus("numero_piece")}
                  error={!!errors.numero_piece}
                  helperText={errors.numero_piece}
                  required
              />
              <div className="w-full">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                      sx={{ width: "100%" }}
                      label="Date Etablissement"
                      format="DD/MM/YYYY"
                      value={dayjs(formData.date_piece)}
                      onChange={(value) => {
                        // Formater la date au format "yyyy/mm/dd" avec Dayjs
                        const formattedDate = dayjs(value).format("YYYY/MM/DD");
                        // Appeler la fonction handleChange avec la date formatée
                        handleChange("date_piece", formattedDate);
                        setErrorDate(false);
                      }}
                      slotProps={{
                        textField: {
                          error: errorDate,
                          helperText: errors.date_piece,
                        },
                      }}
                  />
                </LocalizationProvider>
              </div>

              <TextField
                  label="Lieu d'etablissement piece"
                  fullWidth
                  size="small"
                  value={formData.lieu_piece}
                  onChange={(e) => handleChange("lieu_piece", e.target.value)}
                  onFocus={() => handleFieldFocus("lieu_piece")}
                  error={!!errors.lieu_piece}
                  helperText={errors.lieu_piece}
                  required
              />
              <>
                <p className={`mt-2 mb-2 text-sm text-yellow-400 font-bold`}>Importer la Pièce d'identité
                  recto verso en pdf (2Mo maximum)</p>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUpload/>}
                >
                  Importez votre pièce
                  <input
                      type="file"
                      accept=".pdf"
                      hidden
                      onChange={(event) => handleFileChange(event)}
                  />
                </Button>
                {formData.file && <>{formData.file.name}</>}
                {errors.file && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      {errors.file}
                    </p>
                )}
              </>
            </div>
        );
      case 4:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              <Autocomplete
                  fullWidth
                  // disablePortal
                  autoHighlight
                  id="ville"
                  options={villes}
                  size="small"
                  sx={{ width: "100%" }}
                  //onChange={handleProfessionChange}
                  onChange={handleChangeVille}
                  onFocus={() => handleFieldFocus("ville")}
                  //value={villes.find((ville) =>ville.name === formData.ville) || null}
                  value={
                      villes.find((ville) => ville.id === formData.ville) || null
                  }
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          label="Zone administrative"
                          error={!!errors.ville}
                          helperText={errors.ville}
                      />
                  )}
              />
              {isLoading && <CircularProgress />}
              {villages.length > 0 && (
                  <Autocomplete
                      fullWidth
                      // disablePortal
                      autoHighlight
                      id="village"
                      options={villages}
                      size="small"
                      sx={{ width: "100%" }}
                      //onChange={handleProfessionChange}
                      onChange={handleChangeVillage}
                      onFocus={() => handleFieldFocus("village")}
                      value={
                          villages.find((village) => village.id === formData.village) ||
                          null
                      }
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              label="Localité"
                              error={!!errors.village}
                              helperText={errors.village}
                          />
                      )}
                  />
              )}
            </div>
        );
      case 5: //  Inos sur la parcelle
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              <TextField
                  label="Nom du propriétaire de la parcelle"
                  fullWidth
                  size="small"
                  value={formData.prop_nom}
                  onChange={(e) => handleChange("prop_nom", e.target.value)}
                  onFocus={() => handleFieldFocus("prop_nom")}
                  error={!!errors.prop_nom}
                  helperText={errors.prop_nom}
                  required
              />
              <TextField
                  label="Prénom du propriétaire de la parcelle"
                  fullWidth
                  size="small"
                  value={formData.prop_prenom}
                  onChange={(e) => handleChange("prop_prenom", e.target.value)}
                  onFocus={() => handleFieldFocus("prop_prenom")}
                  error={!!errors.prop_prenom}
                  helperText={errors.prop_prenom}
                  required
              />
              <TextField
                  label="Section"
                  fullWidth
                  size="small"
                  value={formData.section}
                  onChange={(e) =>
                      handleChange("section", e.target.value.toUpperCase())
                  }
                  onFocus={() => handleFieldFocus("section")}
                  error={!!errors.section}
                  helperText={errors.section}
                  required
              />
              <TextField
                  label="Lot"
                  fullWidth
                  size="small"
                  value={formData.lot}
                  onChange={(e) => handleChange("lot", e.target.value)}
                  onFocus={() => handleFieldFocus("lot")}
                  error={!!errors.lot}
                  helperText={errors.lot}
                  required
                  type='number'
              />
              <TextField
                  label="parcelle"
                  fullWidth
                  size="small"
                  value={formData.parcelle}
                  onChange={(e) => handleChange("parcelle", e.target.value)}
                  onFocus={() => handleFieldFocus("parcelle")}
                  error={!!errors.parcelle}
                  helperText={errors.parcelle}
                  required
                  type='number'
              />
              <>
                <p className="mt-1 text-sm font-bold text-red-700">Le fichier ne doit pas excéder 2 Mo et doit être un
                  pdf.</p>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUpload/>}
                >
                  Importez le Document de votre parcelle
                  <input
                      type="file"
                      accept=".pdf"
                      hidden
                      onChange={(event) => handleFileDocumentChange(event)}
                  />
                </Button>
                {formData.document_parcelle && (
                    <p className={`text-green-700`}>
                      {formData.document_parcelle.name}
                    </p>
                )}
                {errors.document_parcelle && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      {errors.document_parcelle}
                    </p>
                )}
              </>
            </div>
        );
      case 6:
        return (
            <div className=" space-y-5 mt-8 mb-8">
              <InputLabel id="demo-select-small-label">
                Type de branchement
              </InputLabel>
              <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={formData.type_amperage}
                  label="type_amperage"
                  size="small"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  onChange={(e) => handleChange("type_amperage", e.target.value)}
              >
                <MenuItem value="monophasé">Monophasé</MenuItem>
                <MenuItem value="triphasé">Triphasé</MenuItem>
              </Select>
              <div
                  className={`flex md:justify-between md:flex-row sm:flex-row space-x-8`}
              >
                <div>
                  Monophasé (02 fils)
                  <p>
                    Pour les applications domestiques et les petits appareils.
                  </p>
                </div>
                <div>
                  Triphasé ( 04 fils )
                  <p>
                    Pour les grandes installations industrielles et commerciales.
                  </p>
                </div>
              </div>
              <InputLabel id="puissance">Ampérage souhaité</InputLabel>
              <Select
                  labelId="puissance"
                  id="puissance"
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={formData.puissance}
                  onChange={(event) => {
                    handleChange("puissance", event.target.value);
                  }}
                  //displayEmpty
                  /*  inputProps={{ "aria-label": "Without label" }} */
                  required
              >
                {puissances.map((k) => (
                    <MenuItem key={k.key} value={k.key}>
                      {k.label}
                    </MenuItem>
                ))}
              </Select>
              {showAlert && (
                  <p style={{ color: "red" }}>
                    Veuillez sélectionner une puissance avant de soumettre le
                    formulaire.
                  </p>
              )}
            </div>
        );
      case 7:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8 dark:text-white">
              <Typography
                  className="text-center mb-8 dark:text-white"
                  variant="h5"
              >
                Voulez-vous vraiment soumettre votre demande ?
              </Typography>
              <div className="flex items-center mb-4">
                <input id="terms-checkbox" type="checkbox" value=""
                       className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                       onChange={handleAcceptTermsAndCondition}
                />
                <label htmlFor="terms-checkbox" className="ml-2 text-sm font-medium text-gray-900">
                  J'accepte les <a href="/clause-juridique" className="text-blue-600 hover:underline" target="_blank">conditions
                  d'utilisation</a> et
                  les <a href="/clause-juridique" className="text-blue-600 hover:underline" target="_blank">clauses de traitement des
                  données</a>.
                </label>
              </div>
            </div>
        );
      default:
        return "Unknown step";
    }
  };

  return (
      <>
        <ToastContainer/>
        <NavbarSidebarLayout>
          {serverOffline && <ServerStatus/>}
          <div
              className="block items-center justify-center border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
            <div className="mb-1 w-full">
              <div className="mb-4">
                <Breadcrumb className="mb-4">
                  <Breadcrumb.Item href="/dashboard">
                    <div className="flex items-center gap-x-3">
                      <HiHome className="text-xl"/>
                      <span className="dark:text-white">Accueil</span>
                    </div>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item href="/list-des-demandes">
                    Liste des services{" "}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Branchement sans abonnement</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div>
                {/*<InfoService message="Pour vos demandes de branchement sans abonnement, vous devez présenter une pièce d'identité valide ainsi que les documents attestant que la parcelle vous appartient."/>*/}
                <div className={`my-8`}>
                  <div className="text-center text-2xl sm:text-3xl mb-8">
                    Branchement sans Abonnement
                  </div>

                  <div className="flex w-full flex-col gap-y-6 lg:flex-row lg:justify-center lg:gap-y-0 md:flex-row md:justify-center md:gap-y-0">
                    <div className=" rounded-lg bg-white p-2 shadow-2xl dark:bg-gray-800  xl:p-8 lg:w-1/2 border-2 lg:p-5">
                      <div className="invisible md:visible">
                        <Stepper activeStep={activeStep} alternativeLabel>
                          {steps.map((label) => (
                              <Step key={label}>
                                <StepLabel>{/*label*/}</StepLabel>
                              </Step>
                          ))}
                        </Stepper>
                      </div>
                      <div>
                        <Typography className="text-center mb-8" variant="h5">
                          {steps[activeStep]}
                        </Typography>
                        {getStepContent(activeStep)}

                        <StepActionComponent
                            activeStep={activeStep}
                            steps={steps}
                            handleNext={handleNext}
                            handleBack={handleBack}
                            handleManageDataSend={handleManageDataSend}
                            stateSend={stateSend}
                            isLoading={isLoading}
                            serverOffline={serverOffline}
                        />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </NavbarSidebarLayout>
      </>
  );
};

export default BrancheSanAbon;
