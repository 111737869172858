import React, {useState} from "react";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea,CardActions } from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone'; // ou PhoneOutlined
// import sinistre1 from "../../assets/images/Declaration_de_sinistre.jpg";
import index from "../../assets/images/Envoie-d'index.jpg";
// import etalonnage from "../../assets/images/Etalonnage.jpg";
import panneDeclaration from "../../assets/images/Declaration-de-panne.jpg";
import Tranvaux_divers from "../../assets/images/Demande-de-travaux.jpg";
import fraude from "../../assets/images/Denonciation-de-fraude.jpg";
import branchement from "../../assets/images/Branchement.jpg";
import abonnement from "../../assets/images/Abonnement.jpg";





const ServiceOnline = () => {

  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 35; // Nombre maximum de caractères à afficher

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      id="service-en-ligne"
      className="w-4/5 container mx-auto p-2 md:p-12 mt-16 border-1 bg-green-100 "
    >
      <div className="text-center mb-4">
        <h2 className="text-2xl font-bold text-center text-stone-700 uppercase">
          Nos services en ligne
        </h2>
        <p>
          Pour toutes vos demandes de{" "}
          <span className="text-red-700 font-bold">raccordement</span>,{" "}
          <span className="text-red-700 font-bold">d'abonnement</span> et de{" "}
          <span className="text-red-700 font-bold">travaux divers</span>.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {services.map((service, index) => (
          <Card key={service.id}>
            <CardActionArea>
              <CardMedia
                component="img"
                 height="250"
                image={service.icon}
                alt="green iguana"
                sx={{height: 250}}
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  {service.title}
                </Typography>
                 <Typography variant="body2" color="text.secondary" className={`text-justify`}>
                   {/*On affiche le descriptif en fonction de la taille qu'on désire*/}
                   {isExpanded ? service.description : `${service.description.substring(0, maxLength)}${service.description.length > maxLength ? '...' : ''}`}

                   {/*button pour Afficher plus et afficher moin*/}
                   {service.description.length > maxLength && (
                       <span onClick={handleToggle} className="text-blue-500 hover:underline cursor-pointer">
                         {isExpanded ? 'Afficher moins' : 'Afficher plus'}
                       </span>
                   )}
                  {/*{service.description.substring(0, maxLength)}*/}
                </Typography>
              </CardContent>
               <CardActions>
                 {  (service.id !== '1' && service.id !== '2') && (<Link
                  to={service.url}
                  className="bg-red-500  mx-auto px-6 py-2 rounded-lg text-white hover:bg-red-700"
                >
                  {service.buttonText} <NavigateNextIcon className="ml-2"/>
                </Link> )}

                 {  (service.id === '2') && (<Link
                     to="/denonciation"
                     className="bg-red-500  mx-auto px-6 py-2 rounded-lg text-white hover:bg-red-700"
                 >
                   {service.buttonText} <NavigateNextIcon className="ml-2"/>
                 </Link> )}

                {  service.id === '1' && (
                 <li className=" mx-auto px-8  py-2  bg-green-500  text-white  text-2xl text-center font-bold list-none"> <PhoneIcon /> 80 00 11 30 </li>
                )}

              </CardActions>
            </CardActionArea>
          </Card>

        ))}
      </div>
    </div>
  );
};

const services = [
  {
    id:'1',
    icon:  panneDeclaration,
    alt: "Declaration de panne",
    title: "Déclaration de pannes",
    description: "Veuillez contacter le centre d'Appels au numéro vert 80 00 11 30 ou nous écrire sur whatsapp au: +22625313720",
    buttonText: "Numero Vert : 80 00 11 30",
  },
  {
    id:'2',
    icon:  fraude,
    alt: "Dénonciation de fraude",
    title: "Dénonciation de fraude",
    description: "Veuillez signaler tout cas de fraude commis sur le réseau de la SONABEL en utilisant notre formulaire.",
    buttonText: "Dénoncer une fraude",
  },
  {
    /*  icon: branchement, */
    id:'3',
    icon: branchement,
    alt: "Branchement",
    title: "Branchement",
    description:
      "Le branchement consiste à raccorder le client au réseau électrique de la SONABEL en basse tension ou en haute tension.",
    buttonText: "Faire ma demande",
    url: '/list-des-demandes'
  },
  {
    /*  icon: branchement, */
    id:'4',
    icon: abonnement,
    alt: "Abonnement",
    title: "Abonnement",
    description:
      "L’abonnement est un contrat conclu entre le client et la SONABEL pour la fourniture et l’utilisation de l’électricité.",
    buttonText: "Faire ma demande",
    url: '/abonnement'
  },
  /* {
    id:'2',
    icon: resiliation1,
    alt: "Modification d'ampérage",
    title: "Modification d'ampérage",
    description:
      "Elle vise à modifier la puissance initiale souscrite par le client (augmentation ou diminution). (Monophasé / Triphasé)",
    buttonText: "Faire ma demande",
  }, */
  {
    id:'5',
    icon: Tranvaux_divers,
    alt: "Demande de travaux divers",
    title: "Demande de travaux divers",
    description:
      "Déplacement de branchement, Dépose de branchement, repose de branchement, mise en conformité",
    buttonText: "Faire ma demande",
    url: '/Demande-de-travaux-divers',
  },
 /*  {
    id:'4',
    icon: resiliation1,
    alt: "Résiliation",
    title: "Résiliation",
    description:
      "La résiliation est la rupture du contrat de fourniture d’électricité qui lie la SONABEL au client, sur demande de ce dernier. (Entreprise ou particulier)",
    buttonText: "Faire ma demande",
  }, */
/*   {
    id:'5',
    icon: Tranvaux_divers,
    alt: "Supervision",
    title: "Supervision de travaux",
    description:
      "C’est le suivi et le contrôle de la réalisation des travaux de génie civil et ouvrages électriques",
    buttonText: "Faire ma demande",
  }, */
  //  {
  //   id:'6',
  //   icon: sinistre1,
  //   alt: "declaration sinistre",
  //   title: "Déclaration sinistre",
  //   description:
  //     "Est défini comme sinistre, tout dommage occasionné aux installations ou équipements d’un utilisateur en raison d’une défaillance de l’alimentation électrique fournie par la SONABEL",
  //   buttonText: "Faire ma demande",
  // },
  // {
  //   id:'7',
  //   icon: etalonnage,
  //   alt: "Etalonnage de compteur",
  //   title: "Etalonnage de compteur",
  //   description:
  //     "Il consiste à vérifier l’exactitude des mesures de comptage contre paiement de frais à la charge du client",
  //   buttonText: "Faire ma demande",
  // },
  /* {
    id:'8',
    icon: Provisoire1,
    alt: "Branchement provisoire",
    title: "Branchement provisoire",
    description:
      "Le branchement consiste à relier votre domicile ou entreprise au réseau électrique de la sonabel ",
    buttonText: "Faire ma demande",
  }, */
  {
    id:'9',
    icon: index,
    alt: "Envoi d'index",
    title: "Envoi d'index",
    description: "Envoyer facilement votre dernier index",
    buttonText: "Envoyer mon index",
    url: '/saisindex',
  },

];

export default ServiceOnline;
