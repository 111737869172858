const  TypePiece = [
    {id: 1,name: "Carte Nationale D'identité"},
    {id: 2,name:  "Passport"},
    {id: 3,name: "Carte Consulaire"},
    {id: 4,name: "Carte de séjour" },
    {id: 5,name: "Permis de conduire"},
    {id:6, name: "Autres"},

];

export default  TypePiece;