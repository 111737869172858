import {Link, useLocation, useNavigate} from "react-router-dom";
import NavbarSidebarLayout from "../../components/Dashboard/Layouts/NavbarSidebar";
import dayjs from "dayjs";
import StatusBadge from "../../components/StatusBadge";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {api, apiUrl} from "../../config/config";
import {Button} from "@mui/material";
import '../../App.css';
import {toast} from "react-toastify";

const Details = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { demande } = location.state || {};
    const data = demande;

    // Modifier une demande en mode mobile
    const handleEditMobileRequest = (id, service) => {
        if (service.includes("Demande de modification d’ampérage")) {
            navigate("/Modifier-modificationamperage", { state: { amperageId: id } });
        } else if (service.includes("Etalonnage de compteur")) {
            navigate("/Modifier-etalonnage-compteur", {
                state: { etalonnageId: id },
            });
        } else if (service.includes("Résiliation personne morale")) {
            navigate("/Modifier-resiliationmorale", {
                state: { resiliationMoraleId: id },
            });
        } else if (service.includes("Résiliation personne physique")) {
            navigate("/Modifier-resiliationphysique", {
                state: { resiliationPhysiqueId: id },
            });
        } else if (service.includes("Branchement sans abonnement")) {
            navigate("/Modifier-branchement-sans-abonnement", {
                state: { branchementSansAbonId: id },
            });
        } else if (service.includes("Branchement avec abonnement")) {
            navigate("/Modifier branchement avec abonnement", {
                state: { branchementAbonId: id },
            });
        } else if (service.includes("Demande de travaux divers")) {
            navigate("/Modifier-Demande-de-travaux-divers", {
                state: { travauxDiversId: id },
            });
        } else if (service.includes("Remplacement disjoncteur")) {
            navigate("/Modifier-remplacement-disjoncteur", {
                state: { disjoncteurId: id },
            });
        } else if (service.includes("Supervision génie civil")) {
            navigate("/Modification-Supervision-genie-civil", {
                state: { supervisionGenieCivilId: id },
            });
        } else if (service.includes("Supervision des travaux")) {
            navigate("/Modifier-Supervision-travaux", {
                state: { supervisionTravauxId: id },
            });
        } else if (service.includes("Branchement provisoire")) {
            navigate("/modifier-un-branchement-provisoire", {
                state: { branchement_provisoire_id: id },
            });
        } else if (service.includes("Envoi d'index")) {
            navigate("/Modifier-votre-index", { state: { indexId: id } });
        }
        else if (service.includes("Abonnement")) {
            navigate("/Modifier-votre-abonnement", { state: { abonnementId: id } });
        }
        else {
            toast.info("Ce service n'est pas encore disponible");
        }
    };

    // Procéder au paiement
    const handlePayment = async (cli_num, type_service, demandeId) => {
        if (cli_num) {
            try {
                api
                    .get(`devis/client/${cli_num}`)
                    .then((response) => {
                        if (response.status === 200) {
                            const devis = response.data.content;
                            if (devis["DEVIS_CODE#"]) {
                                localStorage.setItem("demandeId", demandeId);
                                localStorage.setItem(
                                    "devisId",
                                    response.data.content["DEVIS_CODE#"]
                                );
                                localStorage.setItem(
                                    "montant",
                                    response.data.content.DEVIS_MNT_A_PAYE_TTC
                                );
                                localStorage.setItem("type_service", type_service);

                                navigate("/payement");
                            }
                        }
                    })
                    .catch(() => {});
            } catch (e) {}
        }
        localStorage.setItem("demandeId", type_service);
    };

    return (
        <NavbarSidebarLayout>
            <div className="container mx-auto p-4">
                <h1 className="text-3xl font-bold mb-4">Détail de la demande</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">

                    <div className="bg-white shadow-md rounded-lg overflow-hidden">
                        <div className="p-4">
                            <h2 className="text-xl font-bold mb-2 text-green-700">{data.type_service}</h2>
                            <div>
                                <p className="text-gray-700 mb-4">
                                    {" "}
                                    {data.type_service}
                                </p>
                                <hr></hr>
                                <div className="mb-2 space-x-2">
                                    <p>
                                          <span className="font-medium">
                                            Date de demande :{" "}
                                          </span>{" "}
                                        <span>
                                                {dayjs(data.date_demande).format(
                                                    "DD/MM/YYYY"
                                                )}
                                            </span>
                                    </p>
                                    <hr></hr>
                                </div>
                                {data?.nom && data?.prenom && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">
                                              Info sur le demandeur:
                                            </span>
                                            {data?.nom} {data?.prenom}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}
                                {data?.piece && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">
                                              Type de Piece:{" "}
                                            </span>
                                            {data?.piece}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}
                                {data?.numero_cnib && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">
                                              Numero piece:{" "}
                                            </span>
                                            {data?.numero_cnib}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}
                                {data?.date_etablissement && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">
                                              Date Etablissement Piece:
                                            </span>
                                            {dayjs(data?.date_etablissement).format(
                                                "DD/MM/YYYY"
                                            )}{" "}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}

                                {data?.lieu_etablissement && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">
                                              Lieu Piece:{" "}
                                            </span>
                                            {data?.lieu_etablissement}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}
                                {data?.motif && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">Motif:</span>
                                            {data?.motif}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}
                                {data?.prop_nom && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">
                                              Nom du propriétaire de la parcelle:
                                            </span>
                                            {data?.prop_nom}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}

                                {data?.prop_prenom && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">
                                              Prenom du propriétaire de la parcelle:
                                            </span>
                                            {data?.prop_prenom}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}
                                {data?.td && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">
                                                Type de travaux divers:
                                            </span>
                                            {data.td}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}
                                {data?.section && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">Section:</span>
                                            {data?.section}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}
                                {data?.lot && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">Lot : </span>
                                            {data?.lot}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}

                                {data?.parcelle && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">Parcelle: </span>
                                            {data?.parcelle}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}
                                {data?.rang && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">Rang: </span>
                                            {data?.rang}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}

                                {data?.email && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">
                                              Email du demandeur:{" "}
                                            </span>
                                            {data?.email}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}

                                {data?.nom_entreprise && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">
                                              Nom de l'entreprise:{" "}
                                            </span>
                                            {data?.nom_entreprise}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}
                                {data?.numero_ifu && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">
                                              Numero IFU de l'entreprise:
                                            </span>
                                            {data?.numero_ifu}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}
                                {data?.numero_rccm && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">
                                              RCCM de l'entreprise:
                                            </span>
                                            {data?.numero_rccm}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}

                                {data?.tel1_whatsapp && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">WhatsApp: </span>
                                            {data?.tel1_whatsapp}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}

                                {data?.tel2 && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">
                                              Telephone:{" "}
                                            </span>
                                            {data?.tel2}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}
                                {data?.ville_id && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">
                                              Ville de la demande:{" "}
                                            </span>
                                            {data?.ville_id}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}

                                {data?.type_amperage && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">
                                              Type d'amperage:{" "}
                                            </span>
                                            {data?.type_amperage}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}
                                {data?.puissance && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">
                                              Ampérage souhaité:{" "}
                                            </span>
                                            {data?.puissance}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}

                                {data?.type_compteur && (
                                    <div className="mb-2">
                                        <p>
                                            <span className="font-medium mr-2">
                                              Type compteur:{" "}
                                            </span>
                                            {data?.type_compteur}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}

                                <div className=" bg-red-100 text-teal-950 mb-2">
                                    <p>
                                      <span className="font-medium">
                                        Etat de la demande:{" "}
                                      </span>
                                        <StatusBadge status={data?.status}/>
                                    </p>
                                    <hr></hr>
                                </div>

                                {data?.motif_rejet && (
                                    <div className=" bg-red-100 text-teal-950 mb-2 ">
                                        <p>
                                            <span className="font-medium mr-2">
                                              Motif du rejet:{" "}
                                            </span>
                                            {data?.motif_rejet}
                                        </p>
                                        <hr></hr>
                                    </div>
                                )}
                            </div>
                            {
                                (data.status === 4) && (
                                    <div className="mt-4 text-center">
                                        <div className="text-center text-xl font-bold">Télécharger Reçu</div>
                                        <div className="mt-4">
                                            <a
                                                href={`${apiUrl}/demande/facture/${demande.id}`} download
                                                className="text-indigo-500 hover:underline"
                                            >
                                                <FontAwesomeIcon icon={faFilePdf} size={"2xl"} color='#f44336'/>
                                            </a>
                                        </div>
                                    </div>

                                )
                            }

                            {
                                (data.status === 3) && (
                                    <div className="mt-4 text-center">
                                        <div className="text-center text-xl font-bold">Télécharger Dévis</div>
                                        <div className="mt-4">
                                            <a
                                                href={`${apiUrl}/demande/devis/${demande.id}`} download
                                                className="text-indigo-500 hover:underline"
                                            >
                                                <FontAwesomeIcon icon={faFilePdf} size={"2xl"} color='#f44336'/>
                                            </a>
                                        </div>
                                        <div className="mt-4">
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="success"
                                                onClick={() => handlePayment(data.cli_num, data.type_service, data.id)}
                                            >
                                                Procéder au Paiement
                                            </Button>
                                        </div>
                                    </div>

                                )
                            }
                            <div className={`mt-4`}>
                                {
                                    (data.status === 0 ) && (
                                        <>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="error"
                                                onClick={() => handleEditMobileRequest(data.id, data.type_service)}
                                            >
                                                Modifier
                                            </Button>
                                        </>
                                    )
                                }
                            </div>
                            <div className="text-end mt-4">
                                <Link to="/dashboard" className="button-link">
                                    Retour
                                </Link>
                            </div>

                            {/*<a href="URL_DE_L'ARTICLE" className="text-indigo-500 hover:underline">Lire la suite</a>*/}
                        </div>
                    </div>

                </div>
            </div>
        </NavbarSidebarLayout>
    )
}
export default Details;
