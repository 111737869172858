import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import {
    Button,
    Stepper,
    Step,
    StepLabel,
    Typography,
    TextField, CircularProgress,
} from "@mui/material";
import NavbarSidebarLayout from "../../../components/Dashboard/Layouts/NavbarSidebar";
import { api } from "../../../config/config";
import {Menu} from "../../../components/Menu/Menu";



const EditEtalonnageCompteur = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();

    const [activeStep, setActiveStep] = useState(0);

    const [formData, setFormData] = useState({});

    const [error, setError] = useState({});

    // deactiver le bouton submit lorsqu'on actionne le bouton soumettre
    const [stateSend, setStateSend] = useState(false);
    const [isLoadingClient, setIsLoadingClient] = useState(false);


    const steps = [
        "Mes informations",
        "Motif de la demande",
        "Validation"
    ];

    useEffect(()=>{

        setIsLoadingClient(true);

        // Chercher une demande
        try {
            api.get(`/demande/${location.state.etalonnageId}`).then(response => {
                if (response.status === 200){
                    setFormData(response.data.content);
                    setIsLoadingClient(false);
                }
            }).catch(() => {
                setIsLoadingClient(false);
                toast.warning("Erreur serveur.");
            })
        }catch (e) {

        }
    }, [location.state.etalonnageId]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setStateSend(false);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChange = (field, value) => {
        if(value === "PREPAYE" || value === 'POSTPAYE') {

        }
        setFormData((prevData) => ({ ...prevData, [field]: value }));
    };

    const handleFieldFocus = (field) => {
        setError((prevError) => ({ ...prevError, [field]: "" }));
    };

    const handleDemande = () => {
        if (formData.motif.trim()) {
            setIsLoading(true);

            // On retire les champs qui n'ont pas été soumis pour validation
            const filteredDataEtalonnage = Object.fromEntries(
                Object.entries(formData).filter(([key, value]) => value !== null)
            );

            // Soumettre la demande du client
            try {
                api
                    .put("/demande", filteredDataEtalonnage)
                    .then((response) => {
                        if (response.status === 201) {
                            setFormData({
                                exploitation: "",
                                puissance: "3A",
                                compteur: "",
                                motif: "",
                                police:""
                            });
                            toast.success(
                                "Demande modifiée avec succès."
                            );
                            setIsLoading(false);
                            setStateSend(true); // désactiver le bouton
                            setTimeout(()=> {
                                navigate("/dashboard");
                            },3000);
                        }
                    })
                    .catch(() => {
                        toast.error("Demande non modifier.");
                        setIsLoading(false);
                    });
                // setErrorMessage("");
            } catch (erreur) {
            }
        } else {
            toast.error("Vueillez saisir le motif de la demande");
            setError((prevError) => ({ ...prevError, motif: "Vueillez saisir le motif de la demande" }));

        }
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
                        <>
                            {isLoadingClient && <CircularProgress/>}
                            <div className="flex  flex-row space-x-8 ">
                                <TextField
                                    label="Numero compteur"
                                    fullWidth
                                    size="small"
                                    value={formData.numero_compteur || ''}
                                    InputLabelProps={{shrink: true}}
                                    disabled={true}
                                />
                                <TextField
                                    label="Numero de police"
                                    fullWidth
                                    size="small"
                                    value={formData.numero_police || ''}
                                    InputLabelProps={{shrink: true}}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex  flex-row space-x-8 ">
                                <TextField
                                    label="Nom"
                                    fullWidth
                                    size="small"
                                    value={formData.nom || ''}
                                    InputLabelProps={{shrink: true}}
                                    disabled={true}
                                />
                                <TextField
                                    label="Prenom"
                                    fullWidth
                                    size="small"
                                    value={formData.prenom || ''}
                                    InputLabelProps={{shrink: true}}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex  flex-row space-x-8 ">
                                <TextField
                                    label="Email"
                                    fullWidth
                                    size="small"
                                    value={formData.email || ''}
                                    InputLabelProps={{shrink: true}}
                                    disabled={true}
                                />
                                <TextField
                                    label="Telephone"
                                    fullWidth
                                    size="small"
                                    value={formData.tel2 || ''}
                                    InputLabelProps={{shrink: true}}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex  flex-row space-x-8 ">
                                <TextField
                                    label="Section"
                                    fullWidth
                                    size="small"
                                    value={formData.section || ''}
                                    InputLabelProps={{shrink: true}}
                                    disabled={true}
                                />

                                <TextField
                                    label="lot"
                                    fullWidth
                                    size="small"
                                    value={formData.lot || ''}
                                    InputLabelProps={{shrink: true}}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex  flex-row space-x-8 ">
                                <TextField
                                    label="Parcelle"
                                    fullWidth
                                    size="small"
                                    value={formData.parcelle || ''}
                                    InputLabelProps={{shrink: true}}
                                    disabled={true}
                                />
                                <TextField
                                    label="Rang"
                                    fullWidth
                                    size="small"
                                    value={formData.rang || ''}
                                    InputLabelProps={{shrink: true}}
                                    disabled={true}
                                />
                            </div>
                        </>
                    </div>
                );
            case 1:
                return (
                    <div className=" space-y-5 mt-8 mb-8">
                        <TextField
                            label="Saisir le motif de la demande"
                            fullWidth
                            size="small"
                            value={formData.motif }
                            onChange={(e) => handleChange("motif", e.target.value)}
                            InputLabelProps={{shrink: true}}
                            onFocus={() => handleFieldFocus("motif")}
                            error={!!error.motif}
                            helperText={error.motif}
                        />
                    </div>
                );
            case 2:
                return (
                    <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8 dark:text-white">
                        <Typography
                            className="text-center mb-8 dark:text-white"
                            variant="h5"
                        >
                            Voulez-vous vraiment modifier votre demande ?
                        </Typography>
                    </div>
                );

            default:
                return "Unknown step";
        }
    };

    return (

        <NavbarSidebarLayout>
            <div
                className="block items-center justify-center border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
                <div className="mb-1 w-full">
                    <Menu titre="Modification de demande d'étalonnage de compteur"/>
                    <div className={`my-8`}>
                        <div className="text-center  text-3xl mb-8">
                            Modification de la demande d'etalonnage de compteur
                        </div>

                        <div
                            className="flex w-full flex-col gap-y-6 lg:flex-row lg:justify-center lg:gap-y-0 md:flex-row md:justify-center md:gap-y-0">
                            <div
                                className=" rounded-lg bg-white p-2 shadow-2xl dark:bg-gray-800  xl:p-8 lg:w-1/2 border-2 lg:p-5">
                                <div className="invisible md:visible">
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{/*label*/}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                                <div>
                                    <Typography className="text-center mb-8" variant="h5">
                                        {steps[activeStep]}
                                    </Typography>
                                    {getStepContent(activeStep)}

                                    <div className="flex flex-row justify-between pt-18">
                                        <Button
                                            color="success"
                                            variant="outlined"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            sx={{mr: 1}}
                                        >
                                            Précédent
                                        </Button>

                                        <div className="flex 1 1 auto"/>
                                        {activeStep === steps.length - 1 && (
                                            <>
                                                <Button
                                                    color="secondary"
                                                    variant="outlined"
                                                    onClick={handleDemande}
                                                    sx={{mr: 1}}
                                                    disabled={stateSend}
                                                >
                                                    {isLoading ?
                                                        <>
                                                            <CircularProgress color="primary" size="1rem" /> En
                                                            cours ...
                                                        </>
                                                        :
                                                        <>
                                                            Modifier
                                                        </>
                                                    }
                                                </Button>
                                            </>
                                        )}
                                        {activeStep !== steps.length - 1 && (
                                            <Button
                                                onClick={handleNext}
                                                variant="contained"
                                                color="success"
                                            >
                                                Suivant
                                            </Button>
                                        )}
                                    </div>
                                    <ToastContainer/>
                                    {stateSend && (
                                        <div className="bg-green-100 mt-12">
                                            {" "}
                                            <Link to="/dashboard"> Voir mes demandes</Link>{" "}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </NavbarSidebarLayout>

    );
// );
};

export default EditEtalonnageCompteur;
