import React, {useEffect} from "react";
import TextField from "@mui/material/TextField";
import {Alert, Button, CircularProgress, Snackbar} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';

export function OTPForm(props) {
    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    useEffect(() => {
        if (props.errors){
            setOpen(true);
        }
        if(props.success){
            setOpen(true)
        }
    }, [props.errors, props.success]);

    return(
        <>
            <div
                className="flex flex-col items-center justify-center mx-auto mt-8"
            >
                <div className="container mx-auto">
                    <div className={`flex items-stretch mt-3`}>
                        <div className={`sm:flex-initial sm:w-1/4`}></div>
                        <div className={`sm:flex-initial sm:w-2/4 w-full text-justify`}>
                            <p className="italic text-red-600 text-justify">
                                Entrez le code OTP que vous avez reçu en message pour finaliser la transaction.
                            </p>
                        </div>
                        <div className={`sm:flex-initial sm:w-1/4`}></div>
                    </div>
                </div>
                {/*Alert message afficher ici*/}
                <div className="container mx-auto">
                    <div className={`flex items-stretch justify-center mt-3`}>
                        <div>
                            {/*Message d'erreur*/}
                            {(props.errors) && (
                                <>
                                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                        <Alert
                                            onClose={handleClose}
                                            severity="error"
                                            variant="filled"
                                            sx={{width: '100%'}}
                                        >
                                            {props.errors}
                                        </Alert>
                                    </Snackbar>
                                </>
                            )}

                            {/*Message de succès*/}
                            {(props.success) && (
                                <>
                                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                        <Alert
                                            onClose={handleClose}
                                            severity="success"
                                            variant="filled"
                                            sx={{width: '100%'}}
                                        >
                                            {props.success}
                                        </Alert>
                                    </Snackbar>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className={`flex flex-col sm:flex-row justify-center mt-8`}>
                    <div className="w-full sm:w-[540px]">
                        <TextField
                            id="code"
                            label="Entrez votre code OTP"
                            fullWidth
                            value={props.codeOtp.otp}
                            onChange={(e) => {
                                props.onHandleChangeOTP("otp", e)
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}

                        />
                    </div>
                </div>
                <div className="grid grid-cols-3 sm:gap-8 md:gap-12 mt-4 mx-auto">
                    <div className="hidden md:block w-full"></div>
                    <div className="w-full"></div>
                    {/*Button envoyer pour initier le payement*/}
                    <div className="w-full">
                        <Button variant="outlined" color="secondary" size="large" endIcon={<SendIcon/>}
                                onClick={props.handleSendOTP} disabled={props.disableBtn}>
                            {props.isLoading && (
                                <CircularProgress color="secondary" size="1rem" />
                            )}
                            Envoyer
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );

}
