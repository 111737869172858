import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import SonabelLogo from "../../../../assets/Sonabel_logo.svg.jpg";
import Button from "@mui/material/Button";
import {api} from "../../../../config/config";
import {toast, ToastContainer} from "react-toastify";
import {CircularProgress} from "@mui/material";


const AddCodeReset = () => {

    const [code, setCode] = useState('');

    // Initiation de la navigation
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmitAddCode = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true)
            await api.post('account/lost-password', {
                step: "second",
                email: localStorage.getItem("resetEmail"),
                code: code
            }).then(response => {
                if (response.status === 200){
                    setIsLoading(false);
                    localStorage.setItem("code", code);
                    setCode(code);
                    navigate("/reset");
                }
            }).catch(error => {
                setIsLoading(false);
                if (!error?.response) {
                    toast.warning('Pas de réponse du serveur');
                    // setErrMsg('Pas de réponse du serveur');
                } else if (error.response?.status === 401) {
                    toast.warning(error.response.data.title);
                } else {
                    toast.warning('Le processus de réinitialisation du mot de passe a échoué. Veuillez réessayer.');
                }
            });
        }catch (err) {
            if (!err?.response) {
                toast.warning('Pas de réponse du serveur');
                // setErrMsg('Pas de réponse du serveur');
            } else if (err.response?.status === 401) {

            } else {
                toast.warning('Le processus de réinitialisation du mot de passe a échoué. Veuillez réessayer.');
            }
        }
    };

    return(
        <>
            <ToastContainer/>
            <div
                className="flex flex-col items-center justify-center px-6 pt-8 mx-auto md:h-screen pt:mt-0 dark:bg-gray-900">
                <Link to="/"
                      className="flex items-center justify-center mb-8 text-2xl font-semibold lg:mb-10 dark:text-white">
                    <img src={SonabelLogo} className="mr-4 h-11" alt="FlowBite Logo"/>
                    <span>SONABEL</span>
                </Link>
                {/*// <!-- Card -->*/}
                <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
                    <div className="w-full p-6 sm:p-8">
                        <h3 className="mb-3 text-2xl font-bold text-gray-400 text-center">
                            Etape 2
                        </h3>
                        <h2 className="mb-3 text-2xl font-bold text-gray-900 text-center">
                            Saisissez votre code de réinitialisation !
                        </h2>
                        <p className="text-base font-normal text-gray-500 text-justify">
                            Nous venons de vous envoyer un code par email pour réinitialiser votre mot de passe. Veuillez consulter votre boîte de réception !
                        </p>
                        <form className="mt-8 space-y-6">
                            {/*code email*/}
                            <div>
                                <label htmlFor="code" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Code
                                </label>
                                <input
                                    type="text"
                                    name="code"
                                    id="code"
                                    onChange={(e) => setCode(e.target.value)}
                                    value={code}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                    placeholder="Votre code de réinitialisation"
                                    required
                                />
                            </div>
                            <div className={`flex flex-row justify-between`}>
                                <div className=" justify-start">
                                    <Button
                                        className="w-full px-5 py-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                                        <Link to="/reset_password">Retour</Link>
                                    </Button>
                                </div>
                                <div className="justify-end">
                                    <Button type="submit"
                                            className="w-full px-5 py-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                            onClick={handleSubmitAddCode}
                                    >
                                        {isLoading ?
                                            <>
                                                <CircularProgress color="primary" size="1rem" /> En cours ...
                                            </>
                                            :
                                            <>
                                                Soumettre
                                            </>
                                        }
                                    </Button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

        </>
    );
}
export default AddCodeReset;
