import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

const ModalConfirm = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Voulez-vous vraiment supprimer cette demande ?</DialogTitle>
      <DialogContent>
        {/* Ajoutez du contenu supplémentaire ici si nécessaire */}
      </DialogContent>
      <DialogActions>
        <Button
         size="small"
         variant="outlined"
        
          onClick={onClose}
         
        >
          Annuler
        </Button>
        <Button
          size="small"
          variant="contained"
          color="error"
          onClick={onConfirm}
         
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirm;
