import {Breadcrumb} from "flowbite-react";
import {HiHome} from "react-icons/hi";
import React from "react";

export function Menu(props) {
    return(
        <>
            <div className="mb-4">
                <Breadcrumb className="mb-4">
                    <Breadcrumb.Item href="/dashboard">
                        <div className="flex items-center gap-x-3">
                            <HiHome className="text-xl"/>
                            <span className="dark:text-white">Accueil</span>
                        </div>
                    </Breadcrumb.Item>
                    {/*<Breadcrumb.Item href="/list-des-demandes">Liste des services </Breadcrumb.Item>*/}
                    <Breadcrumb.Item>{props.titre}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
        </>
    );
}