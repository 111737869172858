import React from "react";
import MainNavigation from "../../components/NavBar/MainNavigation";
import FooterComponent from "../../components/Footer/FooterComponent";
import { Container} from "@mui/material";
import ImageInfo from "../../assets/capture.png";

import VerticalTabs from "./components/VerticalTabs";

const InfosUtile = () => {
  return (
    <div className="flex flex-col gap-4 min-h-screen">
       <MainNavigation />
      <div
        className="bg-cover  h-[320px] mt-16  mb-2 relative"
        style={{ backgroundImage: `url(${ImageInfo})` }}
      >
        <h1 className="text-3xl font-black ml-2 text-white absolute bottom-0">INFOS UTILES </h1>

      </div>  
    
    
      <Container  maxWidth="xl" className="flex-grow">
          <VerticalTabs />
      </Container>

      <FooterComponent className="flex-shrink-0" />
    </div>
  );
};

export default InfosUtile;
