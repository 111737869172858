import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import {Typography} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import React from "react";

export function Branchement() {
    return(
        <>
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <div className="text-red-700 text-xl font-bold">BRANCHEMENT</div>
                </AccordionSummary>
                <AccordionDetails>
                    {/* Changed Typography to render as a div to prevent <p> nesting issue */}
                    <Typography variant="body1" component="div">
                        <div className="text-lg mb-4">
                            Le branchement consiste à raccorder le client au réseau électrique de la SONABEL en basse tension ou en haute tension.
                        </div>
                        {/* <ol> is now validly nested within a <div> */}
                        <ol className="list-decimal pl-4 space-y-4">
                            <li>
                                <strong>Conditions à remplir :</strong> Le bâtiment à alimenter doit être dans une zone couverte par le réseau électrique et disposer d’un emplacement approprié pour abriter le branchement.
                            </li>
                            <li>
                                <strong>Documents à fournir :</strong>
                                {/* Ensure that Typography here renders as span or div */}
                                <Typography component="div">
                                    Copie d’un document d’identité en cours de validité et une copie d’un des documents d’identification du lieu à desservir :
                                </Typography>
                                <ul className="list-disc ml-4">
                                    <li>Titre foncier</li>
                                    <li>Permis urbain d’habiter / permis d’exploiter</li>
                                    <li>Attestation d’attribution de parcelle</li>
                                    <li>Fiche d’attribution de parcelle</li>
                                    <li>Autorisation de la mairie pour les zones non loties et domaines publics</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Délai :</strong>
                                <ul className="list-disc ml-4 text-red-700 font-bold">
                                    <li>Sept (7) jours pour l’établissement des devis basse tension</li>
                                    <li>Huit (8) jours pour la réalisation des branchements sans extension</li>
                                    <li>Vingt-et-un (21) jours pour les branchements avec extension</li>
                                    <li>Vingt-et-un (21) jours pour les devis HTA</li>
                                    <li>Quatre-vingt-dix (90) jours pour la réalisation des travaux HTA</li>
                                </ul>
                            </li>
                        </ol>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
    );
}