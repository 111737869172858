import React from "react";
import { HiInformationCircle } from "react-icons/hi";
import { Alert } from "flowbite-react";

const ServerStatus = () => {
  return (
    <Alert color="failure" icon={HiInformationCircle}>
      <span className="font-medium">Info alert!</span> Problème de connexion.
      Veuillez réactualiser la page.
    </Alert>
  );
};

export default ServerStatus;
