import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {api} from "../../../config/config";
import {toast, ToastContainer} from "react-toastify";
import NavbarSidebarLayout from "../../../components/Dashboard/Layouts/NavbarSidebar";
import {Breadcrumb} from "flowbite-react";
import {HiHome} from "react-icons/hi";
import {MuiTelInput} from "mui-tel-input";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const UserProfile = () => {

    const [user, setUser] = useState({});
    const [newPassword, setNewPassword] = useState("");
    const [newPhone, setNewPhone] = useState("+226");
    const [matchPwd, setMatchPwd] = useState("");


    const [validEmail, setValidEmail] = useState(false);
    const [validPassword, setValidPassword] = useState(false);
    const [validMatch, setValidMatch] = useState(false);

    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [matchError, setMatchError] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        api.get(`/account/${localStorage.getItem('userId')}`).then((response) => {
            if (response.status === 200) {
                setNewPhone(response.data.content.phoneNumber);
                setUser(response.data.content || {});
            }
        }).catch(() => {

        })
    }, []);

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(user.email));
        setEmailError(EMAIL_REGEX.test(user.email) ? "" : "Email invalide");
    }, [user.email]);

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(newPassword));
        setPasswordError(
            PWD_REGEX.test(newPassword) ? "" : "Le mot de passe doit contenir 8 caratères au minimun. " +
                "Doit comprendre des lettres majuscules et minuscules, un chiffre et un caractère spécial."
        );
        setError("Le mot de passe doit contenir 8 caratères au minimun. Doit comprendre des lettres majuscules et minuscules, un chiffre et un caractère spécial.");
        setValidMatch(newPassword === matchPwd);
        setMatchError(
            newPassword === matchPwd ? "" : "Les mots de passe ne correspondent pas"
        );
        setError("Les mots de passe ne correspondent pas");
    }, [newPassword, matchPwd]);

    const handleChange = (field, value) => {
        setUser((prevData) => ({
            ...prevData,
            [field]: value.target.value,
        }));
    }
    const handleChangePassword = (value) => {
        setNewPassword(value);
    }
    const handleChangeMatchPassword = (value) => {
        setMatchPwd(value);
    }

    // Contrôler le changement d'état de l'input telephone
    const handleChangePhone = (value) => {
        setNewPhone(value);
        setUser((prevData) => ({ ...prevData, phoneNumber: newPhone }));
    };

    const handleSubmitGeneInformation = async (e) => {
        e.preventDefault();
        if (validEmail){
            try{
                await api.put(`/account`, {
                    email: user.email,
                    fullName: user.fullName,
                    phoneNumber: newPhone,
                    accountId: user.id
                }).then(response => {
                    if (response.status === 201){
                        //On lui affiche un message pour le notifier du succès
                        toast.success(
                            "Vous venez de modifier les informations de votre profil.");
                    }
                }).catch(error => {
                    //s'il existe une erreur afficher le message d'erreur
                    if (error.response){
                        toast.warning(error.response.data.error);
                    }
                });
            }catch (error) {
            }
        }

    };
    const handleSubmitPassword = async (e) => {
        e.preventDefault();
        if (validPassword && validMatch) {
            try{
                await api.put(`/account`, {
                    email: user.email,
                    fullName: user.fullName,
                    phoneNumber: user.phoneNumber,
                    password: newPassword,
                    accountId: user.id
                }).then(response => {
                    if (response.status === 201){
                        //On lui affiche un message pour le notifier du succès
                        toast.success(
                            "Vous venez de modifier les informations de votre profil.");

                    }
                }).catch(error => {
                    //s'il existe une erreur afficher le message d'erreur
                    if (error.response){
                        toast.warning("Une erreur est survenue. Veillez réesseyer.");
                    }
                });
            }catch (error) {
            }
        } else {
            toast.warning(error);
        }
    };

    return(
        <NavbarSidebarLayout>
            <ToastContainer/>
            <div
                className="block items-center justify-between border-b border-gray-200 bg-white p-4 ">
                <div className="mb-1 w-full">
                    <div className="mb-4">
                        <Breadcrumb className="mb-4">
                            <Breadcrumb.Item href="/dashboard">
                                <div className="flex items-center gap-x-3">
                                    <HiHome className="text-xl" />
                                    <span className="dark:text-white">Accueil</span>
                                </div>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href="#">Profile</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className="sm:flex">
                            <div className="grid grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-4 dark:bg-gray-900">
                                {/* Right Content */}
                                <div className="col-span-full xl:col-auto">
                                    <div
                                        className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                                        <div
                                            className="relative w-28 h-28 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                                            <svg className="absolute w-30 h-30 text-gray-400 -left-1"
                                                 fill="currentColor"
                                                 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd"
                                                      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                                      clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div
                                        className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                                        <h3 className="mb-4 text-xl font-semibold dark:text-white">
                                            Mettre à jour mes informations de base
                                        </h3>
                                        <form>
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-6 sm:col-span-3">
                                                    <label
                                                        htmlFor="fullName"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                        Nom complet
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="fullName"
                                                        id="fullName"
                                                        value={user.fullName || ''}
                                                        autoComplete="off"
                                                        onChange={(e) => handleChange('fullName',e)}
                                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                        placeholder="votre nom"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-span-6 sm:col-span-3">
                                                    <label
                                                        htmlFor="email"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                        Email
                                                    </label>
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        value={user.email || ''}
                                                        autoComplete="off"
                                                        onChange={(e) => handleChange('email',e)}
                                                        aria-invalid={validEmail ? "false" : "true"}
                                                        aria-describedby="emailnote"
                                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                        placeholder="Votre email"
                                                        required

                                                    />
                                                    {emailError && (
                                                        <p style={{color: "red", marginTop: "0.5rem"}}>{emailError}</p>
                                                    )}
                                                </div>
                                                <div className="col-span-6 sm:col-span-3">
                                                    <label
                                                        htmlFor="phoneNumber"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                        Numéro de téléphone
                                                    </label>
                                                    <MuiTelInput
                                                        fullWidth
                                                        size="small"
                                                        value={newPhone}
                                                        onChange={handleChangePhone}
                                                        placeholder="Votre numéro de téléphone"
                                                        required
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    />
                                                </div>
                                                <div className="flex justify-end col-span-6 sm:col-full ">
                                                    <Button
                                                        className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                                        onClick={handleSubmitGeneInformation}
                                                    >
                                                        Modifier
                                                    </Button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div
                                        className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                                        <h3 className="mb-4 text-xl font-semibold dark:text-white">
                                            Modifier le mot de passe
                                        </h3>
                                        <form method="post">
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-6 sm:col-span-3">
                                                    <label
                                                        htmlFor="password"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                        Nouveau mot de passe
                                                    </label>
                                                    <input
                                                        data-popover-target="popover-password"
                                                        data-popover-placement="bottom"
                                                        type="password"
                                                        id="password"
                                                        onChange={(e) => handleChangePassword(e.target.value)}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="••••••••"
                                                        required
                                                        value={newPassword || ''}
                                                    />
                                                    {passwordError && (
                                                        <p
                                                            className={`mt-2 text-sm text-red-600 dark:text-green-500`}
                                                        >
                                                            <span className="font-medium">{passwordError}</span>
                                                        </p>
                                                        )}
                                                    <div
                                                        data-popover=""
                                                        id="popover-password"
                                                        role="tooltip"
                                                        className="absolute z-10 invisible inline-block text-sm font-light text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400"
                                                    >
                                                        <div className="p-3 space-y-2">
                                                            <h3 className="font-semibold text-gray-900 dark:text-white">
                                                                Must have at least 6 characters
                                                            </h3>
                                                            <div className="grid grid-cols-4 gap-2">
                                                                <div className="h-1 bg-orange-300 dark:bg-orange-400"/>
                                                                <div className="h-1 bg-orange-300 dark:bg-orange-400"/>
                                                                <div className="h-1 bg-gray-200 dark:bg-gray-600"/>
                                                                <div className="h-1 bg-gray-200 dark:bg-gray-600"/>
                                                            </div>
                                                            <p>It’s better to have:</p>
                                                            <ul>
                                                                <li className="flex items-center mb-1">
                                                                    <svg
                                                                        className="w-4 h-4 mr-2 text-green-400 dark:text-green-500"
                                                                        aria-hidden="true"
                                                                        fill="currentColor"
                                                                        viewBox="0 0 20 20"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                            clipRule="evenodd"
                                                                        />
                                                                    </svg>
                                                                    Upper &amp; lower case letters
                                                                </li>
                                                                <li className="flex items-center mb-1">
                                                                    <svg
                                                                        className="w-4 h-4 mr-2 text-gray-300 dark:text-gray-400"
                                                                        aria-hidden="true"
                                                                        fill="currentColor"
                                                                        viewBox="0 0 20 20"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                                            clipRule="evenodd"
                                                                        />
                                                                    </svg>
                                                                    A symbol (#$&amp;)
                                                                </li>
                                                                <li className="flex items-center">
                                                                    <svg
                                                                        className="w-4 h-4 mr-2 text-gray-300 dark:text-gray-400"
                                                                        aria-hidden="true"
                                                                        fill="currentColor"
                                                                        viewBox="0 0 20 20"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                                            clipRule="evenodd"
                                                                        />
                                                                    </svg>
                                                                    A longer password (min. 12 chars.)
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div data-popper-arrow=""/>
                                                    </div>
                                                </div>
                                                <div className="col-span-6 sm:col-span-3">
                                                    <label
                                                        htmlFor="confirm-password"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                        Confirmer le mot de passe
                                                    </label>
                                                    <input
                                                        type="password"
                                                        name="confirm-password"
                                                        id="confirm-password"
                                                        onChange={(e) => handleChangeMatchPassword(e.target.value)}
                                                        aria-invalid={validPassword ? "false" : "true"}
                                                        aria-describedby="pwdnote"
                                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                        placeholder="••••••••"
                                                        required
                                                        value={matchPwd || ''}
                                                    />
                                                    {matchError && (
                                                        <p
                                                            className={`mt-2 text-sm text-red-600 dark:text-red-500`}
                                                        >
                                                            <span className="font-medium">{matchError}</span>

                                                        </p>
                                                        )}
                                                </div>
                                                <div className="flex justify-end col-span-6 sm:col-full">
                                                    <Button
                                                        className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                                        type="submit"
                                                        onClick={handleSubmitPassword}
                                                    >
                                                        Modifier
                                                    </Button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </NavbarSidebarLayout>
    );
}
export default UserProfile;
