import React from 'react';
import {Production} from "./ActiviteMenu/Production";
import {Transport} from "./ActiviteMenu/Transport";
import {Distribution} from "./ActiviteMenu/Distribution";
import {Commercialisation} from "./ActiviteMenu/Commercialisation";
import {HeureOuverture} from "./ActiviteMenu/HeureOuverture";

const InfosGenerales = () => {
    return (
        <>
            <Production/>
            <Transport/>
            <Distribution/>
            <Commercialisation/>
            <HeureOuverture/>
        </>
    );
}

export default InfosGenerales;