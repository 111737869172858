import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { api, apiUrl } from "../../config/config";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NavbarSidebarLayout from "../../components/Dashboard/Layouts/NavbarSidebar";

import { format } from "date-fns";
import { CircularProgress } from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import { ToastContainer, toast } from "react-toastify";
import ModalConfirm from "../../components/demande/ModalConfirm";
import HeaderMessage from "../../components/HeaderMessage";
import DashboardMenu from "../../components/DashboardMenu";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import Statistique from "../../components/Dashboard/Statistique/Statistique";
import Box from "@mui/material/Box";
import StatusBadge from "../../components/StatusBadge";
// import '../../App.css';

const DashBoardPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [recharge, setRecharge] = useState(false);
  const [reload, setReload] = useState(10);
  const [showDialog, setShowDialog] = useState(false);
  const [demandes, setDemandes] = useState([]);
  const [tdLibelle, setTdLibelle] = useState({});

  // avoir la lignee selectionne
  const [selectedRow, setSelectedRow] = useState([]);

  const [selectedDetails, setSelectedDetails] = useState({});

  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);

  const handleCheckIfDevisExist = async (client) => {
    const ressource = `devis/client/${client}`;
    const response = await api.get(ressource);
    if (response.status === 200 && response.data.content != null) {
      return true;
    }
    return false;
  };

  const handleCheckIfFactureExist = async (client) => {
    const ressource = `recu/client/${client}`;
    const response = await api.get(ressource);
    if (response.status === 200) {
      return response.data.content != null ? 4 : 3;
    }
    return 3;
  };

  // Rechercher le TD via son libelle
  const handleChearchTD = (value) => {
    // Récupérer la liste des TD
    try {
      api
          .get(`/td/${value}`)
          .then((response) => {
            if (response.status === 200) {
              setTdLibelle(response.data.content);
            }
          })
          .catch(() => {});
    } catch (e) {}
  };

  const handleRecherchePV = async (client) => {
    try {
      const response = await api.get(`pv/client/${client}`);

      if (response.status === 200) {
        if (response.data.content !== undefined && response.data.content !== null) {
          return 5;
        } else {
          return 4;
        }
      } else {
        return 4;
      }
    } catch (error) {
      return 4;
    }
  };

  /**
   * Tracker les statuts
   * si statut en traitement(1)  verifier s'il y a un devis etablie
   * Si statut devis(3) verifier s'il y a une facture(4)
   * useCallback => mettre la fonction en memoire afin d'eviter sa creation au rendu prochain
   */
  const trackStatus = useCallback(async (status, client) => {
    try {
      if (status === 1) {
        if (client) {
          const devisExists = await handleCheckIfDevisExist(client);
          if (devisExists) {
            const factureStatus = await handleCheckIfFactureExist(client);
            if (factureStatus === 4) {
              await handleRecherchePV(client, factureStatus);
            }
            return factureStatus;
          }
          return 1;
        }
        return 1;
      } else if (status === -1) {
        return -1;
      }
      return 0;
    } catch (error) {
      // return 0;
    }
  }, []);

  // liste des demandes du user
  useEffect(() => {
    setRecharge(true);
    const userId = localStorage.getItem("userId");

    api
        .get(`/demande/user/${userId}`)
        .then((response) => {
          if (response.status === 200) {
            let nb = 0;

            const formattedDemandes = response.data.content.map(
                async (demande) => ({
                  nombre: ++nb,
                  id: demande?.id,
                  cli_num: demande?.cli_num,
                  type_service: demande?.type_service?.libelle,
                  date_demande: demande?.date_demande,
                  status: (demande?.type_service?.libelle === "Envoi d'index" && demande?.status === 1)
                      ? 10
                      : await trackStatus(
                          demande?.status,
                          demande?.cli_num,
                          demande?.nom,
                          demande?.prenom
                      ),
                  section: demande?.section,
                  lot: demande?.lot,
                  nom: demande?.nom,
                  prenom: demande?.prenom,
                  ville_id: demande?.agence?.agence,
                  village_id: demande?.localite?.localite,
                  parcelle: demande?.parcelle,
                  exploitation: demande?.exploitation,
                  email: demande?.email,
                  nom_entreprise: demande?.nom_entreprise,
                  dure: demande?.duree,
                  lieu_etablissement: demande?.lieu_etablissement,
                  nombre_tableau: demande?.nombre_tableau,
                  numero_cnib: demande?.numero_cnib,
                  numero_compteur: demande?.numero_compteur,
                  numero_police: demande?.numero_police,
                  numero_ifu: demande?.numero_ifu,
                  numero_piece: demande?.numero_piece,
                  date_etablissement: demande?.date_etablissement,
                  numero_rccm: demande?.numero_rccm,
                  motif: demande?.motif,
                  td: demande?.td,
                  piece: demande?.piece,
                  tel1_whatsapp: demande?.tel1_whatsapp,
                  tel2: demande?.tel2,
                  puissance: demande?.puissance,
                  type_amperage: demande?.type_amperage,
                  type_compteur: demande?.type_compteur,
                  objet: demande?.objet,
                  date_satisfaction: demande?.date_satisfaction,
                  rang: demande?.rang,
                  prop_nom: demande?.prop_nom,
                  prop_prenom: demande?.prop_prenom,
                  motif_rejet: demande?.motif_rejet,
                })
            );
            Promise.all(formattedDemandes).then((formattedDemandesResolved) => {
              setDemandes(formattedDemandesResolved);
              setRecharge(false);
            });
          }
        })
        .catch(() => {});
  }, [reload, trackStatus]);

  /**
   * Liste des demandes filtrées avec useMemo
   * useMemo permet de memoriser une valeur calculer
   */
  const filteredDemandes = useMemo(() => {
    return {
      demandeCours: demandes.filter((value) => value.status === 0),
      demandeTraite: demandes.filter((value) => value.status === 1),
      devis: demandes.filter((value) => value.status === 3),
      rejete: demandes.filter((value) => value.status === -1),
      dossier: demandes.filter((value) => value.status === 6),
      transmis: demandes.filter((value) => value.status === 10),
    };
  }, [demandes]);

  const QuickSearchToolbar = () => {
    return (
        <Box
            sx={{
              p: 0.5,
              pb: 0,
              textAlign: "right",
            }}
        >
          <GridToolbarQuickFilter/>
        </Box>
    );
  };

  const handleEditRequest = (id, service) => {
    if (service.includes("Demande de modification d’ampérage")) {
      navigate("/Modifier-modificationamperage", { state: { amperageId: id } });
    } else if (service.includes("Etalonnage de compteur")) {
      navigate("/Modifier-etalonnage-compteur", {
        state: { etalonnageId: id },
      });
    } else if (service.includes("Résiliation personne morale")) {
      navigate("/Modifier-resiliationmorale", {
        state: { resiliationMoraleId: id },
      });
    } else if (service.includes("Résiliation personne physique")) {
      navigate("/Modifier-resiliationphysique", {
        state: { resiliationPhysiqueId: id },
      });
    } else if (service.includes("Branchement sans abonnement")) {
      navigate("/Modifier-branchement-sans-abonnement", {
        state: { branchementSansAbonId: id },
      });
    } else if (service.includes("Branchement avec abonnement")) {
      navigate("/Modifier branchement avec abonnement", {
        state: { branchementAbonId: id },
      });
    } else if (service.includes("Demande de travaux divers")) {
      navigate("/Modifier-Demande-de-travaux-divers", {
        state: { travauxDiversId: id },
      });
    } else if (service.includes("Remplacement disjoncteur")) {
      navigate("/Modifier-remplacement-disjoncteur", {
        state: { disjoncteurId: id },
      });
    } else if (service.includes("Supervision génie civil")) {
      navigate("/Modification-Supervision-genie-civil", {
        state: { supervisionGenieCivilId: id },
      });
    } else if (service.includes("Supervision des travaux")) {
      navigate("/Modifier-Supervision-travaux", {
        state: { supervisionTravauxId: id },
      });
    } else if (service.includes("Branchement provisoire")) {
      navigate("/modifier-un-branchement-provisoire", {
        state: { branchement_provisoire_id: id },
      });
    } else if (service.includes("Envoi d'index")) {
      navigate("/Modifier-votre-index", { state: { indexId: id } });
    } else if (service.includes("Abonnement")) {
      navigate("/Modifier-votre-abonnement", { state: { abonnementId: id } });
    } else {
      toast.info("Ce service n'est pas encore disponible");
    }
  };

  const calculDevisDateExpiration = (date_devis) => {
    const date = new Date(date_devis);
    date.setMonth(date.getMonth() + 6);
    return date.toISOString();
  };

  // Les Colonnes du tableau
  const columns = [
    {
      field: "nombre",
      headerName: "Nº",
      width: 50,
    },
    {
      field: "type_service",
      headerName: "SERVICE",
      width: 300,
    },
    {
      field: "date_demande",
      headerName: "DATE",
      width: 200,
      renderCell: (params) => {
        // Utilisez la fonction format de date-fns pour formater la date
        return format(new Date(params.row.date_demande), "dd/MM/yyyy HH:mm:ss");
      },
    },
    {
      field: "status",
      headerName: "STATUT",
      width: 170,
      renderCell: (params) => <StatusBadge status={params.row.status} />,
    },
    {
      field: "Télecharger Dévis",
      headerName: "DEVIS",
      width: 100,
      renderCell: (params) => {
        const { status, id } = params.row;
        if (status === 3) {
          let devisLink = `${apiUrl}/demande/devis/${id}`;
          return (
              <a href={devisLink} download>
                <FontAwesomeIcon icon={faFilePdf} size={"2xl"} color="#43a047" />
              </a>
          );
        } else {
          return (
              <>
                <span></span>
              </>
          );
        }
      },
    },

    {
      field: "Télecharger REÇU",
      headerName: "REÇU",
      width: 100,
      renderCell: (params) => {
        const { status, id } = params.row;
        if (status === 4) {
          let factureLink = `${apiUrl}/demande/facture/${id}`;
          return (
              <a href={factureLink} download>
                <FontAwesomeIcon icon={faFilePdf} size={"2xl"} color="#f44336" />
              </a>
          );
        } else {
          return (
              <>
                <span></span>
              </>
          );
        }
      },
    },
    {
      field: "Payer Facture",
      headerName: "PAIEMENTS",
      width: 200,
      renderCell: (params) => {
        const { status, id, type_service, cli_num } = params.row;
        if (status === 3) {
          return (
              <Button
                  size="small"
                  variant="contained"
                  color="success"
                  onClick={() => handlePayment(cli_num, type_service, id)}
              >
                Procéder au Paiement
              </Button>
          );
        } else {
          return (
              <>
                <span></span>
              </>
          );
        }
      },
    },

    {
      field: "id",
      headerName: "ACTIONS",
      width: 150,
      renderCell: (params) => {
        const { status } = params.row;
        if (status === 3) {
          // Status 3 : Demande avec devis
          // let devisLink = `${apiUrl}/demande/devis/${id}`;
          return (
              <div className="space-x-4">
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    startIcon={<PreviewIcon />}
                    onClick={() => handleDetailClick(params.row)}
                >
                  Details
                </Button>
              </div>
          );
        } else if (status === 0 || status === -1) {
          // Status: 0 Demande en attente de traitement
          return (
              <div className="space-x-4">
                <DashboardMenu
                    onhandleDetailClick={() => handleDetailClick(params.row)}
                    onHandleEditRequest={() =>
                        handleEditRequest(params.row.id, params.row.type_service)
                    }
                    onHandleDeleteClick={() => handleDeleteClick(params.row.id)}
                />
              </div>
          );
        } else {
          // Autres statuts, afficher le bouton Details
          return (
              <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  startIcon={<PreviewIcon />}
                  onClick={() => handleDetailClick(params.row)}
              >
                Details
              </Button>
          );
        }
      },
    },
  ];

  const handleDeleteClick = (selectionModel) => {
    setShowDialog(true);
    setSelectedRow(selectionModel); // Enregistrez la ligne sélectionnée pour la confirmation
  };

  const handleDetailClick = (selectionModel) => {
    // params.row contient les détails de la ligne sélectionnée
    setSelectedDetails(selectionModel);
    if (selectionModel?.td) {
      handleChearchTD(selectionModel.td);
    }
    setOpenDetailsDialog(true);
  };

  // supprimer la demande
  const handleConfirmDelete = async () => {
    try {
      setIsLoading(true);
      await api.delete(`/demande/${selectedRow}`);
      toast.success("Votre demande a été supprimée.");
      setReload(Math.random() * 100);
    } catch (error) {
      toast.error("Erreur de suppression");
    } finally {
      setIsLoading(false);
      setShowDialog(false);
    }
  };

  // Telecharger la facture

  // Proceder au paiement
  const handlePayment = async (cli_num, type_service, demandeId) => {
    if (cli_num) {
      try {
        api
            .get(`devis/client/${cli_num}`)
            .then((response) => {
              if (response.status === 200) {
                const devis = response.data.content;
                const expireDate = calculDevisDateExpiration(devis.DEVIS_DATE);
                if (devis.DEVIS_DATE <= expireDate) {
                  if (devis["DEVIS_CODE#"]) {
                    localStorage.setItem("demandeId", demandeId);
                    localStorage.setItem(
                        "devisId",
                        response.data.content["DEVIS_CODE#"]
                    );
                    localStorage.setItem("type_service", type_service);
                    // const montantDevis = response.data.content.DEVIS_MNT_A_PAYE_TTC ;

                    navigate("/payement", {
                      state: {
                        montant: response.data.content.DEVIS_MNT_A_PAYE_TTC,
                        devisId: response.data.content["DEVIS_CODE#"],
                        demandeId: demandeId,
                      },
                    });
                  }
                } else {
                  toast.info("Votre devis a expiré");
                }
              }
            })
            .catch(() => {});
      } catch (e) {}
    }
    localStorage.setItem("demandeId", type_service);
  };

  return (
      <NavbarSidebarLayout>
        <div className="rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-6 xl:p-8">
          <div className="block items-center justify-center border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
            <div className="mb-1 w-full">
              <div className="mb-4">
                <ToastContainer />
                <div className="mx-2">
                  <HeaderMessage>
                    <p className="hidden sm:block break-words text-xl sm:text-2xl">
                      {" "}
                      Mes demandes{" "}
                    </p>
                    <Link to="/list-des-demandes">
                      <button className="bg-red-500 px-8 py-2 rounded-lg text-white hover:bg-red-700">
                        {" "}
                        <AddCircleOutlineIcon
                            sx={{ fontSize: "28" }}
                        ></AddCircleOutlineIcon>{" "}
                        Nouvelle demande{" "}
                      </button>{" "}
                    </Link>
                  </HeaderMessage>
                  <Statistique
                      demandes={demandes}
                      demandeCours={filteredDemandes.demandeCours}
                      demandeTraite={filteredDemandes.demandeTraite}
                      devis={filteredDemandes.devis}
                      rejete={filteredDemandes.rejete}
                      dossier={filteredDemandes.dossier}
                  />
                  <br />

                  <div className="hidden md:block justify-center mb-20">
                    <div>
                      <Box className="w-full rounded-lg overflow-hidden">
                        <div style={{ height: 400, width: "100%" }}>
                          <DataGrid
                              {...demandes}
                              columns={columns}
                              rows={demandes}
                              initialState={{
                                pagination: {
                                  paginationModel: { page: 0, pageSize: 10 },
                                },
                              }}
                              pageSizeOptions={[5, 10]}
                              slots={{
                                toolbar: QuickSearchToolbar,
                                noRowsOverlay: () => (
                                    <div
                                        style={{ padding: "5px", textAlign: "left" }}
                                    >
                                      Aucune donnée disponible
                                    </div>
                                ),
                              }}
                              loading={recharge}
                          />
                        </div>
                      </Box>
                    </div>
                    <div>
                      {isLoading && <CircularProgress />}

                      <ModalConfirm
                          open={showDialog}
                          onClose={() => setShowDialog(false)}
                          onConfirm={handleConfirmDelete}
                      />
                    </div>
                  </div>
                  {recharge ? (
                      <div className="block md:hidden text-center">
                        <CircularProgress color="success" size={60} />
                      </div>
                  ) : (
                      <div>
                        {demandes.map((demande, index) => (
                            <div
                                className={`block md:hidden max-w-sm mx-auto bg-white rounded-lg shadow-lg mt-2 overflow-hidden ${
                                    demande.status === 3 ? "border-2 border-red-300" : ""
                                } ${
                                    demande.status === 4
                                        ? "border-2 border-indigo-300"
                                        : ""
                                }`}
                                key={index}
                            >
                              <div className="p-6">
                                <h2 className="text-xl font-semibold text-gray-800 text-center">
                                  {demande?.type_service}
                                </h2>
                                <p className="text-gray-600 mt-2">
                                  <strong>Date et heure de création :</strong>{" "}
                                  {format(
                                      demande.date_demande,
                                      "dd/MM/yyyy HH:mm:ss"
                                  )}
                                </p>
                                <p className="text-gray-600 text-center mt-2">
                                  {demande.status === 0 && (
                                      <>
                                <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                                  En attente de traitement...
                                </span>
                                      </>
                                  )}
                                  {demande.status === 1 && (
                                      <>
                                <span className="bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300">
                                  En Traitement...
                                </span>
                                      </>
                                  )}

                                  {demande.status === 2 && (
                                      <>
                                <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                                  Validé
                                </span>
                                      </>
                                  )}

                                  {demande.status === 3 && (
                                      <>
                                <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                                  Dévis disponible
                                </span>
                                      </>
                                  )}
                                  {demande.status === 4 && (
                                      <>
                                <span className="bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300">
                                  Reçu disponible
                                </span>
                                      </>
                                  )}

                                  {demande.status === 6 && (
                                      <>
                                <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                                  Payé
                                </span>
                                      </>
                                  )}

                                  {demande.status === 10 && (
                                      <>
                                        <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                                          Transmis
                                        </span>
                                      </>
                                  )}

                                  {demande.status === 5 && (
                                      <>
                                        <span className="bg-green-700 text-green-50 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                                          Travaux clôturé.
                                        </span>
                                      </>
                                  )}
                                </p>
                                <div className="mt-4 text-center">
                                  <Link
                                      className="button-detail-link"
                                      to={`/details`}
                                      state={{ demande: demande }}
                                      key={index}
                                  >
                                    Voir détails
                                  </Link>
                                </div>
                              </div>
                            </div>
                        ))}
                      </div>
                  )}

                  {/* Le composant Dialog pour afficher les détails */}
                  <Dialog
                      open={openDetailsDialog}
                      onClose={() => setOpenDetailsDialog(false)}
                      //maxWidth= 'md'
                      fullWidth
                  >
                    <DialogTitle className="text-center font-bold uppercase">
                      Détails de la demande
                    </DialogTitle>
                    <DialogContent>
                      {selectedDetails && (
                          <div>
                            <p className="mb-4 font-medium  text-lg">
                              {" "}
                              {selectedDetails?.type_service}
                            </p>
                            <hr></hr>
                            <div className="mb-2 space-x-2">
                              <p>
                            <span className="font-medium">
                              Date de demande :{" "}
                            </span>{" "}
                                <span>
                              {dayjs(selectedDetails.date_demande).format(
                                  "DD/MM/YYYY"
                              )}
                            </span>
                              </p>
                              <hr></hr>
                            </div>
                            {
                                (selectedDetails?.exploitation) && (
                                    <div className="mb-2 space-x-2">
                                      <p>
                            <span className="font-medium">
                              Exploitation :{" "}
                            </span>{" "}
                                        <span>
                              {selectedDetails?.exploitation}
                            </span>
                                      </p>
                                      <hr></hr>
                                    </div>
                                )
                            }
                            {
                                (selectedDetails?.cd) && (
                                    <div className="mb-2 space-x-2">
                                      <p>
                            <span className="font-medium">
                              CD :{" "}
                            </span>{" "}
                                        <span>
                              {selectedDetails?.cd}
                            </span>
                                      </p>
                                      <hr></hr>
                                    </div>
                                )
                            }
                            {selectedDetails?.nom && selectedDetails?.prenom && (
                                <div className="mb-2">
                                  <p>
                              <span className="font-medium mr-2">
                                Info sur le demandeur:
                              </span>
                                    {selectedDetails?.nom} {selectedDetails?.prenom}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}
                            {selectedDetails?.piece && (
                                <div className="mb-2">
                                  <p>
                              <span className="font-medium mr-2">
                                Type de Piece:{" "}
                              </span>
                                    {selectedDetails?.piece}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}
                            {selectedDetails?.numero_cnib && (
                                <div className="mb-2">
                                  <p>
                              <span className="font-medium mr-2">
                                Numero piece:{" "}
                              </span>
                                    {selectedDetails?.numero_cnib}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}
                            {selectedDetails?.date_etablissement && (
                                <div className="mb-2">
                                  <p>
                              <span className="font-medium mr-2">
                                Date Etablissement Piece:
                              </span>
                                    {dayjs(
                                        selectedDetails?.date_etablissement
                                    ).format("DD/MM/YYYY")}{" "}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}

                            {selectedDetails?.lieu_etablissement && (
                                <div className="mb-2">
                                  <p>
                              <span className="font-medium mr-2">
                                Lieu Piece:{" "}
                              </span>
                                    {selectedDetails?.lieu_etablissement}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}
                            {selectedDetails?.motif && (
                                <div className="mb-2">
                                  <p>
                                    <span className="font-medium mr-2">Motif:</span>
                                    {selectedDetails?.motif}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}
                            {selectedDetails?.prop_nom && (
                                <div className="mb-2">
                                  <p>
                              <span className="font-medium mr-2">
                                Nom du propriétaire de la parcelle:
                              </span>
                                    {selectedDetails?.prop_nom}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}

                            {selectedDetails?.prop_prenom && (
                                <div className="mb-2">
                                  <p>
                              <span className="font-medium mr-2">
                                Prenom du propriétaire de la parcelle:
                              </span>
                                    {selectedDetails?.prop_prenom}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}
                            {selectedDetails?.td && (
                                <div className="mb-2">
                                  <p>
                              <span className="font-medium mr-2">
                                Type de travaux divers:
                              </span>

                                    {tdLibelle?.TD_LIBELLE}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}
                            {selectedDetails?.section && (
                                <div className="mb-2">
                                  <p>
                                    <span className="font-medium mr-2">Section:</span>
                                    {selectedDetails?.section}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}
                            {selectedDetails?.lot && (
                                <div className="mb-2">
                                  <p>
                                    <span className="font-medium mr-2">Lot : </span>
                                    {selectedDetails?.lot}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}

                            {selectedDetails?.parcelle && (
                                <div className="mb-2">
                                  <p>
                              <span className="font-medium mr-2">
                                Parcelle:{" "}
                              </span>
                                    {selectedDetails?.parcelle}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}
                            {selectedDetails?.rang && (
                                <div className="mb-2">
                                  <p>
                                    <span className="font-medium mr-2">Rang: </span>
                                    {selectedDetails?.rang}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}

                            {selectedDetails?.email && (
                                <div className="mb-2">
                                  <p>
                              <span className="font-medium mr-2">
                                Email du demandeur:{" "}
                              </span>
                                    {selectedDetails?.email}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}

                            {selectedDetails?.nom_entreprise && (
                                <div className="mb-2">
                                  <p>
                              <span className="font-medium mr-2">
                                Nom de l'entreprise:{" "}
                              </span>
                                    {selectedDetails?.nom_entreprise}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}
                            {selectedDetails?.numero_ifu && (
                                <div className="mb-2">
                                  <p>
                              <span className="font-medium mr-2">
                                Numero IFU de l'entreprise:
                              </span>
                                    {selectedDetails?.numero_ifu}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}
                            {selectedDetails?.numero_rccm && (
                                <div className="mb-2">
                                  <p>
                              <span className="font-medium mr-2">
                                RCCM de l'entreprise:
                              </span>
                                    {selectedDetails?.numero_rccm}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}

                            {selectedDetails?.tel1_whatsapp && (
                                <div className="mb-2">
                                  <p>
                              <span className="font-medium mr-2">
                                WhatsApp:{" "}
                              </span>
                                    {selectedDetails?.tel1_whatsapp}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}

                            {selectedDetails?.tel2 && (
                                <div className="mb-2">
                                  <p>
                              <span className="font-medium mr-2">
                                Telephone:{" "}
                              </span>
                                    {selectedDetails?.tel2}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}
                            {selectedDetails?.ville_id && (
                                <div className="mb-2">
                                  <p>
                              <span className="font-medium mr-2">
                                Ville du demandeur:{" "}
                              </span>
                                    {selectedDetails?.ville_id}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}
                            {selectedDetails?.village_id && (
                                <div className="mb-2">
                                  <p>
                              <span className="font-medium mr-2">
                                Localité du demandeur:{" "}
                              </span>
                                    {selectedDetails?.village_id}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}

                            {selectedDetails?.type_amperage && (
                                <div className="mb-2">
                                  <p>
                              <span className="font-medium mr-2">
                                Type d'amperage:{" "}
                              </span>
                                    {selectedDetails?.type_amperage}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}
                            {selectedDetails?.puissance && (
                                <div className="mb-2">
                                  <p>
                              <span className="font-medium mr-2">
                                Ampérage souhaité:{" "}
                              </span>
                                    {selectedDetails?.puissance}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}

                            {selectedDetails?.type_compteur && (
                                <div className="mb-2">
                                  <p>
                              <span className="font-medium mr-2">
                                Type compteur:{" "}
                              </span>
                                    {selectedDetails?.type_compteur}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}

                            <div className=" bg-red-100 text-teal-950 mb-2">
                              <p>
                            <span className="font-medium">
                              Etat de la demande:{" "}
                            </span>

                                <StatusBadge status={selectedDetails?.status} />
                              </p>
                              <hr></hr>
                            </div>

                            {selectedDetails?.motif_rejet && (
                                <div className=" bg-red-100 text-teal-950 mb-2 ">
                                  <p>
                              <span className="font-medium mr-2">
                                Motif du rejet:{" "}
                              </span>
                                    {selectedDetails?.motif_rejet}
                                  </p>
                                  <hr></hr>
                                </div>
                            )}
                          </div>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button
                          onClick={() => setOpenDetailsDialog(false)}
                          color="success"
                          variant="contained"
                      >
                        Fermer
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavbarSidebarLayout>
  );
};

export default DashBoardPage;
