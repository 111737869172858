import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import {Typography} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import React from "react";

export function Sinistre() {
    return(
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <div className="text-red-700 text-xl font-bold">Sinistre</div>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1" component="div">
                        <div className="text-justify text-lg mb-2">
                            Un sinistre est défini comme tout dommage survenu aux installations ou équipements
                            d'un utilisateur en raison d'une défaillance de l'alimentation électrique fournie
                            par la SONABEL. Cependant, les dommages résultant d'une panne attribuable à un cas
                            de force majeure, tels qu'un orage, la foudre, une tornade, etc., sont expressément
                            exclus de cette catégorie. Dans tous les cas, des investigations approfondies seront
                            menées pour déterminer les responsabilités.
                        </div>

                        <ol className="list-decimal pl-4 space-y-4">
                            <li>
                                <strong>Conditions à remplir :</strong>
                                <ul className="list-disc ml-4">
                                    <li>Être un abonné SONABEL.</li>
                                    <li>Avoir constaté un dommage électrique sur l'un de ses appareils en fonctionnement.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Documents à fournir :</strong>
                                <div>La déclaration se fait par demande incluant les informations nécessaires :</div>
                                <ul className="list-disc ml-4">
                                    <li>Numéro d'abonné.</li>
                                    <li>Date et heure du sinistre.</li>
                                    <li>Nom et prénom du déclarant.</li>
                                    <li>Numéro de téléphone fixe et mobile.</li>
                                    <li>Liste des appareils endommagés ainsi que les conditions de survenue des dommages subis.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Délai :</strong>
                                <div className="pt-2 text-justify text-red-700 font-bold">
                                    Une équipe spécialisée de la SONABEL se déplacera dans les meilleurs délais
                                    chez l'abonné pour établir un constat d'usage.
                                </div>
                            </li>
                        </ol>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
    );
}