import React from "react";
import {Card} from "flowbite-react";
import Typography from "@mui/material/Typography";

const Statistique = ({demandes, demandeCours, demandeTraite, devis, rejete}) => {
  return (
      <div className={`container mx-auto`}>
          <div className={`break-words text-center`}>
              <div className={`hidden md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4`}>
                  {/*<div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4`}>*/}
                  <div className={`flex-grow`}>
                      <Card sx={{display: 'flex'}}>
                          <Typography component="div" variant="h6" align="center">
                              Nombre de demandes
                          </Typography>

                          <Typography variant="h4" color="text.secondary" component="div"
                                      className={`text-center`}>
                              <strong>{demandes.length}</strong>
                          </Typography>
                      </Card>
                  </div>
                  <div className={`flex-grow`}>
                      <Card sx={{display: 'flex'}}>
                          <Typography component="div" variant="h6" align="center">
                              Demandes en cours
                          </Typography>
                          <Typography variant="h4" color="text.secondary" component="div"
                                      className={`text-center`}>
                              <strong>{demandeCours.length}</strong>
                          </Typography>
                      </Card>
                  </div>
                  <div className={`flex-grow`}>
                      <Card sx={{display: 'flex'}}>
                          <Typography component="div" variant="h6" align="center">
                              Demandes en traitement
                          </Typography>
                          <Typography variant="h4" color="text.secondary" component="div"
                                      className={`text-center`}>
                              <strong>{demandeTraite.length}</strong>
                          </Typography>
                      </Card>
                  </div>
                  <div className={`flex-grow`}>
                      <Card sx={{display: 'flex'}}>
                          <Typography component="div" variant="h6" align="center">
                              Devis Disponibles
                          </Typography>
                          <Typography variant="h4" color="text.secondary" component="div"
                                      className={`text-center`}>
                              <strong>{devis.length}</strong>
                          </Typography>
                      </Card>
                  </div>
                  <div className={`flex-grow`}>
                      <Card sx={{display: 'flex'}}>
                          <Typography component="div" variant="h6" align="center">
                              Demandes rejetées
                          </Typography>
                          <Typography variant="h4" color="text.secondary" component="div"
                                      className={`text-center`}>
                              <strong>{rejete.length}</strong>
                          </Typography>
                      </Card>
                  </div>
              </div>
          </div>
      </div>

  );
}
export default Statistique;
