import React from "react";
import {Navigate} from "react-router-dom";
import EditTravauxDivers from "../../../EditRequest/Demande_travaux_divers/EditTravauxDivers";

const PrivateRouteEditTravauxDivers = () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <EditTravauxDivers/>;
    }
    return <Navigate to="/login"/>
}

export default PrivateRouteEditTravauxDivers;
