import NavbarSidebarLayout from "../../components/Dashboard/Layouts/NavbarSidebar";
import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Card} from "flowbite-react";
import {HiHome} from "react-icons/hi";
import {api} from "../../config/config";
import {useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import HeaderMessage from "../../components/HeaderMessage";
import {CircularProgress} from "@mui/material";

const RequestList = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [recharge, setRecharge] = useState(false);

    useEffect(() => {
        try {
            setRecharge(true);
            const getData = async () => {
                try {
                    api.get('/typeservice/all').then(response =>{
                        setData(response.data.content);
                        setRecharge(false);
                    }).catch(() => {
                    });

                } catch (error) {
                }
            };

            getData();
        }catch (e) {

        }

    }, []);

    const handleRoute = (object) => {
        if (object.libelle.includes("Demande de modification d’ampérage")){
            navigate('/modificationamperage', {state:{idBranchementAbon:object.type_sce}});
        }else if (object.libelle.includes("Etalonnage de compteur")){
            navigate('/etalonnagecompteur', {state:{idBranchementAbon:object.type_sce}});
        }else if (object.libelle.includes("Résiliation personne morale")){
            navigate('/resiliationmorale', {state:{idBranchementAbon:object.type_sce}});
        }else if (object.libelle.includes("Résiliation personne physique")){
            navigate('/resiliationphysique', {state:{idBranchementAbon:object.type_sce}});
        }else if (object.libelle.includes("Branchement sans abonnement")){
            navigate('/branchesanabon', {state:{idBranchementAbon:object.type_sce}});
        }else if (object.libelle.includes("Branchement avec abonnement")){
            navigate('/brancheabon', {state:{idBranchementAbon:object.type_sce}});
        }else if (object.libelle.includes("Demande de travaux divers")){
            navigate('/Demande-de-travaux-divers', {state:{idTravauxDivers:object.type_sce}});
        }else if (object.libelle.includes("Remplacement disjoncteur")){
            navigate('/remplacement', {state:{idBranchementAbon:object.type_sce}});
        }else if (object.libelle.includes("Supervision génie civil")){
            navigate('/Supervision-genie-civil', {state:{idSupervisionGenieCivil:object.type_sce}});
        }else if (object.libelle.includes("Supervision des travaux")){
            navigate('/Supervision-travaux', {state:{idSupervisionTravaux:object.type_sce}});
        }else if (object.libelle.includes("Branchement provisoire")){
            navigate('/branchement-provisoire', {state:{idBranchementProvisoire:object.type_sce}});
        }else if(object.libelle.includes("Envoi d'index")){
            navigate('/saisindex',  {state:{idBranchementAbon:object.type_sce}});
        }else if(object.libelle.includes("Abonnement")){
            navigate('/abonnement',  {state:{idAbonnement:object.type_sce}});
        }
        else{
            toast.info("Ce service n'est pas encore disponible");
        }
    }

    return (
        <NavbarSidebarLayout>
            <main>
                <ToastContainer/>
                <div
                    className="block items-center justify-center border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
                    <div className="mb-1 w-full">
                        <div className="mb-4">
                            <Breadcrumb className="mb-4">
                                <Breadcrumb.Item href="/dashboard">
                                    <div className="flex items-center gap-x-3">
                                        <HiHome className="text-xl"/>
                                        <span className="dark:text-white">Accueil</span>
                                    </div>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item href="/users/list">Liste des services</Breadcrumb.Item>
                            </Breadcrumb>
                            <HeaderMessage>
                                <h1 className=" flex flex-col md:flex-row text-xl font-semibol text-white">
                                    La liste des Services
                                </h1>
                            </HeaderMessage>

                        </div>

                        <div className="justify-center sm:flex ">
                            <div
                                className="mb-3 items-center dark:divide-gray-700 sm:mb-0 sm:flex sm:divide-x sm:divide-gray-100">
                                <div className="grid grid-cols-1 grid-rows-4 gap-4 md:grid-cols-2 lg:grid-cols-4 justify-center">
                                    {recharge ? (
                                        <div className="text-center">
                                            <CircularProgress color="success" size={60}/>
                                        </div>
                                    ) : (
                                        <>
                                            {data.map(
                                                (object) => (
                                                    <div key={object.type_sce}>
                                                        <Card key={object.type_sce} className="max-w-sm">
                                                            <h5 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                                                                {object.libelle}
                                                            </h5>

                                                            <Button
                                                                className="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-green-900 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                                                                key={object.type_sce}
                                                                onClick={() => handleRoute(object)}>
                                                                Faire ma demande
                                                                <svg className="-mr-1 ml-2 h-4 w-4" fill="currentColor"
                                                                     viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                                        clipRule="evenodd"
                                                                    />
                                                                </svg>
                                                            </Button>
                                                        </Card>
                                                    </div>
                                                )
                                            )}
                                        </>
                                    )}

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </main>
        </NavbarSidebarLayout>
    );

}

export default RequestList;
