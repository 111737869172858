import React from "react";
import {Navigate} from "react-router-dom";
import EditEtalonnageCompteur from "../../../EditRequest/Etalonnage_de_compteur/EditEtalonageCompteur";

const PrivateRouteEditEtalonnage = () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <EditEtalonnageCompteur/>;
    }
    return <Navigate to="/login"/>
}

export default PrivateRouteEditEtalonnage;
