import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import {Typography} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import React from "react";

export function Production() {
    return(
        <>
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <p className="text-red-700 text-xl font-bold">Production</p>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        <p className="text-lg mb-4">
                            {" "}
                            L'énergie distribuée par la SONABEL provient de deux sources principales :
                            {" "}
                        </p>
                        <div>
                            <div className="text-justify">
                                <strong>Source Interne:</strong>
                                <p>Les centrales thermiques diesel exploitées par la SONABEL comprennent :</p>
                            </div>
                            <ol className="space-x-8 space-y-4 text-gray-500 list-decimal list-inside">
                                <div></div>
                                <li>Ouaga 1</li>
                                <li>Ouaga 2</li>
                                <li>Kossodo 1</li>
                                <li>Kossodo 2</li>
                                <li>Komsilga</li>
                                <li>Bobo 2</li>
                                <li>Dédougou</li>
                                <li>Ouahigouya</li>
                                <li>Gaoua</li>
                                <li>Dori</li>
                                <li>Fada</li>
                                <li>Djibo</li>
                                <li>Diapaga</li>
                                <li>Kompienga</li>
                                <li>Tougan</li>
                                <li>Nouna</li>
                            </ol>
                            <div className="mt-4 text-justify">
                                La puissance installée de ces centrales est estimée à 364,42 MW.

                                En outre, une centrale thermique diesel supplémentaire, gérée par un privé Aggreko, offre
                                une puissance de 50 MW.

                                Les centrales hydroélectriques comprennent :
                            </div>
                            <ol className="space-x-8 space-y-4 text-gray-500 list-decimal list-inside">
                                <div></div>
                                <li>Kompienga</li>
                                <li>Bagré</li>
                                <li>Tourni</li>
                                <li>Niofila</li>
                                <li>Samendeni</li>
                            </ol>
                            <div className="mt-4 text-justify">
                                Leur puissance installée est de 34 MW.

                                Les centrales solaires photovoltaïques injectant de l'électricité dans le réseau de la
                                SONABEL sont :
                            </div>
                            <ol className="space-x-8 space-y-4 text-gray-500 list-decimal list-inside ">
                                <div></div>
                                <li>Zagtouli</li>
                                <li>Ziga</li>
                            </ol>
                            <div className="mt-4 text-justify">
                                Leur puissance totale est de 34 MWc.

                                Il existe également des centrales solaires photovoltaïques exploitées par des Producteurs
                                Indépendants d'Électricité (PIE) telles que :
                            </div>
                            <ol className="space-x-8 space-y-4 text-gray-500 list-decimal list-inside">
                                <div></div>
                                <li>Kodeni</li>
                                <li>Zano</li>
                                <li>Pa</li>
                                <li>Nagreogo</li>
                            </ol>
                            <div>
                                Ces centrales solaires photovoltaïques cumulent une puissance totale de 122 MWc.
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="text-justify">
                                <strong>Source Externe:</strong>
                                <p>La SONABEL est également connectée à des réseaux externes via des interconnexions avec le Ghana, la Côte d'Ivoire et le Togo/Bénin. Les importations annuelles en 2022 ont représenté 1492,15 GWh.</p>
                            </div>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
    );
}