import React from "react";
import {Navigate} from "react-router-dom";
import EditBranchAbonnement from "../../../EditRequest/EditBranchementAbonnement/EditBranchAbonnement";

const PrivateRouteEditBranchAbonnement = () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <EditBranchAbonnement/>;
    }
    return <Navigate to="/login"/>
}

export default PrivateRouteEditBranchAbonnement;
