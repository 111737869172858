import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import {Typography} from "@mui/material";
import Accordion from "@mui/material/Accordion";

export function HeureOuverture() {
    return(
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <p className="text-red-700 text-xl font-bold">Heure d'ouverture</p>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        <div className="container">
                            <div className="text-center">
                                <div className="text-2xl font-bold"><u>Heure d'ouverture</u></div>
                                <p className="mt-4">Du Lundi-Vendredi de <strong>07h30 à 12h et de 14h à 16h30.</strong></p>
                                <p className="mt-1">Les Samedi de <strong>08h à 12h30.</strong></p>
                            </div>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
    );

}