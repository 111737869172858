import React from "react";

const ErrorPage = () => {
    return (
        <div  className=" h-screen flex flex-col items-center  justify-center">
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
        </div>
    );
}

export default ErrorPage;