import React from "react";
import {Link} from "react-router-dom";

export function NotFound() {
    return (
        <>
            <section className="bg-white dark:bg-gray-900">
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center">
                        <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">404</h1>
                        <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
                            Oups ! Cette page n'existe pas.
                        </p>
                        <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                            Appuyez sur le bouton pour revenir à la page d'accueil.
                        </p>
                        <Link
                            to="/"
                            size="sm"
                            className="inline-block px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-700"
                        >
                            Retour
                        </Link>
                    </div>
                </div>
            </section>
        </>
    );
}
