import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import {Typography} from "@mui/material";
import React from "react";

export function Distribution() {

    return(
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <p className="text-red-700 text-xl font-bold">Distribution</p>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        <p className="text-lg mb-4">
                            {" "}
                            La distribution est le processus vital par lequel la SONABEL fournit de l'énergie électrique aux consommateurs. Les réseaux de distribution sont constitués de lignes, de postes de transformation et d'appareils de contrôle, de commande et de protection.
                            {" "}
                        </p>
                        <div className="mt-4">
                            <div>
                                <ol className="space-x-4 space-y-4 text-gray-500 list-decimal list-inside">
                                    <div></div>
                                    <li>
                                        <strong>Indicateurs 2022 pour la Distribution :</strong>
                                        <ul className="ps-5 mt-2 space-y-1 list-disc list-inside">
                                            <li>Longueur du réseau Haute Tension Aérien (HTA) : 8491 km</li>
                                            <li>Longueur du réseau Basse Tension (BT) : 15799 km</li>
                                            <li>Nombre de postes Haute Tension / Basse Tension (HTA/BT) : 5670</li>
                                            <li>Nombre de Branchements : 84924</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Maintenance Préventive en HTA :</strong>
                                        <ul className="ps-5 mt-2 space-y-1 list-disc list-inside">
                                            <li>
                                                L'entretien du réseau de distribution Haute Tension (HTA) est assuré par la maintenance préventive, y compris des travaux sous tension. Ces travaux sous tension permettent d'effectuer des opérations de maintenance sans interrompre l'alimentation électrique, assurant ainsi une continuité de service pour les consommateurs.
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
    );

}