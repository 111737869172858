import React, { useEffect, useState } from "react";
import { api } from "../../../config/config";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { MuiTelInput } from "mui-tel-input";
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField, CircularProgress,
} from "@mui/material";
import NavbarSidebarLayout from "../../../components/Dashboard/Layouts/NavbarSidebar";
import { Link, useLocation,useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {Breadcrumb, Textarea} from "flowbite-react";
import TypePiece from "../../../shared/TypePiece";
import {EmailRegex} from "../../../components/Validation/EmailRegex";
import {PhoneRegex} from "../../../components/Validation/PhoneRegex";
import {PieceValidation} from "../../../components/Validation/PieceValidation";
import {CloudUpload} from "@mui/icons-material";
import {HiHome} from "react-icons/hi";
import ServerStatus from "../../../components/ServerStatus";
import LogoSonabel from "../../../assets/Logo-site-SONABEL-def.png";
// import InfoService from "../../../components/InfoService/InfoService";
import CnibValid from "../../../components/Functions/CnibValid";


const SupervisionGenieCivil = ({serverOffline}) => {

  const locationCivil = useLocation();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [loadingSGC, setloadingSGC] = useState(false);
  const files = useState({
    cnib: '',
    parcelle: '',
  });
  // Formation des champs pour le type particulier
  const [formData, setFormData] = useState({
    typeClient: "particulier",
    //Initialiser avec les valeurs par défaut du formulaire
    service: "",
    exploitation: "",
    section: "",
    lot: "",
    parcelle: "",
    rang: "",
    nom: "",
    prenom: "",
    email: "",
    telephone: "",
    whatsApp: "",
    rccm: "",
    ifu: "",
    pb: "",
    profession: "",
    region: "",
    province: "",
    ville: "",
    village: "",
    cnib: "",
    passport: "",
    date_delibery: "",
    delivery_place: "",
    amperage: "",
    puissance: "",
    nom_societe: "",
    objet: "",
    piece:"",
    prop_nom: "",
    prop_prenom: "",
    file:null,
    document_parcelle:null,
  });

  //Gestion des erreur
  const [errors, setErrors] = useState({});

  // Récupérer l'objet de la demande
  const [texte, setTexte] = useState('');

  // Liste des professions existantes
  const [professions, setProfessions] = useState([]);
  const [ville, setVille] = useState([]);
  const [village, setVillage] = useState([]);
  const [isContentVillage, setIsContentVillage] = useState(false);
  const [phone, setPhone] = React.useState("+226");
  const [whatsapp, setWhatsapp] = React.useState("+226");
  const [errorDate,setErrorDate] = useState(false);

  // deactiver le bouton submit lorsqu'on actionne le bouton soumettre
  const [stateSend, setStateSend] = useState(false);

  const avertissement = <p className="text-red-600">Avertissement</p>;

  //Récupération des professions de façon dynamique
  useEffect(() => {
    api
        .get("/profession/all")
        .then((response) => {
          if (response.status === 200) {
            setProfessions(response?.data?.content);
          }
        })
        .catch(() => {
          errors.profession = "Problème de réseau."
        });
  }, [errors]);

  useEffect(() => {
    api
        .get("/ville/cd")
        .then((response) => {
          if (response.status === 200) {
            const trimmedVilles = response.data.content.map((ville) => ({
              id: ville.ville_id,
              name: ville.ville_id.trim(),
            }));
            setVille(trimmedVilles);
          }
        })
        .catch(() => {
          errors.ville = "Problème de réseau."
        });
  }, [errors]);

  const steps_civil = [
    avertissement,
    "Type de client",
    "Informations personnelles",
    "Informations sur votre pièce d'identité",
    "Zone Géographique",
    "Information cadastral de la parcelle",
    "Objet",
    "Validation",
  ];

  const handleTextAreaChange = (event) => {
    setTexte(event.target.value);
    setFormData({...formData, objet: event.target.value});
  };

  const handleNextSupervision = () => {
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setErrors(validationErrors);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleFieldFocus = (field) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  };

  //function pour passer la valeur de la région dans le setRegionId
  const handleVilleId = async (_, value) => {
    formData.ville = value?.id;
    api
        .get(`village/ville/${value?.id}`)
        .then((response) => {
          if (response.status === 200) {
            setVillage(response?.data?.content);
            setIsContentVillage(response?.data?.content.length > 0);
          }
        })
        .catch(() => {
          errors.village = "Problème de réseau."
        });
  };
  const handleVillageIdCivil = async (_, value) => {
    formData.village = value?.id;
  };
  const handleProfession = async (_, value) => {
    setFormData((prevData) => ({ ...prevData, profession: value?.id }));
    // formData.profession = value?.id;
  };

  const handleFileChange = (event) => {
    setFormData((prevData) => ({ ...prevData, "file": event.target.files[0] }));
  };

  const handleFileDocumentChange = (event) => {
    setFormData((prevData) => ({ ...prevData, "document_parcelle": event.target.files[0] }));
  };

  //  envoie des fichiers avant l'envoie de la demande
  const handleSendFile = async () => {
    if (formData.file) {
      // create a new FormData object and append the file to it
      const formDataTransform = new FormData();
      formDataTransform.append("file", formData.file);

      try {
        setloadingSGC(true);
        setStateSend(true);
        await api
            .post("/storage/upload", formDataTransform, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              if (response.status === 201) {
                // handleDemande(response.data.content);
                files.cnib = response.data.content;
              }
            })
            .catch(() => {
              //S'il n'a pas pu enregistrer les données on arrête le fonctionnement du spinner
              setloadingSGC(false);
              setStateSend(false);
              //On envoie une notification disant qu'il y a eu echec
              toast.error("Demande non enregistré");
            });
      } catch (e) {}
    }
  };

  // Envoyer le document de la parcelle
  const handleSendDocumentFile = async () => {
    if (formData.document_parcelle) {
      // create a new FormData object and append the file to it
      const formDataTransformDocument = new FormData();
      formDataTransformDocument.append("file", formData.document_parcelle);

      try {
        setloadingSGC(true);
        setStateSend(true);
        return await api
            .post("/storage/upload", formDataTransformDocument, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              if (response.status === 201) {
                // handleDemande(response.data.content);
                files.parcelle = response.data.content;
              }
            })
            .catch(() => {
              //S'il n'a pas pu enregistrer les données on arrête le fonctionnement du spinner
              setloadingSGC(false);
              setStateSend(false);
              //On envoie une notification disant qu'il y a eu echec
              toast.error("Demande non enregistré");
            });
      } catch (e) {}
    }
  };

  // Soumettre le formulaire
  const handleSubmitData = async (cnib, document) => {

    if (cnib || document){
      setloadingSGC(true);

      // Formation des données
      let dataSupervision = {
        lot: formData.lot,
        parcelle: formData.parcelle,
        nom: formData.nom,
        prenom: formData.prenom,
        tel1_whatsapp: formData.whatsApp.replace(/\s+/g, ''),
        tel2: formData.telephone.replace(/\s+/g, ''),
        email: formData.email,
        numero_piece: formData.cnib,
        date_etablissement: formData.date_delibery,
        lieu_etablissement: formData.delivery_place,
        numero_ifu: formData.ifu,
        numero_rccm: formData.rccm,
        nom_entreprise: formData.nom_societe,
        type_sce: locationCivil.state.idSupervisionGenieCivil,
        section: formData.section,
        user_id: localStorage.getItem("userId"),
        ville_id: formData.ville,
        village_id: formData.village,
        profession_id: formData.profession,
        objet : formData.objet,
        piece : formData.piece,
        prop_nom: formData.prop_nom,
        prop_prenom: formData.prop_prenom,
        cat_cli_code: "",
        photo_piece: cnib,
        document_parcelle: document,
      };
      if (formData.typeClient === "particulier"){
        dataSupervision.cat_cli_code = "00";
      }else {
        dataSupervision.cat_cli_code = "02";
      }
      const filteredDataSupervision = Object.fromEntries(
          Object.entries(dataSupervision).filter(([key, value]) => value !== null || undefined || "")
      );
      try {
        await api
            .post("/demande", filteredDataSupervision)
            .then((response) => {
              if (response.status === 201) {
                setFormData({
                  //Initialiser avec les valeurs par défaut du formulaire
                  service: "",
                  exploitation: "",
                  section: "",
                  lot: "",
                  parcelle: "",
                  rang: "",
                  nom: "",
                  prenom: "",
                  email: "",
                  telephone: "+226",
                  whatsApp: "+226",
                  rccm: "",
                  ifu: "",
                  pb: "",
                  profession: "",
                  region: "",
                  province: "",
                  ville: "",
                  village: "",
                  type_amperage: "",
                  puissance_demande: "",
                  duree: "",
                  cnib: "",
                  passport: "",
                  date_delibery: "",
                  delivery_place: "",
                  amperage: "",
                  puissance: "",
                  objet: "",
                  piece: "",
                });

                setloadingSGC(false);

                toast.success(
                    "Demande enregistrée ! Nous vous enverrons des notifications pour le suivi de la demande par mail ou SMS."
                );
                setStateSend(true);
                setTimeout(()=> {
                  navigate("/dashboard");
                },3000);
              }
            })
            .catch(() => {
              setloadingSGC(false);
              setStateSend(false);
              toast.error("Demande non enregistré");
            });
      } catch (e) {}
    }else {
      toast.error("Demande non enregistré");
    }
  };

  // Controller les numero de téléphone
  const handleChangePhoneSupervisionGenieCivil = (value) => {
    setPhone(value);
    formData.telephone = value;
  };

  //Whatsapp
  const handleChangePhoneWhatsapp = (whatsapp) => {
    setWhatsapp(whatsapp);
    formData.whatsApp = whatsapp;
  };

  const validateForm = (formData) => {
    const errors = {};
    if (activeStep === 2) {
      if (!formData.nom.trim()) {
        errors.nom = "Nom obligatoire";
      }
      if (!formData.prenom.trim()) {
        errors.prenom = "Prenom obligatoire";
      }
      if (!formData.email.trim()) {
        errors.email = "Email obligatoire";
      }else if (!EmailRegex(formData.email)){
        errors.email = "Cet email est invalid.";
      }
      if (!formData.telephone.trim()) {
        errors.telephone = "Telephone obligatoire";
      }else if(!PhoneRegex(formData.telephone)){
        errors.telephone = "Le numéro de téléphone doit être au format correct.";
      }
      if(!PhoneRegex(formData.whatsApp)){
        errors.whatsApp = "Le numéro de téléphone doit être au format correct.";
      }
      if (formData.typeClient === "entreprise" && !formData.ifu.trim()) {
        errors.ifu = "Numéro IFU obligatoire";
      }
      if (formData.typeClient === "entreprise" && !formData.rccm.trim()) {
        errors.rccm = "Numéro RCCM obligatoire";
      }
      if (formData.typeClient === "entreprise" && !formData.nom_societe.trim()) {
        errors.nom_societe = "Le nom de la société est obligatoire";
      }
      if (!formData.profession) {
        errors.profession = "Choisissez votre profession. S'il n'existe pas dans la liste choisissez autre.";
      }

    }
    if (activeStep === 3) {
      if (!formData.piece){
        errors.piece = "Ce champ est obligatoire";
      }
      if (!formData.file) {
        errors.file = "Ce champ est obligatoire";
      }
      if (formData.file && formData.file.size > (2*1024*1024) ) {
        errors.file = "Le fichier sélectionné est trop volumineux."
      }
      if (!formData.cnib.trim()) {
        errors.cnib = "Ce champ est obligatoire";
      }
      if (!formData.date_delibery.trim()){
        errors.date_delibery = "Veillez entrer la date de délivrance de votre pièce.";
        setErrorDate(true);
      }else if(!PieceValidation(formData.date_delibery)){
        errors.date_delibery = "Cette date ne peut pas être acceptée." ;
        setErrorDate(true);
      }
      // On verifie si la pièce d'identité est valide
      if (CnibValid(formData.date_delibery) >= 10) {
        errors.date_delibery = "Votre carte d'identité a expiré.";
        setErrorDate(true);
      }
      if (!formData.delivery_place.trim()) {
        errors.delivery_place = "Ce champ est obligatoire";
      }
    }
    if (activeStep === 4) {
      if (!formData.ville) {
        errors.ville = "Veuillez sélectionner la ville";
      }
      if (isContentVillage && !formData.village) {
        errors.village = "Veuillez sélectionner le village";
      }
    }
    if (activeStep === 5){
      if (!formData.prop_nom.trim()){
        errors.prop_nom = "Ce champ est obligatoire";
      }
      if (!formData.prop_prenom.trim()){
        errors.prop_prenom = "Ce champ est obligatoire";
      }
      if(!formData.section.trim()){
        errors.section = "Ce champ est obligatoire."
      }
      if(!formData.lot.trim()){
        errors.lot = "Ce champ est obligatoire."
      }
      if(!formData.parcelle.trim()){
        errors.parcelle = "Ce champ est obligatoire."
      }
      if (formData.document_parcelle && formData.document_parcelle.size > (2*1024*1024)){
        errors.document_parcelle = "Le fichier sélectionné est trop volumineux.";
      } else if(formData.document_parcelle === 0 || null) {
        errors.document_parcelle = {};
      }
    }
    if (activeStep === 6) {
      if(!formData.objet.trim()){
        errors.objet = "Ce champ est obligatoire";
      }
    }
    return errors;
  };

  const handleChangePiece = (event, value) => {
    setFormData((prevData) => ({ ...prevData, piece: value?.name }));
  };

  const handleManageDataSend = async () => {
    try {
      // On upload le fichier de la cnib
      if (formData.file) {
        await handleSendFile();
      }
      // On upload le fichier du document de la parcelle
      if (formData.document_parcelle){
        await handleSendDocumentFile();
      }

      // On verifie que l'un des deux existes
      if(files.cnib || files.parcelle) {
        // Si parcelle est n'existe pas on renvoie null
        await handleSubmitData(files.cnib, files.parcelle || null);
      }
    } catch (e) {

    }
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
            <div>
              <div className="flex flex-col items-center">
                <div className={`text-center items-center mt-4`}>
                  <img src={LogoSonabel} alt="" width={200}/>
                </div>
                <div className={`text-justify m-8`}>
                  <h2 className="mb-2 text-lg font-bold text-gray-900 dark:text-white">Conditions pour cette demande :</h2>
                  <ol className="max-w-md space-y-1 text-gray-500 list-decimal list-inside dark:text-gray-400">
                    <li>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      Assurez-vous de disposer de votre pièce d'identité, du document relatif à votre parcelle, des informations cadastrales de celle-ci,
                      ainsi que de connaître le type de branchement et l'ampérage souhaité.
                    </span>
                    </li>
                    <li>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      La SONABEL décline toute responsabilité pour toute inexactitude des informations fournies par les demandeurs.
                    </span>
                    </li>
                    <li>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      Aucun remboursement ne sera accordé en cas de fausses informations.
                      La SONABEL se réserve le droit de poursuivre en justice les cas de fraude.
                    </span>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
        );
      case 1:
        return (
            <div className="flex justify-center  h-32 items-center dark:text-white">
              <FormControl>
                <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="mb-5"
                >
                  {/* Type de client */}
                </FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="type-client"
                    value={formData.typeClient}
                >
                  <FormControlLabel
                      value="particulier"
                      control={<Radio/>}
                      label="Particulier"
                      onChange={(e) => handleChange("typeClient", e.target.value)}
                  />
                  <FormControlLabel
                      value="entreprise"
                      control={<Radio/>}
                      label="Entreprise"
                      onChange={(e) => handleChange("typeClient", e.target.value)}
                  />
                </RadioGroup>
              </FormControl>
            </div>
        );
      case 2:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8 dark:text-white">
              <>
                <TextField
                    className="dark:text-white"
                    label="Nom"
                    fullWidth
                    size="small"
                    value={formData.nom}
                    onChange={(e) => handleChange("nom", e.target.value)}
                    onFocus={() => handleFieldFocus("nom")}
                    error={!!errors.nom}
                    helperText={errors.nom}
                    required
                />
                <TextField
                    label="Prenom"
                    fullWidth
                    size="small"
                    value={formData.prenom}
                    onChange={(e) => handleChange("prenom", e.target.value)}
                    onFocus={() => handleFieldFocus("prenom")}
                    error={!!errors.prenom}
                    helperText={errors.prenom}
                    required
                    className="dark:text-white"
                />
                <TextField
                    label="Email"
                    type="email"
                    fullWidth
                    size="small"
                    value={formData.email}
                    onChange={(e) => handleChange("email", e.target.value)}
                    onFocus={() => handleFieldFocus("email")}
                    error={!!errors.email}
                    helperText={errors.email}
                    required
                    className="dark:text-white"
                />
                <MuiTelInput
                    label="Telephone"
                    fullWidth
                    size="small"
                    value={phone}
                    onChange={handleChangePhoneSupervisionGenieCivil}
                    onFocus={() => handleFieldFocus("telephone")}
                    error={!!errors.telephone}
                    helperText={errors.telephone}
                    required
                    className="dark:text-white"
                />
                <MuiTelInput
                    label="WhatSApp"
                    fullWidth
                    size="small"
                    value={whatsapp}
                    onChange={handleChangePhoneWhatsapp}
                    onFocus={() => handleFieldFocus("whatsApp")}
                    error={!!errors.whatsApp}
                    helperText={errors.whatsApp}
                    className="dark:text-white"
                />
                {formData.typeClient === "entreprise" && (
                    <>
                      <TextField
                          label="Nom de la société"
                          fullWidth
                          size="small"
                          value={formData.nom_societe}
                          onChange={(e) => handleChange("nom_societe", e.target.value)}
                          onFocus={() => handleFieldFocus("nom_societe")}
                          error={!!errors.nom_societe}
                          helperText={errors.nom_societe}
                          required
                          className="dark:text-white"
                      />
                      <TextField
                          label="Numéro IFU"
                          fullWidth
                          size="small"
                          value={formData.ifu}
                          onChange={(e) => handleChange("ifu", e.target.value)}
                          onFocus={() => handleFieldFocus("ifu")}
                          error={!!errors.ifu}
                          helperText={errors.ifu}
                          required
                          className="dark:text-white"
                      />
                      <TextField
                          label="Numéro rccm"
                          fullWidth
                          size="small"
                          value={formData.rccm}
                          onChange={(e) => handleChange("rccm", e.target.value)}
                          onFocus={() => handleFieldFocus("rccm")}
                          error={!!errors.rccm}
                          helperText={errors.rccm}
                          required
                          className="dark:text-white"
                      />
                    </>
                )}
                <Autocomplete
                    autoHighlight
                    // disablePortal
                    id="profession"
                    options={professions}
                    size="small"
                    sx={{ width: "100%" }}
                    onChange={handleProfession}
                    getOptionLabel={(option) => option.name}
                    value={professions.find((option) => option?.id === formData.profession) || null}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Profession *"
                            error={!!errors.profession}
                            helperText={errors.profession}
                        />
                    )}
                />
              </>
            </div>
        );
      case 3:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              <>
                <Autocomplete
                    autoHighlight
                    // disablePortal
                    id="typepiece"
                    options={TypePiece}
                    size="small"
                    sx={{ width: "100%" }}
                    //onChange={handleProfessionChange}
                    onChange={handleChangePiece}
                    value={TypePiece.find((piece) => piece.name === formData.piece) || null}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Type de piece"
                            error={!!errors.piece}
                            helperText={errors.piece}
                        />
                    )}
                />
                <TextField
                    label="Référence de la pièce (CNIB, Passport, etc)"
                    fullWidth
                    size="small"
                    value={formData.cnib}
                    onChange={(e) => handleChange("cnib", e.target.value)}
                    onFocus={() => handleFieldFocus("cnib")}
                    error={!!errors.cnib}
                    helperText={errors.cnib}
                    required
                    className="dark:text-white"
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                      sx={{ width: "100%" }}
                      label="Date Etablissement"
                      value={dayjs(formData.date_delibery)}
                      format="DD/MM/YYYY"
                      onChange={(value) => {
                        // Formater la date au format "yyyy/mm/dd" avec Dayjs
                        const formattedDate = dayjs(value).format("YYYY/MM/DD");
                        // Appeler la fonction handleChange avec la date formatée
                        handleChange("date_delibery", formattedDate);
                        setErrorDate(false)
                      }}
                      slotProps={{
                        textField: {
                          error: errorDate,
                          helperText: errors.date_delibery,
                        },
                      }}

                  />
                </LocalizationProvider>

                <TextField
                    label="Lieu d'établissement"
                    fullWidth
                    size="small"
                    value={formData.delivery_place}
                    onChange={(e) => handleChange("delivery_place", e.target.value)}
                    onFocus={() => handleFieldFocus("delivery_place")}
                    error={!!errors.delivery_place}
                    helperText={errors.delivery_place}
                    required
                    className="dark:text-white"
                />
              </>
              <>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUpload/>}
                >
                  Importez votre pièce
                  <input
                      type="file"
                      hidden
                      onChange={event => handleFileChange(event)}
                  />
                </Button>
                <p className="mt-1 text-sm font-bold">Le fichier ne doit pas dépasser 5 Mo.</p>
                {formData.file && (
                    <>{formData.file.name}</>
                )}
                {errors.file && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.file}</p>
                )}
              </>
            </div>
        );
      case 4:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              <>
                <Autocomplete
                    autoHighlight
                    // disablePortal
                    id="ville"
                    options={ville}
                    size="small"
                    sx={{ width: "100%" }}
                    //onChange={handleProfessionChange}
                    onChange={handleVilleId}
                    value={ville.find((option) => option?.id === formData.ville) || null}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Ville"
                            error={!!errors.ville}
                            helperText={errors.ville}
                        />
                    )}
                />
                {isContentVillage && (
                    <Autocomplete
                        autoHighlight
                        // disablePortal
                        id="Village"
                        options={village}
                        size="small"
                        sx={{ width: "100%" }}
                        //onChange={handleProfessionChange}
                        onChange={handleVillageIdCivil}
                        value={village.find((option) => option?.id === formData.village) || null}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Village"
                                error={!!errors.village}
                                helperText={errors.village}
                            />
                        )}
                    />
                )}
              </>
            </div>
        );
      case 5:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              <TextField
                  label="Nom du propriétaire de la parcelle"
                  fullWidth
                  size="small"
                  value={formData.prop_nom}
                  onChange={(e) => handleChange("prop_nom", e.target.value)}
                  onFocus={() => handleFieldFocus("prop_nom")}
                  error={!!errors.prop_nom}
                  helperText={errors.prop_nom}
                  required
              />
              <TextField
                  label="Prénom du propriétaire de la parcelle"
                  fullWidth
                  size="small"
                  value={formData.prop_prenom}
                  onChange={(e) => handleChange("prop_prenom", e.target.value)}
                  onFocus={() => handleFieldFocus("prop_prenom")}
                  error={!!errors.prop_prenom}
                  helperText={errors.prop_prenom}
                  required
              />
              <TextField
                  label="Section"
                  fullWidth
                  size="small"
                  value={formData.section}
                  onChange={(e) => handleChange("section", e.target.value.toUpperCase())}
                  onFocus={() => handleFieldFocus("section")}
                  error={!!errors.section}
                  helperText={errors.section}
                  required
              />
              <TextField
                  label="Lot"
                  type="number"
                  fullWidth
                  size="small"
                  value={formData.lot}
                  onChange={(e) => handleChange("lot", e.target.value)}
                  onFocus={() => handleFieldFocus("lot")}
                  error={!!errors.lot}
                  helperText={errors.lot}
                  required
              />
              <TextField
                  label="Parcelle"
                  fullWidth
                  type="number"
                  size="small"
                  value={formData.parcelle}
                  onChange={(e) => handleChange("parcelle", e.target.value)}
                  onFocus={() => handleFieldFocus("parcelle")}
                  error={!!errors.parcelle}
                  helperText={errors.parcelle}
                  required
              />
              <>
                <p className="mt-1 text-sm font-bold text-red-700">Le fichier ne doit pas dépasser 5 Mo.</p>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUpload/>}
                >
                  Importez le Document de votre parcelle
                  <input
                      type="file"
                      hidden
                      onChange={(event) => handleFileDocumentChange(event)}
                  />
                </Button>
                {formData.document_parcelle && <p className={`text-green-700`}>{formData.document_parcelle.name}</p>}
                {errors.document_parcelle && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.document_parcelle}</p>
                )}
              </>
            </div>
        );
      case 6:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8 dark:bg-slate-800">
              <>
                <FormLabel>L'objet de votre demande</FormLabel>
                <Textarea
                    className="resize rounded-md"
                    placeholder="Pourquoi faite vous cette demande?"
                    value={texte}
                    rows="5"
                    onChange={handleTextAreaChange}
                    onFocus={() => handleFieldFocus("objet")}
                    error={!!errors.objet ? errors.objet : 'false'}
                    helperText={errors.objet}
                    required
                />
              </>
            </div>
        );
      case 7:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8 dark:text-white">
              <Typography
                  className="text-center mb-8 dark:text-white"
                  variant="h5"
              >
                Voulez-vous vraiment soumettre votre demande ?
              </Typography>
            </div>
        );
      default:
        return "Unknown step";
    }
  };

  return (
      <NavbarSidebarLayout>
        {serverOffline && <ServerStatus />}
        <div
            className="block items-center justify-center border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
          <div className="mb-1 w-full">
            <div className="mb-4">
              <Breadcrumb className="mb-4">
                <Breadcrumb.Item href="/dashboard">
                  <div className="flex items-center gap-x-3">
                    <HiHome className="text-xl"/>
                    <span className="dark:text-white">Accueil</span>
                  </div>
                </Breadcrumb.Item>
                <Breadcrumb.Item href="/list-des-demandes">Liste des services </Breadcrumb.Item>
                <Breadcrumb.Item>Supervision Génie Civil</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className={`my-8`}>
              {/*<InfoService message="Pour vos demandes de supervision en génie civil, vous devez présenter une pièce d'identité valide ainsi que les documents attestant de la propriété de la parcelle."/>*/}
              <div className="text-center  text-3xl mb-8">
                Supervision Génie Civil
              </div>

              <div
                  className="flex w-full flex-col gap-y-6 lg:flex-row lg:justify-center lg:gap-y-0 md:flex-row md:justify-center md:gap-y-0">
                <div
                    className=" rounded-lg bg-white p-2 shadow-2xl dark:bg-gray-800  xl:p-8 lg:w-1/2 border-2 lg:p-5">
                  <div className="invisible md:visible">
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps_civil.map((label) => (
                          <Step key={label}>
                            <StepLabel>{/*label*/}</StepLabel>
                          </Step>
                      ))}
                    </Stepper>
                  </div>
                  <div>
                    <Typography
                        className="text-center mb-8 dark:text-white"
                        variant="h4"
                    >
                      {steps_civil[activeStep]}
                    </Typography>
                    {getStepContent(activeStep)}

                    <div className="flex flex-row justify-between pt-18">
                      <Button
                          color="success"
                          variant="outlined"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{mr: 1}}
                      >
                        Précédent
                      </Button>
                      <div className="flex 1 1 auto"/>
                      {activeStep === steps_civil.length - 1 && (
                          <>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={handleManageDataSend}
                                sx={{mr: 1}}
                                disabled={stateSend}
                            >
                              {loadingSGC ?
                                  <>
                                    <CircularProgress color="secondary" size="1rem" /> En cours ...
                                  </>
                                  :
                                  <>
                                    Soumettre
                                  </>
                              }
                            </Button>
                            {/*  <div className="bg-green-500 mt-12">  <Link to="/register"> Mes demandes</Link> </div> */}
                          </>
                      )}
                      {activeStep !== steps_civil.length - 1 && (
                          <Button
                              onClick={handleNextSupervision}
                              variant="contained"
                              color="success"
                              disabled = {serverOffline}
                          >
                            Suivant
                          </Button>
                      )}
                    </div>
                  </div>
                  <ToastContainer/>
                  <div className="bg-green-100 mt-12">
                    {stateSend && (
                        <>
                          <Link
                              to="/dashboard"
                              className="text-justify text-white dark:text-white"
                          >
                            <Button>Voir mes demandes</Button>
                          </Link>
                        </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavbarSidebarLayout>
  );
};

export default SupervisionGenieCivil;
