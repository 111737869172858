import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
} from "@mui/material";
import NavbarSidebarLayout from "../../../components/Dashboard/Layouts/NavbarSidebar";
import {api, apiUrl} from "../../../config/config";
import Autocomplete from "@mui/material/Autocomplete";
import {Menu} from "../../../components/Menu/Menu";
import {CloudUpload, FileDownload} from "@mui/icons-material";


const EditTravauxDivers = () => {
  const navigate = useNavigate();

  const [isLoadingDivers, setIsLoadingDivers] = useState(false);

  //initialisation de useLocation pour récupérer l'URL action ou faire une navigation
  const location = useLocation();

  const [activeStep, setActiveStep] = useState(0);

  const [formData, setFormData] = useState({});

  // deactiver le bouton submit lorsqu'on actionne le bouton soumettre
  const [stateSend, setStateSend] = useState(false);
  const [errors, setErrors] = useState({});
  const [TDList, setTDList] = useState([]);
  // upload des nouveaux fichiers
  const [newFile, setNewFile] = useState({
    file: null,
  });

  const filesDelete = useState({
    cnib: false,
  });

  const files = useState({
    cnib: "",
  });

  //Recherche de la demande à modifier
  useEffect(()=>{
    // Chercher une demande
    try {
      setIsLoadingDivers(true);
      api.get(`/demande/${location.state.travauxDiversId}`).then(response => {
        if (response.status === 200){
          setFormData(response.data.content);
          setIsLoadingDivers(false);
        }
      }).catch(() => {
        toast.warning("Erreur serveur.");
        setIsLoadingDivers(false);
      })
    }catch (e) {

    }
  }, [location.state.travauxDiversId]);
  useEffect(()=>{
    // Récupérer la liste des TD
    try {
      api.get(`/td/all`).then(response => {
        if (response.status === 200){
          setTDList(response.data.content);
        }
      }).catch(() => {

      })
    }catch (e) {

    }
  }, [])

  const steps = [
    "Mes informations",
    "Type de travaux divers",
    "Validation"
  ];

  const handleNext = () => {
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (formData) => {
    const errors = {};
    if (activeStep === 1) {
      if (!formData.photo_piece && !newFile.file) {
        // Si la pièce et le fichier sont tous les deux absents, on affiche une erreur
        errors.photo_piece = "Ce champ est obligatoire.";
      } else if (newFile.file && newFile.file.size > 2 * 1024 * 1024) {
        // Si un fichier est présent mais qu'il est trop volumineux, on affiche une erreur
        errors.photo_piece = "Le fichier sélectionné est volumineux.";
      }
      // Assurez-vous que formData.td est défini avant d'appeler trim()
      if (formData && formData.td && typeof formData.td === 'string' && formData.td.trim() === '') {
        errors.td = "Ce champ est obligatoire.";
      }
    }
    return errors;
  };

  const handleBack = () => {
    setStateSend(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDemande = (cnib) => {
    if (cnib) {
      formData.photo_piece = cnib;
    }
    setIsLoadingDivers(true);
    setStateSend(true); // désactiver le bouton
    // On retire les champs qui n'ont pas été soumis pour validation
    const dataTravauxDivers = Object.fromEntries(
        Object.entries(formData).filter(([key, value]) => value !== null)
    );

    // Soumettre la demande du client
    try {
      api
          .put("/demande", dataTravauxDivers)
          .then((response) => {
            if (response.status === 201) {
              setFormData({
                exploitation: "",
                puissance: "3A",
                compteur: "",
                type_amperage: "monophasé", // triphasé
                typeCompteur: "PREPAYE",
              });
              toast.success(
                  "Demande modifiée avec succès."
              );
              setIsLoadingDivers(false);
              setStateSend(true); // désactiver le bouton
              setTimeout(()=> {
                navigate("/dashboard");
              },3000);
            }
          })
          .catch(() => {
            toast.error("Demande non enregistré");
            setIsLoadingDivers(false);
          });
      // setErrorMessage("");
    } catch (erreur) {
    }

  };

  const handleTdList = (event, value) => {
    setFormData((prevData) => {
      //On Vérifie si value est défini et contient la clé "TD_CODE#"
      if (value && typeof value === 'object' && 'TD_CODE' in value) {
        return { ...prevData, td: value.TD_CODE };
      } else {
        //On Gère le cas où value est null ou ne contient pas la clé "TD_CODE#"
        return prevData;
      }
    });
  };

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleFileChange = (event) => {
    setNewFile((prevData) => ({ ...prevData, file: event.target.files[0] }));
  };

  // Supprimer la cnib
  const handleDeleteFile = async () => {
    try {
      setIsLoadingDivers(true);
      if (newFile.file && formData.photo_piece) {
        await api
            .delete(`/storage/${formData.photo_piece}`)
            .then((response) => {
              if (response.status === 201) {
                filesDelete.cnib = true;
              }
            })
            .catch(() => {
              setIsLoadingDivers(false);
              filesDelete.cnib = false;
              toast.error("Le fichier n'existe pas.");
            });
      } else {
        await handleSendFile();
      }
    } catch (e) {}
  };

  //  envoie des fichiers avant l'envoie de la demande
  const handleSendFile = async () => {
    if (newFile.file) {
      // create a new FormData object and append the file to it
      const formDataTransform = new FormData();
      formDataTransform.append("file", newFile.file);

      try {
        setIsLoadingDivers(true);
        setStateSend(true);
        await api
            .post("/storage/upload", formDataTransform, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              if (response.status === 201) {
                // handleDemande(response.data.content);
                files.cnib = response.data.content;
              }
            })
            .catch(() => {
              //S'il n'a pas pu enregistrer les données on arrête le fonctionnement du spinner
              setIsLoadingDivers(false);
              setStateSend(false);
              //On envoie une notification disant qu'il y a eu echec
              toast.error("Demande non enregistré");
            });
      } catch (e) {}
    }
  };

  // Controler toutes les méthodes qui entre dans l'envoie de la demande
  const handleManageTD = async () => {
    // Si un nouveau fichier est chargé on supprime l'ancien
    if (newFile.file) {
      await handleDeleteFile();
    }
    // Si le fichier est supprimer on enregistre le nouveau fichier
    if (filesDelete.cnib) {
      // Envoi de CNIB
      await handleSendFile();
    }

    // On verifie que le fichier a été enregistrer et on procède à la modification de la demnade
    if (files.cnib) {
      await handleDemande(files.cnib);
    } else {
      // Dans le cas contraire on renvoie les existants
      await handleDemande(null);
    }
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              <>
                {isLoadingDivers && <CircularProgress/>}
                <div className="flex  flex-row space-x-8 ">
                  <TextField
                      label="Numero compteur"
                      fullWidth
                      size="small"
                      value={formData.numero_compteur || ''}
                      onChange={(event)=>handleChange("numero_compteur",event.target.value )}
                      InputLabelProps={{shrink: true}}
                      disabled={true}
                  />
                  <TextField
                      label="Numero de police"
                      fullWidth
                      size="small"
                      value={formData.numero_police || ''}
                      onChange={(event)=>handleChange("police",event.target.value )}
                      InputLabelProps={{shrink: true}}
                      disabled={true}
                  />
                </div>
                <div className="flex  flex-row space-x-8 ">
                  <TextField
                      label="Nom"
                      fullWidth
                      size="small"
                      value={formData.nom || ''}
                      onChange={(event)=>handleChange("nom",event.target.value )}
                      InputLabelProps={{shrink: true}}
                      disabled={true}
                  />
                  <TextField
                      label="Prenom"
                      fullWidth
                      size="small"
                      value={formData.prenom || ''}
                      onChange={(event)=>handleChange("prenom",event.target.value )}
                      InputLabelProps={{shrink: true}}
                      disabled={true}
                  />
                </div>
                <div className="flex  flex-row space-x-8 ">
                  <TextField
                      label="Email"
                      fullWidth
                      size="small"
                      value={formData.email || ''}
                      onChange={(event)=>handleChange("email",event.target.value )}
                      InputLabelProps={{shrink: true}}
                      disabled={true}
                  />
                  <TextField
                      label="Telephone"
                      fullWidth
                      size="small"
                      value={formData.tel2 || ''}
                      onChange={(event)=>handleChange("tel",event.target.value )}
                      InputLabelProps={{shrink: true}}
                      disabled={true}
                  />
                </div>
                <div className="flex  flex-row space-x-8 ">
                  <TextField
                      label="Section"
                      fullWidth
                      size="small"
                      value={formData.section || ''}
                      onChange={(event)=>handleChange("section",event.target.value.toUpperCase() )}
                      InputLabelProps={{shrink: true}}
                      disabled={true}
                  />

                  <TextField
                      label="lot"
                      fullWidth
                      size="small"
                      value={formData.lot || ''}
                      onChange={(event)=>handleChange("lot",event.target.value )}
                      InputLabelProps={{shrink: true}}
                      disabled={true}
                  />
                </div>
                <div className="flex  flex-row space-x-8 ">
                  <TextField
                      label="Parcelle"
                      fullWidth
                      size="small"
                      value={formData.parcelle || ''}
                      onChange={(event)=>handleChange("parcelle",event.target.value )}
                      InputLabelProps={{shrink: true}}
                      disabled={true}
                  />
                  <TextField
                      label="Rang"
                      fullWidth
                      size="small"
                      value={formData.rang || ''}
                      onChange={(event)=>handleChange("rang",event.target.value )}
                      InputLabelProps={{shrink: true}}
                      disabled={true}
                  />
                </div>
              </>
            </div>
        );
      case 1:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              <Autocomplete
                  fullWidth
                  // disablePortal
                  autoHighlight
                  id="typepiece"
                  options={TDList}
                  size="small"
                  sx={{width: "100%"}}
                  onChange={handleTdList}
                  value={formData.td ? TDList.find((list) => list.TD_CODE === formData.td) || null : null}
                  getOptionLabel={(option) => option.TD_LIBELLE}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          label="Type de travaux divers"
                          error={!!errors.td}
                          helperText={errors.td}
                      />
                  )}
              />
              <>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUpload/>}
                >
                  Modifier votre pièce
                  <input
                      type="file"
                      accept=".pdf"
                      hidden
                      onChange={(event) => handleFileChange(event)}
                  />
                </Button>
                <p className="mt-1 text-sm font-bold">
                  Le fichier ne doit pas dépasser 5 Mo.
                </p>
                {newFile.file && (
                    <>
                      <strong>Nouveau fichier:</strong>
                      <div className={`text-blue-800`}>{newFile.file.name}</div>
                    </>
                )}
                {formData.photo_piece && (
                    <>
                      <a href={apiUrl + `/storage/${formData.photo_piece}`} target="_blank" rel="noreferrer">
                        <FileDownload color={`primary`} sx={{fontSize: 40}}/>
                      </a>
                      {formData.photo_piece}
                    </>
                )}
                {errors.photo_piece && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      {errors.photo_piece}
                    </p>
                )}
              </>
            </div>
        );
      case 2:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8 dark:text-white">
              <Typography
                  className="text-center mb-8 dark:text-white"
                  variant="h5"
              >
                Voulez-vous vraiment modifier votre demande ?
              </Typography>
            </div>
        );
      default:
        return "Unknown step";
    }
  };

  return (
      <NavbarSidebarLayout>
        <div
            className="block items-center justify-center border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
          <div className="mb-1 w-full">
            <Menu titre="Modification de demande de travaux divers"/>
            <div className={`my-8`}>
              <div className="text-center  text-3xl mb-8">
                Modification de la demande de Travaux Divers
              </div>

              <div
                  className="flex w-full flex-col gap-y-6 lg:flex-row lg:justify-center lg:gap-y-0 md:flex-row md:justify-center md:gap-y-0">
                <div
                    className=" rounded-lg bg-white p-2 shadow-2xl dark:bg-gray-800  xl:p-8 lg:w-1/2 border-2 lg:p-5">
                  <div className="invisible md:visible">
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{/*label*/}</StepLabel>
                          </Step>
                      ))}
                    </Stepper>
                  </div>
                  <div>
                    <Typography className="text-center mb-8" variant="h4">
                      {steps[activeStep]}
                    </Typography>
                    {getStepContent(activeStep)}

                    <div className="flex flex-row justify-between pt-18">
                      <Button
                          color="success"
                          variant="outlined"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{mr: 1}}
                      >
                        Précédent
                      </Button>

                      <div className="flex 1 1 auto"/>
                      {activeStep === steps.length - 1 && (
                          <>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={handleManageTD}
                                sx={{mr: 1}}
                                disabled={stateSend}
                            >
                              {isLoadingDivers ?
                                  <>
                                    <CircularProgress color="primary" size="1rem" /> En cours ...
                                  </>
                                  :
                                  <>
                                    Modifier
                                  </>
                              }
                            </Button>
                            {/*  <div className="bg-green-500 mt-12">  <Link to="/register"> Mes demandes</Link> </div> */}
                          </>
                      )}
                      {activeStep !== steps.length - 1 && (
                          <Button
                              onClick={handleNext}
                              variant="contained"
                              color="success"
                          >
                            Suivant
                          </Button>
                      )}
                    </div>
                    <ToastContainer/>
                    {stateSend && (
                        <div className="bg-green-100 mt-12">
                          {" "}
                          <Link to="/dashboard"> Voir mes demandes</Link>{" "}
                        </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavbarSidebarLayout>
  );
// );
};

export default EditTravauxDivers;
