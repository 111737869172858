import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import SonabelLogo from "../../../assets/Sonabel_logo.svg.jpg";
import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";
import { api } from "../../../config/config";
import { toast, ToastContainer } from "react-toastify";
import { EmailRegex } from "../../../components/Validation/EmailRegex";
import { PhoneRegex } from "../../../components/Validation/PhoneRegex";
import { MuiTelInput } from "mui-tel-input";
import { CircularProgress } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const RegisterPage = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState({
    fullName: "",
    email: "",
  });

  const [phoneNumber, setPhoneNumber] = useState("+226");
  const [password, setPassword] = useState("");
  const [matchPwd, setMatchPwd] = useState("");
  const [isCheck, setIsCheck] = useState(false);

  const [validEmail, setValidEmail] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [validMatch, setValidMatch] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [matchError, setMatchError] = useState("");

  const [fullNameError, setFullNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(user.email));
    setEmailError(EMAIL_REGEX.test(user.email) ? "" : "Email invalide");
  }, [user.email]);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
    setPasswordError(
        PWD_REGEX.test(password)
            ? ""
            : "Le mot de passe doit contenir 8 caratères au minimun. " +
            "Doit comprendre des lettres majuscules et minuscules, un chiffre et un caractère spécial."
    );
    setValidMatch(password === matchPwd);
    setMatchError(
        password === matchPwd ? "" : "Les mots de passe ne correspondent pas"
    );
  }, [password, matchPwd]);

  const validateForm = () => {
    let isValid = true;

    // Validation de l'email
    if (!user.email) {
      setEmailError("Veuillez saisir votre email.");
      isValid = false;
    } else if (!EmailRegex(user.email)) {
      setEmailError("Veuillez saisir une adresse email valide.");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Validation du fullName
    if (!user.fullName) {
      setFullNameError("Veuillez saisir votre nom complet.");
      isValid = false;
    } else {
      setFullNameError("");
    }

    // Validation du numéro de téléphone
    if (!phoneNumber) {
      setPhoneNumberError("Veuillez saisir votre numéro de téléphone.");
      isValid = false;
    } else if (!PhoneRegex(phoneNumber)) {
      setPhoneNumberError(
          "Veuillez saisir votre numéro de téléphone en indiquant l'indicatif plus le numéro de téléphone."
      );
    } else {
      setPhoneNumberError("");
    }

    // Validation du mot de passe
    if (!password) {
      setPasswordError("Veuillez saisir votre mot de passe.");
      isValid = false;
    } else if (password.length < 8) {
      setValidPassword(PWD_REGEX.test(password));
      setPasswordError(
          PWD_REGEX.test(password)
              ? ""
              : "Le mot de passe doit contenir 8 caratères au minimun. " +
              "Doit comprendre des lettres majuscules et minuscules, un chiffre et un caractère spécial."
      );
      isValid = false;
    } else {
      setPasswordError("");
    }

    // Validation de la correspondance du mot de passe
    if (password !== matchPwd) {
      setMatchError("Les mots de passe ne correspondent pas.");
      isValid = false;
    } else {
      setMatchError("");
    }

    return isValid;
  };

  // Contrôler le changement d'état des inputs
  const handleChange = (field, value) => {
    setUser((prevData) => ({ ...prevData, [field]: value }));
  };

  // Contrôler le changement d'état de l'input mot de passe
  const handleChangePassword = (value) => {
    setPassword(value);
  };

  // Contrôler le changement d'état de l'input telephone
  const handleChangePhone = (value) => {
    setPhoneNumber(value);
  };

  // Contrôler le changement d'état de l'input confirmer mot de passe
  const handleChangePasswordMatch = (value) => {
    setMatchPwd(value);
  };

  // Contrôler le changement d'état du button check
  const handleChancheCheck = (value) => {
    setIsCheck(value);
  };

  // Fonction pour créer un utilisateur
  const handleSubmitFormRegister = async (e) => {
    e.preventDefault();

    if ((isCheck === true)) {
      if (validateForm() && validEmail && validPassword && validMatch) {
        setIsLoading(true);
        await api
            .post("/account/register", {
              email: user.email,
              fullName: user.fullName,
              phoneNumber: phoneNumber.replace(/\s+/g, ""),
              password: password,
            })
            .then((response) => {
              if (response.status === 200) {
                setIsLoading(false);
                localStorage.setItem("isConnected", true);
                localStorage.setItem(
                    "accessToken",
                    response?.data?.content?.sessionKey
                );
                localStorage.setItem(
                    "userId",
                    response.data.content.user.accountId
                );
                localStorage.setItem(
                    "username",
                    user.fullName
                    // response.data.content.user.fullName
                );
                localStorage.setItem(
                    "email",
                    response.data.content.user.email
                );
                navigate("/dashboard");
              }
            })
            .catch((err) => {
              setIsLoading(false);

              if (!err?.response) {
                toast.warning("Aucune reponse du server");
              } else if (err?.response?.status === 400) {
                toast.warning("Un incident s'est produit lors de l'inscription.");
              } else if (err?.response?.status === 401) {
                toast.warning("L'email ou le numéro de téléphone existe déjà.");
              } else {
                toast.warning("Echec de l'inscription");
              }
            });
      } else {
        toast.warning("Veuillez corriger les erreurs dans le formulaire");
      }
    }else {
      toast.info("Veuillez accepter les termes et conditions pour poursuivre");
    }
  };

  return (
      <main>
        <ToastContainer />
        {localStorage.getItem("isConnected") &&
        localStorage.getItem("accessToken") ? (
            <>
              <Navigate to="/" replace={true} />
            </>
        ) : (
            <div>
              <section className="bg-gray-50 dark:bg-gray-900">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                  <Link
                      to="/"
                      className="text-center flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
                  >
                    <img className="w-50 h-12 mr-2" src={SonabelLogo} alt="logo"/>
                    SONABEL
                  </Link>
                  <div
                      className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                      <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                        Créer votre compte
                      </h1>
                      <form className="space-y-4 md:space-y-6">
                        {/*email*/}
                        <div>
                          <label
                              htmlFor="email"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Email
                          </label>
                          <input
                              type="email"
                              name="email"
                              id="email"
                              autoComplete="off"
                              onChange={(e) => handleChange("email", e.target.value)}
                              value={user.email}
                              aria-invalid={validEmail ? "false" : "true"}
                              aria-describedby="emailnote"
                              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                                  emailError ? "border-red-500" : ""
                              }`}
                              placeholder="votre email"
                              required
                          />
                          {emailError && (
                              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                                {emailError}
                              </p>
                          )}
                        </div>
                        {/*fullName*/}
                        <div>
                          <label
                              htmlFor="fullName"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Nom complet
                          </label>
                          <input
                              type="text"
                              name="fullName"
                              id="fullName"
                              autoComplete="off"
                              onChange={(e) => handleChange("fullName", e.target.value)}
                              value={user.fullName}
                              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Nom complet"
                              required
                          />
                          {fullNameError && (
                              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                                {fullNameError}
                              </p>
                          )}
                        </div>
                        {/*Phone number*/}
                        <div>
                          <label
                              htmlFor="phoneNumber"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Numéro de Téléphone
                          </label>
                          <MuiTelInput
                              fullWidth
                              size="small"
                              value={phoneNumber}
                              onChange={handleChangePhone}
                              placeholder="Votre numéro de téléphone"
                              required
                              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          />
                          {phoneNumberError && (
                              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                                {phoneNumberError}
                              </p>
                          )}
                        </div>
                        {/*Password*/}
                        <div>
                          <label
                              htmlFor="password"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Mot de passe
                          </label>
                          <div className="relative">
                            <input
                                type={showPassword ? "text" : "password"}
                                name="password"
                                id="password"
                                onChange={(e) => handleChangePassword(e.target.value)}
                                value={password}
                                placeholder="••••••••"
                                className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                                    passwordError ? "border-red-500" : ""
                                }`}
                                required
                                aria-invalid={validPassword ? "false" : "true"}
                                aria-describedby="pwdnote"
                            />
                            <button
                                type="button"
                                className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-600"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                  <VisibilityOffIcon/>
                              ) : (
                                  <VisibilityIcon/>
                              )}
                            </button>
                          </div>
                          {passwordError && (
                              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                                {passwordError}
                              </p>
                          )}
                          <br/>
                        </div>
                        {/*confirm password*/}
                        <div>
                          <label
                              htmlFor="confirm-password"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Confirmez le mot de passe
                          </label>
                          <div className="relative">
                            <input
                                type={showPassword1 ? "text" : "password"}
                                name="matchPwd"
                                id="confirm-password"
                                onChange={(e) => handleChangePasswordMatch(e.target.value)}
                                value={matchPwd}
                                placeholder="••••••••"
                                className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                                    matchError ? "border-red-500" : ""
                                }`}
                                required
                                aria-invalid={validMatch ? "false" : "true"}
                                aria-describedby="confirmnote"
                            />
                            <button
                                type="button"
                                className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-600"
                                onClick={() => setShowPassword1(!showPassword1)}
                            >
                              {showPassword1 ? (
                                  <VisibilityOffIcon/>
                              ) : (
                                  <VisibilityIcon/>
                              )}
                            </button>
                          </div>
                          {matchError && (
                              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                                {matchError}
                              </p>
                          )}
                        </div>
                        <div className="flex items-start">
                          <div className="flex items-center h-5">
                            <input
                                id="terms"
                                aria-describedby="terms"
                                type="checkbox"
                                value={isCheck}
                                onChange={(e) => handleChancheCheck(e.target.checked)}
                                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                required
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                                htmlFor="terms"
                                className="font-light text-gray-500 dark:text-gray-300"
                            >
                              j'accepte les{" "}
                              <Link
                                  className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                                  to="/clause-juridique"
                              >
                                Termes et Conditions
                              </Link>
                            </label>
                          </div>
                        </div>

                        <Button
                            className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                            onClick={handleSubmitFormRegister}
                            disabled={isCheck === false}
                        >
                          {isLoading && (
                              <CircularProgress color="primary" size="1rem"/>
                          )}
                          Créez le compte
                        </Button>
                        {(isCheck === false) && (
                            <>
                              <p className={`mt-2 text-sm text-red-600 dark:text-red-500 text-center`}>Pour activer le
                                buton "CREEZ le compte", vous devez accepter les conditions.</p>
                            </>
                        )}
                        <div className="flex flex-row justify-around">
                          <div>
                            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                              Vous avez deja un compte? {""}
                              <Link
                                  to="/login"
                                  className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                              >
                                Connectez-vous
                              </Link>
                            </p>
                          </div>
                          <div>
                            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                              <Link
                                  to="/"
                                  className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                              >
                                <HomeIcon/>
                              </Link>
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
        )}
      </main>
  );
};

export default RegisterPage;
