import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Box from "@mui/material/Box";
import PhoneIcon from "@mui/icons-material/Phone";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
//import com5 from "../../assets/com5.jpg";
import Accueil from "../../assets/Lap-and-Phone.png";

const HeroComponent = () => {
  const imageStyle = {
    width: "600px",
    height: "400px",
    objectFit: "cover",
  };

  return (
    <div
      className="flex flex-row justify-around p-2"
      style={{
        background:
          "linear-gradient(135deg, rgb(36, 145, 58) 0%, #1ec540 50%, #56ab2f 100%)",
        textAlign: "center",
        borderRadius: "10px",
      }}
    >
      <div className="mt-4 flex flex-col items-center">
        <div className="mb-8">
          <motion.div
            initial={{ opacity: 0, scale: 0.3 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-2">
              Bienvenue dans votre guichet virtuel
            </h1>
          </motion.div>
          <motion.div
            initial={{ x: -200, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <p className="text-xl md:text-2xl text-white font-medium">
              Assurance d'un service d'électricité fiable et moderne.
            </p>
          </motion.div>
          <motion.div
            initial={{ x: 250 }}
            animate={{ x: 0 }}
            transition={{ ease: "easeOut", duration: 2 }}
          >
            <p className="text-lg text-white">
              <span className="text-[#f4e826] font-bold">SONABEL</span> -
              Société Nationale d’Electricité du Burkina
            </p>
          </motion.div>
        </div>
        <p className="text-lg text-white font-medium">
          Désormais, plus besoin de vous déplacer pour vos demandes de services.
        </p>

        <p className="text-lg text-white font-semibold italic">
          Accédez à l'ensemble de nos services à partir de votre ordinateur ou
          votre téléphone mobile en toute 
          <span className="text-[#f4e826] font-semibold italic"> sérénité</span>.
        </p>

        <div className="mt-8">
          <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-4">
            <Link to="/list-des-demandes">
              <button className="border text-base  font-bold rounded-full px-8 py-5 shadow-lg bg-white text-[#d3212b] hover:border-red-700 hover:bg-red-700 hover:text-white flex items-center w-full md:w-auto">
                {/*<Box sx={{ mr: 2 }}>*/}
                {/*  <CreateIcon />*/}
                {/*</Box>*/}
                Commencer ma demande
                <NavigateNextIcon />
              </button>
            </Link>

            <button className="border text-base  font-bold rounded-full px-8 py-1 shadow-lg bg-white text-[#24913a]  hover:bg-[#f4e826] hover:text-red-700 flex items-center w-full md:w-auto">
              <Box sx={{ mr: 2 }}>
                <PhoneIcon />
              </Box>
              <Link to="https://api.whatsapp.com/send/?phone=22625313720&text&type=phone_number&app_absent=0" target="_blanck">
                WhatsApp : +226 25313720
              </Link>
            </button>
          </div>
        </div>
         <div className="mt-4 hidden 2xl:block">
          <ul className="grid grid-cols-2 grid-rows-2  gap-4 place-items-start text-yellow-100">
            <li>
              <CheckCircleOutlinedIcon sx={{ color: "rgb(244 232 38)" }} />
              Service Disponible 24h/24 7j/7
            </li>
            <li>
              <CheckCircleOutlinedIcon sx={{ color: "rgb(244 232 38)" }} />
              Assistance de bout en bout
            </li>
            <li>
              <CheckCircleOutlinedIcon sx={{ color: "rgb(244 232 38)" }} />
              Démarche simple et rapide
            </li>
            <li>
              <CheckCircleOutlinedIcon sx={{ color: "rgb(244 232 38)" }} />
              Traçabilité et suivi de votre demande
            </li>
          </ul>
        </div> 
      </div>

      <div className={`hidden lg:inline`}>
        {/*  <img src={com5} alt="commercial" style={imageStyle} /> */}
        <img src={Accueil} alt="commercial" style={imageStyle} />
      </div>
    </div>
  );
};

export default HeroComponent;
