import React from "react";
import {Navigate} from "react-router-dom";
import EditBranchementProvisoire from "../../../EditRequest/Branchement_provisoire/EditBranchementProvisoire";

const PrivateRouteEditBranchementProvisoire = () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <EditBranchementProvisoire/>;
    }
    return <Navigate to="/login"/>
}

export default PrivateRouteEditBranchementProvisoire;
