import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {api, apiUrl} from "../../../config/config";
import {toast, ToastContainer} from "react-toastify";
import {Button, CircularProgress, Step, StepLabel, Stepper, TextField, Typography} from "@mui/material";
import NavbarSidebarLayout from "../../../components/Dashboard/Layouts/NavbarSidebar";
import {Breadcrumb} from "flowbite-react";
import {HiHome} from "react-icons/hi";
import {CloudUpload, FileDownload} from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import {PuissanceMonophase} from "../../demande/ListPuissance/PuissanceMonophase";
import {PuissanceTriphase} from "../../demande/ListPuissance/PuissanceTriphase";
import FormControl from "@mui/material/FormControl";


const EditAbonnement = () => {
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const [activeStep, setActiveStep] = useState(0);

    // On recupère le code du reçu
    const [formData, setFormData] = useState({});
    const [oldAmperage, setOldAmperage] = useState("");
    //Gestion des erreur
    const [errors, setErrors] = useState({});

    // deactiver le bouton submit lorsqu'on actionne le bouton soumettre
    const [stateSend, setStateSend] = useState(false);
    const [puissances, setPuissances] = useState([]);
    const [isNext, setIsNext] = useState(false);
    //Controler la selection de la puissance
    const [showAlert, setShowAlert] = useState(false);
    const files = useState({
        cnib: '',
        recu: ''
    });

    // Mise à jour des status des fichier après suppression
    const filesDelete = useState({
        cnib: false,
        recu: false
    });

    const [newFile, setNewFile] = useState({
        file: '',
        file_branchement: '',
    });

    // Multipart pour l'envoie des fichiers
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    // Etapes du steps
    const steps = ["Les informations sur le branchement", "Pièces à fournir","Puissance souhaitée", "Validation"];

    //  Recherche de la demande à modifier
    useEffect(()=>{
        // Chercher une demande
        try {
            setIsLoading(true);
            api.get(`/demande/${location.state.abonnementId}`).then(response => {
                if (response.status === 200){
                    setOldAmperage(response.data.content.type_amperage)
                    setFormData(response.data.content);
                    setIsLoading(false);
                    setIsNext(true);
                }
            }).catch(() => {
                toast.warning("Erreur serveur.");
                setIsLoading(false);
            })
        }catch (e) {

        }
    }, [location.state.abonnementId]);

    //Gérer les puissances en fonction du types d'ampérange
    useEffect(() => {
        let filtered = [];
        if (formData.type_amperage === "monophasé") {
            filtered = PuissanceMonophase.filter(({ key }) => key !== 'DT');
            setPuissances(filtered);
            if (!filtered.some(p => p.key === formData.puissance)) {
                setFormData(prevData => ({ ...prevData, puissance: filtered[0]?.key || '' }));
            }
        } else {
            filtered = PuissanceTriphase.filter(({ key }) => key !== 'DT');
            setPuissances(filtered);
            if (!filtered.some(p => p.key === formData.puissance)) {
                setFormData(prevData => ({ ...prevData, puissance: filtered[0]?.key || '' }));
            }
        }
    }, [formData.puissance, formData.type_amperage]);

    const handleNext = () => {
        const abonnement = validateForm(formData);
        if (Object.keys(abonnement).length === 0) {
            setErrors({});
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            setErrors(abonnement);
        }
    };

    const handleBack = () => {
        setStateSend(false);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //  Mettre à jours nos données
    const handleChange = (field, value) => {
        setFormData((prevData) => ({ ...prevData, [field]: value }));
    };


    const validateForm = (formData) => {
        const errors = {};
        if (activeStep === 1) {
            // if (!newFile.file ) {
            //     errors.file = "Ce champ est obligatoire";
            // }
            // if (!newFile.file_branchement) {
            //     errors.file_branchement = "Ce champ est obligatoire";
            // }
            if (newFile.file && newFile.file.size > (2*1024*1024) ) {
                errors.file = "Le fichier sélectionné est trop volumineux."
            }

            if (newFile.file_branchement && newFile.file_branchement.size > (2*1024*1024) ) {
                errors.file_branchement = "Le fichier sélectionné est trop volumineux."
            }
            if (formData.type_amperage === 'Triphasé' && !formData.type_amperage ) {
                errors.type_amperage = "Ce champ est obligatoire";
            }
            if (formData.type_amperage === 'Triphasé' && !formData.puissance_demande) {
                errors.puissance_demande = "Ce champ est obligatoire";
                setShowAlert(true);
            }
        }
        return errors;
    };

    // Gérer l'import de la CNIB
    const handleFileChange = (event) => {
        setNewFile((prevData) => ({ ...prevData, "file": event.target.files[0] }));
    };

    // Gérer l'import du reçu de branchement
    const handleFileBranchementChange = (event) => {
        setNewFile((prevData) => ({ ...prevData, "file_branchement": event.target.files[0] }));
    };

    // Supprimer la CNIB
    const handleDeleteFile = async () => {
        try {
            setIsLoading(true);
            if (formData.photo_piece && newFile.file){
                await api.delete(`/storage/${formData.photo_piece}`).then(response => {
                    if (response.status === 201) {
                        filesDelete.cnib = true;
                        setIsLoading(false);
                        return true;
                    } else {
                        setIsLoading(false);
                        toast.error("Fichier non supprimer");
                        filesDelete.cnib = false;
                        return false;
                    }
                }).catch((e) => {
                    setIsLoading(false);
                    toast.error("Fichier non supprimer");
                    filesDelete.cnib = false;
                    return false;
                })
            }else {
                filesDelete.cnib = true;
                setIsLoading(false);
                await handleSendFile();
            }
        }catch (e) {
        }
    };

    // Supprimer le reçu de branchement
    const handleDeleteFileRecu = async () => {
        try {
            setIsLoading(true);
            if (formData.document_parcelle){
                await api.delete(`/storage/${formData.document_parcelle}`).then(response => {
                    if(response.status === 201){
                        filesDelete.recu = true;
                        setIsLoading(false);
                        return true;
                    } else {
                        setIsLoading(false);
                        toast.error("Demande non enregistrée");
                        filesDelete.recu = false;
                        return false;
                    }
                }).catch(() => {
                    filesDelete.recu = false;
                    setIsLoading(false)
                    toast.error("Demande non enregistré");
                })
            }else {
                filesDelete.recu = true;
                setIsLoading(false);
                await handleDeleteFileRecu();
            }
        }catch (e) {

        }
    };

    //envoie des fichiers avant l'envoie de la demande
    const handleSendFile =  async () =>{
        if(newFile.file){
            setIsLoading(true);
            // create a new FormData object and append the file to it
            const formDataAbonnementCNIB = new FormData();
            formDataAbonnementCNIB.append("file", newFile.file);

            try {
                await api
                    .post("/storage/upload", formDataAbonnementCNIB, config)
                    .then((response) => {
                        if (response.status === 201) {
                            files.cnib = response.data.content;
                        }
                    })
                    .catch((err) => {
                        if (err.status === 413) {
                            toast.error("Votre CNIB est fichier volumineux");
                        }
                        setIsLoading(false);
                        //On envoie une notification disant qu'il y a eu echec
                        toast.error("Demande non enregistré");
                    });
            } catch (e) {}
        }
    }

    // envoie du fichier reçu branchement
    const handleSendFileRecu = async () =>{
        if(newFile.file && newFile.file_branchement){
            setIsLoading(true);
            // create a new FormData object and append the file to it
            const formDataAbonnementRecu = new FormData();
            formDataAbonnementRecu.append("file", newFile.file_branchement);

            try {
                await api
                    .post("/storage/upload", formDataAbonnementRecu, config)
                    .then((response) => {
                        if (response.status === 201) {
                            files.recu = response.data.content;
                        }
                    })
                    .catch((err) => {
                        if (err.status === 413) {
                            toast.error("Votre CNIB est fichier volumineux");
                        }
                        setIsLoading(false);
                        //On envoie une notification disant qu'il y a eu echec
                        toast.error("Demande non enregistré");
                    });
            } catch (e) {}
        }
    }

    // envoi des données
    const handleEditAbonnementDemande = async (files) => {
        setStateSend(true);
        setIsLoading(true);
        // On actualise les valeurs de formData avec les nouvelles valeurs
        if(files.cnib || files.recu) {
            formData.photo_piece = files.cnib;
            formData.document_parcelle = files.recu;
        }

        // On retire les champs qui n'ont pas été soumis pour validation
        const filteredDataAbonnement = Object.fromEntries(
            Object.entries(formData).filter(([key, value]) => value !== null)
        );
        // Soumettre la demande du client
        try {
            await api
                .put("/demande", filteredDataAbonnement)
                .then((response) => {
                    if (response.status === 201) {

                        toast.success(
                            "Demande enregistrée ! Nous vous enverrons des notifications pour le suivi de la demande par mail ou SMS."
                        );
                        setIsLoading(false);
                        setStateSend(true); // désactiver le bouton
                        setTimeout(()=> {
                            navigate("/dashboard");
                        },2000);
                    }
                })
                .catch(() => {
                    setStateSend(false);
                    toast.error("Demande non enregistré");
                    setIsLoading(false);
                });
        } catch (err) {
        }

    };

    const handleEditManageSendFunction = async () => {
        try {
            if (newFile.file) {
                // Suppression des fichiers
                await handleDeleteFile();
            }
            if (newFile.file_branchement) {
                // Suppression des fichiers reçus
                await handleDeleteFileRecu();
            }

            // Vérification des fichiers supprimés
            if (filesDelete.cnib) {
                // Envoi de CNIB
                await handleSendFile();
            }
            // On verifie que le reçu a été supprimer et on enregistre le nouveau fichier
            if (filesDelete.recu) {
                // Envoi du reçu de paiement
                await handleSendFileRecu();
            }

            // Si notre objet est bien renseigné, on envoie le formulaire avec les valeurs de la CNIB et du reçu
            if (files.recu || files.cnib) {
                await handleEditAbonnementDemande(files);
            } else {
                await handleEditAbonnementDemande(files);
            }
        } catch (e) {
        }
    }



    // Gestion du stepper
    const getStepContent = (step) => {
        switch (step) {

            case 0:
                return (
                    <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
                        { isLoading && <CircularProgress/> }
                        {formData && (
                            <>
                                <div className="flex  flex-row space-x-8 ">
                                    <TextField
                                        id="dem_numero"
                                        label="Numéro branchement"
                                        fullWidth
                                        size="small"
                                        value={formData.dem_numero || ""}
                                        InputLabelProps={{shrink: true}}
                                        disabled={true}
                                    />
                                    <TextField
                                        id="exploitation"
                                        label="Exploitation"
                                        fullWidth
                                        size="small"
                                        value={formData.exploitation || ""}
                                        InputLabelProps={{shrink: true}}
                                        disabled={true}
                                    />
                                </div>
                                <div className="flex  flex-row space-x-8 ">
                                    <TextField
                                        id="nom"
                                        label="Nom"
                                        fullWidth
                                        size="small"
                                        value={formData.nom || ""}
                                        InputLabelProps={{shrink: true}}
                                        disabled={true}
                                    />
                                    <TextField
                                        id="prenom"
                                        label="Prenom"
                                        fullWidth
                                        size="small"
                                        value={formData.prenom || ""}
                                        InputLabelProps={{shrink: true}}
                                        disabled={true}
                                    />
                                </div>
                                <div className="flex  flex-row space-x-8 ">
                                    <TextField
                                        label="Email"
                                        fullWidth
                                        size="small"
                                        value={formData.email || ""}
                                        InputLabelProps={{shrink: true}}
                                        disabled={true}
                                    />
                                    <TextField
                                        id="tel2"
                                        label="Telephone"
                                        fullWidth
                                        size="small"
                                        value={formData.tel2 || ""}
                                        InputLabelProps={{shrink: true}}
                                        disabled={true}
                                    />
                                </div>
                                <div className="flex  flex-row space-x-8 ">
                                    <TextField
                                        id="section"
                                        label="Section"
                                        fullWidth
                                        size="small"
                                        value={formData.section || ""}
                                        InputLabelProps={{shrink: true}}
                                        disabled={true}
                                    />

                                    <TextField
                                        id="lot"
                                        label="lot"
                                        fullWidth
                                        size="small"
                                        value={formData.lot || ""}
                                        InputLabelProps={{shrink: true}}
                                        disabled={true}
                                    />
                                </div>
                                <div className="flex  flex-row space-x-8 ">
                                    <TextField
                                        id='parcelle'
                                        label="Parcelle"
                                        fullWidth
                                        size="small"
                                        value={formData.parcelle || ""}
                                        InputLabelProps={{shrink: true}}
                                        disabled={true}
                                    />
                                    <TextField
                                        id='rang'
                                        label="Rang"
                                        fullWidth
                                        size="small"
                                        value={formData.rang || ""}
                                        InputLabelProps={{shrink: true}}
                                        disabled={true}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                );
            case 1:
                return (
                    <div className={`mt-8`}>
                        <div className={`text-center`}>
                            <p className="mt-1 text-sm text-red-700 font-bold">L'ensemble des fichiers à charger ne
                                doivent
                                pas dépasser 5 Mo.</p>

                            {/*CNIB document upload*/}
                            <div className={`mt-2 `}>
                                <div>
                                    <p className={`font-bold`}>Télécharger votre CNIB</p>
                                    {formData.photo_piece && (
                                        <>
                                            <a href={apiUrl + `/storage/${formData.photo_piece}`} target="_blank" rel="noreferrer">
                                                <FileDownload color={`primary`} sx={{fontSize: 40}}/> {formData.photo_piece}
                                            </a>
                                        </>
                                    )}
                                </div>
                                <Button
                                    component="label"
                                    role={undefined}
                                    variant="contained"
                                    tabIndex={-1}
                                    startIcon={<CloudUpload/>}
                                >
                                    Modifier votre CNIB
                                    <input
                                        type="file"
                                        hidden
                                        accept=".pdf"
                                        onChange={event => handleFileChange(event)}
                                    />
                                </Button>
                                {/*Upload du nouveau fichier*/}
                                <div className={`text-red-700`}>
                                    {newFile.file && (
                                        <p>{newFile.file.name}</p>
                                    )}
                                </div>
                                <div>
                                    {errors.file && (
                                        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.file}</p>
                                    )}
                                </div>
                            </div>

                            {/*reçu de branchement*/}
                            <div className={`mt-6 mb-4`}>
                                <div>
                                    <p className={`font-bold`}>Télécharger reçu de branchement</p>
                                    {formData.document_parcelle && (
                                        <>
                                            <a href={apiUrl + `/storage/${formData.document_parcelle}`} target="_blank" rel="noreferrer">
                                                <FileDownload color={`primary`} sx={{fontSize: 40}}/> {formData.document_parcelle}
                                            </a>
                                        </>
                                    )}
                                </div>
                                <Button
                                    component="label"
                                    role={undefined}
                                    variant="contained"
                                    tabIndex={-1}
                                    startIcon={<CloudUpload/>}
                                >
                                    Modifier le reçu de branchement
                                    <input
                                        type="file"
                                        accept=".pdf"
                                        hidden
                                        onChange={event => handleFileBranchementChange(event)}
                                    />
                                </Button>
                                <div className={`text-red-700`}>
                                    {newFile.file_branchement && (
                                        <p>{newFile.file_branchement.name}</p>
                                    )}
                                </div>
                                <div>
                                    {errors.file_branchement && (
                                        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.file_branchement}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <>
                        {
                            (oldAmperage === "triphasé" || "monophasé") && (
                                <>
                                    <div className="space-y-5 mt-8 mb-8">
                                        <>
                                            <>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Choisissez le type ampérage</InputLabel>
                                                    <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        value={formData.type_amperage}
                                                        label="type_amperage"
                                                        size="small"
                                                        sx={{width: "100%"}}
                                                        onChange={(e) => handleChange("type_amperage", e.target.value)}
                                                        disabled={oldAmperage === "monophasé"}
                                                    >
                                                        <MenuItem value="monophasé">Monophasé</MenuItem>
                                                        <MenuItem value="triphasé">Triphasé</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                {showAlert && (
                                                    <FormHelperText error>{errors.type_amperage}</FormHelperText>
                                                )}
                                                <div className={`flex md:justify-between md:flex-row sm:flex-row space-x-8`}>
                                                    <div>
                                                        Monophasé
                                                        <p>
                                                            Pour les applications domestiques et les petits appareils.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        Triphasé
                                                        <p>
                                                            Pour les grandes installations industrielles et commerciales.
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                            <InputLabel id="puissance">Ampérage souhaité</InputLabel>
                                            <Select
                                                labelId="puissance"
                                                id="puissance"
                                                value={formData.puissance}
                                                label="puissance"
                                                size="small"
                                                sx={{width: "100%"}}
                                                onChange={(e) => handleChange("puissance", e.target.value)}
                                                disabled={oldAmperage === "monophasé"}
                                            >
                                                {/*{puissances}*/}
                                                {puissances.map((k) => (
                                                    <MenuItem key={k.key} value={k.key}>{k.value}</MenuItem>
                                                ))}
                                            </Select>
                                            {showAlert && (
                                                <FormHelperText error>{errors.puissance_demande}</FormHelperText>
                                            )}
                                        </>
                                    </div>
                                </>
                            )
                        }
                    </>
                );
            case 3:
                return (
                    <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8 dark:text-white">
                        <Typography
                            className="text-center mb-8 dark:text-white"
                            variant="h5"
                        >
                            Voulez-vous vraiment soumettre votre demande ?
                        </Typography>
                    </div>
                );
            default:
                return "Unknown step";
        }
    };

    return (
        <NavbarSidebarLayout>
            <div
                className="block items-center justify-center border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
                <div className="mb-1 w-full">
                    <div className="mb-4">
                        <Breadcrumb className="mb-4">
                            <Breadcrumb.Item href="/dashboard">
                                <div className="flex items-center gap-x-3">
                                    <HiHome className="text-xl"/>
                                    <span className="dark:text-white">Accueil</span>
                                </div>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href="/list-des-demandes">Liste des services </Breadcrumb.Item>
                            <Breadcrumb.Item>abonnement</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className={`my-8`}>
                        <div className="text-center  text-3xl mb-8">
                            Demande d'abonnement
                        </div>

                        <div
                            className="flex w-full flex-col gap-y-6 lg:flex-row lg:justify-center lg:gap-y-0 md:flex-row md:justify-center md:gap-y-0">
                            <div
                                className=" rounded-lg bg-white p-2 shadow-2xl dark:bg-gray-800  xl:p-8 lg:w-1/2 border-2 lg:p-5">
                                <div className="invisible md:visible">
                                    <Stepper activeStep={activeStep} alternativeLabel>
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel>{/*label*/}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </div>
                                <div>
                                    <Typography className="text-center mb-8" variant="h5">
                                        {steps[activeStep]}
                                    </Typography>
                                    {getStepContent(activeStep)}

                                    <div className="flex flex-row justify-between pt-18">
                                        <Button
                                            color="success"
                                            variant="outlined"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            sx={{mr: 1}}
                                        >
                                            Précédent
                                        </Button>

                                        <div className="flex 1 1 auto"/>
                                        {activeStep === steps.length - 1 && (
                                            <>
                                                <Button
                                                    color="secondary"
                                                    variant="outlined"
                                                    onClick={handleEditManageSendFunction}
                                                    sx={{mr: 1}}
                                                    disabled={stateSend}
                                                >
                                                    {isLoading && <CircularProgress color="secondary" size="1rem" />}
                                                    Modifier
                                                </Button>
                                                {/*  <div className="bg-green-500 mt-12">  <Link to="/register"> Mes demandes</Link> </div> */}
                                            </>
                                        )}
                                        {activeStep !== steps.length - 1 && (
                                            <Button
                                                onClick={handleNext}
                                                variant="contained"
                                                color="success"
                                                disabled={!isNext}
                                            >
                                                Suivant
                                            </Button>
                                        )}
                                    </div>
                                    <ToastContainer/>
                                    {stateSend && (
                                        <div className="bg-green-100 mt-12">
                                            {" "}
                                            <Link to="/dashboard"> Voir mes demandes</Link>{" "}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </NavbarSidebarLayout>
    );
}

export default EditAbonnement;
