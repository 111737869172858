import React from "react";
import {Navigate} from "react-router-dom";
import RequestList from "../../RequestList/RequestList";

const PrivateRouteRequestList = () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <RequestList/>;
    }
    return <Navigate to="/login"/>
}

export default PrivateRouteRequestList;
