export default function CnibValid (date) {
    // On recupère la date d'aujourd'hui
    const todayDate = new Date();

    // La date de délivrance de la pièce
    const delivyDate = new Date(date);

    const diffDates = todayDate - delivyDate;

    return diffDates / (1000 * 60 * 60 * 24 * 365.25);
}
