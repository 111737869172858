import React, { useState} from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";

import {
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
} from "@mui/material";
import NavbarSidebarLayout from "../Dashboard/Layouts/NavbarSidebar";

import { api } from "../../config/config";
import {Breadcrumb} from "flowbite-react";
import {HiHome} from "react-icons/hi";
import ServerStatus from "../ServerStatus";
import LogoSonabel from "../../assets/Logo-site-SONABEL-def.png";

const DemandeIndex = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);

  const [formData, setFormData] = useState({
    exploitation: "",
    puissance: "3A",
    compteur: "",
    index: "",
    type_amperage: "monophasé", // triphasé
    typeCompteur: "POSTPAYE",
    police: "",
  });

  const [error, setError] = useState({});

  // deactiver le bouton submit lorsqu'on actionne le bouton soumettre
  const [stateSend, setStateSend] = useState(false);
  // const [stateSendMessage, setStateSendMessage] = useState(false);

  const [isNext, setIsNext] = useState(false);
  const [abonne, setAbonne] = useState({});
  const [message, setMessage] = useState("");
  const [period, setPeriod] = useState("");

  const [lastIndex, setLastIndex] = useState("");
  const avertissement = <p className="text-red-600">Avertissement</p>;

  const steps = [avertissement,"Recherche du client", "Mes informations", "Saisir Index"];

  // Vérifier l'index saisie
  const handleValideIndex = () => {
    let isValid = true;
    if(formData.index < lastIndex){
      error.index = "Index invalide."
      isValid = false;
    }
    return isValid;
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (stateSend) {
      setStateSend(true)
    }else {
      setStateSend(false);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleDemande = () => {
    if (handleValideIndex() && formData.index.trim()) {
      try {
        const demandeService = {
          lot: abonne.lot,
          parcelle: abonne.parcelle,
          type_sce: location.state.idBranchementAbon,
          nom: abonne.nom,
          prenom: abonne.prenom,
          tel2: abonne.tel || '00000',
          email: abonne.email,
          exploitation: abonne.exploitation,
          //type_amperage: formData.type_amperage,
          //puissance: formData.puissance,
          section: abonne.section,
          user_id: props.userId,
          type_compteur: formData.typeCompteur,
          rang: abonne.rang,
          numero_police: abonne.police,
          numero_compteur: abonne.numeroCompteur,
          index: formData.index,
          prop_nom: abonne.nom,
          prop_prenom: abonne.prenom,
          cat_cli_code: abonne.typeAbonne
        };
        const filteredDataIndex = Object.fromEntries(
            Object.entries(demandeService).filter(([key, value]) => value !== null || undefined || '')
        );
        setIsLoading(true);
        api
            .post("/demande", filteredDataIndex)
            .then((response) => {
              if (response.status === 201) {
                setFormData({
                  exploitation: "",
                  puissance: "3A",
                  compteur: "",
                  index: "",
                  type_amperage: "monophasé", // triphasé
                  typeCompteur: "POSTPAYE",
                  police: "",
                });
                toast.success("L'index a été enregistré avec succès !");
                setIsLoading(false);
                setStateSend(true); // désactiver le bouton
                // setStateSendMessage(true); // désactiver le bouton
                setTimeout(()=> {
                  navigate("/dashboard");
                },2000);
              }
            })
            .catch(() => {
              toast.error("Index non enregistré!");
              setIsLoading(false);
            });
        // setErrorMessage("");
      } catch (erreur) {
      }
    } else {
      //toast.error("Vueillez saisir le index de la demande");
      setError((prevError) => ({
        ...prevError,
        index: "Vueillez saisir l'index",
      }));
    }
  };

  const handleSearchIndex = async () => {
    try {
      let url = `/dernierindex/${formData.exploitation}/${formData.police}`;
      await api
          .get(url)
          .then((response) => {
            if (response.status === 200 && response.data.content) {
              // On recupère son dernier index
              setLastIndex(response.data.content.DERNIER_INDEX);
              // On verifie s'il peut envoyer son index
              const ifIsSendIndex = handleCalculFacturationGroupe(response.data.content['SAI_GROUPE#']);
              if (ifIsSendIndex === true) {
                setStateSend(false);
                setMessage("");
              } else if (ifIsSendIndex === false) {
                setStateSend(true);
                setMessage(`Veuillez attendre votre période de facturation qui est du : `);
                if (response.data.content['SAI_GROUPE#'] === 1) {
                  setPeriod("1er au 15 du mois");
                } else if (response.data.content['SAI_GROUPE#'] === 2){
                  setPeriod("16 à la fin mois");
                }
              }
            }
          })
          .catch((e) => {
          });
    }catch (e) {

    }
  };

  const handleCalculFacturationGroupe = (faturationGroup) => {
    const currentDate = new Date();
    const daysOfMonth = currentDate.getDate();

    // recupérer le dernier jour du mois courant
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();

    if (faturationGroup === 1) {
      if (daysOfMonth >= 1 && daysOfMonth <= 15) {
        return true;
      } else {
        return false;
      }
    }

    if (faturationGroup === 2) {
      if (daysOfMonth >= 16 && daysOfMonth <= lastDayOfMonth) {
        return true;
      } else {
        return false;
      }
    }
  }

  const handleRechercheClient = async () => {
    // Activer le loader
    setIsLoading(true);
    //`/abonne/${formData.exploitation}/${formData.compteur}/${formData.typeCompteur}`
    let lien = `/abonne/${formData.exploitation}/${formData.police}/${formData.typeCompteur}`;
    await api
        .get(lien)
        .then((response) => {

          if (response.status === 200 && response.data.content) {
            handleSearchIndex();
            setAbonne(response.data.content);

            toast.success(
                `Client identifié : ${response.data.content.nom} ${response.data.content.prenom}`
            );
            //setStateSend(true);
            setIsNext(true);
            setIsLoading(false);
          } else {
            toast.error(
                `Désolé ces informations ne figurent pas dans notre système,`
            );
            setIsLoading(false);
            setIsNext(false);
          }
        })
        .catch(() => {
          // Désactiver le loader en cas d'erreur
          setIsLoading(false);
          setIsNext(true);
          toast.error(
              `Désolé ces informations ne figurent pas dans notre système,`
          );
        });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
            <div>
              <div className="flex flex-col items-center">
                <div className={`text-center items-center mt-4`}>
                  <img src={LogoSonabel} alt="" width={200}/>
                </div>
                <div className={`text-justify m-8`}>
                  <h2 className="mb-2 text-lg font-bold text-gray-900 dark:text-white">Conditions pour cette demande : </h2>
                  <ol className="max-w-md space-y-1 text-gray-500 list-decimal list-inside dark:text-gray-400">
                    <li>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      Assurez-vous d'avoir le numéro de police et le numéro d'exploitation pour effectuer cette demande.
                    </span>
                    </li>
                    <li>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      La SONABEL décline toute responsabilité pour toute inexactitude des informations fournies par les demandeurs.
                    </span>
                    </li>
                    <li>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      Aucun remboursement ne sera accordé en cas de fausses informations.
                      La SONABEL se réserve le droit de poursuivre en justice les cas de fraude.
                    </span>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
        );
      case 1:
        return (
            <div>
              <div className=" mt-5 space-y-8">
                <TextField
                    id="exploitation"
                    label="Saisir le numero d'exploitation"
                    fullWidth
                    size="small"
                    value={formData.exploitation}
                    onChange={(e) => handleChange("exploitation", e.target.value)}
                    type="number"
                />

                <TextField
                    label="Saisir le numero de police"
                    fullWidth
                    size="small"
                    value={formData.police}
                    onChange={(e) => handleChange("police", e.target.value)}
                    type="number"
                />
              </div>
              <Button
                  color="success"
                  variant="contained"
                  onClick={handleRechercheClient}
                  sx={{mb: 5, mt: 8, mr: 1, width: "100%"}}
                  disabled={isLoading || props.serverOffline}
              >
                {isLoading && <CircularProgress/>}{" "}
                <span className="ml-5">Rechercher</span>
              </Button>
            </div>
        );
      case 2:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              {abonne && (
                  <>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Numero compteur"
                          fullWidth
                          size="small"
                          value={abonne.numeroCompteur}
                          disabled={true}
                      />
                      <TextField
                          label="Numero de police"
                          fullWidth
                          size="small"
                          value={abonne.police}
                          disabled={true}
                      />
                    </div>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Nom"
                          fullWidth
                          size="small"
                          value={abonne.nom}
                          disabled={true}
                      />
                      <TextField
                          label="Prenom"
                          fullWidth
                          size="small"
                          value={abonne.prenom}
                          disabled={true}
                      />
                    </div>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Email"
                          fullWidth
                          size="small"
                          value={abonne.email}
                          disabled={true}
                      />
                      <TextField
                          label="Telephone"
                          fullWidth
                          size="small"
                          value={abonne.tel}
                          disabled={true}
                      />
                    </div>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Section"
                          fullWidth
                          size="small"
                          value={abonne.section}
                          disabled={true}
                      />

                      <TextField
                          label="lot"
                          fullWidth
                          size="small"
                          value={abonne.lot}
                          disabled={true}
                      />
                    </div>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Parcelle"
                          fullWidth
                          size="small"
                          value={abonne.parcelle}
                          disabled={true}
                      />
                      <TextField
                          label="Rang"
                          fullWidth
                          size="small"
                          value={abonne.rang}
                          disabled={true}
                      />
                    </div>
                  </>
              )}
            </div>
        );
      case 3:
        return (
            <div className=" space-y-5 mt-8 mb-8">
              {
                  (message) && (
                      <div>
                        <p className={`text-center text-red-700 text-xl font-bold mt-8 mb-2`}>{message}</p>
                        <p className={`text-center text-green-700 text-xl font-bold mt-2 mb-8`}>{period}</p>
                      </div>
                  )
              }
              <div className="text-center text-4xl mb-8">
                Dernier Index: <strong className={`text-green-800`}>{lastIndex}</strong>
              </div>
              <TextField
                  label="Saisir votre index"
                  fullWidth
                  size="small"
                  value={formData.index}
                  onChange={(e) => handleChange("index", e.target.value)}
                  error={!!error.index}
                  helperText={error.index}
                  disabled={stateSend}
                  type='number'
              />
            </div>
        );
      default:
        return "Unknown step";
    }
  };

  return (
      <NavbarSidebarLayout>
        {props.serverOffline && <ServerStatus />}
        <div
            className="block items-center justify-center border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
          <div className="mb-1 w-full">
            <div className="mb-4">
              <Breadcrumb className="mb-4">
                <Breadcrumb.Item href="/dashboard">
                  <div className="flex items-center gap-x-3">
                    <HiHome className="text-xl"/>
                    <span className="dark:text-white">Accueil</span>
                  </div>
                </Breadcrumb.Item>
                <Breadcrumb.Item href="/list-des-demandes">Liste des services </Breadcrumb.Item>
                <Breadcrumb.Item>Saisie d'index</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className={`my-8`}>
              <div className="text-center  text-3xl mb-8">
                {props.titre}
              </div>

              <div
                  className="flex w-full flex-col gap-y-6 lg:flex-row lg:justify-center lg:gap-y-0 md:flex-row md:justify-center md:gap-y-0">
                <div
                    className=" rounded-lg bg-white p-2 shadow-2xl dark:bg-gray-800  xl:p-8 lg:w-1/2 border-2 lg:p-5">
                  <div className="invisible md:visible">
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{/*label*/}</StepLabel>
                          </Step>
                      ))}
                    </Stepper>
                  </div>
                  <div>
                    <Typography className="text-center mb-8" variant="h5">
                      {steps[activeStep]}
                    </Typography>
                    {getStepContent(activeStep)}
                    <div className="flex flex-row justify-between pt-18">
                    {activeStep === 0 ? (
                      <Button
                        color="success"
                        variant="outlined"
                        onClick={() => {
                          navigate("/list-des-demandes");
                        }}
                        sx={{ mr: 1 }}
                      >
                        Refuser
                      </Button>
                    ) : (
                      <Button
                        color="success"
                        variant="outlined"
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Précédent
                      </Button>
                    )}
                    <div className="flex 1 1 auto" />
                    {activeStep === steps.length - 1 && (
                      <>
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={handleDemande}
                          sx={{ mr: 1 }}
                          disabled={stateSend}
                        >
                          {isLoading && (
                            <CircularProgress color="secondary" size="1rem" />
                          )}
                          <span className="ml-1">Soumettre</span>
                        </Button>
                      </>
                    )}
                    {activeStep !== steps.length - 1 && (
                      <div>
                        <Button
                          onClick={handleNext}
                          variant="contained"
                          color="success"
                          disabled={activeStep === 1 && !isNext}
                        >
                          {activeStep === 0 ? "Accepter" : "Suivant "}
                        </Button>
                      </div>
                    )}
                  </div>

                    {/* <div className="flex flex-row justify-between pt-18">
                      <Button
                          color="success"
                          variant="outlined"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{mr: 1}}
                      >
                        Précédent
                      </Button>

                      <div className="flex 1 1 auto"/>
                      {activeStep === steps.length - 1 && (
                          <>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={handleDemande}
                                sx={{mr: 1}}
                                disabled={stateSend}
                            >
                              {isLoading && <CircularProgress color="secondary" size="1rem" />}
                              Soumettre
                            </Button>

                          </>
                      )}
                      {activeStep !== steps.length - 1 && (
                          <Button
                              onClick={handleNext}
                              variant="contained"
                              color="success"
                              disabled={((!isNext && activeStep === 0) || (isNext && activeStep === 0)) ? (isNext) : (!isNext)}
                          >
                            Suivant
                          </Button>
                      )}
                    </div> */}
                    <ToastContainer/>
                   {/*  {stateSendMessage && (
                        <div className="bg-green-100 mt-12">
                          {" "}
                          <Link to="/dashboard"> Voir mes demandes</Link>{" "}
                        </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavbarSidebarLayout>
  );
};

export default DemandeIndex;
