import React from "react";
//import MobileWeb from "../../assets/Slide-1.jpg";
// import MobileWeb from "../../assets/images/2--Accedez-à-nos-services-en-ligne2.jpg";
import MobileWeb from "../../assets/images/e-guichet accueil.jpg";

const ServiceAccess = () => {
  const imageStyle = {
    width: "100%",
    height: "400px",
    objectFit: "contain",
  };

  return (
    <div
      className="flex flex-row  justify-around p-2"
      style={{
        background:"inherit",
          // "linear-gradient(135deg, rgb(36, 145, 58) 0%, #1ec540 50%, #56ab2f 100%)",
        textAlign: "center",
        borderRadius: "10px",
      }}
    >
      <img src={MobileWeb} alt="Accès web" style={imageStyle} />
    </div>
  );
};

export default ServiceAccess;
