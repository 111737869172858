import React, { useState } from "react";
import { Link,useLocation,useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress } from "@mui/material";
import ServerStatus from "../ServerStatus";

import {
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
} from "@mui/material";
import NavbarSidebarLayout from "../Dashboard/Layouts/NavbarSidebar";

import { api } from "../../config/config";
import {Breadcrumb} from "flowbite-react";
import {HiHome} from "react-icons/hi";

//import { FormProvider } from "../../context/FormContext";

const DemandeMotif = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);

  const [formData, setFormData] = useState({
    exploitation: "",
    puissance: "3A",
    compteur: "",
    motif: "",
    rccm: "",
    ifu: "",
    type_amperage: "monophasé", // triphasé
    typeCompteur: "PREPAYE",
    police: "",
    nom_entreprise: "",
  });

  const [errors, setErrors] = useState({});

  // deactiver le bouton submit lorsqu'on actionne le bouton soumettre
  const [stateSend, setStateSend] = useState(false);

  const [isNext, setIsNext] = useState(false);
  const [abonne, setAbonne] = useState({});

  const isEntreprise = props.type === "entreprise";

  // Menu du stepper
  const steps = [
    "Recherche du client",
    "Mes informations",
    "Motif de ma demande",
  ];

  // Function qui gère le stepper en fonction de sa position dans le but d'avancer de la position dans laquelle il se trouve
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  //Retourner au pages précédentes du stepper
  const handleBack = () => {
    setStateSend(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //Ecouter les changement qui s'effectue dans les formulaires
  const handleChange = (field, value) => {
    if(value === "PREPAYE" || value === 'POSTPAYE') {
      setIsNext(false);
      setErrors({});
    }
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleFieldFocus = (field) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  };

  // Validation des champs du formulaire
  const validateForm = (formData) => {
    const errors = {};
    if (activeStep === 2) {
      if (isEntreprise) {
        if (!formData.ifu.trim()) {
          errors.ifu = "Numero IFU obligatoire";
        }
        if (!formData.rccm.trim()) {
          errors.rccm = "Numero RCCM obligatoire";
        }
        if (!formData.nom_entreprise) {
          errors.nom_entreprise = "Vueillez saisir le nom de l'entreprise";
        }
      }
      if (!formData.motif) {
        errors.motif = "Vueillez saisir le motif de la demande";
      }

    }
    return errors;
  };

  const handleDemande = () => {

    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);
      const demandeService = {
        lot: abonne.lot,
        parcelle: abonne.parcelle,
        type_sce: location.state.idBranchementAbon,
        nom: abonne.nom,
        prenom: abonne.prenom,
        tel2: abonne.tel || '00000',
        email: abonne.email,
        exploitation: abonne.exploitation,
        //type_amperage: formData.type_amperage,
        //puissance: formData.puissance,
        section: abonne.section,
        user_id: props.userId,
        type_compteur: formData.typeCompteur,
        rang: abonne.rang,
        numero_police: abonne.police,
        numero_compteur: abonne.numeroCompteur,
        motif: formData.motif,
        numero_rccm : formData.rccm,
        numero_ifu : formData.ifu,
        nom_entreprise: formData.nom_entreprise,
        prop_nom: abonne.nom,
        prop_prenom: abonne.prenom,
        cat_cli_code: abonne.typeAbonne,
      };
      // Si entreprise ajouter rccm et if
      /*  if (isEntreprise) {
         demandeService.rccm = formData.rccm;
         demandeService.ifu = formData.ifu;
         demandeService.nom_entreprise = formData.nom_entreprise;
       } */

      // Soumettre la demande du client
      try {
        api
            .post("/demande", demandeService)
            .then((response) => {
              if (response.status === 201) {
                setFormData({
                  exploitation: "",
                  puissance: "3A",
                  compteur: "",
                  type_amperage: "monophasé", // triphasé
                  typeCompteur: "PREPAYE",
                  motif: "",
                  rccm: "",
                  ifu: "",
                  police:"",
                  nom_entreprise: "",
                });
                toast.success(
                    "Demande enregistrée ! Nous vous enverrons des notifications pour le suivi de la demande par mail ou SMS."
                );
                setIsLoading(false);
                setStateSend(true); // désactiver le bouton

                setTimeout(() => {
                  navigate("/dashboard");
                },3000);

              }
            })
            .catch(() => {
              toast.error("Demande non enregistré");
              setIsLoading(false);
            });
        // setErrorMessage("");
      } catch (erreur) {
      }
    } else {
      //toast.error("Vueillez saisir le motif de la demande");
      /* setError((prevError) => ({
        ...prevError,
        motif: "Vueillez saisir le motif de la demande",
      })); */
      setErrors(validationErrors);
    }
  };

  const handleRechercheClient = () => {
    // Activer le loader
    setIsLoading(true);
    let lien = '';
    if(formData.typeCompteur === 'POSTPAYE') {
      lien = `/abonne/${formData.exploitation}/${formData.police}/${formData.typeCompteur}`
    } else {
      lien = `/abonne/compteur/${formData.compteur}/${formData.typeCompteur}`
    }
    api
        .get(
            lien
        )
        .then((response) => {
          if (response.status === 200 && response.data.content) {
            setAbonne(response.data.content);
            // Si on est dans un cas de résiliation de personne morale
            if (props.titre === "Résiliation personne morale"){
              // On verifie que c'est une entreprise en vérifiant son typeAbonne
              if (response.data.content.typeAbonne === "01") {
                toast.success(
                    `Client identifié : ${response.data.content.nom} ${response.data.content.prenom}`
                );
                // s'il existe on desactive le button
                setIsNext(true);
              }else {
                toast.error(
                    `Vous ne pouvez pas être considéré comme une entreprise. Nous ne possédons aucune information concernant votre IFU et RCCM.`
                );
              }
            }
            // Si c'est une personne physique on desactique automatiquement le champs
            else if(props.titre === "Résiliation personne physique"){
              toast.success(
                  `Client identifié : ${response.data.content.nom} ${response.data.content.prenom}`
              );
              // s'il existe on desactive le button
              setIsNext(true);
            }
            setIsLoading(false);

          } else {
            toast.error(
                `Désolé ces informations ne figurent pas dans notre système,`
            );
            setIsLoading(false);
            setIsNext(false);
          }
        })
        .catch(() => {
          // Désactiver le loader en cas d'erreur
          setIsLoading(false);
          toast.error(
              `Désolé ces informations ne figurent pas dans notre système,`
          );
        });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
            <div>
              <div className="flex justify-center h-32 items-center">
                <FormControl>
                  <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className="mb-5"
                  >
                    {/* Type de client */}
                  </FormLabel>
                  <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="type-client"
                      value={formData.typeCompteur}
                  >
                    <FormControlLabel
                        value="PREPAYE"
                        control={<Radio />}
                        label="Prepayé"
                        onChange={(e) =>
                            handleChange("typeCompteur", e.target.value)
                        }
                    />
                    <FormControlLabel
                        value="POSTPAYE"
                        control={<Radio />}
                        label="Postpayé"
                        onChange={(e) =>
                            handleChange("typeCompteur", e.target.value)
                        }
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="space-y-8">
                { formData.typeCompteur === 'POSTPAYE' && (

                    <>
                      <TextField
                          id="exploitation"
                          label="Saisir le numero d'exploitation"
                          fullWidth
                          size="small"
                          value={formData.exploitation}
                          onChange={(e) => handleChange("exploitation", e.target.value)}
                      />
                      <TextField
                          id="police"
                          label="Saisir le numero de police"
                          fullWidth
                          size="small"
                          value={formData.police}
                          onChange={(e) => handleChange("police", e.target.value)}
                      />
                    </>
                )
                }

                {
                    formData.typeCompteur === 'PREPAYE' &&  (<TextField
                        label="Saisir le numero de compteur"
                        fullWidth
                        size="small"
                        value={formData.compteur}
                        onChange={(e) => handleChange("compteur", e.target.value)}
                    />)
                }
              </div>
              <Button
                  color="success"
                  variant="contained"
                  onClick={handleRechercheClient}
                  sx={{ mb: 5, mt: 8, mr: 1, width: "100%" }}
                  disabled={isLoading || props.serverOffline }
              >
                {isLoading && <CircularProgress />}{" "}
                <span className="ml-5">Rechercher</span>
              </Button>
            </div>
        );
      case 1:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              {abonne && (
                  <>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Numero compteur"
                          fullWidth
                          size="small"
                          value={abonne.numeroCompteur}
                          disabled={true}
                      />
                      <TextField
                          label="Numero de police"
                          fullWidth
                          size="small"
                          value={abonne.police}
                          disabled={true}
                      />
                    </div>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Nom"
                          fullWidth
                          size="small"
                          value={abonne.nom}
                          disabled={true}
                      />
                      <TextField
                          label="Prenom"
                          fullWidth
                          size="small"
                          value={abonne.prenom}
                          disabled={true}
                      />
                    </div>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Email"
                          fullWidth
                          size="small"
                          value={abonne.email}
                          disabled={true}
                      />
                      <TextField
                          label="Telephone"
                          fullWidth
                          size="small"
                          value={abonne.tel}
                          disabled={true}
                      />
                    </div>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Section"
                          fullWidth
                          size="small"
                          value={abonne.section}
                          disabled={true}
                      />

                      <TextField
                          label="lot"
                          fullWidth
                          size="small"
                          value={abonne.lot}
                          disabled={true}
                      />
                    </div>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Parcelle"
                          fullWidth
                          size="small"
                          value={abonne.parcelle}
                          disabled={true}
                      />
                      <TextField
                          label="Rang"
                          fullWidth
                          size="small"
                          value={abonne.rang}
                          disabled={true}
                      />
                    </div>
                    {isEntreprise && (
                        <div className="flex flex-row space-x-8">
                          <TextField
                              label="Numero IFU"
                              fullWidth
                              size="small"
                              value={abonne.ifu}
                              disabled={true}
                          />

                          <TextField
                              label="RCCM"
                              fullWidth
                              size="small"
                              value={abonne.rccm}
                              disabled={true}
                          />
                        </div>
                    )}
                  </>
              )}
            </div>
        );

      case 2:
        return (
            <div className=" space-y-5 mt-8 mb-8">
              <TextField
                  label="Saisir le motif de la demande"
                  fullWidth
                  size="small"
                  value={formData.motif}
                  onChange={(e) => handleChange("motif", e.target.value)}
                  onFocus={() => handleFieldFocus("motif")}
                  error={!!errors.motif}
                  helperText={errors.motif}
              />
              {isEntreprise && (
                  <>
                    <TextField
                        label="Nom Entreprise"
                        fullWidth
                        size="small"
                        value={formData.nom_entreprise}
                        onChange={(e) => handleChange("nom_entreprise", e.target.value)}
                        onFocus={() => handleFieldFocus("nom_entreprise")}
                        error={!!errors.nom_entreprise}
                        helperText={errors.nom_entreprise}
                        required
                    />
                    <TextField
                        label="Numero IFU"
                        fullWidth
                        size="small"
                        value={formData.ifu}
                        onChange={(e) => handleChange("ifu", e.target.value)}
                        onFocus={() => handleFieldFocus("ifu")}
                        error={!!errors.ifu}
                        helperText={errors.ifu}
                        required
                    />

                    <TextField
                        label="RCCM"
                        fullWidth
                        size="small"
                        value={formData.rccm}
                        onChange={(e) => handleChange("rccm", e.target.value)}
                        onFocus={() => handleFieldFocus("rccm")}
                        error={!!errors.rccm}
                        helperText={errors.rccm}
                        required
                    />
                  </>
              )}
            </div>
        );

      default:
        return "Unknown step";
    }
  };

  return (
      <NavbarSidebarLayout>
        {props.serverOffline && <ServerStatus />}
        <div
            className="block items-center justify-center border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
          <div className="mb-1 w-full">
            <div className="mb-4">
              <Breadcrumb className="mb-4">
                <Breadcrumb.Item href="/dashboard">
                  <div className="flex items-center gap-x-3">
                    <HiHome className="text-xl"/>
                    <span className="dark:text-white">Accueil</span>
                  </div>
                </Breadcrumb.Item>
                <Breadcrumb.Item href="/list-des-demandes">Liste des services </Breadcrumb.Item>
                <Breadcrumb.Item>{props.titre}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className={`my-8`}>
              <div className="text-center  text-3xl mb-8">
                {props.titre}
              </div>

              <div
                  className="flex w-full flex-col gap-y-6 lg:flex-row lg:justify-center lg:gap-y-0 md:flex-row md:justify-center md:gap-y-0">
                <div
                    className=" rounded-lg bg-white p-2 shadow-2xl dark:bg-gray-800  xl:p-8 lg:w-1/2 border-2 lg:p-5">
                  <div className="invisible md:visible">
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{/*label*/}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                  <div>
                    <Typography className="text-center mb-8" variant="h5">
                      {steps[activeStep]}
                    </Typography>
                    {getStepContent(activeStep)}

                    <div className="flex flex-row justify-between pt-18">
                      <Button
                          color="success"
                          variant="outlined"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{mr: 1}}
                      >
                        Précédent
                      </Button>

                      <div className="flex 1 1 auto"/>
                      {activeStep === steps.length - 1 && (
                          <>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={handleDemande}
                                sx={{mr: 1}}
                                disabled={stateSend}
                            >
                              {isLoading && <CircularProgress color="secondary" size="1rem" />}
                              <span className="ml-1">Soumettre</span>
                            </Button>
                            {/*  <div className="bg-green-500 mt-12">  <Link to="/register"> Mes demandes</Link> </div> */}
                          </>
                      )}
                      {activeStep !== steps.length - 1 && (
                          <Button
                              onClick={handleNext}
                              variant="contained"
                              color="success"
                              disabled={!isNext}
                          >
                            Suivant
                          </Button>
                      )}
                    </div>
                    <ToastContainer/>
                    {stateSend && (
                        <div className="bg-green-100 mt-12">
                          {" "}
                          <Link to="/dashboard"> Voir mes demandes</Link>{" "}
                        </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavbarSidebarLayout>
  );
};

export default DemandeMotif;
