import {Navbar } from "flowbite-react";

const NavbarDash = ({ onToggleSidebar }) => {
    return (
        <Navbar fluid className={`bg-gray-50 border border-gray-100`}>
            <div className="w-full p-3 lg:px-5 lg:pl-3">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <button onClick={onToggleSidebar} className="p-2 mr-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>
                        <Navbar.Brand href="/">
                            {/*<img alt="" src={SonabelLogo} className="mr-3 h-6 sm:h-8" />*/}
                            <span className="self-center whitespace-nowrap text-2xl font-semibold dark:text-white">
                            </span>
                        </Navbar.Brand>
                    </div>
                    <div className="flex items-center gap-3">
                        <Navbar.Brand href="/">
                            Retour sur le site
                        </Navbar.Brand>
                    </div>
                </div>
            </div>
        </Navbar>
    );
};

export default NavbarDash;
