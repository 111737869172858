import React from "react";
import {Navigate} from "react-router-dom";
import EditPersonneMorale from "../../../EditRequest/Personne_morale/EditPersonneMorale";

const PrivateRouteEditPersonneMorale = () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <EditPersonneMorale/>;
    }
    return <Navigate to="/login"/>
}

export default PrivateRouteEditPersonneMorale;
