import React, { useEffect, useState } from "react";
import {api, apiUrl} from "../../../config/config";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { MuiTelInput } from "mui-tel-input";
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField, CircularProgress,
} from "@mui/material";
import NavbarSidebarLayout from "../../../components/Dashboard/Layouts/NavbarSidebar";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {Textarea} from "flowbite-react";
import TypePiece from "../../../shared/TypePiece";
import {EmailRegex} from "../../../components/Validation/EmailRegex";
import {PieceValidation} from "../../../components/Validation/PieceValidation";
import {CloudUpload, FileDownload} from "@mui/icons-material";
import {Menu} from "../../../components/Menu/Menu";

const EditSupervisionGenieCivil = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const [activeStep, setActiveStep] = useState(0);
  const [loadingSGC, setloadingSGC] = useState(false);

  // Formation des champs pour le type particulier
  const [formData, setFormData] = useState({});

  //Gestion des erreur
  const [errors, setErrors] = useState({});

  // Liste des professions existantes
  const [professions, setProfessions] = useState([]);
  const [ville, setVille] = useState([]);
  const [village, setVillage] = useState([]);
  const [isContentVillage, setIsContentVillage] = useState(false);
  const [errorDate,setErrorDate] = useState(false);
  const [newFile, setNewFile]= useState({
    file: null,
    document_parcelle: null,
  });

  const files = useState({
    cnib: '',
    parcelle: '',
  });

  // Mise à jour des status des fichier après suppression
  const filesDelete = useState({
    cnib: false,
    parcelle: false
  });

  // deactiver le bouton submit lorsqu'on actionne le bouton soumettre
  const [stateSend, setStateSend] = useState(false);

  //Récupération des professions de façon dynamique
  useEffect(() => {
    api
        .get("/profession/all")
        .then((response) => {
          if (response.status === 200) {
            setProfessions(response?.data?.content);
          }
        })
        .catch(() => {
          errors.profession = "Problème de réseau."
        });
  }, [errors]);

  //Recupération des villes
  useEffect(() => {
    api
        .get("/ville/cd")
        .then((response) => {
          if (response.status === 200) {
            const trimmedVilles = response.data.content.map((ville) => ({
              id: ville.ville_id,
              name: ville.ville_id.trim(),
            }));
            setVille(trimmedVilles);
          }
        })
        .catch(() => {});
  }, [errors]);

  // Village
  useEffect(() => {
    if (formData.village_id){
      api
          .get(`village/ville/${formData.ville_id}`)
          .then((response) => {
            if (response.status === 200) {
              setVillage(response?.data?.content);
              setIsContentVillage(response?.data?.content.length > 0);
            }
          })
          .catch(() => {
            errors.village = "Problème de réseau."
          });
    }
  }, [errors, formData.village_id, formData.ville_id]);

  //Récuperer la demande en cours
  useEffect(()=>{
    // Chercher une demande
    try {
      api.get(`/demande/${location.state.supervisionGenieCivilId}`).then(response => {
        if (response.status === 200){
          setFormData(response.data.content);
        }
      }).catch(() => {
        toast.warning("Erreur serveur.");
      })
    }catch (e) {

    }
  }, [location.state.supervisionGenieCivilId]);

  const steps_civil = [
    "Type de client",
    "Informations personnelles",
    "Informations sur votre pièce d'identité",
    "Zone Géographique",
    "parcelle",
    "Objet",
    "Validation",
  ];

  const handleFileChange = (event) => {
    setNewFile((prevData) => ({ ...prevData, "file": event.target.files[0] }));
  };
  const handleFileDocumentChange = (event) => {
    setNewFile((prevData) => ({ ...prevData, "document_parcelle": event.target.files[0] }));
  };

  const handleTextAreaChange = (event) => {
    setFormData({...formData, objet: event.target.value});
  };

  const handleNextSupervision = () => {
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setErrors(validationErrors);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleFieldFocus = (field) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  };

  //function pour passer la valeur de la région dans le setRegionId
  const handleVilleId = async (_, value) => {
    setFormData((prevData) => ({ ...prevData, "ville_id": value?.id }));
    api
        .get(`village/ville/${value?.id}`)
        .then((response) => {
          if (response.status === 200) {
            setVillage(response?.data?.content);
            setIsContentVillage(response?.data?.content.length > 0);
          }
        })
        .catch(() => {
          errors.village = "Problème de réseau."
        });
  };

  const handleVillageIdCivil = async (_, value) => {
    setFormData((prevData) => ({ ...prevData, "village_id": value?.id }));
  };

  const handleProfession = async (_, value) => {
    setFormData((prevData) => ({ ...prevData, profession_id: value.id }));
  };

  // Supprimer la cnib
  const handleDeleteFile = async () => {
    try {
      setloadingSGC(true);
      if (newFile.file && formData.photo_piece){
        await api.delete(`/storage/${formData.photo_piece}`).then(response => {
          if(response.status === 201){
            filesDelete.cnib = true;
          }
        }).catch(() => {
          setloadingSGC(false)
          filesDelete.cnib = false;
          toast.error("Le fichier n'existe pas.");
        })
      }else {
        await handleSendFile();
      }
    }catch (e) {

    }
  };

  // Supprimer le document de la parcelle
  const handleDeleteFileDocument = async () => {
    try {
      setloadingSGC(true);
      if (newFile.document_parcelle && formData.document_parcelle){
        await api.delete(`/storage/${formData.document_parcelle}`).then(response => {
          if(response.status === 201){
            filesDelete.parcelle = true;
          }
        }).catch(() => {
          setloadingSGC(false);
          filesDelete.parcelle = false;
          toast.error("Le fichier n'existe pas.");
        })
      }else {
        await handleSendDocumentFile();
      }
    }catch (e) {

    }
  };

  //  envoie des fichiers avant l'envoie de la demande
  const handleSendFile = async () => {
    if (newFile.file) {
      // create a new FormData object and append the file to it
      const formDataTransform = new FormData();
      formDataTransform.append("file", newFile.file);

      try {
        setloadingSGC(true);
        setStateSend(true);
        await api
            .post("/storage/upload", formDataTransform, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              if (response.status === 201) {
                // handleDemande(response.data.content);
                files.cnib = response.data.content;
              }
            })
            .catch(() => {
              //S'il n'a pas pu enregistrer les données on arrête le fonctionnement du spinner
              setloadingSGC(false);
              setStateSend(false);
              //On envoie une notification disant qu'il y a eu echec
              toast.error("Demande non enregistré");
            });
      } catch (e) {}
    }
  };

  // Envoyer le document de la parcelle
  const handleSendDocumentFile = async () => {
    if (newFile.document_parcelle) {
      // create a new FormData object and append the file to it
      const formDataTransformDocument = new FormData();
      formDataTransformDocument.append("file", newFile.document_parcelle);

      try {
        setloadingSGC(true);
        setStateSend(true);
        return await api
            .post("/storage/upload", formDataTransformDocument, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              if (response.status === 201) {
                // handleDemande(response.data.content);
                files.parcelle = response.data.content;
              }
            })
            .catch(() => {
              //S'il n'a pas pu enregistrer les données on arrête le fonctionnement du spinner
              setloadingSGC(false);
              setStateSend(false);
              //On envoie une notification disant qu'il y a eu echec
              toast.error("Demande non enregistré");
            });
      } catch (e) {}
    }
  };

  // Soumettre le formulaire
  const handleSubmitData = async (cnib, document) => {
    if (cnib || document) {
      // on crée une copie de l'objet formDataBP
      formData.photo_piece = cnib;
      formData.document_parcelle = document;
    }

    setloadingSGC(true);

    const filteredDataCivil = Object.fromEntries(
        Object.entries(formData).filter(([key, value]) => value !== null)
    );

    try {
      api
          .put("/demande", filteredDataCivil)
          .then((response) => {
            if (response.status === 201) {
              setFormData({
                //Initialiser avec les valeurs par défaut du formulaire
                service: "",
                exploitation: "",
                section: "",
                lot: "",
                parcelle: "",
                rang: "",
                nom: "",
                prenom: "",
                email: "",
                telephone: "+226",
                whatsApp: "+226",
                rccm: "",
                numero_ifu: "",
                pb: "",
                profession: "",
                region: "",
                province: "",
                ville: "",
                village: "",
                type_amperage: "",
                puissance_demande: "",
                duree: "",
                cnib: "",
                passport: "",
                date_delibery: "",
                delivery_place: "",
                amperage: "",
                puissance: "",
                objet: "",
                piece: "",
                prop_nom: "",
                prop_prenom: "",
              });

              setloadingSGC(false);

              toast.success(
                  "Demande Modifiée avec succès."
              );
              setStateSend(true);
              setTimeout(()=> {
                navigate("/dashboard");
              },3000);
            }
          })
          .catch(() => {
            setloadingSGC(false);
            toast.error("Demande non modifier");
          });
    } catch (e) {}
  };

  // Controller les numero de téléphone
  const handleChangePhoneSupervisionGenieCivil = (value) => {
    setFormData((prevData) => ({ ...prevData, "tel2": value }));
  };

  //Whatsapp
  const handleChangePhoneWhatsapp = (whatsapp) => {
    setFormData((prevData) => ({ ...prevData, "tel1_whatsapp": whatsapp }));
  };

  const validateForm = (formData) => {
    const errors = {};
    if (activeStep === 1) {
      if (!formData.nom.trim()) {
        errors.nom = "Nom obligatoire";
      }
      if (!formData.prenom.trim()) {
        errors.prenom = "Prenom obligatoire";
      }
      // Validation de l'email
      if (!formData.email.trim()) {
        errors.email = "Email obligatoire";
      } else if (!EmailRegex(formData.email.trim())) {
        errors.email = "Format d'email invalide";
      }
      if (!formData.tel2.trim()) {
        errors.tel2 = "Telephone obligatoire";
      }
      // else if(!PhoneRegex(formData.tel2)){
      //   errors.tel2 = "Le numéro de téléphone doit être au format correct.";
      // }
      if(!formData.tel1_whatsapp){
        errors.tel1_whatsapp = "Le numéro de téléphone doit être au format correct.";
      }
      if (formData.numero_ifu && !formData.numero_ifu.trim()) {
        errors.numero_ifu = "Numéro IFU obligatoire";
      }
      if (formData.numero_rccm && !formData.numero_rccm.trim()) {
        errors.numero_rccm = "Numéro RCCM obligatoire";
      }
      if (formData.nom_entreprise && !formData.nom_entreprise.trim()) {
        errors.nom_entreprise = "Le nom de la société est obligatoire";
      }
      if (!formData.profession_id) {
        errors.profession_id = "Choisissez votre profession. S'il n'existe pas dans la liste choisissez autre.";
      }

    }
    if (activeStep === 2) {
      if (!formData.numero_piece.trim()) {
        errors.numero_piece = "Ce champ est obligatoire";
      }
      if (!formData.piece) {
        errors.piece = "Type piece obligatoire";
      }
      if (!formData.date_etablissement.trim()) {
        errors.date_etablissement = "La date d'etablissement est  obligatoire";
        setErrorDate(true);
      }else if(PieceValidation(formData.date_etablissement.replace(/-/g, "/")) === false){
        errors.date_etablissement = "Cette date ne peut pas être acceptée." ;
        setErrorDate(true);
      }
      if (!formData.lieu_etablissement.trim()) {
        errors.lieu_etablissement = "Ce champ est obligatoire";
      }
    }
    if (activeStep === 3) {
      if (!formData.ville_id) {
        errors.ville_id = "Veuillez sélectionner la ville";
      }
      if (isContentVillage && !formData.ville_id) {
        errors.ville_id = "Veuillez sélectionner le village";
      }
    }
    if (activeStep === 4){
      if (!formData.prop_nom.trim()){
        errors.prop_nom = "Ce champ est obligatoire";
      }
      if (!formData.prop_prenom.trim()){
        errors.prop_prenom = "Ce champ est obligatoire";
      }
      if(!formData.section.trim()){
        errors.section = "Ce champ est obligatoire."
      }
      if(!formData.lot){
        errors.lot = "Ce champ est obligatoire."
      }
      if(!formData.parcelle){
        errors.parcelle = "Ce champ est obligatoire."
      }
    }
    if (activeStep === 5) {
      if(!formData.objet.trim()){
        errors.objet = "Ce champ est obligatoire";
      }
    }
    return errors;
  };

  const handleChangePiece = (event, value) => {
    setFormData((prevData) => ({ ...prevData, piece: value?.name }));
  };

  const handleManageSendDataEdit = async () => {
    if (newFile.file) {
      await handleDeleteFile();
      // await handleSendFile();
    }
    if (newFile.document_parcelle){
      await handleDeleteFileDocument();
      // await handleSendDocumentFile();
    }

    if (filesDelete.cnib ) {
      // Envoi de CNIB
      await handleSendFile();
    }
    if (filesDelete.parcelle) {
      // Envoi du document de la parcelle
      await handleSendDocumentFile();
    }

    // S'il y a de nouveau document à uploader
    if (files.cnib || files.parcelle) {
      await handleSubmitData(files.cnib, files.parcelle);
    } else {
      // Dans le cas contraire on renvoie les existants
      await handleSubmitData(null, null);
    }

  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
            <div className="flex justify-center  h-32 items-center dark:text-white">
              <FormControl>
                <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="mb-5"
                >
                  {/* Type de client */}
                </FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="type-client"
                    value={formData.typeClient}
                >
                  {(formData.numero_ifu && formData.numero_rccm) ? (
                      <FormControlLabel
                          value="entreprise"
                          control={<Radio />}
                          label="Entreprise"
                          checked
                          // onChange={(e) => handleChange("typeClient", e.target.value)}
                      />
                  ): (
                      <FormControlLabel
                          value="particulier"
                          control={<Radio />}
                          label="Particulier"
                          checked
                          // onChange={(e) => handleChange("typeClient", e.target.value)}/
                      />
                  )}

                </RadioGroup>
              </FormControl>
            </div>
        );
      case 1:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8 dark:text-white">
              <>
                <TextField
                    className="dark:text-white"
                    label="Nom"
                    fullWidth
                    size="small"
                    value={formData.nom}
                    onChange={(e) => handleChange("nom", e.target.value)}
                    onFocus={() => handleFieldFocus("nom")}
                    error={!!errors.nom}
                    helperText={errors.nom}
                    required
                />
                <TextField
                    label="Prenom"
                    fullWidth
                    size="small"
                    value={formData.prenom}
                    onChange={(e) => handleChange("prenom", e.target.value)}
                    onFocus={() => handleFieldFocus("prenom")}
                    error={!!errors.prenom}
                    helperText={errors.prenom}
                    required
                    className="dark:text-white"
                />
                <TextField
                    label="Email"
                    type="email"
                    fullWidth
                    size="small"
                    value={formData.email}
                    onChange={(e) => handleChange("email", e.target.value)}
                    onFocus={() => handleFieldFocus("email")}
                    error={!!errors.email}
                    helperText={errors.email}
                    required
                    className="dark:text-white"
                />
                <MuiTelInput
                    label="Telephone"
                    fullWidth
                    size="small"
                    value={formData.tel2}
                    onChange={handleChangePhoneSupervisionGenieCivil}
                    onFocus={() => handleFieldFocus("telephone")}
                    error={!!errors.tel2}
                    helperText={errors.tel2}
                    required
                    className="dark:text-white"
                />
                <MuiTelInput
                    label="WhatSApp"
                    fullWidth
                    size="small"
                    value={formData.tel1_whatsapp}
                    onChange={handleChangePhoneWhatsapp}
                    onFocus={() => handleFieldFocus("whatsApp")}
                />
                {(formData.numero_ifu && formData.numero_rccm) && (
                    <>
                      <TextField
                          label="Nom de la société"
                          fullWidth
                          size="small"
                          value={formData.nom_entreprise}
                          onChange={(e) => handleChange("nom_entreprise", e.target.value)}
                          InputLabelProps={{shrink: true}}
                          onFocus={() => handleFieldFocus("nom_entreprise")}
                          error={!!errors.nom_entreprise}
                          helperText={errors.nom_entreprise}
                          required
                          className="dark:text-white"
                      />
                      <TextField
                          label="Numéro IFU"
                          fullWidth
                          size="small"
                          value={formData.numero_ifu}
                          onChange={(e) => handleChange("numero_ifu", e.target.value)}
                          onFocus={() => handleFieldFocus("numero_ifu")}
                          error={!!errors.numero_ifu}
                          helperText={errors.numero_ifu}
                          required
                          className="dark:text-white"
                      />
                      <TextField
                          label="Numéro RCCM"
                          fullWidth
                          size="small"
                          value={formData.numero_rccm}
                          onChange={(e) => handleChange("numero_rccm", e.target.value)}
                          onFocus={() => handleFieldFocus("numero_rccm")}
                          error={!!errors.numero_rccm}
                          helperText={errors.numero_rccm}
                          required
                          className="dark:text-white"
                      />
                    </>
                )}
                <Autocomplete
                    // disablePortal
                    autoHighlight
                    id="profession"
                    options={professions}
                    size="small"
                    sx={{ width: "100%" }}
                    onChange={handleProfession}
                    getOptionLabel={(option) => option.name}
                    value={formData?.profession_id ? professions?.find((option) => option?.id === formData?.profession_id) || null : null}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Profession *"
                            error={!!errors.profession_id}
                            helperText={errors.profession_id}
                        />
                    )}
                />
              </>
            </div>
        );
      case 2:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              <>
                <Autocomplete
                    // disablePortal
                    autoHighlight
                    id="typepiece"
                    options={TypePiece}
                    size="small"
                    sx={{ width: "100%" }}
                    onChange={handleChangePiece}
                    value={formData.piece ? TypePiece.find((option) => option.name === formData.piece) || null : null}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Type de piece"
                            error={!!errors.piece}
                            helperText={errors.piece}
                        />
                    )}
                />
                <TextField
                    label="Référence de la pièce (CNIB, Passport, etc)"
                    fullWidth
                    size="small"
                    value={formData.numero_piece}
                    onChange={(e) => handleChange("numero_piece", e.target.value)}
                    onFocus={() => handleFieldFocus("numero_piece")}
                    error={!!errors.numero_piece}
                    helperText={errors.numero_piece}
                    required
                    className="dark:text-white"
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                      sx={{ width: "100%" }}
                      label="Date Etablissement"
                      format="DD/MM/YYYY"
                      value={dayjs(formData.date_etablissement)}
                      onChange={(value) => {
                        // Formater la date au format "yyyy/mm/dd" avec Dayjs
                        const formattedDate = dayjs(value).format("YYYY/MM/DD");
                        // Appeler la fonction handleChange avec la date formatée
                        handleChange("date_etablissement", formattedDate);
                        setErrorDate(false)
                      }}
                      slotProps={{
                        textField: {
                          error: errorDate,
                          helperText: errors.date_etablissement,
                        },
                      }}

                  />
                </LocalizationProvider>

                <TextField
                    label="Lieu d'établissement"
                    fullWidth
                    size="small"
                    value={formData.lieu_etablissement}
                    onChange={(e) => handleChange("lieu_etablissement", e.target.value)}
                    onFocus={() => handleFieldFocus("lieu_etablissement")}
                    error={!!errors.lieu_etablissement}
                    helperText={errors.lieu_etablissement}
                    required
                    className="dark:text-white"
                />
              </>
              <>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUpload/>}
                >
                  Modifiez votre pièce
                  <input
                      type="file"
                      hidden
                      onChange={event => handleFileChange(event)}
                  />
                </Button>
                <p className="mt-1 text-sm font-bold">Le fichier ne doit pas dépasser 5 Mo.</p>
                {newFile.file && (
                    <><strong>Nouveau fichier:</strong>
                      <div className={`text-blue-800`}>{newFile.file.name}</div>
                    </>
                )}
                {formData.photo_piece && (
                    <>
                      <a href={apiUrl + `/storage/${formData.photo_piece}`} target="_blank" rel="noreferrer">
                        <FileDownload color={`primary`} sx={{fontSize: 40}}/>
                      </a>
                      {formData.photo_piece}
                    </>
                )}
                {errors.photo_piece && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.file}</p>
                )}
              </>
            </div>
        );
      case 3:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              <>
                <Autocomplete
                    // disablePortal
                    autoHighlight
                    id="ville"
                    options={ville}
                    size="small"
                    sx={{ width: "100%" }}
                    value={formData.ville_id ? ville.find((option) => option?.id === formData.ville_id) || null : null}
                    onChange={handleVilleId}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Ville"
                            error={!!errors.ville_id}
                            helperText={errors.ville_id}
                        />
                    )}
                />
                {(isContentVillage || formData.village_id) && (
                    <Autocomplete
                        // disablePortal
                        autoHighlight
                        id="Village"
                        options={village}
                        size="small"
                        sx={{ width: "100%" }}
                        onChange={handleVillageIdCivil}
                        value={formData.village_id ? village.find((option) => option?.id === formData.village_id) || null : null}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Village"
                                error={!!errors.village_id}
                                helperText={errors.village_id
                                }
                            />
                        )}
                    />
                )}
              </>
            </div>
        );
      case 4:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              <TextField
                  label="Nom du propriétaire de la parcelle"
                  fullWidth
                  size="small"
                  value={formData.prop_nom}
                  onChange={(e) => handleChange("prop_nom", e.target.value)}
                  onFocus={() => handleFieldFocus("prop_nom")}
                  error={!!errors.prop_nom}
                  helperText={errors.prop_nom}
                  required
              />
              <TextField
                  label="Prénom du propriétaire de la parcelle"
                  fullWidth
                  size="small"
                  value={formData.prop_prenom}
                  onChange={(e) => handleChange("prop_prenom", e.target.value)}
                  onFocus={() => handleFieldFocus("prop_prenom")}
                  error={!!errors.prop_prenom}
                  helperText={errors.prop_prenom}
                  required
              />
              <TextField
                  label="Section"
                  fullWidth
                  size="small"
                  value={formData.section}
                  onChange={(e) => handleChange("section", e.target.value.toUpperCase())}
                  onFocus={() => handleFieldFocus("section")}
                  error={!!errors.section}
                  helperText={errors.section}
                  required
              />
              <TextField
                  label="Lot"
                  type="number"
                  fullWidth
                  size="small"
                  value={formData.lot}
                  onChange={(e) => handleChange("lot", e.target.value)}
                  onFocus={() => handleFieldFocus("lot")}
                  error={!!errors.lot}
                  helperText={errors.lot}
                  required
              />
              <TextField
                  label="Parcelle"
                  fullWidth
                  type="number"
                  size="small"
                  value={formData.parcelle}
                  onChange={(e) => handleChange("parcelle", e.target.value)}
                  onFocus={() => handleFieldFocus("parcelle")}
                  error={!!errors.parcelle}
                  helperText={errors.parcelle}
                  required
              />
              <>
                <p className="mt-1 text-sm font-bold text-red-700">Le fichier ne doit pas dépasser 5 Mo.</p>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUpload/>}
                >
                  Modifier le Document de votre parcelle
                  <input
                      type="file"
                      hidden
                      onChange={(event) => handleFileDocumentChange(event)}
                  />
                </Button>
                {newFile.document_parcelle && (
                    <><strong>Nouveau fichier:</strong>
                      <div className={`text-blue-800`}>{newFile.document_parcelle.name}</div>
                    </>
                )}

                {formData.document_parcelle && (
                    <>
                      <a href={apiUrl + `/storage/${formData.document_parcelle}`} target="_blank" rel="noreferrer">
                        <FileDownload color={`primary`} sx={{fontSize: 40}}/>
                      </a>
                      {formData.document_parcelle}
                    </>
                )}
              </>
            </div>
        );
      case 5:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8 dark:bg-slate-800">
              <>
                <FormLabel>L'objet de votre demande</FormLabel>
                <Textarea
                    className="resize rounded-md"
                    placeholder="Pourquoi faite vous cette demande?"
                    value={formData.objet}
                    rows="5"
                    onChange={handleTextAreaChange}
                    onFocus={() => handleFieldFocus("objet")}
                    error={!!errors.objet ? errors.objet : 'false'}
                    helperText={errors.objet}
                    required
                />
              </>
            </div>
        );
      case 6:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8 dark:text-white">
              <Typography
                  className="text-center mb-8 dark:text-white"
                  variant="h5"
              >
                Voulez-vous vraiment modifier votre demande ?
              </Typography>
            </div>
        );
      default:
        return "Unknown step";
    }
  };

  return (
      <NavbarSidebarLayout>
        <div
            className="block items-center justify-center border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
          <div className="mb-1 w-full">
            <Menu titre="Modification de demande de supervision Génie Civil"/>
            <div className={`my-8`}>
              <div className="text-center  text-3xl mb-8">
                Modification de la demande de supervision Génie Civil
              </div>

              <div
                  className="flex w-full flex-col gap-y-6 lg:flex-row lg:justify-center lg:gap-y-0 md:flex-row md:justify-center md:gap-y-0">
                <div
                    className=" rounded-lg bg-white p-2 shadow-2xl dark:bg-gray-800  xl:p-8 lg:w-1/2 border-2 lg:p-5">

                  <div className="invisible md:visible">
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps_civil.map((label) => (
                          <Step key={label}>
                            <StepLabel>{/*label*/}</StepLabel>
                          </Step>
                      ))}
                    </Stepper>
                  </div>
                  <div>
                    <Typography
                        className="text-center mb-8 dark:text-white"
                        variant="h4"
                    >
                      {steps_civil[activeStep]}
                    </Typography>
                    {getStepContent(activeStep)}

                    <div className="flex flex-row justify-between pt-18">
                      <Button
                          color="success"
                          variant="outlined"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{mr: 1}}
                      >
                        Précédent
                      </Button>
                      <div className="flex 1 1 auto"/>
                      {activeStep === steps_civil.length - 1 && (
                          <>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={handleManageSendDataEdit}
                                // onClick={handleDemande}
                                sx={{mr: 1}}
                                //disabled={stateSend}
                            >
                              {loadingSGC ? (
                                  <>
                                    <CircularProgress color="primary" size="1rem" /> En cours...
                                  </>
                              ) : (
                                  <>
                                    Modifier
                                  </>
                              )}
                            </Button>
                            {/*  <div className="bg-green-500 mt-12">  <Link to="/register"> Mes demandes</Link> </div> */}
                          </>
                      )}
                      {activeStep !== steps_civil.length - 1 && (
                          <Button
                              onClick={handleNextSupervision}
                              variant="contained"
                              color="success"
                          >
                            Suivant
                          </Button>
                      )}
                    </div>
                  </div>
                  <ToastContainer/>
                  <div className="bg-green-100 mt-12">
                    {stateSend && (
                        <>
                          <Link
                              to="/dashboard"
                              className="text-justify text-white dark:text-white"
                          >
                            <Button>Voir mes demandes</Button>
                          </Link>
                        </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavbarSidebarLayout>
  );
};

export default EditSupervisionGenieCivil;
