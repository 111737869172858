import React from "react";
import {Resiliation} from "./ServicesMenu/Resiliation";
import {SupervisionGenieCivil} from "./ServicesMenu/SupervisionGenieCivil";
import {Sinistre} from "./ServicesMenu/Sinistre";
import {Branchement} from "./ServicesMenu/Branchement";
import {Abonnement} from "./ServicesMenu/Abonnement";

const NosServices = () => {
  return (
    <div>
      <Branchement/>
      <Abonnement/>
      <Resiliation/>
      <SupervisionGenieCivil/>
      <Sinistre/>
    </div>
  );
};
export default NosServices;
