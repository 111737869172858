import React from "react";

function ForgetPassword() {

    return(
        <div className=" h-screen flex flex-col items-center  justify-center">
            Mot de passe oublié
        </div>
    );

}

export default ForgetPassword