import React from "react";
import {Navigate} from "react-router-dom";
import EditSupervisionGenieCivil from "../../../EditRequest/Supervision_genie_civil/EditSupervisionGenieCivil";


const PrivateRouteEditSupervisionGenieCivil = () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <EditSupervisionGenieCivil/>;
    }
    return <Navigate to="/login"/>
}

export default PrivateRouteEditSupervisionGenieCivil;
