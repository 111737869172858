import React from "react";
import MainNavigation from "../../components/NavBar/MainNavigation";
import FooterComponent from "../../components/Footer/FooterComponent";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";

import ContactItem from "./composant/ContactItem";

import ContactUs from "./composant/ContactUs";

const ContactPage = () => {
  return (
    <div className="flex flex-col gap-4 min-h-screen">
      <MainNavigation />
      <div
        className="h-48 mt-12 flex justify-center items-center"
        /*  style={{ backgroundImage: `url(${ImageInfo})` }} */
        style={{
          background:
            "linear-gradient(135deg, rgb(36, 145, 58) 0%, #1ec540 50%, #56ab2f 100%)",
          /*  textAlign: "center",  */
          borderRadius: "10px",
        }}
      >
        <section className="text-center">
          <h1 className="text-center text-2xl font-black  text-white uppercase">
            Contactez-nous
          </h1>
    
             <p className="text-yellow-200 font-bold underline underline-offset-2 ">
            Besoin d'assistance ? <span>Nous sommes à votre service</span>
            </p>
         
         
        </section>
      </div>
       <div className="flex-grow">
        <div className="flex flex-col md:flex-row space-x-2 p-4 ">
           <div className="min-h-48 shadow-md  basis-1/2  p-2">
             <div className="grid  grid-rows-2 lg:grid-flow-col  gap-2 ">
              <ContactItem
                title="Téléphone"
                body="Disponible 7j/7 de 8h à 22h"
                foot=" Appelez au 80 00 11 30"
                color="bg-red-700"
                buttonType="button"
              >
                <PhoneIcon fontSize="large" />
              </ContactItem>
           
              <ContactItem
                title="Adresse"
                body=" 55, Avenue de la nation Ouagadougou, Burkina Faso"
                foot=" 55, Avenue de la nation"
                color="bg-white"
                buttonType="button"
              >
                <LocationOnIcon fontSize="large" />
              </ContactItem>

                <ContactItem
                title="Email"
                body="Cliquer sur le bouton pour nous envoyer un mail"
                foot="info@sonabel.bf"
                color="bg-white"
                buttonType="email"
              >
                <MailIcon fontSize="large" />
              </ContactItem>

              <ContactItem
                title="Horaires d'ouverture"
                body=" Du Lundi-Vendredi de 07h30 à 12h et de 14h à 16h30.
                      Les Samedi de 08h à 12h30."
                foot="Nos Horaires"
                color="bg-white"
              >
                <MailIcon fontSize="large" />
              </ContactItem> 
            </div> 
          </div> 

          <div className="min-h-48 shadow-xl basis-1/2 p-2">
           <ContactUs /> 
          </div>
        </div>
      </div> 

      <FooterComponent className="flex-shrink-0" />
    </div>
  );
};

export default ContactPage;
