class Env {
    // Exemple d'utilisation
   constructor() {
     //   Machine locale
     //  this.apiUrl = 'http://192.168.16.216:8000/api/V0.1';
     //   Serveur test
     // this.apiUrl = 'http://10.210.210.14:8000/api/V0.1';
    //  Serveur de production
    this.apiUrl = 'https://eguichet.sonabel.bf/gateway/api/V0.1/';
    this.userName = '98774c127b6bcc37b5f36aa7f78d9a38';
    this.userToken =  '6f501b982de652a92222104fda94c80e';
   }
}
export default Env;
