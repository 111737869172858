/* export function PhoneRegex(phoneNumber) {
    const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    const nonZeroRegex = /^[1-9]\d*$/;

    const parts = phoneNumber.split(" ");
    parts.shift();
    const number = parts.join('');

    // Vérifier si le nombre après l'indicatif est supérieur à 8
    return !(!phoneRegex.test() && (!nonZeroRegex.test(number) || number.length < 8));
} */
export function PhoneRegex(phoneNumber) {
    // Expression régulière pour valider un numéro de téléphone international
    const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

    // Expression régulière pour vérifier que le nombre après l'indicatif n'est pas nul
    const nonZeroRegex = /^[1-9]\d*$/;

    // Séparer la chaîne du numéro de téléphone par les espaces
    const parts = phoneNumber.split(" ");

    // Supprimer le premier élément du tableau (l'indicatif)
    parts.shift();

    // Joindre les parties restantes pour former le numéro de téléphone
    const number = parts.join('');

    // Vérifier si le numéro de téléphone correspond au format international
    // et que le nombre après l'indicatif est supérieur ou égal à 8
    return phoneRegex.test(phoneNumber) && nonZeroRegex.test(number) && number.length >= 8;
}
