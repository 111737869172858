import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import NosServices from "./NosServices";
import InfosGenerales from "./InfosGenerales";
import Agences from "./Agences";
import GrilleTarifaire from "./GrilleTarifaire";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

export default function VerticalTabs() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            {isSmallScreen ? (
                <div>
                    <Tabs
                        orientation="horizontal"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        textColor="white"
                        aria-label="Vertical tabs example"
                        sx={{ bgcolor: "transparent"}}
                    >
                        <Tab
                            label="Nos Services"
                            {...a11yProps(0)}
                            sx={{ bgcolor: value === 0 ? "green" : "transparent", color: value === 0 ? "white" : "inherit" }}
                        />
                        <Tab
                            label="Informations générales"
                            {...a11yProps(1)}
                            sx={{ bgcolor: value === 1 ? "green" : "transparent", color: value === 1 ? "white" : "inherit" }}
                        />
                        <Tab
                            label="Nos agences"
                            {...a11yProps(2)}
                            sx={{ bgcolor: value === 2 ? "green" : "transparent", color: value === 2 ? "white" : "inherit" }}
                        />
                        <Tab
                            label="Grille tarifaire"
                            {...a11yProps(3)}
                            sx={{ bgcolor: value === 3 ? "green" : "transparent", color: value === 3 ? "white" : "inherit" }}
                        />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <NosServices />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <InfosGenerales />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Agences />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <GrilleTarifaire />
                    </TabPanel>
                </div>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            textColor='inherit'
                            aria-label="Vertical tabs example"
                            sx={{
                                bgcolor: "transparent"
                            }}
                        >
                            <Tab
                                label="Nos Services"
                                {...a11yProps(0)}
                                sx={{ bgcolor: value === 0 ? "green" : "transparent", color: value === 0 ? "white" : "inherit" }}
                            />
                            <Tab
                                label="Informations générales"
                                {...a11yProps(1)}
                                sx={{ bgcolor: value === 1 ? "green" : "transparent", color: value === 1 ? "white" : "inherit" }}
                            />
                            <Tab
                                label="Nos agences"
                                {...a11yProps(2)}
                                sx={{ bgcolor: value === 2 ? "green" : "transparent", color: value === 2 ? "white" : "inherit" }}
                            />
                            <Tab
                                label="Grille tarifaire"
                                {...a11yProps(3)}
                                sx={{ bgcolor: value === 3 ? "green" : "transparent", color: value === 3 ? "white" : "inherit" }}
                            />
                        </Tabs>
                    </Grid>
                    <Grid item xs={9}>
                        <TabPanel value={value} index={0}>
                            <NosServices />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <InfosGenerales />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Agences />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <GrilleTarifaire />
                        </TabPanel>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}
