import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
} from "@mui/material";
import NavbarSidebarLayout from "../../../components/Dashboard/Layouts/NavbarSidebar";
import { api } from "../../../config/config";
import Autocomplete from "@mui/material/Autocomplete";
import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import ServerStatus from "../../../components/ServerStatus";
import LogoSonabel from "../../../assets/Logo-site-SONABEL-def.png";
import {CloudUpload} from "@mui/icons-material";

const TravauxDivers = ({ serverOffline }) => {
  const [isLoadingDivers, setIsLoadingDivers] = useState(false);

  //initialisation de useLocation pour récupérer l'URL action ou faire une navigation
  const location = useLocation();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);

  const [formData, setFormData] = useState({
    exploitation: "",
    puissance: "3A",
    compteur: "",
    numero_police: "",
    td: "",
    typeCompteur: "PREPAYE",
    file: "",
  });

  // deactiver le bouton submit lorsqu'on actionne le bouton soumettre
  const [stateSend, setStateSend] = useState(true);

  const [isNext, setIsNext] = useState(false);
  const [abonne, setAbonne] = useState({});
  const [errors, setErrors] = useState({});
  const [TDList, setTDList] = useState([]);
  const avertissement = <p className="text-red-600">Avertissement</p>;
  const files = {
    cnib: '',
  }


  const steps = [
    avertissement,
    "Recherche du client",
    "Mes informations",
    "Type de travaux divers",
    "Validation",
  ];

  useEffect(() => {
    // Récupérer la liste des TD
    try {
      api
          .get(`/td/all`)
          .then((response) => {
            if (response.status === 200) {
              setTDList(response.data.content);
            }
          })
          .catch(() => {});
    } catch (e) {}
  }, []);

  const handleNext = () => {
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (formData) => {
    const errors = {};
    if (activeStep === 3) {
      // Assurez-vous que formData.td est défini avant d'appeler trim()
      if (!formData.td){
        errors.td = "Ce champ est obligatoire.";
      }
      if (!formData.file){
        errors.file = "Ce champ est obligatoire.";
      }
      if (formData.file && formData.file.size > 2 * 1024 * 1024) {
        errors.file = "Le fichier sélectionné est trop volumineux.";
      }
    }
    return errors;
  };

  const handleBack = () => {
    //setStateSend(false);
    //setIsNext(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleFileChange = (event) => {
    setFormData((prevData) => ({ ...prevData, file: event.target.files[0] }));
  };

  //envoie des fichiers avant l'envoie de la demande
  const handleSendFile = async () => {
    if (formData.file) {
      // create a new FormData object and append the file to it
      const formDataTransform = new FormData();
      formDataTransform.append("file", formData.file);

      try {
        setIsLoadingDivers(true);
        setStateSend(true);
        await api
            .post("/storage/upload", formDataTransform, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              if (response.status === 201) {
                files.cnib = response.data.content;
                // handleDemande(response.data.content);
              }
            })
            .catch(() => {
              //S'il n'a pas pu enregistrer les données on arrête le fonctionnement du spinner
              setIsLoadingDivers(false);
              setStateSend(false);
              //On envoie une notification disant qu'il y a eu echec
              toast.error("Demande non enregistré");
            });
      } catch (e) {}
    }
  };

  const handleDemande = async (cnib) => {
    if (cnib) {
      // Soumettre la demande du client
      try {
        setStateSend(true);
        setIsLoadingDivers(true);
        const travaux_divers = {
          lot: abonne.lot,
          parcelle: abonne.parcelle,
          type_sce: location.state.idTravauxDivers,
          nom: abonne.nom,
          prenom: abonne.prenom,
          tel2: abonne.tel || "00000",
          email: abonne.email,
          exploitation: abonne.exploitation,
          type_amperage: formData.type_amperage,
          puissance: formData.puissance,
          section: abonne.section,
          user_id: localStorage.getItem("userId"),
          type_compteur: formData.typeCompteur,
          rang: abonne.rang,
          numero_police: abonne.police,
          numero_compteur: abonne.numeroCompteur,
          td: formData.td,
          prop_nom: abonne.nom,
          prop_prenom: abonne.prenom,
          cat_cli_code: abonne.typeAbonne,
          photo_piece: cnib,
        };
        const filteredDataTD = Object.fromEntries(
            Object.entries(travaux_divers).filter(([key, value]) => value !== null || undefined || '')
        );
        await api
            .post("/demande", filteredDataTD)
            .then((response) => {
              if (response.status === 201) {
                setFormData({
                  exploitation: "",
                  compteur: "",
                });
                toast.success(
                    "Demande enregistrée ! Nous vous enverrons des notifications pour le suivi de la demande par mail ou SMS."
                );
                setIsLoadingDivers(false);
                setStateSend(true); // désactiver le bouton
                setTimeout(() => {
                  navigate("/dashboard");
                }, 2000);
              }
            })
            .catch(() => {
              toast.error("Demande non enregistré");
              setIsLoadingDivers(false);
              setStateSend(false);
            });
      } catch (err) {}
    }else {
      toast.error("Demande non enregistré");
    }
  };

  const handleRecherchePrepaye = () => {
    // Activer le loader
    setIsLoadingDivers(true);

    api
        .get(`/abonne/compteur/${formData.compteur}/${formData.typeCompteur}`)
        .then((response) => {
          if (response.status === 200 && response.data.content) {
            setAbonne(response.data.content);

            toast.success(
                `Client identifié : ${response.data.content.nom} ${response.data.content.prenom}`
            );

            setIsNext(true);
            setIsLoadingDivers(false);
          } else {
            toast.error(
                `Désolé ces informations ne figurent pas dans notre système,`
            );
            setIsLoadingDivers(false);
            setIsNext(false);
          }
        })
        .catch(() => {
          // Désactiver le loader en cas d'erreur
          setIsLoadingDivers(false);
          setIsNext(false);
          toast.error(
              `Désolé ces informations ne figurent pas dans notre système,`
          );
        });
  };
  const handleRecherchePostpaye = () => {
    // Activer le loader
    setIsLoadingDivers(true);

    api
        .get(
            `/abonne/${formData.exploitation}/${formData.numero_police}/${formData.typeCompteur}`
        )
        .then((response) => {
          if (response.status === 200 && response?.data?.content) {
            setAbonne(response.data.content);

            toast.success(
                `Client identifié : ${response?.data?.content?.nom} ${response?.data?.content?.prenom}`
            );
            //setStateSend(true);
            setIsNext(true);
            setIsLoadingDivers(false);
          } else {
            toast.error(
                `Désolé ces informations ne figurent pas dans notre système,`
            );
            setIsLoadingDivers(false);
            setIsNext(false);
          }
        })
        .catch(() => {
          // Désactiver le loader en cas d'erreur
          setIsLoadingDivers(false);
          setIsNext(false);
          toast.error(
              `Désolé ces informations ne figurent pas dans notre système,`
          );
        });
  };

  const handleTdList = (event, value) => {
    setFormData((prevData) => {
      //On Vérifie si value est défini et contient la clé "TD_CODE#"
      if (value && typeof value === "object" && "TD_CODE" in value) {
        return { ...prevData, td: value.TD_CODE };
      } else {
        //On Gère le cas où value est null ou ne contient pas la clé "TD_CODE#"
        return prevData;
      }
    });
  };

  const handleManageDemande = async () => {
    // On upload le fichier de la cnib
    if (formData.file) {
      await handleSendFile();
    }
    // On verifie que l'un des deux existes
    if (files.cnib) {
      // Si parcelle est n'existe pas on renvoie null
      await handleDemande(files.cnib);
    }
  }
  const handleAcceptTermsAndCondition = async (event) => {
    setStateSend(!event.target.checked);
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
            <div>
              <div className="flex flex-col items-center">
                <div className={`text-center items-center mt-4`}>
                  <img src={LogoSonabel} alt="" width={200} />
                </div>
                <div className={`text-justify m-8`}>
                  <h2 className="mb-2 text-lg font-bold text-gray-900 dark:text-white">
                    Conditions pour cette demande :
                  </h2>
                  <ol className="max-w-md space-y-1 text-gray-500 list-decimal list-inside dark:text-gray-400">
                    <li>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      Assurez-vous d'avoir le numéro de compteur pour les
                      prépayés, ainsi que le numéro de police et le numéro
                      d'exploitation pour les postpayés.
                    </span>
                    </li>
                    <li>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      La SONABEL décline toute responsabilité pour toute
                      inexactitude des informations fournies par les demandeurs.
                    </span>
                    </li>
                    <li>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      Aucun remboursement ne sera accordé en cas de fausses
                      informations. La SONABEL se réserve le droit de poursuivre
                      en justice les cas de fraude.
                    </span>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
        );
      case 1:
        return (
            <div>
              <div className="flex justify-center h-32 items-center">
                <FormControl>
                  <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className="mb-5"
                  >
                    {/* Type de client */}
                  </FormLabel>
                  <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="type-client"
                      value={formData.typeCompteur}
                  >
                    <FormControlLabel
                        value="PREPAYE"
                        control={<Radio />}
                        label="Prepayé"
                        onChange={(e) =>
                            handleChange("typeCompteur", e.target.value)
                        }
                    />
                    <FormControlLabel
                        value="POSTPAYE"
                        control={<Radio />}
                        label="Postpayé"
                        onChange={(e) =>
                            handleChange("typeCompteur", e.target.value)
                        }
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="space-y-8">
                {formData.typeCompteur === "POSTPAYE" && (
                    <>
                      <TextField
                          id="exploitation"
                          label="Saisir le numero d'exploitation"
                          fullWidth
                          size="small"
                          value={formData.exploitation}
                          onChange={(e) =>
                              handleChange("exploitation", e.target.value)
                          }
                          type='number'
                      />
                      <TextField
                          label="Saisir le numero de police"
                          fullWidth
                          size="small"
                          value={formData.numero_police}
                          onChange={(e) =>
                              handleChange("numero_police", e.target.value)
                          }
                          type='number'
                      />
                    </>
                )}
                {formData.typeCompteur === "PREPAYE" && (
                    <>
                      <TextField
                          label="Saisir le numero de compteur"
                          fullWidth
                          size="small"
                          value={formData.compteur}
                          onChange={(e) => handleChange("compteur", e.target.value)}
                      />
                    </>
                )}
              </div>
              <Button
                  color="success"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    if (formData.typeCompteur === "PREPAYE") {
                      handleRecherchePrepaye();
                    } else if (formData.typeCompteur === "POSTPAYE") {
                      handleRecherchePostpaye();
                    }
                  }}
                  sx={{ mb: 5, mt: 8, mr: 1, width: "100%" }}
                  disabled={isLoadingDivers || serverOffline}
              >
                {isLoadingDivers && <CircularProgress />}{" "}
                <span className="ml-5">Rechercher</span>
              </Button>
            </div>
        );
      case 2:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              {abonne && (
                  <>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Numero compteur"
                          fullWidth
                          size="small"
                          value={abonne.numeroCompteur || ""}
                          disabled={true}
                      />
                      <TextField
                          label="Numero de police"
                          fullWidth
                          size="small"
                          value={abonne.police || ""}
                          disabled={true}
                      />
                    </div>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Nom"
                          fullWidth
                          size="small"
                          value={abonne.nom || ""}
                          disabled={true}
                      />
                      <TextField
                          label="Prenom"
                          fullWidth
                          size="small"
                          value={abonne.prenom || ""}
                          disabled={true}
                      />
                    </div>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Email"
                          fullWidth
                          size="small"
                          value={abonne.email || ""}
                          disabled={true}
                      />
                      <TextField
                          label="Telephone"
                          fullWidth
                          size="small"
                          value={abonne.tel || ""}
                          disabled={true}
                      />
                    </div>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Section"
                          fullWidth
                          size="small"
                          value={abonne.section || ""}
                          disabled={true}
                      />

                      <TextField
                          label="lot"
                          fullWidth
                          size="small"
                          value={abonne.lot || ""}
                          disabled={true}
                      />
                    </div>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Parcelle"
                          fullWidth
                          size="small"
                          value={abonne.parcelle || ""}
                          disabled={true}
                      />
                      <TextField
                          label="Rang"
                          fullWidth
                          size="small"
                          value={abonne.rang || ""}
                          disabled={true}
                      />
                    </div>
                  </>
              )}
            </div>
        );
      case 3:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              <Autocomplete
                  fullWidth
                  size="small"
                  // disablePortal
                  autoHighlight
                  id="typepiece"
                  options={TDList}
                  onChange={handleTdList}
                  value={
                      TDList.find((list) => list.TD_CODE === formData.td) || null
                  }
                  getOptionLabel={(option) => option.TD_LIBELLE}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          label="Type de travaux divers"
                          error={!!errors.td}
                          helperText={errors.td}
                      />
                  )}
              />
              <>
                <p className={`mt-2 mb-2 text-sm text-yellow-400 font-bold`}>Importer la Pièce d'identité
                  recto verso en pdf (2Mo maximum)</p>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUpload/>}
                >
                  Importez votre pièce
                  <input
                      type="file"
                      accept=".pdf"
                      hidden
                      onChange={(event) => handleFileChange(event)}
                  />
                </Button>
                {formData.file && <>{formData.file.name}</>}
                {errors.file && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      {errors.file}
                    </p>
                )}
              </>
            </div>
        );
      case 4:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8 dark:text-white">
              <Typography
                  className="text-center mb-8 dark:text-white"
                  variant="h5"
              >
                Voulez-vous vraiment soumettre votre demande ?
              </Typography>
              <div className="flex items-center mb-4">
                <input id="terms-checkbox" type="checkbox" value=""
                       className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                       onChange={handleAcceptTermsAndCondition}
                />
                <label htmlFor="terms-checkbox" className="ml-2 text-sm font-medium text-gray-900">
                  J'accepte les <a href="/clause-juridique" className="text-blue-600 hover:underline" target="_blank">conditions
                  d'utilisation</a> et
                  les <a href="/clause-juridique" className="text-blue-600 hover:underline" target="_blank">clauses de traitement des
                  données</a>.
                </label>
              </div>
            </div>
        );
      default:
        return "Unknown step";
    }
  };

  return (
      <NavbarSidebarLayout>
        {serverOffline && <ServerStatus/>}
        <div
            className="block items-center justify-center border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
          <div className="mb-1 w-full">
            <div className="mb-4">
              <Breadcrumb className="mb-4">
                <Breadcrumb.Item href="/dashboard">
                  <div className="flex items-center gap-x-3">
                    <HiHome className="text-xl"/>
                    <span className="dark:text-white">Accueil</span>
                  </div>
                </Breadcrumb.Item>
                <Breadcrumb.Item href="/list-des-demandes">
                  Liste des services{" "}
                </Breadcrumb.Item>
                <Breadcrumb.Item>Demande de travaux divers</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className={`my-8`}>
              {/*<InfoService message="Pour vos demandes d'abonnement, vous devez avoir en votre possession une pièce d'identité à jour, et le reçu de votre abonnement."/>*/}
              <div className="text-center  text-3xl mb-8">
                Demande de Travaux Divers
              </div>

              <div className="flex w-full flex-col gap-y-6 lg:flex-row lg:justify-center lg:gap-y-0 md:flex-row md:justify-center md:gap-y-0">
                <div className=" rounded-lg bg-white p-2 shadow-2xl dark:bg-gray-800  xl:p-8 lg:w-1/2 border-2 lg:p-5">
                  <div className="invisible md:visible">
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{/*label*/}</StepLabel>
                          </Step>
                      ))}
                    </Stepper>
                  </div>
                  <div>
                    <Typography className="text-center mb-8" variant="h5">
                      {steps[activeStep]}
                    </Typography>
                    {getStepContent(activeStep)}

                    <div className="flex flex-row justify-between pt-18">
                      {activeStep === 0 ? (
                          <Button
                              color="success"
                              variant="outlined"
                              onClick={() => {
                                navigate("/list-des-demandes");
                              }}
                              sx={{ mr: 1 }}
                          >
                            Refuser
                          </Button>
                      ) : (
                          <Button
                              color="success"
                              variant="outlined"
                              onClick={handleBack}
                              sx={{ mr: 1 }}
                          >
                            Précédent
                          </Button>
                      )}

                      <div className="flex 1 1 auto" />
                      {activeStep === steps.length - 1 && (
                          <>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={handleManageDemande}
                                sx={{ mr: 1 }}
                                disabled={stateSend}
                            >
                              {isLoadingDivers && (
                                  <CircularProgress color="secondary" size="1rem" />
                              )}
                              <span className="ml-1">Soumettre</span>
                            </Button>
                            {/*  <div className="bg-green-500 mt-12">  <Link to="/register"> Mes demandes</Link> </div> */}
                          </>
                      )}
                      {activeStep !== steps.length - 1 && (
                          <Button
                              onClick={handleNext}
                              variant="contained"
                              color="success"
                              //disabled={serverOffline}
                              disabled={activeStep === 1 && !isNext}
                          >
                            {activeStep === 0 ? "Accepter" : "Suivant "}
                          </Button>
                      )}
                    </div>
                    <ToastContainer />
                    {/*  {stateSend && (
                        <div className="bg-green-100 mt-12">
                          {" "}
                          <Link to="/dashboard"> Voir mes demandes</Link>{" "}
                        </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavbarSidebarLayout>
  );
  // );
};

export default TravauxDivers;
