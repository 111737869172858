import React from "react";
import {Navigate} from "react-router-dom";

import ResiliationMorale from "../../demande/Resiliations/ResiliationMorale";

const PrivateRouteResiliationMorale= () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <ResiliationMorale/>;
    }
    return <Navigate to="/login"/>
}

export default PrivateRouteResiliationMorale;
