import React from "react";
import {Navigate} from "react-router-dom";
import EditBranchSansAbonnement from "../../../EditRequest/branchement_sans_abonnement/EditBranchSansAbonnement";

const PrivateRouteBranchSansAbonnement = () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <EditBranchSansAbonnement/>;
    }
    return <Navigate to="/login"/>
}

export default PrivateRouteBranchSansAbonnement;
