import React from "react";

const Particulier = () => {
    return (
        <div> 
            <p>Page  de presentation des services</p>
        </div>
    );
}

export default Particulier;