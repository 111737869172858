import React, {createContext, useContext, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {LuChevronFirst, LuChevronLast} from "react-icons/lu";
import Avatar from "@mui/material/Avatar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faHouse,
    faRightFromBracket,
    faUser,
    faList, faFileInvoiceDollar
} from "@fortawesome/free-solid-svg-icons";
import LogoSonabel from "../../../assets/Logo-site-SONABEL-def.png";
import handleLogout from "../../../pages/auth/Lougout/Logout";
import {Button, CircularProgress} from "@mui/material";

const SidebarContext = createContext();

export default function SidebarDash() {
    const location = useLocation();
    const [expanded, setExpanded] = useState(true);
    const [isDiseable, SetIsDiseable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleClickBtnLogout = async (e) => {
        SetIsDiseable(true);
        setIsLoading(true);
        await handleLogout();
        SetIsDiseable(false);
        setIsLoading(false);
    };

    return (
        <>
            <aside className="fixed top-0 left-0 z-40 lg:w-64 h-screen">
                <nav className="h-screen flex flex-col bg-gray-100 border-r shadow-sm">
                    <div className="p-4 pb-2 flex justify-center items-center mt-10 mb-8">
                        <img
                            src={LogoSonabel}
                            className={`object-cover overflow-hidden transition-all ${
                                expanded ? "w-32" : "w-0"
                            }`}
                            alt=""
                        />
                        <button
                            onClick={() => setExpanded((curr) => !curr)}
                            className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100 lg:inline-block lg:hidden"
                        >
                            {expanded ? <LuChevronFirst/> : <LuChevronLast/>}
                        </button>
                    </div>

                    <SidebarContext.Provider value={{expanded}}>
                        <ul className="flex-1 px-3">
                            {/* Inclure directement les SidebarItem ici */}
                            <SidebarItem icon={<FontAwesomeIcon icon={faHouse} />} text="Tableau de bord" active={location.pathname === '/dashboard'} alert={false} path="/dashboard" />
                            <SidebarItem icon={<FontAwesomeIcon icon={faList} />} text="Liste des services" active={location.pathname === '/list-des-demandes'} alert={false} path="/list-des-demandes" />
                            <SidebarItem icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />} text="Voir mes impayés" active={location.pathname === '/mes-impayes'} alert={false} path="/mes-impayes" />
                            <SidebarItem icon={<FontAwesomeIcon icon={faUser} />} text="Profil" active={location.pathname === '/profile'} alert={false} path="/profile" />
                            <SidebarItem>
                                <Button
                                    color="inherit"
                                    onClick={handleClickBtnLogout}
                                    startIcon={isLoading ? <CircularProgress size="1rem" color="success"/> : <FontAwesomeIcon icon={faRightFromBracket} className="md:pl-2" />}
                                    disabled={isDiseable}
                                >
                                    <span
                                        className={`overflow-hidden transition-all ${
                                            expanded ? "w-32" : "w-0"
                                        }`}
                                    >
                                    Déconnecter
                                </span>
                                </Button>
                            </SidebarItem>
                        </ul>

                    </SidebarContext.Provider>

                    <div className="border-t flex p-3">
                        <Avatar
                            alt={localStorage.getItem("username")}
                            src="/static/images/avatar/2.jpg"
                            variant="rounded"
                        />
                        <div
                            className={`flex justify-between items-center overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}`}
                        >
                            <div className="leading-4">
                                <h4 className="font-semibold">{localStorage.getItem("username")}</h4>
                                <span className="text-xs text-gray-600">{localStorage.getItem("email")}</span>
                            </div>
                        </div>
                    </div>
                </nav>
            </aside>
        </>
    )
}

export function SidebarItem(props) {
    const { expanded } = useContext(SidebarContext);

    return (
        <>
            <Link to={props.path}
                  className={`
                    relative flex items-center py-2 px-3 my-1
                    font-medium rounded-md cursor-pointer
                    transition-colors group
                    ${
                                  props.active
                                      ? "bg-gradient-to-tr from-green-200 to-green-100 text-green-800"
                                      : "hover:bg-green-50 text-gray-600"
                              }
                `}
            >
                {props.icon}
                <span
                    className={`overflow-hidden transition-all 
                    ${
                        expanded ? "sm:w-52 sm:ml-3 ml-3" : "w-0"
                    }
                    `}
                >
                    {props.text}
               </span>

                {!expanded && (
                    <div
                        className={`
                          absolute left-full rounded-md px-2 py-1 ml-6
                          bg-green-100 text-green-800 text-sm
                          invisible opacity-20 -translate-x-3 transition-all
                          group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
                      `}
                    >
                        {props.text}
                    </div>
                )}
            </Link>
            {
                props.children
            }
        </>
    )
}
