import axios from 'axios';
import Env from './env';

const environment = new Env();

const apiUrl = environment.apiUrl;

function apiFunction() {
    try {

        // Créer la requête axios avec les valeurs mises à jour
        const instance =  axios.create({
            baseURL: apiUrl,
            headers: {
                'Content-Type': 'application/json',
                // Authorization: 'Basic ' + btoa(username + ':' + password)
            },
        });

        instance.interceptors.request.use(
            (config) => {
                // Mettre à jour les en-têtes avec les dernières valeurs du token avant chaque requête
                const userId = localStorage.getItem('userId') ||  environment.userName;
                const sessionKey = localStorage.getItem('accessToken') || environment.userToken;

                config.headers.Authorization = 'Basic ' + btoa(userId + ':' + sessionKey);

                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
        return instance;
    } catch (error) {
        // throw error;
    }
}

const api = apiFunction();

export { api,apiUrl  };
