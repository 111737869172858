import React from "react";
import NavbarSidebarLayout from "../../components/Dashboard/Layouts/NavbarSidebar";

const UserCompte = () => {

    return (
        <NavbarSidebarLayout>

        </NavbarSidebarLayout>
    );

}

export default UserCompte