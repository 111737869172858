import React, {useState} from 'react';
import {CircularProgress} from "@mui/material";

const Agences = () => {

    const url = "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d62363.49414389102!2d-1.5416650534165246!3d12.33473211288695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sAgence%20Sonabel%20!5e0!3m2!1sen!2sbf!4v1712221840385!5m2!1sen!2sbf";
    const [loading, setLoading] = useState(true);

    const handleLoadSpinner = () => {
      setLoading(false);
    }

    return (
        <>
            <div className="items-center">
                {loading && <CircularProgress color="secondary" size="1rem" />}
                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                <iframe
                    src={url}
                    width={600}
                    height={450}
                    style={{border: 0}}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    onLoad={handleLoadSpinner}
                />
            </div>
        </>
    );
}
export default Agences;
