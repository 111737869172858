import React from "react";
import {Navigate} from "react-router-dom";
import Login from "../../auth/Login/Login";


const PrivateRouteLogin = () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <Navigate to="/dashboard"/>
    }
    return <Login/>
}

export default PrivateRouteLogin;
