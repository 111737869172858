import React from "react";

const Entreprise = () => {
    return (
        <div> 
            <p>Page  de presentation des services</p>
        </div>
    );
}

export default Entreprise;