import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import {MuiTelInput} from "mui-tel-input";
import {Alert, Button, Card, CardMedia, CircularProgress, Snackbar} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';

export function MobileForm(props) {
    const [open, setOpen] = React.useState(false);

    const [selectedCardIndex, setSelectedCardIndex] = useState(null);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleSelect = (index, value) => {
        setSelectedCardIndex(index);
        props.handleMobileDataChange("partenaire", value);
    };
    useEffect(() => {
        if (props.errors){
            setOpen(true);
        }
    }, [props.errors]);
    return(
        <>
            <div
                className="flex flex-col justify-center mx-auto mt-8"
            >

                <div className={`flex flex-col items-center`}>
                    <p className={`text-center text-xl font-bold mt-4 mb-4`}>Choissez votre opérateur pour le payement</p>
                    <div className="flex space-x-4 p-4">
                        {props.mobilMoney.map((card, index) => (
                            <Card
                                key={index}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: index === selectedCardIndex ? 'green' : 'white',
                                    cursor: 'pointer',
                                    padding: 2,
                                    boxShadow: 3,
                                }}
                                onClick={() => handleSelect(index, card.id)}
                            >
                                <CardMedia
                                    component="img"
                                    sx={{ width: 100 }}
                                    image={card.image}
                                    alt={`Image for ${card.title}`}
                                />
                            </Card>

                        ))}
                    </div>
                </div>
                <div className="container mx-auto">
                    <div className={`flex items-stretch mt-3`}>
                        <div className={`sm:flex-initial sm:w-1/4`}></div>
                        <div className={`sm:flex-initial sm:w-2/4 w-full text-justify`}>
                            {/*Si c'est orange on affiche ce message*/}

                        </div>
                        <div className={`sm:flex-initial sm:w-1/4`}></div>
                    </div>
                </div>
                <div className="container mx-auto">
                    <div className={`flex items-stretch justify-center mt-3`}>
                        <div>
                            {(props.errors) && (
                                <>
                                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                        <Alert
                                            onClose={handleClose}
                                            severity="error"
                                            variant="filled"
                                            sx={{ width: '100%' }}
                                        >
                                            {props.errors}
                                        </Alert>
                                    </Snackbar>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className={`flex flex-col sm:flex-row justify-center sm:space-x-2 mt-8`}>
                    <div className="w-full sm:w-[540px]">
                        <MuiTelInput
                            onlyCountries={['BF']}
                            id="tel"
                            label="Numéro de téléphone"
                            value={props.mobileDataSend.tel}
                            onChange={props.handleMobileDataSendChange}
                            required
                            className="dark:text-white"
                            fullWidth
                        />
                    </div>
                </div>
                {/*Si c'est orange on affiche le input pour l'*/}
                {(props.orange) && (
                    <div className={`flex flex-col sm:flex-row justify-center mt-8`}>
                        <div className="w-full sm:w-[540px]">
                            <TextField
                                id="code"
                                label="Entrez votre code OTP"
                                fullWidth
                                value={props.mobileDataSend.otp}
                                onChange={(e) => props.handleMobileDataChange("otp", e)}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </div>

                    </div>
                )}

                <div className="grid grid-cols-3 sm:gap-8 md:gap-12 mt-4 mx-auto">
                    {/*Masquer le div lorsque je suis en mode mobile*/}
                    <div className="hidden md:block w-full"></div>
                    <div className="w-full"></div>
                    {/*Button envoyer pour initier le payement*/}
                    <div className="w-full">
                        <Button variant="outlined" color="secondary" size="large" endIcon={<SendIcon/>}
                                onClick={props.handleInitPayMobile} disabled={props.disableBtn}>
                            {(props.isLoading === true) && (
                                <CircularProgress color="secondary" size="1rem"/>
                            )}
                            <>Envoyer</>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );

}
