import MainNavigation from "../../components/NavBar/MainNavigation";
import React, {useEffect, useState} from "react";
import FooterComponent from "../../components/Footer/FooterComponent";
import {CircularProgress, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {toast, ToastContainer} from "react-toastify";
import FormHelperText from "@mui/material/FormHelperText";
import {PieceValidation} from "../../components/Validation/PieceValidation";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {api} from "../../config/config";


const Denonciation = () => {
    const resetValue = {
        nom: "",
        prenom: "",
        telephone: "",
        email: "",
        fraudeur: "",
        numero_fraudeur: "",
        adresse: "",
        description: "",
        repere_proche: "",
        type_fraude: "",
        description_fraude: "",
        date_fraude: null,
        type_fraude_description: "",
    };
    const [fraudeInfo, setFraudeInfo] = useState({
        nom: "",
        prenom: "",
        telephone: "",
        email: "",
        fraudeur: "",
        numero_fraudeur: "",
        adresse: "",
        description: "",
        repere_proche: "",
        type_fraude: "",
        description_fraude: "",
        date_fraude: "",
        longitude: "",
        lattitude: "",
        type_fraude_description: "",
    });
    // const [description_detaille, setDescriptionDetaille] = useState("");
    const [diseable, setDiseable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorDate, setErrorDate] = useState(false);
    const [listFraude, setListFraude] = useState([]);

    useEffect(() => {
        api.get(`/typefraude/all`)
            .then((response) => {
                if (response.status === 200) {
                    setListFraude(response.data.content);
                }
            })
            .catch(() => {

            });
    }, []);

    useEffect(() => {
        if (fraudeInfo.type_fraude.name === "Branchement illégal" || fraudeInfo.type_fraude.name === "Compteur manipulé") {
            setDiseable(true);
        } else if (fraudeInfo.type_fraude.name === "Autres") {
            setDiseable(false);
        }
    }, [fraudeInfo.type_fraude]);

    const handleChange = (field, value) => {
        setFraudeInfo((prevData) => ({ ...prevData, [field]: value }));
    };

    const validateData = () => {
        let error = {};
        if(!fraudeInfo.date_fraude){
            error.date_fraude = "Ce champ est obligatoire.";
            setErrorDate(true);
        }else if (!PieceValidation(fraudeInfo.date_fraude)){
            error.date_fraude = "Revoyez votre date svp.";
            setErrorDate(true);
        }
        if(!fraudeInfo.adresse.trim()){
            error.adresse = "Ce champ est obligatoire.";
        }
        if(!fraudeInfo.description_fraude.trim()){
            error.description_fraude = "Ce champ est obligatoire.";
        }
        if(!fraudeInfo.repere_proche.trim()){
            error.repere_proche = "Ce champ est obligatoire.";
        }
        if(!fraudeInfo.type_fraude){
            error.type_fraude = "Ce champ est obligatoire.";
        }
        if(!fraudeInfo.type_fraude_description && (diseable === false)) {
            error.type_fraude_description = "Ce champ est obligatoire.";
        }
        if(!fraudeInfo.description.trim()){
            error.description = "Ce champ est obligatoire.";
        }
        setErrors(error);
        return Object.keys(error).length === 0;
    }

    const handleSendDenonciation = async () => {
        if (validateData()) {
            setLoading(true);
            let dataFraude  = {
                nom: fraudeInfo.nom,
                prenom: fraudeInfo.prenom,
                telephone: fraudeInfo.telephone,
                email: fraudeInfo.email,
                fraudeur: fraudeInfo.fraudeur,
                numero_fraudeur: fraudeInfo.numero_fraudeur,
                adresse: fraudeInfo.adresse,
                description: fraudeInfo.description,
                repere_proche: fraudeInfo.repere_proche,
                type_fraude: fraudeInfo.type_fraude.id,
                description_fraude: fraudeInfo.description_fraude,
                date_fraude: fraudeInfo.date_fraude,
                type_fraude_description: fraudeInfo.type_fraude_description,
            };

            // On parcours l'objet et on supprime les valeurs vide
            const filteredData = Object.fromEntries(
                Object.entries(dataFraude).filter(([key, value]) => value !== "")
            );
            try {
                await api.post(`/fraude`,
                    filteredData
                ).then((response) => {
                    if (response.status === 201){
                        setFraudeInfo(resetValue);
                        setErrors({});
                        setLoading(false);
                        toast.success("Votre demande a été envoyé et sera traité.");
                    }
                }).catch(() => {
                    setLoading(false);
                    toast.error("L'envoie a échoué veuillez réessayer. Si cela persiste, Veuillez contactez le service clientèle.")
                });
            }catch (e) {

            }
        }
    }

    return (
        <>
            <ToastContainer/>
            <div className="flex flex-col gap-4 min-h-screen">
                <MainNavigation />
                <div className={`mt-5`}>

                </div>
                <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-16">
                    <Typography variant="h5" className="mb-4 text-center">Formulaire de Déclaration de Fraude</Typography>

                    <div className="mb-6 mt-5">
                        <Typography variant="h6" className={`text-red-700`}>1- Information sur le dénonciateur (facultatif)</Typography>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                            <TextField
                                label="Nom"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => handleChange("nom", e.target.value)}
                                value={fraudeInfo.nom}
                            />
                            <TextField
                                label="Prénom"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => handleChange("prenom", e.target.value)}
                                value={fraudeInfo.prenom}
                            />
                            <TextField
                                label="Numéro de téléphone"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => handleChange("telephone", e.target.value)}
                                value={fraudeInfo.telephone}
                            />
                            <TextField
                                label="E-mail"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => handleChange("email", e.target.value)}
                                value={fraudeInfo.email}
                            />
                        </div>
                    </div>

                    <div className="mb-6">
                        <Typography variant="h6" className={`text-red-700`}>2- Information sur le fraudeur présumé</Typography>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                            <TextField
                                label="Nom et Prénom (si connu)"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => handleChange("fraudeur", e.target.value)}
                                value={fraudeInfo.fraudeur}
                            />
                            <TextField
                                label="Numéro de téléphone (si connu)"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => handleChange("numero_fraudeur", e.target.value)}
                                value={fraudeInfo.numero_fraudeur}
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{ width: "100%" }}
                                    label="Date de constation de la fraude"
                                    defaultValue={dayjs(fraudeInfo.date_fraude)}
                                    format="DD/MM/YYYY"
                                    onChange={(value) => {
                                        // Formater la date au format "yyyy/mm/dd" avec Dayjs
                                        const formattedDate = dayjs(value).format("YYYY/MM/DD");

                                        // Appeler la fonction handleChange avec la date formatée
                                        handleChange("date_fraude", formattedDate);
                                    }}
                                    slotProps={{
                                        textField: {
                                            error: errorDate,
                                            helperText: errors.date_fraude,
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                            <TextField
                                label="Adresse complète où la fraude est suspectée"
                                variant="outlined"
                                fullWidth
                                multiline rows={3}
                                onChange={(e) => handleChange("adresse", e.target.value)}
                                value={fraudeInfo.adresse}
                                error={!!errors.adresse}
                                helperText={errors.adresse}
                            />
                            <TextField
                                label="Description de l'emplacement"
                                variant="outlined"
                                fullWidth
                                multiline rows={3}
                                onChange={(e) => handleChange("description", e.target.value)}
                                value={fraudeInfo.description}
                                error={!!errors.description}
                                helperText={errors.description}
                            />
                            <TextField
                                label="Repères proches pour faciliter la localisation (GPS, n° poteau etc...)"
                                variant="outlined"
                                fullWidth
                                multiline rows={3}
                                onChange={(e) => handleChange("repere_proche", e.target.value)}
                                value={fraudeInfo.repere_proche}
                                error={!!errors.repere_proche}
                                helperText={errors.repere_proche}
                            />
                        </div>

                        <div className="mt-4">
                            <Typography variant="subtitle1" className={`text-red-700 font-bold`}>Type de fraude
                                suspectée :</Typography>
                            <div className="flex items-center mt-2 space-x-4">
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id="fraud-select-label">Choisissez le type de fraude</InputLabel>
                                    <Select
                                        label="Type de fraude"
                                        id="type_fraude"
                                        value={fraudeInfo.type_fraude}
                                        labelId="type_fraude"
                                        size="small"
                                        sx={{ width: "100%" }}
                                        onChange={(e) => handleChange("type_fraude", e.target.value)}
                                        renderValue={(selected) => selected ? selected.name : ''}
                                        variant="outlined"
                                    >
                                        {listFraude.map((k) => (
                                            <MenuItem key={k.name} value={k}>
                                                {k.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                {errors.type_fraude && (
                                    <FormHelperText sx={{color: 'red'}}>{errors.type_fraude}</FormHelperText>
                                )}
                            </div>
                        </div>

                        <div className="mt-4 space-y-3">
                            {
                                (diseable === false && fraudeInfo.type_fraude.name === "Autres") && (
                                    <TextField
                                        label="Autres, à décrire:"
                                        variant="outlined"
                                        fullWidth
                                        onChange={(e) =>
                                            handleChange("type_fraude_description",e.target.value)
                                        }
                                        value={fraudeInfo.type_fraude_description}
                                        error={!!errors.type_fraude_description}
                                        helperText={errors.type_fraude_description}
                                    />
                                )
                            }
                            <TextField
                                label="Description détaillée des faits observés"
                                variant="outlined"
                                fullWidth
                                multiline rows={4}
                                onChange={(e) =>
                                    handleChange("description_fraude", e.target.value)
                                }
                                error={!!errors.description_fraude}
                                helperText={errors.description_fraude}
                                value={fraudeInfo.description_fraude}
                            />
                        </div>
                    </div>

                    <div className="mt-6 flex justify-end">
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleSendDenonciation}
                        >
                            {
                                loading && (
                                    <>
                                        <CircularProgress color="primary" size="1rem" />
                                    </>
                                )
                            }
                            Soumettre
                        </Button>
                    </div>
                </div>
                <FooterComponent className="flex-shrink-0"/>
            </div>
        </>
    );
}

export default Denonciation;
