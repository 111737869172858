import React, {useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import {Breadcrumb} from "flowbite-react";
import {HiHome} from "react-icons/hi";
import NavbarSidebarLayout from "../../components/Dashboard/Layouts/NavbarSidebar";
import Box from "@mui/material/Box";
import {
    Checkbox,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {api} from "../../config/config";
import FormControlLabel from "@mui/material/FormControlLabel";

const Impaye = () => {
    const [infos, setInfos] = useState({
        exploitation: "",
        police: ""
    });

    const [impayeList, setImpayeList] = useState([]);

    const [confirm, setConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checkConfirm, setCheckConfirm] = useState(false);
    const [userCompteur, setUserCompteur] = useState({
        exploitation: "",
        police: ""
    });

    useEffect(() => {
        api.get(`/account/${localStorage.getItem('userId')}`).then((response) => {
            if (response.status === 200) {
                setUserCompteur({
                    exploitation: response.data.content.agent_expl_code,
                    police: response.data.content.numero_police,
                });
            }
        }).catch(() => {

        })
    }, []);
    useEffect(() => {
        if (userCompteur.exploitation && userCompteur.police) {
            api.get(`/impayes/${userCompteur.exploitation}/${userCompteur.police}`).then(
                (response) => {
                    if (response.status === 200) {
                        const impayes = response.data.content.map((values) => ({
                            id: values['FACTA_NUMPOL#'],
                            mois: handlePeriodeFacturation(values['FACTA_MOIS#']),
                            annee: values['FACTA_ANNEE#'],
                            date: dateFormat(values.FACTA_DATE),
                            date_limite: dateFormat(values.FACTA_DATEREGL),
                            montant: values.FACTA_MNTCONS,
                            exploitation: userCompteur.exploitation,
                            police: userCompteur.police
                        }));
                        if (impayes.length === 0) {
                            setConfirm(true);
                            setImpayeList([]);
                        }
                        setImpayeList(impayes);
                    } else if (response.status === 204) {
                        setLoading(false);
                        setConfirm(true);
                        setImpayeList([]);
                    }
                }
            ).catch(() => {
            });
        }
    }, [userCompteur]);

    // Controller le check
    const handleChangeCheck = (event) => {
        setCheckConfirm(event.target.checked);
    }

    const handleChange = (field, value) => {
        setInfos((prevData) => ({ ...prevData, [field]: value }));
    };

    const saveCompteurInfo = async () => {
        try {
          await api.put(`/account/info`, {
                accountId: localStorage.getItem('userId'),
                exploitation: infos.exploitation,
                police: infos.police,
            }).then((res) => {
                if (res.status === 201){
                    handleSearchImpaye();
                }
            }).catch((e) => {
                return false;
            });
        }catch (e) {

        }
    }

    const handleSearchImpaye = async () => {
        await api.get(`/impayes/${infos.exploitation}/${infos.police}`).then(
            (response) => {
                if (response.status === 200) {
                    setLoading(false);
                    const impayes = response.data.content.map((values) => ({
                        id: values['FACTA_NUMPOL#'],
                        mois: handlePeriodeFacturation(values['FACTA_MOIS#']),
                        annee: values['FACTA_ANNEE#'],
                        date: dateFormat(values.FACTA_DATE),
                        date_limite: dateFormat(values.DATE_LIMITE),
                        montant: values.FACTA_MNTCONS,
                        exploitation: infos.exploitation,
                        police: infos.police
                    }));
                    if (impayes.length === 0) {
                        setConfirm(true);
                        setImpayeList([]);
                    }
                    setImpayeList(impayes);
                } else if (response.status === 204) {
                    setLoading(false);
                    setConfirm(true);
                    setImpayeList([]);
                }
            }
        ).catch(() => {
            setLoading(false);
            toast.error(
                `Désolé ces informations ne figurent pas dans notre système,`
            );
        });
    }

    const handleSearch = async () => {
        setLoading(true);
        if (checkConfirm && infos.exploitation && infos.police){
            await saveCompteurInfo();
            // if (updateConfirm) {
            //     await handleSearchImpaye();
            // }
        } else if (infos.exploitation && infos.police){
            await handleSearchImpaye();
        } else {
            setLoading(false);
            toast.error("Assurez-vous d'entrer toutes les informations.");
        }
    }

    function dateFormat(dateValue) {
        const date = new Date(dateValue);
        return new Intl.DateTimeFormat('fr-FR', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
        }).format(date);
    }

    function handlePeriodeFacturation(moisChiffre) {
        // Tableau des mois de l'années
        const yearMonth = [
            "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
            "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
        ];
        return yearMonth[moisChiffre - 1];
    }

    return(
        <NavbarSidebarLayout>
            <ToastContainer/>
            <div
                className="block items-center justify-between border-b border-gray-200 bg-white p-4 ">
                <div className="mb-1 w-full">
                    <div className="mb-4">
                        <Breadcrumb className="mb-4">
                            <Breadcrumb.Item href="/dashboard">
                                <div className="flex items-center gap-x-3">
                                    <HiHome className="text-xl" />
                                    <span className="dark:text-white">Accueil</span>
                                </div>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href="#">Impayés</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className={`text-center font-bold text-xl`}>
                            <h3>Consulter ses impayés</h3>
                        </div>
                        <div className={`container`}>
                            {/*Champs de recherche*/}
                            <Box className="p-6 bg-gray-100">
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            variant="outlined"
                                            placeholder="Exploitation"
                                            value={infos.exploitation || ""}
                                            fullWidth
                                            onChange={(e) => handleChange("exploitation", e.target.value)}
                                            InputProps={{
                                                classes: {
                                                    root: 'bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500',
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            variant="outlined"
                                            placeholder="Police"
                                            fullWidth
                                            value={infos.police || ""}
                                            onChange={(e) => handleChange("police", e.target.value)}
                                            InputProps={{
                                                classes: {
                                                    root: 'bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500',
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            onClick={handleSearch}
                                            className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                            disabled={loading}
                                        >
                                            {(loading) && (
                                                <>
                                                    <CircularProgress color="primary" size="1rem" />
                                                </>
                                            )}
                                            Rechercher
                                        </Button>
                                    </Grid>
                                </Grid>
                                <div>
                                    <FormControlLabel
                                        label="Sauvegarder les informations?"
                                        control={
                                            <Checkbox
                                                checked={checkConfirm}
                                                onChange={handleChangeCheck}
                                                inputProps={{'aria-label': 'controlled'}}
                                            />
                                        } />
                                </div>
                            </Box>
                            {/*Afficher impayés*/}
                            <Box className="p-6">
                                <Paper className="p-4">
                                    <Typography variant="h6" className="mb-4 text-center">
                                        Liste des impayés
                                    </Typography>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className="font-bold">Exploitation</TableCell>
                                                    <TableCell className="font-bold">Police</TableCell>
                                                    <TableCell className="font-bold">Période</TableCell>
                                                    <TableCell className="font-bold">Date facture</TableCell>
                                                    <TableCell className="font-bold">Date limite payement</TableCell>
                                                    <TableCell className="font-bold">Montant</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    (impayeList.length === 0 && confirm === true) ? (
                                                        <>
                                                            <TableRow>
                                                                <TableCell colSpan={3} align={"center"}>
                                                                    <p className={`text-red-700`}>Aucun impayé pour ce compteur.</p>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {impayeList.map((value) => (
                                                                <TableRow key={`${value.id}-${value.date_limite}`}>
                                                                    <TableCell>{value.exploitation}</TableCell>
                                                                    <TableCell>{value.police}</TableCell>
                                                                    <TableCell>{value.mois} {value.annee}</TableCell>
                                                                    <TableCell>{value.date}</TableCell>
                                                                    <TableCell>{value.date_limite}</TableCell>
                                                                    <TableCell>{value.montant} CFA</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </>
                                                    )
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </NavbarSidebarLayout>
    );

}

export default Impaye;
