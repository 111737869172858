import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import {Typography} from "@mui/material";
import React from "react";

export function Commercialisation() {
    return(
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <p className="text-red-700 text-xl font-bold">Commercialisation</p>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        <p className="text-lg mb-4">
                            {" "}
                            La commercialisation de l'énergie électrique est effectuée à travers un réseau d'agences réparties sur le territoire, comprenant 14 agences à travers le pays,
                            dont 2 à Ouagadougou. En plus de ces agences physiques,
                            la SONABEL adopte une dynamique de digitalisation de ses services,
                            permettant la commercialisation via des moyens électroniques en collaboration avec plusieurs partenaires.
                            {" "}
                        </p>
                        <div className="mt-4">
                            <div>
                                <ol className="space-x-4 space-y-4 text-gray-500 list-decimal list-inside">
                                    <div></div>
                                    <li>
                                        <strong>Nombre d'Abonnés:</strong>
                                        <ul className="ps-5 mt-2 space-y-1 list-disc list-inside">
                                            <li>À la fin de l'année 2022, le nombre total d'abonnés s'est élevé à 983 289, enregistrant une augmentation de 10% par rapport à l'année précédente.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Ventes d'Énergie:</strong>
                                        <ul className="ps-5 mt-2 space-y-1 list-disc list-inside">
                                            <li>
                                                Les ventes d'énergie pour l'exercice 2022 ont atteint 2 172 847 435 kWh, démontrant une forte demande pour les services énergétiques de la SONABEL.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Encaissements et Transactions:</strong>
                                        <ul className="ps-5 mt-2 space-y-1 list-disc list-inside">
                                            <li>
                                                Les paiements électroniques jouent un rôle crucial dans les encaissements de factures et les achats d'unités prépayées pour les clients BT simple tarif. En 2022, trois nouveaux partenaires (BDU, WAVE et RCPB) ont intégré le système, portant le nombre total de partenaires à douze (12), diversifiant ainsi l'offre auprès de la clientèle.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Encaissements et Transactions:</strong>
                                        <ul className="ps-5 mt-2 space-y-1 list-disc list-inside">
                                            <li>
                                                Les paiements électroniques jouent un rôle crucial dans les encaissements de factures et les achats d'unités prépayées pour les clients BT simple tarif. En 2022, trois nouveaux partenaires (BDU, WAVE et RCPB) ont intégré le système, portant le nombre total de partenaires à douze (12), diversifiant ainsi l'offre auprès de la clientèle.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Recouvrement des Créances:</strong>
                                        <ul className="ps-5 mt-2 space-y-1 list-disc list-inside">
                                            <li>
                                                Le taux de recouvrement pour l'année 2022 s'est établi à un impressionnant 98,90%, démontrant l'efficacité des efforts de recouvrement de la SONABEL.
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
    );
}