import React, { useState } from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import mobile1 from "../../assets/moov_money.png";
import corisMoney from "../../assets/mobile5.png";
import bank1 from "../../assets/bank1.png";
import bank2 from "../../assets/bank2.png";
import bank3 from "../../assets/bank3.png";
import bank4 from "../../assets/bank4.png";
import bank5 from "../../assets/bank5.png";
import bank6 from "../../assets/bank6.png";
import bank7 from "../../assets/bank7.png";
import bank8 from "../../assets/bank8.png";
import bank9 from "../../assets/bank9.png";
import sankLogo from "../../assets/sank/sank_logo.jpeg"

//import sankLogo from "../../assets/sank/Sank_red.0bd112c047b134883b71.png"
import { motion } from "framer-motion";

const Paiement = () => {
  const [value, setValue] = useState("mobile");

  // const [mobileImages,setMobileImages] = useState([]);
  // const [bankImages,setBankImages] = useState([]);


 /*  useEffect(()=> {
   // Generer un ordre aleatoire pour les images
    const mobileOrder = generateRandomOrder(5);
    const mobileImagesArray = mobileOrder.map((index) => ({
        src: require(`../assets/mobile${index}.png`).default,
        alt: `mobile${index}`,
    }));

  },[]);
 */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div id="nos-partenaires" className=" w-4/5 container mx-auto my-8 px-4">
      <h2 className="text-3xl font-bold text-center mb-4">
        Nos partenaires de paiement
      </h2>
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="Partenaires de paiement">
          <Tab label="Paiement Mobile" value="mobile" />
          {/*<Tab label="Paiement par Banques" value="bank" />*/}
        </TabList>
        <TabPanel
          value="mobile"
        >

          <motion.div
              className="grid grid-cols-5 gap-4 justify-items-center mt-4"
              animate={{x: [0, 100, 0]}}
          >
            <img src={sankLogo} alt="mobile1" width={85}/>
            <img src={mobile1} alt="mobile2" width={80}/>
            <img src={corisMoney} alt="mobile5" width={70}/>
            {/*<img src={mobile2} alt="mobile2" width={80}/>*/}
            {/*<img src={mobile3} alt="mobile3" width={80}/>*/}
            {/*<img src={mobile4} alt="mobile4" width={80}/>*/}
          </motion.div>


        </TabPanel>
        <TabPanel
          value="bank"
          className="grid grid-cols-5 gap-4 justify-items-center mt-4"
        >
          <img src={bank1} alt="bank1" width={80} />
          <img src={bank2} alt="bank2" width={80} />
          <img src={bank3} alt="bank3" width={80} />
          <img src={bank4} alt="bank4" width={80} />
          <img src={bank5} alt="bank5" width={80} />
          <img src={bank6} alt="bank6" width={80} />
          <img src={bank7} alt="bank7" width={80} />
          <img src={bank8} alt="bank8" width={80} />
          <img src={bank9} alt="bank9" width={80} />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default Paiement;
