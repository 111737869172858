import React from 'react';

const StatusBadge = ({ status }) => {
  let bgColor, textColor, text;

  switch (status) {
    case 0:
      bgColor = 'bg-yellow-50';
      textColor = 'text-yellow-800';
      text = 'En attente de traitement...';
      break;
    case 1:
      bgColor = 'bg-purple-50';
      textColor = 'text-purple-700';
      text = 'En Traitement...';
      break;
    case 2:
      bgColor = 'bg-green-50';
      textColor = 'text-green-700';
      text = 'Validé';
      break;
    case 3:
      bgColor = 'bg-blue-50';
      textColor = 'text-blue-700';
      text = 'Devis fait.';
      break;
    case 4:
      bgColor = 'bg-indigo-50';
      textColor = 'text-indigo-700';
      text = 'Paiement effectué.';
      break;
    case 6:
      bgColor = 'bg-green-50';
      textColor = 'text-green-900';
      text = 'Payé';
      break;
    case 5:
      bgColor = 'bg-green-50';
      textColor = 'text-green-900';
      text = 'Travaux clôturés';
      break;
    case -1:
      bgColor = 'bg-red-50';
      textColor = 'text-red-700';
      text = 'Rejeté.';
      break;
    case 10:
      bgColor = 'bg-green-50';
      textColor = 'text-green-900';
      text = 'Transmis';
      break;
    default:
      bgColor = 'bg-gray-50';
      textColor = 'text-gray-700';
      text = 'Statut inconnu';
      break;
  }

  return (
    <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${bgColor} ${textColor}`}>
      {text}
    </span>
  );
};

export default StatusBadge;
