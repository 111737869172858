import React from "react";
import {Navigate} from "react-router-dom";
import RegisterPage from "../../auth/Register/RegisterPage";


const PrivateRouteRegister = () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <Navigate to="/dashboard"/>
    }
    return <RegisterPage/>
}

export default PrivateRouteRegister;
