import React from "react";
import MainNavigation from "../../components/NavBar/MainNavigation";
import FooterComponent from "../../components/Footer/FooterComponent";
import { Container, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ClauseJurique = () => {
  return (
      <div className="flex flex-col min-h-screen">
        <MainNavigation />

        <Container component="main" maxWidth="lg" className="mt-20 flex-grow">
          <Typography
              variant="h4"
              component="h1"
              sx={{ textAlign: "center", mb: "12px", mt: "8px" }}
          >
            Clauses Juridiques de la plateforme eguichet
          </Typography>
          <div>
            <Accordion defaultExpanded>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Termes et Conditions
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1">
                  <ol className="list-decimal pl-4">
                    <li>
                      <strong>Acceptation des conditions :</strong> En utilisant
                      notre plateforme, vous acceptez automatiquement les
                      présentes conditions générales.
                    </li>
                    <li>
                      <strong>Utilisation autorisée :</strong> Vous vous engagez à
                      utiliser notre plateforme conformément aux lois applicables
                      et aux présentes conditions.
                    </li>
                    <li>
                      <strong>Modifications des conditions :</strong> Nous nous
                      réservons le droit de modifier les présentes conditions à
                      tout moment, et il est de votre responsabilité de consulter
                      régulièrement ces conditions pour toute mise à jour.
                    </li>
                  </ol>
                </Typography>
              </AccordionDetails>
            </Accordion>
            {/*<Accordion>*/}
            {/*  <AccordionSummary*/}
            {/*      expandIcon={<ExpandMoreIcon />}*/}
            {/*      aria-controls="panel1-content"*/}
            {/*      id="panel1-header"*/}
            {/*  >*/}
            {/*    <Typography variant="h6" sx={{ fontWeight: "bold" }}>*/}
            {/*      Mentions Légales*/}
            {/*    </Typography>*/}
            {/*  </AccordionSummary>*/}
            {/*  <AccordionDetails>*/}
            {/*    <Typography variant="body1">*/}
            {/*      Conformément aux dispositions des Articles 6-III et 19 de la Loi*/}
            {/*      n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie*/}
            {/*      numérique (L.C.E.N.), nous vous informons que les utilisateurs*/}
            {/*      du site{" "}*/}
            {/*      <a*/}
            {/*          href="https://eguichet.sonabel.bf"*/}
            {/*          target="_blank"*/}
            {/*          rel="noopener noreferrer"*/}
            {/*      >*/}
            {/*        eguichet.sonabel.bf*/}
            {/*      </a>{" "}*/}
            {/*      sont soumis aux présentes mentions légales. En naviguant sur le*/}
            {/*      Site, les utilisateurs acceptent pleinement et sans réserve ces*/}
            {/*      mentions légales. Pour plus d'informations, veuillez consulter*/}
            {/*      notre site{" "}*/}
            {/*      <a*/}
            {/*          href="https://eguichet.sonabel.bf/clause-juridique"*/}
            {/*          target="_blank"*/}
            {/*          rel="noopener noreferrer"*/}
            {/*      >*/}
            {/*        eguichet.sonabel.bf/clause-juridique*/}
            {/*      </a>*/}
            {/*      .*/}
            {/*      <br />*/}
            {/*      <br />*/}
            {/*      <strong>*/}
            {/*        Le site web actuel, accessible via l'URL{" "}*/}
            {/*        <a*/}
            {/*            href="https://eguichet.sonabel.bf/"*/}
            {/*            target="_blank"*/}
            {/*            rel="noopener noreferrer"*/}
            {/*        >*/}
            {/*          eguichet.sonabel.bf*/}
            {/*        </a>*/}
            {/*        , est la propriété de la Société Nationale d'Electricité du*/}
            {/*        Burkina (SONABEL). Voici les coordonnées de la société :*/}
            {/*      </strong>*/}
            {/*      <ul>*/}
            {/*        <li>*/}
            {/*          Adresse : 55, AVENUE DE LA NATION, Ouagadougou, Burkina Faso*/}
            {/*        </li>*/}
            {/*        <li>Boîte postale : Ouagadougou 01 BP 54 Burkina Faso</li>*/}
            {/*        <li>Numéro de téléphone : (+226) 25 31 37 20</li>*/}
            {/*        <li>Fax : (+226) 50 31 03 40</li>*/}
            {/*        <li>*/}
            {/*          Adresse e-mail :{" "}*/}
            {/*          <a href="mailto:info@sonabel.bf">info@sonabel.bf</a>*/}
            {/*        </li>*/}
            {/*      </ul>*/}
            {/*      <strong>Créateur :</strong> DIRECTION DU SYSTÈME D'INFORMATION*/}
            {/*      DE LA SONABEL*/}
            {/*      <br />*/}
            {/*      <strong>Responsable publication :</strong> SONABEL,*/}
            {/*      Direction de la communication –{" "}*/}
            {/*      <a href="mailto:courrier@sonabel.bf">*/}
            {/*        courrier@sonabel.bf*/}
            {/*      </a>*/}
            {/*      <br />*/}
            {/*      <strong>Hébergeur :</strong> SONABEL*/}
            {/*    </Typography>*/}
            {/*  </AccordionDetails>*/}
            {/*</Accordion>*/}
            <Accordion>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Conditions Générales d'Utilisation
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1">
                  En tant qu'utilisateur de notre plateforme eguichet, vous
                  consentez à la collecte et au traitement de vos données
                  personnelles conformément aux dispositions suivantes :
                  <br />
                  <br />
                  <strong>Collecte d'informations :</strong> Nous recueillons des
                  informations lorsque vous vous inscrivez sur notre site, lorsque
                  vous vous connectez à votre compte, lorsque vous naviguez sur
                  notre site et lorsque vous vous déconnectez. Ces informations
                  incluent votre nom, adresse e-mail, numéro de téléphone,
                  identifiant de l’abonnement, date de naissance, lieu
                  d’établissement de la pièce d’identité, raison sociale s’il
                  s’agit d’une société, etc.
                  <br />
                  <br />
                  <strong>Utilisation des informations :</strong> Les informations
                  collectées peuvent être utilisées pour les finalités suivantes :
                  <ul>
                    <li>
                      Améliorer le service client et vos besoins de prise en
                      charge ;
                    </li>
                    <li>Améliorer notre site Web ;</li>
                    <li>Vous contacter par e-mail, téléphone, SMS, etc.</li>
                  </ul>
                  <strong>Traitement des requêtes :</strong> Vos informations
                  personnelles sont nécessaires à la SONABEL pour traiter vos
                  requêtes. Si vous ne fournissez pas ces informations, nous ne
                  serons pas en mesure de répondre à vos demandes.
                  <br />
                  <br />
                  <strong>Confidentialité et sécurité :</strong> Vos informations
                  personnelles ne seront pas commercialisées, échangées,
                  transférées ou données à une autre société sans votre
                  consentement, sauf dans les cas suivants :
                  <ul>
                    <li>
                      Règlement de facture avec nos partenaires de confiance ayant
                      des contrats de confidentialité avec nous ;
                    </li>
                    <li>
                      Respect d'une obligation légale à laquelle la SONABEL est
                      soumise.
                    </li>
                  </ul>
                  {/*<strong>Protection des données :</strong> La SONABEL s'engage à*/}
                  {/*assurer le meilleur niveau de protection de vos données*/}
                  {/*personnelles conformément à la loi n°2013-450 du 19 juin 2013*/}
                  {/*relative à la protection des données à caractère personnel.*/}
                  <br />
                  <br />
                  Pour exercer vos droits ou pour toute question relative à la
                  protection de vos données, veuillez contacter la Direction de la
                  Relation Client de la SONABEL au Tél : (226) 50 30 61 00 / 02 /
                  03 / 04.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </Container>
        <FooterComponent className="flex-shrink-0" />
      </div>
  );
};

export default ClauseJurique;
