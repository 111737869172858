import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import SonabelLogo from "../../../../assets/Sonabel_logo.svg.jpg";
import Button from "@mui/material/Button";
import {api} from "../../../../config/config";
import {toast, ToastContainer} from "react-toastify";
import {CircularProgress} from "@mui/material";


const ResetPassword = () => {

    const [email, setEmail] = useState('');

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmitReset = async (e) => {
      e.preventDefault();
      try {
          setIsLoading(true);
            await api.post('account/lost-password', {
                step: "first",
                email: email
            }).then(response => {
                if (response.status === 200){
                    setIsLoading(false);
                    localStorage.setItem("resetEmail", email);
                    toast.success("Un email a été envoyé à votre adresse contenant le code pour réinitialiser votre compte.");
                    navigate('/reset_password_code');
                }
            }).catch(error => {
                setIsLoading(false);
                if (!error?.response) {
                    toast.error('Pas de réponse du serveur');
                    // setErrMsg('Pas de réponse du serveur');
                } else if (error.response.status === 401) {
                    toast.error('Ce compte n\'existe pas.');
                } else {
                    toast.error('Le processus de réinitialisation du mot de passe a échoué. Veuillez réessayer.');
                }
            })
      }catch (err) {
          if (!err?.response) {
              toast.warning('Pas de réponse du serveur');
              // setErrMsg('Pas de réponse du serveur');
          } else if (err.response?.status === 401) {
              toast.warning('Ce compte n\'existe pas.');
          } else {
              toast.warning('Le processus de réinitialisation du mot de passe a échoué. Veuillez réessayer.');
          }
      }
    };

    return(
        <>
            <ToastContainer/>
            <div
                className="flex flex-col items-center justify-center px-6 pt-8 mx-auto md:h-screen pt:mt-0 dark:bg-gray-900">
                <Link to="/"
                      className="flex items-center justify-center mb-8 text-2xl font-semibold lg:mb-10 dark:text-white">
                    <img src={SonabelLogo} className="mr-4 h-11" alt="FlowBite Logo"/>
                    <span>SONABEL</span>
                </Link>
                {/*// <!-- Card -->*/}
                <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
                    <div className="w-full p-6 sm:p-8">
                        <h3 className="mb-3 text-2xl font-bold text-gray-400 text-center">
                            Etape 1
                        </h3>
                        <h2 className="mb-3 text-2xl font-bold text-gray-900 text-center">
                            Vous avez oublié votre mot de passe ?
                        </h2>
                        <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                            Ne vous inquiétez pas ! Saisissez simplement votre adresse email et nous vous enverrons un
                            code pour réinitialiser votre mot de passe !
                        </p>
                        <form className="mt-8 space-y-6">
                            <div>
                                <label htmlFor="email"
                                       className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                    placeholder="Votre email"
                                    required
                                />
                            </div>
                            <div>
                                <div className="flex justify-end">
                                    <Button type="submit"
                                            className="w-full px-5 py-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                            onClick={handleSubmitReset}
                                    >
                                        {isLoading ?
                                            <>
                                                <CircularProgress color="primary" size="1rem"/> En cours ...
                                            </>
                                            :
                                            <>
                                                Envoyer le code
                                            </>
                                        }

                                    </Button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </>
    )
        ;
}
export default ResetPassword;
