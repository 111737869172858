import React, { useEffect, useState } from "react";
// import { TypePaiement } from "./Options/TypePaiement";
import NavbarSidebarLayout from "../../components/Dashboard/Layouts/NavbarSidebar";
import { MobileForm } from "./Options/MobileForm";
// import { ServiceBancaire } from "./Options/ServiceBancaire";
import { api } from "../../config/config";
import { OTPForm } from "./Options/OTPForm";
import {toast, ToastContainer} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";
import sankLogo from '../../assets/sank/sank_logo.jpeg';
import corisMoney from '../../assets/bank1.png';
import moovMoney from '../../assets/mobile1.png';

const PayementDevis = () => {
  const location = useLocation();
  // Initiation de la navigation
  const navigate = useNavigate();

  // Message d'erreur
  const [errors, setErrors] = useState("");

  // Message de sucès
  const [sucess, setSucess] = useState("");

  // Si l'initialisation du paiement est valide on passe sa valeur à true pour entrer l'OTP
  const [initSucess, setInitSucess] = useState(false);

  const [ussdCode, setUssdCode] = useState("");
  const [orange, setOrange] = useState(false);
  const [moov, setMoov] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [disableBtn, setDisableBtn] = useState(false);

  const mobilMoney = [
    {id:'SANK', title: 'Sank money', image: sankLogo },
    {id:'MOOV_MONEY', title: 'Moov money', image: moovMoney },
    {id:'CORIS', title: 'Coris money', image: corisMoney },
  ];

  //Donnée option Mobile money
  const [mobileDataSend, setMobileDataSend] = useState({
    partenaire: "",
    tel: "+226",
    amount: location.state?.montant,
    demandeId: location.state?.demandeId,
    accountId: localStorage.getItem("userId"),
    devisId: location.state?.devisId,
    otp: ""
  });

  // Formattage des données à envoyer
  const [dataInit, setDataInit] = useState({});

  // Récupération des élément qui vont faciliter l'envoie de données
  const [codeOtp, setCodeOtp] = useState({
    payId: "",
    otp: "",
    channels: ""
  });

  //Vérifier les options choisie par le client et afficher la procedure à suivre
  useEffect(() => {
    if (mobileDataSend.partenaire === "ORANGE_MONEY") {
      setUssdCode(`*144*4*6*${mobileDataSend.amount}#`);
      setMoov(false);
      setOrange(true);
    } else if (mobileDataSend.partenaire === "MOOV_MONEY") {
      setMoov(true);
      setOrange(false);
    }
  }, [mobileDataSend.amount, mobileDataSend.partenaire]);

  // Transférer les valeurs de mobileDateSend dans dataInit tout en effeçant l'indicatif ou les espaces
  useEffect(() => {
    let numero = mobileDataSend.tel.replace(/\s+/g, '');
    // Si c'est Moov africa on efface les espaces et le +
    if (mobileDataSend.partenaire === "MOOV_MONEY") {
      const mobileDataSendInit = {
        partenaire: mobileDataSend.partenaire,
        tel: numero.replace("+", ""),
        amount: mobileDataSend.amount,
        demandeId: mobileDataSend.demandeId,
        accountId: mobileDataSend.accountId,
        devisId: mobileDataSend.devisId,
        otp: mobileDataSend.otp,
      }
      setDataInit(mobileDataSendInit)
    }
    else {
      const mobileDataSendInit = {
        partenaire: mobileDataSend.partenaire,
        tel: numero,
        amount: mobileDataSend.amount,
        demandeId: mobileDataSend.demandeId,
        accountId: mobileDataSend.accountId,
        devisId: mobileDataSend.devisId,
        otp: mobileDataSend.otp,
      }
      setDataInit(mobileDataSendInit)
    }
  },[mobileDataSend])

  // Gestion Mobile money et service bancaire
  // const handleCheckBoxChange = (event) => {
  //   setData((prevData) => ({ ...prevData, choisePayment: event.target.value }));
  // };

  // Changement input
  const handleMobileDataSendChange = (value) => {
    setMobileDataSend((prevData) => ({ ...prevData, tel: value }));
  };

  // Changement input mobile data
  const handleMobileDataChange = (field, value) => {
    // On s'assure que la value est bien défini avant d'essayer d'y accéder
    if (value && typeof value === 'object' && 'target' in value) {
      setMobileDataSend((prevData) => ({
        ...prevData,
        [field]: value.target.value,
      }));
    } else {
      setMobileDataSend((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    }
  };

  // On vérifie que le formulaire est bien saisie
  const handleValidateMobileData = async () => {
    let isValidData;

    // Si le partenaire est choisie
    if (!dataInit.partenaire){
      setErrors("Veuillez choisir le partenaire avec lequel vous souhaitez débuter le paiement.");
      isValidData = false;
    } else {
      setErrors('');
      isValidData = true;
    }

    if(dataInit.partenaire === "ORANGE_MONEY" && !dataInit.otp){
      setErrors("Veuillez-saisir l'OTP reçu");
      isValidData = false;
    }
    return isValidData;
  };

  const handleValidationMonileNumber = async () => {
    let isValidMobile;

    // On verifie si le numero de téléphone est saisie et est valide
    if (!(/^\+?\d{3}\d{8}$/.test(dataInit.tel.replace(/\s+/g, '')))) {
      setErrors("Veuillez-saisir votre numéro de téléphone");
      isValidMobile = false;
    } else {
      setErrors('');
      isValidMobile = true;
    }

    return isValidMobile;
  }

  // Validation de l'OTP
  const handleValidOTP = () => {
    let isValid = true;
    // Si le partenaire est choisie
    if (!codeOtp.otp){
      setErrors("Veuillez saisir le code OTP");
      isValid = false;
    }
    return isValid;
  };

  // Initialisation du payement
  const handleInitPayMobile = async () => {
    try {
      if (await handleValidateMobileData() && await handleValidationMonileNumber()) {
        //  On active le spinner pour montrer qu'une opération est en cours
        setIsLoading(true);
        setDisableBtn(true); // On désactive le button au clique de l'utilisateur
        await api
            .post("/payment/mobile/checkout", dataInit)
            .then((response) => {
              if (response.status === 201) {
                setIsLoading(false);
                setDisableBtn(false); //  On le reactive si l'opération c'est bien passé
                // Si le partenaire est orange on l'envoie le message et on desactive l'OTP
                if (dataInit.partenaire === "ORANGE_MONEY"){
                  setInitSucess(false);
                  toast.success(
                      "Votre paiement a été pris en compte avec succès. Vous pouvez télécharger votre reçu sur votre tableau de bord."
                  );
                  // Au bout de 5s on le redirige vers la page de succès
                  setTimeout(()=> {
                    navigate("/successpage");
                  },5000);
                }else {
                  setSucess("Initialisation Effectuer avec succès. Entrez le code OTP.");
                  setCodeOtp((prevData) => ({ ...prevData, payId: response.data.content.payId }));
                  setCodeOtp((prevData) => ({ ...prevData, channels: mobileDataSend.partenaire }));
                  setInitSucess(true);
                }
              }
            })
            .catch(() => {
              //  On désactive le spinner pour montrer que l'opération est terminer et a échouer
              setIsLoading(false);
              //  Dans le cas ou l'opération échoue on le reactive pour permettre une autre tentative
              setDisableBtn(false);
              toast.error("le numéro de téléphone fournie ne peut pas initier le paiement.");
            });
      }
    } catch (e) {}
  };

  // Envoie de l'OTP
  const handleSendOTP = async () => {
    try {

      if (handleValidOTP()){
        setIsLoading(true);
        setDisableBtn(true);
        await api
            .post("/payment/mobile/otp", codeOtp)
            .then((response) => {
              if (response.status === 201) {
                setIsLoading(false);
                setDisableBtn(true);
                // On vide les sessions
                localStorage.removeItem('demandeId');
                localStorage.removeItem('devisId');
                localStorage.removeItem('montant');
                localStorage.removeItem('type_service');
                toast.success(
                    "Votre paiement a été pris en compte avec succès."
                );

                // Au bout de 5s on le redirige vers la page de succès
                setTimeout(()=> {
                  navigate("/successpage");
                },3000);
              }
            })
            .catch(() => {
              setIsLoading(false);
              setDisableBtn(false);
              toast.error("Le code OTP ne correspond pas.");
            });
      }
    } catch (e) {

    }
  };

  // Ecouter les changement effectuer sur l'input de l'otp
  const handleChangeOTP = (field, value) => {
    setCodeOtp((prevData) => ({
      ...prevData,
      [field]: value.target.value,
    }));
  };


  return (
      <NavbarSidebarLayout>
        <ToastContainer />
        <div className="block items-center justify-center border-b border-gray-200 bg-white p-4 sm:flex">
          <div className="mb-1 w-full">
            <div className="mb-4">
              <>
                <div className="rounded-lg bg-white p-4 shadow-2xl dark:bg-gray-800 sm:p-6 xl:p-8">
                  <div className="text-center text-2xl sm:text-4xl mb-8">
                    <h2>
                      Services: <strong className="text-green-800">
                      {localStorage.getItem('type_service')}
                    </strong>
                    </h2>
                    <br/>
                    Montant à payer:{" "}
                    <strong className={`text-red-600`}>
                      {location.state?.montant} FCFA
                    </strong>
                  </div>
                  {/*
                    Type de paiement.
                    En fonction du type de paiement on affiche l'interface dedié.
                    Mobile money et opération bancaire
                   */}
                  {/*<TypePaiement*/}
                  {/*    service={data.choisePayment}*/}
                  {/*    onHandleCheckBoxChange={handleCheckBoxChange}*/}
                  {/*/>*/}

                  {/* Mobile Money */}
                  {/*{data.choisePayment === "mobile_money" && (*/}
                      <>
                        {(initSucess === false) ? (
                            <>
                              <MobileForm
                                  mobileDataSend={mobileDataSend}
                                  handleMobileDataSendChange={
                                    handleMobileDataSendChange
                                  }
                                  handleMobileDataChange={handleMobileDataChange} // Fonction pour ecouter le changement de valeur des inputs
                                  handleInitPayMobile={handleInitPayMobile} // Fonction pour initier un paiement
                                  ussCode={ussdCode}
                                  orange={orange}
                                  moov={moov}
                                  codeOtp={codeOtp}
                                  handleChangeOTP={handleChangeOTP}
                                  handleSendOTP={handleSendOTP}
                                  isLoading={isLoading}
                                  disableBtn={disableBtn}
                                  errors={errors}
                                  mobilMoney={mobilMoney}
                              />
                            </>
                        ) : (
                            <>
                              <OTPForm
                                  codeOtp={codeOtp}
                                  onHandleChangeOTP={handleChangeOTP}
                                  handleSendOTP={handleSendOTP}
                                  isLoading={isLoading}
                                  errors={errors}
                                  success={sucess}
                                  disableBtn={disableBtn}
                              />
                            </>
                        )}
                      </>
                  {/*)}*/}
                  {/*{data.choisePayment === "service_bancaire" && (*/}
                  {/*    <>*/}
                  {/*      <ServiceBancaire mobileDataSend={mobileDataSend}/>*/}
                  {/*    </>*/}
                  {/*)}*/}

                </div>
              </>
            </div>
          </div>
        </div>
      </NavbarSidebarLayout>
  );
};

export default PayementDevis;
