import React from "react";
import MainNavigation from "../components/NavBar/MainNavigation";
import FooterComponent from "../components/Footer/FooterComponent";
import Paiement from "../components/home/Paiement";
import ServiceOnline from "../components/home/ServiceOnline";
import SimpleSlider from "../components/home/SimpleSlider";
import HeroComponent from "../components/home/HeroComponent";

const Home = () => {
  return (
    <>
      {/*<ToastContainer/>*/}
      <MainNavigation />
       <main className="mt-20 mx-1">
        <div className=" md:hidden">
           <HeroComponent />
        </div>
        <div className="hidden md:block">
          <SimpleSlider />
        </div>
        <ServiceOnline />
     
         <Paiement /> 
      </main> 
       <FooterComponent /> 
    </>
  );
};

export default Home;
