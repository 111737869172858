import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../config/config";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import NavbarSidebarLayout from "../../../components/Dashboard/Layouts/NavbarSidebar";
import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import { CloudUpload } from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { PuissanceMonophase } from "../ListPuissance/PuissanceMonophase";
import { PuissanceTriphase } from "../ListPuissance/PuissanceTriphase";
import FormControl from "@mui/material/FormControl";
import ServerStatus from "../../../components/ServerStatus";
import LogoSonabel from "../../../assets/Logo-site-SONABEL-def.png";
import FormHelperText from "@mui/material/FormHelperText";

const Abonnement = ({ serverOffline }) => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);

  const [message, setMessage] = useState("");

  // On recupère le code du reçu
  const [formData, setFormData] = useState({
    code_recu: "",
    file: null,
    file_branchement: null,
    puissance: "",
    type_amperage: "",
    puissance_demande: "",
  });
  //Gestion des erreur
  const [errors, setErrors] = useState({});

  // deactiver le bouton submit lorsqu'on actionne le bouton soumettre
  const [stateSend, setStateSend] = useState(true);
  const [puissances, setPuissances] = useState([]);
  const [isNext, setIsNext] = useState(false);
  const [abonne_branchement, setAbonneBranchement] = useState({});
  //Controler la selection de la puissance
  const [showAlert, setShowAlert] = useState(false);
  const files = useState({
    cnib: "",
    recu: "",
  });
  const avertissement = <p className="text-red-600">Avertissement</p>;

  // Multipart pour l'envoie des fichiers
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const [brachementValue, setBranchementValue] = useState(null);
  const [minMaxValue, setMinMaxValue] = useState({
    minValue: 0,
    maxValue: 0,
  });

  // Etapes du steps
  const steps = [
    avertissement,
    "Recherche du branchement",
    "Les informations sur le branchement",
    "Pièces à fournir",
    "Puissance souhaitée",
    "Validation",
  ];

  useEffect(() => {
    if (brachementValue) {
      // Utiliser une expression régulière pour extraire les parties numériques avec le suffixe A
      const regex = /(\d+A)(?: - (\d+A))?/;
      const matches = brachementValue.match(regex);

      if (matches) {
        const minValue = matches[1];
        const maxValue = matches[2] ? matches[2] : matches[1];
        setMinMaxValue({ minValue, maxValue });
      }
    }
  }, [brachementValue]);

  //Gérer les puissances en fonction du types d'ampérange
  useEffect(() => {
    if (abonne_branchement.type_amperage === "monophasé") {
      // Filtrer les valeurs dans le tableau PuissanceTriphase
      const filtered = PuissanceMonophase.filter(({ value }) => {
        return (
            parseInt(value) >= parseInt(minMaxValue.minValue) &&
            parseInt(value) <= parseInt(minMaxValue.maxValue)
        );
      });
      setPuissances(filtered);
    } else if (abonne_branchement.type_amperage === "triphasé" || formData.type_amperage === "triphasé") {
      // Filtrer les valeurs dans le tableau PuissanceTriphase
      const filtered = PuissanceTriphase.filter(({ value }) => {
        return (
            parseInt(value) >= parseInt(minMaxValue.minValue) &&
            parseInt(value) <= parseInt(minMaxValue.maxValue)
        );
      });
      setPuissances(filtered);
    }
  }, [abonne_branchement.amperage, abonne_branchement.type_amperage, formData.type_amperage, minMaxValue.maxValue, minMaxValue.minValue]);

  const handleNext = () => {
    const abonnement = validateForm(formData);
    if (Object.keys(abonnement).length === 0) {
      setErrors({});
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setErrors(abonnement);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate("/list-des-demandes");
    }
    //setIsNext(false);
    setStateSend(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //  Mettre à jours nos données
  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const validateForm = (formData) => {
    const errors = {};
    if (activeStep === 3) {
      if (!formData.file) {
        errors.file = "Ce champ est obligatoire";
      }
      if (formData.file && formData.file.size > 2 * 1024 * 1024) {
        errors.file = "Le fichier sélectionné est trop volumineux.";
      }
      if (!formData.file_branchement) {
        errors.file_branchement = "Ce champ est obligatoire";
      }
      if (
          formData.file_branchement &&
          formData.file_branchement.size > 2 * 1024 * 1024
      ) {
        errors.file_branchement = "Le fichier sélectionné est trop volumineux.";
      }
      if (
          !formData.file ||
          !formData.file.name ||
          !formData.file.name.toLowerCase().endsWith(".pdf")
      ) {
        errors.file = "Le fichier doit être un pdf";
      }
      if (
          !formData.file_branchement ||
          !formData.file_branchement.name ||
          !formData.file_branchement.name.toLowerCase().endsWith(".pdf")
      ) {
        errors.file_branchement = "Le fichier doit être un pdf";
      }
    } else if (activeStep === 4) {
      if (
          abonne_branchement.type_amperage === "triphasé" &&
          !formData.type_amperage
      ) {
        errors.type_amperage = "Ce champ est obligatoire";
      }
      if (
          abonne_branchement.type_amperage === "triphasé" &&
          !formData.puissance_demande
      ) {
        errors.puissance_demande = "Ce champ est obligatoire";
        setShowAlert(true);
      }
      if (
          abonne_branchement.type_amperage === "monophasé" &&
          !formData.puissance_demande
      ) {
        errors.puissance_demande = "Ce champ est obligatoire";
        setShowAlert(true);
      }
    }
    return errors;
  };

  // Gérer l'import de la CNIB
  const handleFileChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      file: event.target.files ? event.target.files[0] : null,
    }));
  };

  // Gérer l'import du reçu de branchement
  const handleFileBranchementChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      file_branchement: event.target.files ? event.target.files[0] : null,
    }));
  };

  // Recherche d'un branchement
  const handleRechercheBranchement = () => {
    // Activer le loader
    setIsLoading(true);

    api
        .get(`abonnement/branchement/client/${formData.code_recu}`)
        .then((response) => {
          if (response.status === 200 && response.data.content) {
            if (response.data.content.amperage === "DT") {
              setIsLoading(false);
              setIsNext(false);
              setMessage("Vous avez un branchement à double tarif. Il n'est pas possible de faire votre demande d'abonnement en ligne. Merci de vous rendre au guichet pour poursuivre.");
              toast.info("Vous avez un branchement à double tarif. Il n'est pas possible de faire votre demande d'abonnement en ligne. Merci de vous rendre au guichet pour poursuivre.");
            } else {
              setMessage("");
              setAbonneBranchement(response.data.content);
              setBranchementValue(response.data.content.amperage);
              toast.success(
                  `Branchement de ${response.data.content.CLI_NOM} ${response.data.content.CLI_PRENOM} trouvé !`
              );
              setIsNext(true);
              setIsLoading(false);
            }
          } else {
            toast.error(
                `Désolé ces informations ne figurent pas dans notre système.`
            );
            setIsLoading(false);
            setIsNext(false);
          }
        })
        .catch(() => {
          // Désactiver le loader en cas d'erreur
          setIsLoading(false);
          setIsNext(false);
          toast.error(
              `Désolé ces informations ne figurent pas dans notre système,`
          );
        });
  };

  //envoie des fichiers avant l'envoie de la demande
  const handleSendFile = async () => {
    setStateSend(true);
    if (formData.file && formData.file_branchement) {
      setIsLoading(true);
      // create a new FormData object and append the file to it
      const formDataAbonnementCNIB = new FormData();
      formDataAbonnementCNIB.append("file", formData.file);

      try {
        await api
            .post("/storage/upload", formDataAbonnementCNIB, config)
            .then((response) => {
              if (response.status === 201) {
                files.cnib = response.data.content;
                // setFiles((prevDataFile) => ({ ...prevDataFile, "cnib": response.data.content }));
              }
            })
            .catch((err) => {
              if (err.status === 413) {
                toast.error("Votre CNIB est fichier volumineux");
              }
              setIsLoading(false);
              //On envoie une notification disant qu'il y a eu echec
              toast.error("Demande non enregistré");
            });
      } catch (e) {}
    }
  };
  const handleSendFileRecu = async () => {
    if (formData.file && formData.file_branchement) {
      setIsLoading(true);
      // create a new FormData object and append the file to it
      const formDataAbonnementRecu = new FormData();
      formDataAbonnementRecu.append("file", formData.file_branchement);

      try {
        await api
            .post("/storage/upload", formDataAbonnementRecu, config)
            .then((response) => {
              if (response.status === 201) {
                files.recu = response.data.content;
              }
            })
            .catch((err) => {
              if (err.status === 413) {
                toast.error("Votre CNIB est fichier volumineux");
              }
              setIsLoading(false);
              //On envoie une notification disant qu'il y a eu echec
              toast.error("Demande non enregistré");
            });
      } catch (e) {}
    }
  };

  // envoi des données
  const handleSendAbonnementDemande = async (files) => {
    setIsLoading(true);
    // On forme les données
    let dataAbonnement = {
      nom: abonne_branchement.CLI_NOM,
      prenom: abonne_branchement.CLI_PRENOM,
      email: abonne_branchement.CLI_EMAIL,
      dem_numero: abonne_branchement["DEM_NUMERO#"],
      cat_cli_code: abonne_branchement["CAT_CLI_CODE#"],
      // cli_code: abonne_branchement['CLI_CODE#'],
      // cli_date_observation: abonne_branchement.CLI_DATE_OBSERVATION,
      cli_nom: abonne_branchement.CLI_NOM,
      cli_num: abonne_branchement["CLI_NUM#"],
      cli_prenom: abonne_branchement.CLI_PRENOM,
      // cli_ville: abonne_branchement.CLI_VILLE,
      cd: abonne_branchement["DIST_CODE#"],
      // etat_code: abonne_branchement,
      exploitation: abonne_branchement["EXPL_CODE#"],
      // code: abonne_branchement['PAR_CODE'],
      lot: abonne_branchement["PAR_LOT#"],
      parcelle: abonne_branchement["PAR_NUM#"],
      section: abonne_branchement["PAR_SECTION#"],
      prop_nom: abonne_branchement.PROP_NOM,
      prop_prenom: abonne_branchement.PROP_PRENOM,
      tel2:
          abonne_branchement.CLI_TEL_CEL ||
          abonne_branchement.CLI_TEL_DOM ||
          abonne_branchement.CLI_TEL_SER ||
          abonne_branchement.PROP_TEL_SERVICE ||
          abonne_branchement.PROP_TEL_SERVICE ||
          "00000",
      prop_email: abonne_branchement.PROP_EMAIL,
      type_sce: location.state.idAbonnement,
      user_id: localStorage.getItem("userId"),
      photo_piece: files.cnib,
      document_parcelle: files.recu,
      rang: abonne_branchement["RANG#"],
      amperage: abonne_branchement.amperage,
      type_amperage: abonne_branchement.type_amperage,
    };

    if (abonne_branchement.type_amperage === "triphasé") {
      dataAbonnement.type_amperage = formData.type_amperage;
      dataAbonnement.puissance = formData.puissance_demande;
    } else if (abonne_branchement.type_amperage === "monophasé") {
      dataAbonnement.puissance = formData.puissance_demande;
    }

    // On retire les champs qui n'ont pas été soumis pour validation
    const filteredDataAbonnement = Object.fromEntries(
        Object.entries(dataAbonnement).filter(([key, value]) => value !== null)
    );
    // Soumettre la demande du client
    try {
      await api
          .post("/demande", filteredDataAbonnement)
          .then((response) => {
            if (response.status === 201) {
              setFormData({
                code_recu: "",
                file: "",
                file_branchement: "",
                puissance: "",
                type_amperage: "",
                puissance_demande: "",
              });
              toast.success(
                  "Demande enregistré!, Vous serez contacter pour plus d'informations"
              );
              setIsLoading(false);
              setStateSend(false); // désactiver le bouton
              setTimeout(() => {
                navigate("/dashboard");
              }, 2000);
            }
          })
          .catch(() => {
            setStateSend(false);
            toast.error("Demande non enregistré");
            setIsLoading(false);
          });
    } catch (err) {}
  };

  // gestion des functions d'envoi des données
  const handleManageSendFunction = async () => {
    try {
      // Désactivé le button
      //setStateSend(true);
      // Envoi de CNIB
      await handleSendFile();

      // Envoi du recu de paiemnet
      await handleSendFileRecu();

      // Si notre objet est bien renseigné on envoie le formulaire avec les valeurs de la cnib et du reçu
      if (files.recu && files.cnib) {
        await handleSendAbonnementDemande(files);
      }
      setStateSend(true);
      //setStateSend(false);
    } catch (e) {
      setStateSend(false);
    }
  };

  const handleAcceptTermsAndCondition = async (event) => {
    setStateSend(!event.target.checked);
  }

  // Gestion du stepper
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
            <div>
              <div className="flex flex-col items-center">
                <div className={`text-center items-center mt-4`}>
                  <img src={LogoSonabel} alt="" width={200} />
                </div>
                <div className={`text-justify m-8`}>
                  <h2 className="mb-2 text-lg font-bold text-gray-900 dark:text-white">
                    Conditions pour cette demande :
                  </h2>
                  <ol className="max-w-md space-y-1 text-gray-500 list-decimal list-inside dark:text-gray-400">
                    <li>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      Assurez-vous d'avoir votre pièce d'identité, votre reçu de
                      branchement avec le numéro, et surtout que le branchement
                      soit effectué.
                    </span>
                    </li>
                    <li>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      La SONABEL décline toute responsabilité pour toute
                      inexactitude des informations fournies par les demandeurs.
                    </span>
                    </li>
                    <li>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      Aucun remboursement ne sera accordé en cas de fausses
                      informations. La SONABEL se réserve le droit de poursuivre
                      en justice les cas de fraude.
                    </span>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
        );
      case 1:
        return (
            <div>
              <div className={`mt-5 font-bold`}>
                {message && (
                    <p className={'text-red-700 text-center'}>
                      {message}
                    </p>
                )}
              </div>
              <div className=" mt-5 space-y-8">
                <TextField
                    id="code_recu"
                    label="Numéro de reçu (code du reçu)"
                    fullWidth
                    size="small"
                    value={formData.code_recu}
                    onChange={(e) => handleChange("code_recu", e.target.value)}
                />
              </div>
              <Button
                  color="success"
                  variant="contained"
                  onClick={handleRechercheBranchement}
                  sx={{ mb: 5, mt: 8, mr: 1, width: "100%" }}
                  disabled={isLoading || serverOffline}
              >
                {isLoading && <CircularProgress />}{" "}
                <span className="ml-5">Rechercher</span>
              </Button>
            </div>
        );
      case 2:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              {abonne_branchement && (
                  <>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Numéro branchement"
                          fullWidth
                          size="small"
                          value={abonne_branchement["DEM_NUMERO#"] || ""}
                          disabled={true}
                      />
                      <TextField
                          label="Exploitation"
                          fullWidth
                          size="small"
                          value={abonne_branchement["EXPL_CODE#"] || ""}
                          disabled={true}
                      />
                    </div>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Nom"
                          fullWidth
                          size="small"
                          value={abonne_branchement.CLI_NOM || ""}
                          disabled={true}
                      />
                      <TextField
                          label="Prenom"
                          fullWidth
                          size="small"
                          value={abonne_branchement.CLI_PRENOM || ""}
                          disabled={true}
                      />
                    </div>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Email"
                          fullWidth
                          size="small"
                          value={abonne_branchement.PROP_EMAIL || ""}
                          disabled={true}
                      />
                      <TextField
                          label="Telephone"
                          fullWidth
                          size="small"
                          value={
                              abonne_branchement.CLI_TEL_CEL ||
                              abonne_branchement.CLI_TEL_DOM ||
                              abonne_branchement.CLI_TEL_SER ||
                              abonne_branchement.PROP_TEL_DOMICILE ||
                              ""
                          }
                          disabled={true}
                      />
                    </div>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Section"
                          fullWidth
                          size="small"
                          value={abonne_branchement["PAR_SECTION#"] || ""}
                          disabled={true}
                      />

                      <TextField
                          label="lot"
                          fullWidth
                          size="small"
                          value={abonne_branchement["PAR_LOT#"] || ""}
                          disabled={true}
                      />
                    </div>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          id="parcelle"
                          label="Parcelle"
                          fullWidth
                          size="small"
                          value={abonne_branchement["PAR_NUM#"]}
                          disabled={true}
                      />
                      <TextField
                          id="rang"
                          label="Rang"
                          fullWidth
                          size="small"
                          value={abonne_branchement["RANG#"]}
                          disabled={true}
                      />
                    </div>
                  </>
              )}
            </div>
        );
      case 3:
        return (
            <div className={`mt-8`}>
              <div className={`text-center`}>
                <p className="mt-1 text-sm text-red-700 font-bold">
                  L'ensemble des fichiers à charger ne doivent pas dépasser 5 Mo.
                </p>
                <p className={`mt-4 mb-2 text-sm text-yellow-400 font-bold`}>
                  Importer la Pièce d'identité recto verso en pdf (2Mo maximum)
                </p>
                <div className={`mt-2 `}>
                  <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUpload />}
                  >
                    Importez votre CNIB
                    <input
                        type="file"
                        accept=".pdf"
                        hidden
                        onChange={(event) => handleFileChange(event)}
                    />
                  </Button>
                  <div>{formData.file && <p>{formData.file.name}</p>}</div>
                  <div>
                    {errors.file && (
                        <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                          {errors.file}
                        </p>
                    )}
                  </div>
                </div>
                <p className={`mt-4 mb-2 text-sm text-yellow-400 font-bold`}>
                  Le reçu de branchement ne doit pas excéder 2 Mo.
                </p>
                <div className={`mt-2 mb-4`}>
                  <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUpload />}
                  >
                    Reçu de branchement
                    <input
                        type="file"
                        accept=".pdf"
                        hidden
                        onChange={(event) => handleFileBranchementChange(event)}
                    />
                  </Button>
                  <div>
                    {formData.file_branchement && (
                        <p>{formData.file_branchement.name}</p>
                    )}
                  </div>
                  <div>
                    {errors.file_branchement && (
                        <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                          {errors.file_branchement}
                        </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
        );
      case 4:
        return (
            <>
              {abonne_branchement.type_amperage === "triphasé" && (
                  <>
                    <div className="space-y-5 mt-8 mb-8">
                      <>
                        <>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Choisissez le type ampérage
                            </InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={formData.type_amperage}
                                label="type_amperage"
                                size="small"
                                sx={{ width: "100%" }}
                                variant="outlined"
                                onChange={(e) =>
                                    handleChange("type_amperage", e.target.value)
                                }
                            >
                              <MenuItem value="monophasé">Monophasé</MenuItem>
                              <MenuItem value="triphasé">Triphasé</MenuItem>
                            </Select>
                          </FormControl>
                          {showAlert && (
                              <FormHelperText error>
                                {errors.type_amperage}
                              </FormHelperText>
                          )}
                          <div
                              className={`flex md:justify-between md:flex-row sm:flex-row space-x-8`}
                          >
                            <div>
                              Monophasé
                              <p>
                                Pour les applications domestiques et les petits
                                appareils.
                              </p>
                            </div>
                            <div>
                              Triphasé
                              <p>
                                Pour les grandes installations industrielles et
                                commerciales.
                              </p>
                            </div>
                          </div>
                        </>
                        <FormControl fullWidth>
                          <InputLabel id="puissance">Ampérage souhaité</InputLabel>
                          <Select
                              labelId="puissance"
                              id="puissance"
                              value={formData.puissance_demande}
                              label="puissance"
                              size="small"
                              variant="outlined"
                              sx={{ width: "100%" }}
                              onChange={(e) => handleChange("puissance_demande", e.target.value)}
                          >
                            {puissances.map((k) => (
                                <MenuItem key={k.key} value={k.key}>
                                  {k.value}
                                </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {showAlert && (
                            <FormHelperText error>
                              {errors.puissance_demande}
                            </FormHelperText>
                        )}
                      </>
                    </div>
                  </>
              )}

              {abonne_branchement.type_amperage === "monophasé" && (
                  <>
                    <div className="space-y-5 mt-8 mb-8">
                      <>
                        <FormControl fullWidth>
                          <InputLabel id="puissance">Ampérage souhaité</InputLabel>
                          <Select
                              labelId="puissance"
                              id="puissance"
                              value={formData.puissance_demande}
                              label="puissance"
                              size="small"
                              variant="outlined"
                              sx={{ width: "100%" }}
                              onChange={(e) =>
                                  handleChange("puissance_demande", e.target.value)
                              }
                              required={true}
                          >
                            {/*{puissances}*/}
                            {puissances.map((k) => (
                                <MenuItem key={k.key} value={k.key}>
                                  {k.value}
                                </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {showAlert && (
                            <FormHelperText error>
                              {errors.puissance_demande}
                            </FormHelperText>
                        )}
                      </>
                    </div>
                  </>
              )}
            </>
        );
      case 5:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8 dark:text-white">
              <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="status">
                <p className="font-bold">Information importante</p>
                <p>Le montant de l'abonnement sera déduit lors de votre première recharge.</p>
              </div>

              <Typography className="text-center mb-8 dark:text-white" variant="h5">
                Voulez-vous vraiment soumettre votre demande ?
              </Typography>

              <div className="flex items-center mb-4">
                <input id="terms-checkbox" type="checkbox" value=""
                       className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                       onChange={handleAcceptTermsAndCondition}
                />
                <label htmlFor="terms-checkbox" className="ml-2 text-sm font-medium text-gray-900">
                  J'accepte les <a href="/clause-juridique" className="text-blue-600 hover:underline" target="_blank">conditions
                  d'utilisation</a> et
                  les <a href="/clause-juridique" className="text-blue-600 hover:underline" target="_blank">clauses de traitement des
                  données</a>.
                </label>
              </div>
            </div>
        );
      default:
        return "Unknown step";
    }
  };

  return (
      <NavbarSidebarLayout>
        {serverOffline && <ServerStatus/>}
        <div
            className="block items-center justify-center border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
          <div className="mb-1 w-full">
            <div className="mb-4">
              <Breadcrumb className="mb-4">
                <Breadcrumb.Item href="/dashboard">
                  <div className="flex items-center gap-x-3">
                    <HiHome className="text-xl" />
                    <span className="dark:text-white">Accueil</span>
                  </div>
                </Breadcrumb.Item>
                <Breadcrumb.Item href="/list-des-demandes">
                  Liste des services{" "}
                </Breadcrumb.Item>
                <Breadcrumb.Item>abonnement</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className={`my-8`}>
              {/*<InfoService message="Pour vos demandes d'abonnement, vous devez avoir en votre possession une pièce d'identité à jour, et le reçu de votre abonnement."/>*/}
              <div className="text-center  text-3xl mb-8">
                Demande d'abonnement
              </div>

              <div className="flex w-full flex-col gap-y-6 lg:flex-row lg:justify-center lg:gap-y-0 md:flex-row md:justify-center md:gap-y-0">
                <div className=" rounded-lg bg-white p-2 shadow-2xl dark:bg-gray-800  xl:p-8 lg:w-1/2 border-2 lg:p-5">
                  <div className="invisible md:visible">
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{/*label*/}</StepLabel>
                          </Step>
                      ))}
                    </Stepper>
                  </div>
                  <div>
                    <Typography className="text-center mb-8" variant="h5">
                      {steps[activeStep]}
                    </Typography>
                    {getStepContent(activeStep)}

                    <div className="flex flex-row justify-between pt-18">
                      {activeStep === 0 ? (
                          <Button
                              color="success"
                              variant="outlined"
                              onClick={() => {
                                navigate("/list-des-demandes");
                              }}
                              sx={{ mr: 1 }}
                          >
                            Refuser
                          </Button>
                      ) : (
                          <Button
                              color="success"
                              variant="outlined"
                              onClick={handleBack}
                              sx={{ mr: 1 }}
                          >
                            Précédent
                          </Button>
                      )}
                      <div className="flex 1 1 auto" />
                      {activeStep === steps.length - 1 && (
                          <>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={handleManageSendFunction}
                                sx={{ mr: 1 }}
                                disabled={stateSend}
                            >
                              {isLoading && (
                                  <CircularProgress color="secondary" size="1rem" />
                              )}
                              <span className="ml-1">Soumettre</span>
                            </Button>
                          </>
                      )}
                      {activeStep !== steps.length - 1 && (
                          <div>
                            <Button
                                onClick={handleNext}
                                variant="contained"
                                color="success"
                                disabled={activeStep === 1 && !isNext}
                            >
                              {activeStep === 0 ? "Accepter" : "Suivant "}
                            </Button>

                          </div>
                      )}
                    </div>
                    <ToastContainer />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavbarSidebarLayout>
  );
};

export default Abonnement;
