import React from "react";
import {Navigate} from "react-router-dom";
import Details from "../../mobilSize/Details";

const PrivateRouteDetail = () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <Details/>
    }
    return <Navigate to="/login"/>
}

export default PrivateRouteDetail;
