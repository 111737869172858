import React from "react";

const ContactItem = (props) => {
  const BUTTONTYPE =
    props.buttonType === "email" ? (
      <a
        className="cursor-pointer border-2 text-white text-center font-bold rounded-full px-4 py-3 shadow-lg bg-green-700  hover:bg-red-700 hover:text-white"
        href="mailto:info@sonabel.bf"
      >
        {props.foot}
      </a>
    ) : (
      <button className="border-2 text-black text-center font-bold rounded-full px-4 py-3 shadow-lg bg-white">
        {props.foot}
      </button>
    );
  return (
    <div
      className={`flex flex-col justify-between border border-black ${
        props.color
      } ${
        props.color === "bg-red-700" ? "text-white" : "text-black"
      } rounded-lg  min-h-48 p-6 `}
    >
      <div className="flex items-center space-x-4">
        <div className=" inline-flex  w-20 min-w20  h-14 justify-center items-center  border rounded-lg">
       
          {props.children}
        </div>
        <div className="space-y-1">
          <p className="text-lg font-bold">{props.title}</p>
          <div>{props.body}</div>
         
        </div>
       
      </div>
      {BUTTONTYPE}
    </div>
  );
};

export default ContactItem;
