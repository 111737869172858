import React from "react";
import {Navigate} from "react-router-dom";
import EditIndex from "../../../EditRequest/Saisie_index/EditIndex";

const PrivateRouteEditIndex = () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <EditIndex/>;
    }
    return <Navigate to="/login"/>
}

export default PrivateRouteEditIndex;
