import React, {useEffect, useState} from "react";
import { Link,useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "@mui/material/Select";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";

import {
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
} from "@mui/material";
import NavbarSidebarLayout from "../../../components/Dashboard/Layouts/NavbarSidebar";

import { api } from "../../../config/config";
import {Breadcrumb} from "flowbite-react";
import {PuissanceMonophase} from "../ListPuissance/PuissanceMonophase";
import {PuissanceTriphase} from "../ListPuissance/PuissanceTriphase";
import {HiHome} from "react-icons/hi";

import ServerStatus from "../../../components/ServerStatus";
import LogoSonabel from "../../../assets/Logo-site-SONABEL-def.png";


const ModificationAmperage = ({ serverOffline }) => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);

  const [formData, setFormData] = useState({

    exploitation: "",
    puissance: "",
    compteur: "",
    type_amperage: "monophasé", // triphasé
    typeCompteur: "PREPAYE",
  });

  // deactiver le bouton submit lorsqu'on actionne le bouton soumettre
  const [stateSend, setStateSend] = useState(false);

  const [isNext, setIsNext] = useState(false);
  const [abonne, setAbonne] = useState({});
  const [puissances, setPuissances] = useState([]);
  const avertissement = <p className="text-red-600">Avertissement</p>;

  //Gérer les puissances en fonction du types d'ampérange
  useEffect(() => {
    if (formData.type_amperage ==="monophasé"){
      setFormData((prevData) => ({ ...prevData, "puissance": PuissanceMonophase[0].key }));
      setPuissances(PuissanceMonophase);

    }else{
      setFormData((prevData) => ({ ...prevData, "puissance": PuissanceTriphase[0].key }));
      setPuissances(PuissanceTriphase);
    }
  }, [formData.type_amperage]);

  const steps = [
    avertissement,
    "Recherche du client",
    "Mes informations",
    "Branchement et amperage",
    "Validation"
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setStateSend(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (field, value) => {
    if(value === "PREPAYE" || value === 'POSTPAYE') {
      setIsNext(false);

    }
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };


  const handleDemande = async () => {

    // Soumettre la demande du client
    try {
      setStateSend(true);
      setIsLoading(true);
      const demandeService = {
        lot: abonne.lot,
        parcelle: abonne.parcelle,
        type_sce: location.state.idBranchementAbon,
        nom: abonne.nom,
        prenom: abonne.prenom,
        tel2: abonne.tel || '00000',
        email: abonne.email,
        exploitation: abonne.exploitation,
        type_amperage: formData.type_amperage,
        puissance: formData.puissance,
        section: abonne.section,
        user_id: localStorage.getItem("userId"),
        type_compteur: formData.typeCompteur,
        rang: abonne.rang,
        numero_police: abonne.police,
        numero_compteur: abonne.numeroCompteur,
        prop_nom: abonne.nom,
        prop_prenom: abonne.prenom,
        cat_cli_code: abonne.typeAbonne,
      };
      await api
          .post("/demande", demandeService)
          .then((response) => {
            if (response.status === 201) {
              setFormData({
                exploitation: "",
                puissance: "",
                compteur: "",
                type_amperage: "monophasé", // triphasé
                typeCompteur: "PREPAYE",
                police:""

              });
              toast.success(
                  "Demande enregistrée ! Nous vous enverrons des notifications pour le suivi de la demande par mail ou SMS."
              );
              setIsLoading(false);
              setStateSend(true); // désactiver le bouton
              setTimeout(()=> {
                navigate("/dashboard");
              },3000);
            }
          })
          .catch(() => {
            toast.error("Demande non enregistré");
            setIsLoading(false);
            setStateSend(false);
          });
      // setErrorMessage("");
    } catch (erreur) {
    }

  };

  const handleRechercheClient = async () => {
    // Activer le loader
    setIsLoading(true);
    let lien = '';
    if(formData.typeCompteur === 'POSTPAYE') {
      lien = `/abonne/${formData.exploitation}/${formData.police}/${formData.typeCompteur}`
    } else {
      lien = `/abonne/compteur/${formData.compteur}/${formData.typeCompteur}`
    }
    await api
        .get(
            lien
        )
        .then((response) => {
          if (response.status === 200 && response.data.content) {
            setAbonne(response.data.content);
            toast.success(
                `Client identifié : ${response.data.content.nom} ${response.data.content.prenom}`
            );
            //setStateSend(true);
            setIsNext(true);
            setIsLoading(false);
          } else {
            toast.error(
                `Désolé ces informations ne figurent pas dans notre système,`
            );
            setIsLoading(false);
            setIsNext(false);
          }
        })
        .catch(() => {
          // Désactiver le loader en cas d'erreur
          setIsLoading(false);
          toast.error(
              `Désolé ces informations ne figurent pas dans notre système,`
          );
        });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
            <div>
              <div className="flex flex-col items-center">
                <div className={`text-center items-center mt-4`}>
                  <img src={LogoSonabel} alt="" width={200}/>
                </div>
                <div className={`text-justify m-8`}>
                  <h2 className="mb-2 text-lg font-bold text-gray-900 dark:text-white">Conditions pour cette demande :</h2>
                  <ol className="max-w-md space-y-1 text-gray-500 list-decimal list-inside dark:text-gray-400">
                    <li>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      Assurez-vous d'avoir le numéro de compteur pour les prépayés, ainsi que le numéro de police et le numéro d'exploitation pour les postpayés.
                    </span>
                    </li>
                    <li>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      La SONABEL décline toute responsabilité pour toute inexactitude des informations fournies par les demandeurs.
                    </span>
                    </li>
                    <li>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      Aucun remboursement ne sera accordé en cas de fausses informations.
                      La SONABEL se réserve le droit de poursuivre en justice les cas de fraude.
                    </span>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
        );
      case 1:
        return (
            <div>
              <div className="flex justify-center h-32 items-center">
                <FormControl>
                  <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className="mb-5"
                  >
                    {/* Type de client */}
                  </FormLabel>
                  <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="type-client"
                      value={formData.typeCompteur}
                  >
                    <FormControlLabel
                        value="PREPAYE"
                        control={<Radio/>}
                        label="Prepayé"
                        onChange={(e) =>
                            handleChange("typeCompteur", e.target.value)
                        }
                    />
                    <FormControlLabel
                        value="POSTPAYE"
                        control={<Radio/>}
                        label="Postpayé"
                        onChange={(e) =>
                            handleChange("typeCompteur", e.target.value)
                        }
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="space-y-8">
                {formData.typeCompteur === 'POSTPAYE' && (

                    <>
                      <TextField
                          id="exploitation"
                          label="Saisir le numero d'exploitation"
                          fullWidth
                          size="small"
                          value={formData.exploitation}
                          onChange={(e) => handleChange("exploitation", e.target.value)}
                          InputLabelProps={{shrink: true}}
                      />
                      <TextField
                          id="police"
                          label="Saisir le numero de police"
                          fullWidth
                          size="small"
                          value={formData.police}
                          onChange={(e) => handleChange("police", e.target.value)}
                          InputLabelProps={{shrink: true}}
                      />
                    </>
                )
                }

                {
                    formData.typeCompteur === 'PREPAYE' &&  (<TextField
                        label="Saisir le numero de compteur"
                        fullWidth
                        size="small"
                        value={formData.compteur}
                        onChange={(e) => handleChange("compteur", e.target.value)}
                        InputLabelProps={{shrink: true}}
                    />)
                }

              </div>
              <Button
                  color="success"
                  variant="contained"
                  onClick={handleRechercheClient}
                  sx={{ mb: 5, mt: 8, mr: 1, width: "100%" }}
                  disabled={isLoading || serverOffline }
              >
                {isLoading && <CircularProgress />}{" "}
                <span className="ml-5">Rechercher</span>
              </Button>
            </div>
        );
      case 2:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              {abonne && (
                  <>
                    <div className="flex  flex-row space-x-8 ">

                      <TextField
                          label="Numero compteur"
                          fullWidth
                          size="small"
                          value={abonne.numeroCompteur}
                          disabled={true}
                      />
                      <TextField
                          label="Numero de police"
                          fullWidth
                          size="small"
                          value={abonne.police}
                          disabled={true}
                      />
                    </div>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Nom"
                          fullWidth
                          size="small"
                          value={abonne.nom}
                          disabled={true}
                      />
                      <TextField
                          label="Prenom"
                          fullWidth
                          size="small"
                          value={abonne.prenom}
                          disabled={true}
                      />
                    </div>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Email"
                          fullWidth
                          size="small"
                          value={abonne.email}
                          disabled={true}
                      />
                      <TextField
                          label="Telephone"
                          fullWidth
                          size="small"
                          value={abonne.tel}
                          disabled={true}
                      />
                    </div>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Section"
                          fullWidth
                          size="small"
                          value={abonne.section}
                          disabled={true}
                      />

                      <TextField
                          label="lot"
                          fullWidth
                          size="small"
                          value={abonne.lot}
                          disabled={true}
                      />
                    </div>
                    <div className="flex  flex-row space-x-8 ">
                      <TextField
                          label="Parcelle"
                          fullWidth
                          size="small"
                          value={abonne.parcelle}
                          disabled={true}
                      />
                      <TextField
                          label="Rang"
                          fullWidth
                          size="small"
                          value={abonne.rang}
                          disabled={true}
                      />
                    </div>
                  </>
              )}
            </div>
        );
      case 3:
        return (
            <div className=" space-y-5 mt-8 mb-8">
              <InputLabel id="demo-select-small-label">
                Type de branchement
              </InputLabel>
              <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={formData.type_amperage}
                  label="type_amperage"
                  size="small"
                  sx={{width: "100%"}}
                  onChange={(e) => handleChange("type_amperage", e.target.value)}
              >
                <MenuItem value="monophasé">Monophasé</MenuItem>
                <MenuItem value="triphasé">Triphasé</MenuItem>
              </Select>
              <div className={`flex md:justify-between md:flex-row sm:flex-row space-x-8`}>
                <div>
                  Monophasé (02 fils)
                  <p>
                    Pour les applications domestiques et les petits appareils.
                  </p>
                </div>
                <div>
                  Triphasé ( 04 fils)
                  <p>
                    Pour les grandes installations industrielles et commerciales.
                  </p>
                </div>
              </div>
              <InputLabel id="puissance">Ampérage souhaité</InputLabel>
              <Select
                  labelId="puissance"
                  id="puissance"
                  value={formData.puissance}
                  label="puissance"
                  size="small"
                  sx={{width: "100%"}}
                  onChange={(e) => handleChange("puissance", e.target.value)}
              >
                {/*{puissances}*/}
                {puissances.map((k) => (
                    <MenuItem key={k.key} value={k.key}>{k.value}</MenuItem>
                ))}
              </Select>
            </div>
        );
      case 4:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8 dark:text-white">
              <Typography
                  className="text-center mb-8 dark:text-white"
                  variant="h5"
              >
                Voulez-vous vraiment soumettre votre demande ?
              </Typography>
            </div>
        );
      default:
        return "Unknown step";
    }
  };

  return (

      <NavbarSidebarLayout>
        {serverOffline && <ServerStatus />}
        <div
            className="block items-center justify-center border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
          <div className="mb-1 w-full">
            <div className="mb-4">
              <Breadcrumb className="mb-4">
                <Breadcrumb.Item href="/dashboard">
                  <div className="flex items-center gap-x-3">
                    <HiHome className="text-xl"/>
                    <span className="dark:text-white">Accueil</span>
                  </div>
                </Breadcrumb.Item>
                <Breadcrumb.Item href="/list-des-demandes">Liste des services </Breadcrumb.Item>
                <Breadcrumb.Item>Modification d'ampérage</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className={`my-8`}>
              <div className="text-center  text-3xl mb-8">
                Modification d'amperage
              </div>

              <div
                  className="flex w-full flex-col gap-y-6 lg:flex-row lg:justify-center lg:gap-y-0 md:flex-row md:justify-center md:gap-y-0">
                <div
                    className=" rounded-lg bg-white p-2 shadow-2xl dark:bg-gray-800  xl:p-8 lg:w-1/2 border-2 lg:p-5">
                  <div className="invisible md:visible">
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{/*label*/}</StepLabel>
                          </Step>
                      ))}
                    </Stepper>
                  </div>
                  <div>
                    <Typography className="text-center mb-8" variant="h5">
                      {steps[activeStep]}
                    </Typography>
                    {getStepContent(activeStep)}

                    <div className="flex flex-row justify-between pt-18">
                      <Button
                          color="success"
                          variant="outlined"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{mr: 1}}
                      >
                        Précédent
                      </Button>

                      <div className="flex 1 1 auto"/>
                      {activeStep === steps.length - 1 && (
                          <>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={handleDemande}
                                sx={{mr: 1}}
                                disabled={stateSend}
                            >
                              {isLoading && <CircularProgress color="secondary" size="1rem" />}
                              <span className="ml-1">Soumettre</span>
                            </Button>
                            {/*  <div className="bg-green-500 mt-12">  <Link to="/register"> Mes demandes</Link> </div> */}
                          </>
                      )}
                      {activeStep !== steps.length - 1 && (
                          <Button
                              onClick={handleNext}
                              variant="contained"
                              color="success"
                              disabled={((!isNext && activeStep === 0) || (isNext && activeStep === 0)) ? (isNext) : (!isNext)}
                          >
                            Suivant
                          </Button>
                      )}
                    </div>
                    <ToastContainer/>
                    {stateSend && (
                        <div className="bg-green-100 mt-12">
                          {" "}
                          <Link to="/dashboard"> Voir mes demandes</Link>{" "}
                        </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavbarSidebarLayout>

  );
// );
};

export default ModificationAmperage;
