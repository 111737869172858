import {api} from "../../../config/config";
import {toast} from "react-toastify";

const handleLogout = async () => {
    try {
       await api
            .get(`/account/logout/${localStorage.getItem("userId")}`)
            .then((response) => {
                if (response.status === 200) {
                    localStorage.clear();
                    //Refraish
                    window.location.href = "/";
                }
            })
            .catch((error) => {
                if (!error?.response) {
                    toast.warning("Pas de réponse du serveur");
                } else if (error.response?.status === 401) {
                    localStorage.clear();
                    window.location.reload(); // Rafraishir la page
                } else {
                    toast.warning("Problème survenu.");
                }
            });
    } catch (except) {
    }
};

export default handleLogout;
