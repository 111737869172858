import React from 'react';

const EchecPage = () => {
  return (
    <div className="bg-gray-100 h-screen">
      <div className="bg-white p-6 md:mx-auto">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="text-red-600 w-16 h-16 mx-auto my-6"
        >
          <path
            fill="currentColor"
            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.827,16.449L13.448,9.071a1.08,1.08,0,0,0-1.506,0L5.173,16.449a1.079,1.079,0,0,0,0,1.509,1.079,1.079,0,0,0,1.509,0l5.769-5.774,5.768,5.774a1.08,1.08,0,0,0,1.509,0A1.08,1.08,0,0,0,18.827,16.449Z"
          />
        </svg>

        <div className="text-center">
          <h3 className="md:text-4xl text-base text-gray-900 font-semibold text-center">
            Paiement échoué !
          </h3>
          <p className="text-gray-600 my-2 text-2xl">
            Désolé, le paiement n'a pas abouti.
          </p>
          <p className='text-lg'>Veuillez vérifier que vous avez suffisamment de fonds !</p>
        </div>
      </div>
    </div>
  );
};

export default EchecPage;
