import React from "react";
import {Navigate} from "react-router-dom";
import EditAbonnement from "../../../EditRequest/AbonnementSimple/EditAbonnement";

const PrivateRouteEditAbonnement = () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <EditAbonnement/>;
    }
    return <Navigate to="/login"/>
}

export default PrivateRouteEditAbonnement;
