import React from "react";
import {Navigate} from "react-router-dom";
import EditDisjoncteur from "../../../EditRequest/Remplacement_de_disjoncteur/EditDisjoncteur";

const PrivateRouteEditDisjoncteur = () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <EditDisjoncteur/>;
    }
    return <Navigate to="/login"/>
}

export default PrivateRouteEditDisjoncteur;
