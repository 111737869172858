import React, { useState, useEffect } from "react";
import {Link, useNavigate} from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "@mui/material/Select";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";

import {
    Button,
    Stepper,
    Step,
    StepLabel,
    Typography,
    TextField,
} from "@mui/material";
import NavbarSidebarLayout from "../../../components/Dashboard/Layouts/NavbarSidebar";

import { api } from "../../../config/config";
//import {PuissanceMonophase} from "../../demande/ListPuissance/PuissanceMonophase";
import {Menu} from "../../../components/Menu/Menu";
import {PuissanceMonophase} from "../../demande/ListPuissance/PuissanceMonophase";
import {PuissanceTriphase} from "../../demande/ListPuissance/PuissanceTriphase";

const EditAmperage = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const location = useLocation();

    const [activeStep, setActiveStep] = useState(0);

    const [formData, setFormData] = useState({});

    const [puissances, setPuissances] = useState([]);

    // deactiver le bouton submit lorsqu'on actionne le bouton soumettre
    const [stateSend, setStateSend] = useState(false);

    const steps = [
        "Recherche du client",
        "Mes informations",
        "Branchement et amperage",
    ];


    //Gérer les puissances en fonction du types d'ampérange
    useEffect(() => {
        if (formData.type_amperage ==="monophasé"){
            setFormData((prevData) => ({ ...prevData, "puissance": formData.puissance }));
            setPuissances(PuissanceMonophase);

        }else{
            setFormData((prevData) => ({ ...prevData, "puissance": formData.puissance }));
            setPuissances(PuissanceTriphase);
        }
    }, [formData.puissance, formData.type_amperage]);

    //Récuperer la demande en cours
    useEffect(()=>{
        setIsLoading(true);
        // Chercher une demande
        try {
            api.get(`/demande/${location.state.amperageId}`).then(response => {
                if (response.status === 200){
                    setFormData(response.data.content);
                    setIsLoading(false);
                }
            }).catch(() => {
                toast.warning("Erreur serveur.");
            })
        }catch (e) {

        }
    }, [location.state.amperageId]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setStateSend(false);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChange = (field, value) => {
        setFormData((prevData) => ({ ...prevData, [field]: value }));
    };

    const handleDemande = () => {

        const filteredDataAmperage = Object.fromEntries(
            Object.entries(formData).filter(([key, value]) => value !== null)
        );

        // Soumettre la demande du client
        try {
            setIsLoading(true);
            api
                .put("/demande", filteredDataAmperage)
                .then((response) => {
                    if (response.status === 201) {
                        setFormData({
                            exploitation: "",
                            puissance: "3A",
                            compteur: "",
                            type_amperage: "monophasé", // triphasé
                            typeCompteur: "PREPAYE",
                            police:""

                        });
                        toast.success(
                            "Demande enregistrée ! Nous vous enverrons des notifications pour le suivi de la demande par mail ou SMS."
                        );
                        setIsLoading(false);
                        setStateSend(true); // désactiver le bouton
                        setTimeout(()=> {
                            navigate("/dashboard");
                        },3000);
                    }
                })
                .catch(() => {
                    toast.error("Demande non enregistré");
                    setIsLoading(false);
                });
        } catch (erreur) {
        }

    };


    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
                        <>
                            {isLoading && <CircularProgress/>}
                            <div className="flex  flex-row space-x-8 ">

                                <TextField
                                    label="Numero compteur"
                                    fullWidth
                                    size="small"
                                    value={formData.numero_compteur || ''}
                                    InputLabelProps={{shrink: true}}
                                    disabled={true}
                                />
                                <TextField
                                    label="Numero de police"
                                    fullWidth
                                    size="small"
                                    value={formData.numero_police || ''}
                                    InputLabelProps={{shrink: true}}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex  flex-row space-x-8 ">
                                <TextField
                                    label="Nom"
                                    fullWidth
                                    size="small"
                                    value={formData.nom || ''}
                                    InputLabelProps={{shrink: true}}
                                    disabled={true}
                                />
                                <TextField
                                    label="Prenom"
                                    fullWidth
                                    size="small"
                                    value={formData.prenom || ''}
                                    InputLabelProps={{shrink: true}}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex  flex-row space-x-8 ">
                                <TextField
                                    label="Email"
                                    fullWidth
                                    size="small"
                                    value={formData.email || ''}
                                    InputLabelProps={{shrink: true}}
                                    disabled={true}
                                />
                                <TextField
                                    label="Telephone"
                                    fullWidth
                                    size="small"
                                    value={formData.tel2 || ''}
                                    InputLabelProps={{shrink: true}}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex  flex-row space-x-8 ">
                                <TextField
                                    label="Section"
                                    fullWidth
                                    size="small"
                                    value={formData.section || ''}
                                    InputLabelProps={{shrink: true}}
                                    disabled={true}
                                />

                                <TextField
                                    label="lot"
                                    fullWidth
                                    size="small"
                                    value={formData.lot || ''}
                                    InputLabelProps={{shrink: true}}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex  flex-row space-x-8 ">
                                <TextField
                                    label="Parcelle"
                                    fullWidth
                                    size="small"
                                    value={formData.parcelle || ''}
                                    InputLabelProps={{shrink: true}}
                                    disabled={true}
                                />
                                <TextField
                                    label="Rang"
                                    fullWidth
                                    size="small"
                                    value={formData.rang || ''}
                                    InputLabelProps={{shrink: true}}
                                    disabled={true}
                                />
                            </div>
                        </>
                    </div>
                );
            case 1:
                return (
                    <div className=" space-y-5 mt-8 mb-8">
                        <InputLabel id="demo-select-small-label">
                            Type de branchement
                        </InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={formData.type_amperage}
                            label="type_amperage"
                            size="small"
                            sx={{ width: "100%" }}
                            onChange={(e) => handleChange("type_amperage", e.target.value)}
                        >
                            <MenuItem value="monophasé">Monophasé</MenuItem>
                            <MenuItem value="triphasé">Triphasé</MenuItem>
                        </Select>

                        <InputLabel id="puissance">Ampérage souhaité</InputLabel>
                        <Select
                            labelId="puissance"
                            id="puissance"
                            value={formData.puissance}
                            label="puissance"
                            size="small"
                            sx={{ width: "100%" }}
                            onChange={(e) => handleChange("puissance", e.target.value)}
                        >
                             {puissances.map((k) => (
                    <MenuItem key={k.key} value={k.key}>{k.value}</MenuItem>
                ))}
                        </Select>
                    </div>
                );
            case 2:
                return (
                    <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8 dark:text-white">
                        <Typography
                            className="text-center mb-8 dark:text-white"
                            variant="h5"
                        >
                            Voulez-vous vraiment modifier votre demande ?
                        </Typography>
                    </div>
                );

            default:
                return "Unknown step";
        }
    };

    return (

        <NavbarSidebarLayout>
            <div
                className="block items-center justify-center border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
                <div className="mb-1 w-full">
                    <Menu titre="Modification d'ampérage"/>
                    <div className={`my-8`}>
                        <div className="text-center  text-3xl mb-8">
                            Modification de la demande de modification d'amperage
                        </div>

                        <div
                            className="flex w-full flex-col gap-y-6 lg:flex-row lg:justify-center lg:gap-y-0 md:flex-row md:justify-center md:gap-y-0">
                            <div
                                className=" rounded-lg bg-white p-2 shadow-2xl dark:bg-gray-800  xl:p-8 lg:w-1/2 border-2 lg:p-5">
                               <div className="invisible md:visible">
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{/*label*/}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                                <div>
                                    <Typography className="text-center mb-8" variant="h5">
                                        {steps[activeStep]}
                                    </Typography>
                                    {getStepContent(activeStep)}

                                    <div className="flex flex-row justify-between pt-18">
                                        <Button
                                            color="success"
                                            variant="outlined"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            sx={{mr: 1}}
                                        >
                                            Précédent
                                        </Button>

                                        <div className="flex 1 1 auto"/>
                                        {activeStep === steps.length - 1 && (
                                            <>
                                                <Button
                                                    color="secondary"
                                                    variant="outlined"
                                                    onClick={handleDemande}
                                                    sx={{mr: 1}}
                                                    disabled={stateSend}
                                                >
                                                    {isLoading ?
                                                        <>
                                                            <CircularProgress color="primary" size="1rem" /> En cours ...
                                                        </>
                                                        :
                                                        <>
                                                            Modifier
                                                        </>
                                                    }
                                                </Button>
                                                {/*  <div className="bg-green-500 mt-12">  <Link to="/register"> Mes demandes</Link> </div> */}
                                            </>
                                        )}
                                        {activeStep !== steps.length - 1 && (
                                            <Button
                                                onClick={handleNext}
                                                variant="contained"
                                                color="success"
                                            >
                                                Suivant
                                            </Button>
                                        )}
                                    </div>
                                    <ToastContainer/>
                                    {stateSend && (
                                        <div className="bg-green-100 mt-12">
                                            {" "}
                                            <Link to="/dashboard"> Voir mes demandes</Link>{" "}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </NavbarSidebarLayout>

    );
// );
};

export default EditAmperage;
