import React, { useEffect, useState } from "react";
import { api } from "../../../config/config";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { MuiTelInput } from "mui-tel-input";
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField, CircularProgress,
} from "@mui/material";
import NavbarSidebarLayout from "../../../components/Dashboard/Layouts/NavbarSidebar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation } from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormHelperText from "@mui/material/FormHelperText";
import dayjs from "dayjs";
import TypePiece from "../../../shared/TypePiece";
import {Breadcrumb} from "flowbite-react";
import InputLabel from "@mui/material/InputLabel";
import {PuissanceMonophase} from "../ListPuissance/PuissanceMonophase";
import {PuissanceTriphase} from "../ListPuissance/PuissanceTriphase";
import {PhoneRegex} from "../../../components/Validation/PhoneRegex";
import {EmailRegex} from "../../../components/Validation/EmailRegex";
import {PieceValidation} from "../../../components/Validation/PieceValidation";
import {CloudUpload} from "@mui/icons-material";
import {HiHome} from "react-icons/hi";


const BranchementProvisoire = () => {
  const location = useLocation();
  const [activeStepBP, setActiveStepBP] = useState(0);

  // Formation des champs pour le type particulier
  const [formDataBP, setFormDataBP] = useState({
    typeClient: "particulier",
    //Initialiser avec les valeurs par défaut du formulaire
    service: "",
    exploitation: "",
    section: "",
    lot: "",
    parcelle: "",
    rang: "",
    nom: "",
    prenom: "",
    email: "",
    telephone: "",
    whatsApp: "",
    rccm: "",
    ifu: "",
    pb: "",
    profession: "",
    region: "",
    province: "",
    ville: "",
    village: "",
    type_amperage: "monophasé",
    puissance_demande: "",
    duree: "",
    cnib: "",
    passport: "",
    date_delibery: "",
    delivery_place: "",
    amperage: "",
    puissance: "",
    nom_societe: "",
    piece: "",
    prop_nom : "",
    prop_prenom: "",
    file: null,
  });

  //Gestion des erreur
  const [errors, setErrors] = useState({});

  // Liste des professions existantes
  const [professions, setProfessions] = useState([]);
  const [puissances, setPuissances] = useState([]);
  const [ville, setVille] = useState([]);
  const [village, setVillage] = useState([]);
  const [isContentVillage, setIsContentVillage] = useState(false);
  const [phone, setPhone] = React.useState("+226");
  const [whatsapp, setWhatsapp] = React.useState("+226");
  const [errorDate,setErrorDate] = useState(false);

  // Variable pour controler les spinners
  const [loadingBP, setLoadingBP] = useState(false);
  //Controler la selction de la puissance
  const [showAlert, setShowAlert] = useState(false);

  // deactiver le bouton submit lorsqu'on actionne le bouton soumettre
  const [stateSend, setStateSend] = useState(false);


  //Récupération des professions de façon dynamique
  useEffect(() => {
    api
        .get("/profession/all")
        .then((response) => {
          if (response.status === 200) {
            setProfessions(response?.data?.content);
          }
        })
        .catch(() => {

        });
  }, []);

  useEffect(() => {
    api
        .get("/ville/cd")
        .then((response) => {
          if (response.status === 200) {
            setVille(response?.data?.content);
          }
        })
        .catch(() => {

        });
  }, []);


  //Gérer les puissances en fonction du types d'ampérange
  useEffect(() => {
    if (formDataBP.type_amperage ==="monophasé"){
      setFormDataBP((prevData) => ({ ...prevData, "puissance_demande": PuissanceMonophase[0] }));
      setPuissances(PuissanceMonophase);

    }else{
      setFormDataBP((prevData) => ({ ...prevData, "puissance_demande": PuissanceTriphase[0] }));
      setPuissances(PuissanceTriphase);
    }
  }, [formDataBP.type_amperage]);


  const steps = [
    "Type de client",
    "Informations personnelles",
    "Informations sur votre pièce d'identité",
    "Zone Géographique",
    "Information cadastral de la parcelle",
    "Souscription",
    "Validation",
  ];

  const handleNextBranchementProvisoire = () => {
    const validationErrorsBranchementProvisoire = validateForm(formDataBP);
    if (Object.keys(validationErrorsBranchementProvisoire).length === 0) {
      setErrors({});
      setActiveStepBP((prevActiveStep) => prevActiveStep + 1);
    } else {
      setErrors(validationErrorsBranchementProvisoire);
    }
  };

  const handleBackBranchementProvisoire = () => {
    setActiveStepBP((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (field, value) => {
    setFormDataBP((prevData) => ({ ...prevData, [field]: value }));
  };
  const handleFileChange = (event) => {
    setFormDataBP((prevData) => ({ ...prevData, "file": event.target.files[0] }));
  };
  const handleFieldFocus = (field) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  };

  //envoie des fichiers avant l'envoie de la demande
  const handleSendFile =  async () =>{
    if(formDataBP.file){

      // create a new FormData object and append the file to it
      const formData = new FormData();
      formData.append("file", formDataBP.file);

      try {
        setStateSend(true);
        setLoadingBP(true);
        api
            .post("/storage/upload", formData,{ headers: {
                "Content-Type": "multipart/form-data",
              },})
            .then((response) => {
              if (response.status === 201) {

                // setStateSend(false);
                handleSubmitData(response.data.content)
              }
            })
            .catch((err) => {
              // S'il n'a pas pu enregistrer les données on arrête le fonctionnement du spinner
              setLoadingBP(false);

              // Activivé le fichier
              setStateSend(false);

              //On envoie une notification disant qu'il y a eu echec
              toast.error("Demande non enregistré");
            });
      } catch (e) {}
    }
  }

  //function pour passer la valeur de la région dans le setRegionId
  const handleVilleId = async (_, value) => {
    setFormDataBP((prevValue) => ({...prevValue, ville: value?.id}));
    api
        .get(`village/ville/${value?.id}`)
        .then((response) => {
          if (response.status === 200) {
            setVillage(response?.data?.content);
            setIsContentVillage(response.data.content.length > 0);
          }
        })
        .catch(() => {

        });
  };

  const handleVillageId = async (_, value) => {
    setFormDataBP((prevValue) => ({...prevValue, village: value?.id}));
  };

  const handleProfession = async (_, value) => {
    setFormDataBP((prevData) => ({ ...prevData, profession: value?.id }));
  };

  // Soumettre le formulaire
  const handleSubmitData = async (value) => {
    if (value) {
      // On enregistre les données
      try {
        // Formation des données
        let data = {
          lot: formDataBP.lot,
          parcelle: formDataBP.parcelle,
          nom: formDataBP.nom,
          prenom: formDataBP.prenom,
          tel1_whatsapp: formDataBP.whatsApp,
          tel2: formDataBP.telephone,
          email: formDataBP.email,
          type_amperage: formDataBP.type_amperage,
          puissance: formDataBP.puissance_demande,
          numero_piece: formDataBP.cnib,
          date_etablissement: formDataBP.date_delibery,
          lieu_etablissement: formDataBP.delivery_place,
          numero_ifu: formDataBP.ifu,
          numero_rccm: formDataBP.rccm,
          duree: formDataBP.duree,
          type_sce: location.state.idBranchementProvisoire,
          section: formDataBP.section,
          user_id: localStorage.getItem("userId"),
          ville_id: formDataBP.ville,
          village_id: formDataBP.village,
          profession_id: formDataBP.profession,
          piece: formDataBP.piece,
          nom_entreprise: formDataBP.nom_societe,
          prop_nom: formDataBP.prop_nom,
          prop_prenom: formDataBP.prop_prenom,
          cat_cli_code: "",
          photo_piece: value,
        };

        if (formDataBP.typeClient === "particulier"){
          data.cat_cli_code = "00";
        }else {
          data.cat_cli_code = "02";
        }

        // On retire les champs qui n'ont pas été soumis pour validation
        const filteredData = Object.fromEntries(
            Object.entries(data).filter(([key, value]) => value !== null || undefined || "")
        );

        api
            .post("/demande", filteredData)
            .then((response) => {
              if (response.status === 201) {

                // Réactivé le button
                setStateSend(false);

                // On vide le formulaire
                setFormDataBP({
                  //Initialiser avec les valeurs par défaut du formulaire
                  service: "",
                  exploitation: "",
                  section: "",
                  lot: "",
                  parcelle: "",
                  rang: "",
                  nom: "",
                  prenom: "",
                  email: "",
                  telephone: "+226",
                  whatsApp: "+226",
                  rccm: "",
                  ifu: "",
                  pb: "",
                  profession: "",
                  region: "",
                  province: "",
                  ville: "",
                  village: "",
                  type_amperage: "monophasé",
                  puissance_demande: "10A",
                  duree: "",
                  cnib: "",
                  passport: "",
                  date_delibery: "",
                  delivery_place: "",
                  amperage: "",
                  puissance: "",
                  piece: "",
                  prop_nom : "",
                  prop_prenom: "",
                  file: null
                });
                setShowAlert(false);

                //S'il a pu enregistrer les données on arrête le fonctionnement du spinner
                setLoadingBP(false);

                //On envoie une notification
                toast.success(
                    "Demande enregistrée ! Nous vous enverrons des notifications pour le suivi de la demande par mail ou SMS."
                );
                setStateSend(true);
              }
            })
            .catch(() => {
              //S'il n'a pas pu enregistrer les données on arrête le fonctionnement du spinner
              setLoadingBP(false);
              setStateSend(false);
              //On envoie une notification disant qu'il y a eu echec
              toast.error("Demande non enregistré");
            });
      } catch (e) {}
    }
  };

  // Controller les numero de téléphone
  const handleChangePhone = (value) => {
    setPhone(value);
    formDataBP.telephone = value;
  };

  //Whatsapp
  const handleChangePhoneWhatsapp = (whatsapp) => {
    setWhatsapp(whatsapp);
    formDataBP.whatsApp = whatsapp;
  };

  const validateForm = (formDataBP) => {
    const errors = {};
    if (activeStepBP === 1) {
      if (!formDataBP.nom.trim()) {
        errors.nom = "Ce champ est obligatoire";
      }
      if (!formDataBP.prenom.trim()) {
        errors.prenom = "Ce champ est obligatoire";
      }
      if (!formDataBP.email.trim()) {
        errors.email = "Ce champ est obligatoire";
      }else if(!EmailRegex(formDataBP.email)){
        errors.email = "Ce mail est incorrect.";
      }
      if (!formDataBP.telephone.trim()) {
        errors.telephone = "Ce champ est obligatoire";
      }else if(!PhoneRegex(formDataBP.telephone)){
        errors.telephone = "Le numéro de téléphone doit être au format correct.";
      }
      if(!PhoneRegex(formDataBP.whatsApp)){
        errors.whatsApp = "Le numéro de whatsApp doit être au format correct.";
      }
      if (formDataBP.typeClient === "entreprise" && !formDataBP.ifu.trim()) {
        errors.ifu = "Ce champ est obligatoire";
      }
      if (formDataBP.typeClient === "entreprise" && !formDataBP.rccm.trim()) {
        errors.rccm = "Ce champ est obligatoire";
      }
      if (formDataBP.typeClient === "entreprise" && !formDataBP.nom_societe.trim()) {
        errors.nom_societe = "Ce champ est obligatoire";
      }
      if (!formDataBP.profession) {
        errors.profession = "Ce champ est obligatoire";
      }

    }
    if (activeStepBP === 2) {
      if (!formDataBP.piece){
        errors.piece = "Ce champ est obligatoire";
      }
      if (!formDataBP.cnib.trim()) {
        errors.cnib = "Ce champ est obligatoire";
      }
      if (!formDataBP.date_delibery.trim()){
        errors.date_delibery = "Ce champ est obligatoire";
        setErrorDate(true);
      }else if(!PieceValidation(formDataBP.date_delibery)){
        errors.date_delibery = "Cette date ne peut pas être acceptée." ;
        setErrorDate(true);
      }
      if (!formDataBP.delivery_place.trim()) {
        errors.delivery_place = "Ce champ est obligatoire";
      }
      if (!formDataBP.file) {
        errors.file = "Ce champ est obligatoire";
      }
      if (formDataBP.file && formDataBP.file.size > (2*1024*1024) ) {
        errors.file = "Le fichier sélectionné est trop volumineux."
      }
    }
    if (activeStepBP === 3) {
      if (!formDataBP.ville) {
        errors.ville = "Ce champ est obligatoire";
      }
      if (isContentVillage && !formDataBP.village) {
        errors.village = "Ce champ est obligatoire";
      }
    }
    if (activeStepBP === 4){
      if (!formDataBP.prop_nom.trim()){
        errors.prop_nom = "Ce champ est obligatoire";
      }
      if (!formDataBP.prop_prenom.trim()){
        errors.prop_prenom = "Ce champ est obligatoire";
      }
      if(!formDataBP.section.trim()){
        errors.section = "Ce champ est obligatoire.";
      }
      if(!formDataBP.lot.trim()){
        errors.lot = "Ce champ est obligatoire.";
      }
      if(!formDataBP.parcelle.trim()){
        errors.parcelle = "Ce champ est obligatoire.";
      }
    }
    if (activeStepBP === 5) {
      if (!formDataBP.type_amperage ) {
        errors.type_amperage = "Ce champ est obligatoire";
      }
      if (!formDataBP.duree.trim()) {
        errors.duree = "Ce champ est obligatoire";
      }
      if (!formDataBP.puissance_demande) {
        errors.puissance_demande = "Ce champ est obligatoire";
        setShowAlert(true);
      }
    }
    return errors;
  };

  const handleChangePiece = (event, value) => {
    setFormDataBP((prevData) => ({ ...prevData, piece: value?.name }));
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
            <div className="flex justify-center  h-32 items-center dark:text-white">
              <FormControl>
                <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="mb-5"
                >
                  {/* Type de client */}
                </FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="type-client"
                    value={formDataBP.typeClient}
                >
                  <FormControlLabel
                      value="particulier"
                      control={<Radio />}
                      label="Particulier"
                      onChange={(e) => handleChange("typeClient", e.target.value)}
                  />
                  <FormControlLabel
                      value="entreprise"
                      control={<Radio />}
                      label="Entreprise"
                      onChange={(event) => handleChange("typeClient", event.target.value)}
                  />
                </RadioGroup>
              </FormControl>
            </div>
        );
      case 1:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8 dark:text-white">
              <>
                <TextField
                    className="dark:text-white"
                    label="Nom"
                    fullWidth
                    size="small"
                    value={formDataBP.nom}
                    onChange={(e) => handleChange("nom", e.target.value)}
                    onFocus={() => handleFieldFocus("nom")}
                    error={!!errors.nom}
                    helperText={errors.nom}
                    required
                />
                <TextField
                    label="Prenom"
                    fullWidth
                    size="small"
                    value={formDataBP.prenom}
                    onChange={(e) => handleChange("prenom", e.target.value)}
                    onFocus={() => handleFieldFocus("prenom")}
                    error={!!errors.prenom}
                    helperText={errors.prenom}
                    required
                    className="dark:text-white"
                />
                <TextField
                    label="Email"
                    type="email"
                    fullWidth
                    size="small"
                    value={formDataBP.email}
                    onChange={(e) => handleChange("email", e.target.value)}
                    onFocus={() => handleFieldFocus("email")}
                    error={!!errors.email}
                    helperText={errors.email}
                    required
                    className="dark:text-white"
                />
                <MuiTelInput
                    label="Telephone"
                    fullWidth
                    size="small"
                    value={phone}
                    onChange={handleChangePhone}
                    onFocus={() => handleFieldFocus("telephone")}
                    error={!!errors.telephone}
                    helperText={errors.telephone}
                    required
                    className="dark:text-white"
                />
                <MuiTelInput
                    label="WhatSApp"
                    fullWidth
                    size="small"
                    value={whatsapp}
                    onChange={handleChangePhoneWhatsapp}
                    onFocus={() => handleFieldFocus("whatsApp")}
                    error={!!errors.whatsApp}
                    helperText={errors.whatsApp}
                    className="dark:text-white"
                />
                {formDataBP.typeClient === "entreprise" && (
                    <>
                      <TextField
                          label="Nom de la société"
                          fullWidth
                          size="small"
                          value={formDataBP.nom_societe}
                          onChange={(e) => handleChange("nom_societe", e.target.value)}
                          onFocus={() => handleFieldFocus("nom_societe")}
                          error={!!errors.nom_societe}
                          helperText={errors.nom_societe}
                          required
                          className="dark:text-white"
                      />
                      <TextField
                          label="Numéro IFU"
                          fullWidth
                          size="small"
                          value={formDataBP.ifu}
                          onChange={(e) => handleChange("ifu", e.target.value)}
                          onFocus={() => handleFieldFocus("ifu")}
                          error={!!errors.ifu}
                          helperText={errors.ifu}
                          required
                          className="dark:text-white"
                      />
                      <TextField
                          label="Numéro rccm"
                          fullWidth
                          size="small"
                          value={formDataBP.rccm}
                          onChange={(e) => handleChange("rccm", e.target.value)}
                          onFocus={() => handleFieldFocus("rccm")}
                          error={!!errors.rccm}
                          helperText={errors.rccm}
                          required
                          className="dark:text-white"
                      />
                    </>
                )}
                <Autocomplete
                    // disablePortal
                    autoHighlight
                    id="profession"
                    options={professions}
                    size="small"
                    sx={{ width: "100%" }}
                    onChange={handleProfession}
                    getOptionLabel={(option) => option.name}
                    value={professions?.find((option) => option?.id === formDataBP?.profession) || null}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Profession *"
                            error={!!errors.profession}
                            helperText={errors.profession}
                        />
                    )}
                />
              </>
            </div>
        );
      case 2:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              <>
                <Autocomplete
                    // disablePortal
                    autoHighlight
                    id="typepiece"
                    options={TypePiece}
                    size="small"
                    sx={{ width: "100%" }}
                    //onChange={handleProfessionChange}
                    onChange={handleChangePiece}
                    value={TypePiece.find((piece) => piece.name === formDataBP.piece) || null}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Type de piece *"
                            error={!!errors.piece}
                            helperText={errors.piece}
                        />
                    )}
                />
                <TextField
                    label="Référence de la pièce (CNIB, Passport, etc)"
                    fullWidth
                    size="small"
                    value={formDataBP.cnib}
                    onChange={(e) => handleChange("cnib", e.target.value)}
                    onFocus={() => handleFieldFocus("cnib")}
                    error={!!errors.cnib}
                    helperText={errors.cnib}
                    required
                    className="dark:text-white"
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                      sx={{ width: "100%" }}
                      label="Date Etablissement"
                      defaultValue={dayjs(formDataBP.date_delibery)}
                      format="DD/MM/YYYY"
                      onChange={(value) => {
                        // Formater la date au format "yyyy/mm/dd" avec Dayjs
                        const formattedDate = dayjs(value).format("YYYY/MM/DD");

                        // Appeler la fonction handleChange avec la date formatée
                        handleChange("date_delibery", formattedDate);
                        setErrorDate(false)
                      }}
                      slotProps={{
                        textField: {
                          error: errorDate,
                          helperText: errors.date_delibery,
                        },
                      }}
                  />
                </LocalizationProvider>

                <TextField
                    label="Lieu d'établissement"
                    fullWidth
                    size="small"
                    value={formDataBP.delivery_place}
                    onChange={(e) => handleChange("delivery_place", e.target.value)}
                    onFocus={() => handleFieldFocus("delivery_place")}
                    error={!!errors.delivery_place}
                    helperText={errors.delivery_place}
                    required
                    className="dark:text-white"
                />
              </>
              <>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUpload/>}
                >
                  Importez votre pièce
                  <input
                      type="file"
                      hidden
                      onChange={event => handleFileChange(event)}
                  />
                </Button>
                <p className="mt-1 text-sm font-bold">Le fichier ne doit pas dépasser 5 Mo.</p>
                {formDataBP.file && (
                    <>{formDataBP.file.name}</>
                )}
                {errors.file && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.file}</p>
                )}
              </>
            </div>
        );
      case 3:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              <>
                <Autocomplete
                    // disablePortal
                    autoHighlight
                    id="ville"
                    options={ville}
                    size="small"
                    sx={{ width: "100%" }}
                    //onChange={handleProfessionChange}
                    onChange={handleVilleId}
                    value={ville?.find((option) => option?.id === formDataBP?.ville) || null}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Ville"
                            error={!!errors.ville}
                            helperText={errors.ville}
                        />
                    )}
                />
                {isContentVillage && (
                    <Autocomplete
                        // disablePortal
                        autoHighlight
                        id="Village"
                        options={village}
                        size="small"
                        sx={{ width: "100%" }}
                        //onChange={handleProfessionChange}
                        onChange={handleVillageId}
                        value={village?.find((option) => option?.id === formDataBP?.village) || null}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Village"
                                error={!!errors.village}
                                helperText={errors.village}
                            />
                        )}
                    />
                )}
              </>
            </div>
        );
      case 4:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              <TextField
                  label="Nom du propriétaire de la parcelle"
                  fullWidth
                  size="small"
                  value={formDataBP.prop_nom}
                  onChange={(e) => handleChange("prop_nom", e.target.value)}
                  onFocus={() => handleFieldFocus("prop_nom")}
                  error={!!errors.prop_nom}
                  helperText={errors.prop_nom}
                  required
              />
              <TextField
                  label="Prénom du propriétaire de la parcelle"
                  fullWidth
                  size="small"
                  value={formDataBP.prop_prenom}
                  onChange={(e) => handleChange("prop_prenom", e.target.value)}
                  onFocus={() => handleFieldFocus("prop_prenom")}
                  error={!!errors.prop_prenom}
                  helperText={errors.prop_prenom}
                  required
              />
              <TextField
                  label="Section"
                  fullWidth
                  size="small"
                  value={formDataBP.section}
                  onChange={(e) => handleChange("section", e.target.value.toUpperCase())}
                  onFocus={() => handleFieldFocus("section")}
                  error={!!errors.section}
                  helperText={errors.section}
                  required
              />
              <TextField
                  label="Lot"
                  type="number"
                  fullWidth
                  size="small"
                  value={formDataBP.lot}
                  onChange={(e) => handleChange("lot", e.target.value)}
                  onFocus={() => handleFieldFocus("lot")}
                  error={!!errors.lot}
                  helperText={errors.lot}
                  required
              />
              <TextField
                  label="Parcelle"
                  fullWidth
                  type="number"
                  size="small"
                  value={formDataBP.parcelle}
                  onChange={(e) => handleChange("parcelle", e.target.value)}
                  onFocus={() => handleFieldFocus("parcelle")}
                  error={!!errors.parcelle}
                  helperText={errors.parcelle}
                  required
              />
            </div>
        );
      case 5:
        return (
            <div className="space-y-5 mt-8 mb-8">
              <>
                <InputLabel id="demo-select-small-label">
                  Type de branchement
                </InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={formDataBP.type_amperage}
                    label="type_amperage"
                    size="small"
                    sx={{width: "100%"}}
                    onChange={(e) => handleChange("type_amperage", e.target.value)}
                >
                  <MenuItem value="monophasé">Monophasé</MenuItem>
                  <MenuItem value="triphasé">Triphasé</MenuItem>
                </Select>
                <div className={`flex md:justify-between md:flex-row sm:flex-row space-x-8`}>
                  <div>
                    Monophasé
                    <p>
                      Pour les applications domestiques et les petits appareils.
                    </p>
                  </div>
                  <div>
                    Triphasé
                    <p>
                      Pour les grandes installations industrielles et commerciales.
                    </p>
                  </div>
                </div>
                <InputLabel id="demo-select-small-label">
                  Ampérage souhaité
                </InputLabel>
                <Select
                    fullWidth
                    size="small"
                    value={formDataBP.puissance_demande}
                    onChange={(event) => {
                      handleChange("puissance_demande", event.target.value);
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    required
                >
                  {puissances.map((value) => (
                      <MenuItem key={value} value={value}>{value}</MenuItem>
                  ))}
                </Select>
                {showAlert && (
                    <FormHelperText error>{errors.puissance_demande}</FormHelperText>
                )}
                <TextField
                    label="Durée de souscription en jour"
                    type="number"
                    placeholder="par exemple 20 jours"
                    fullWidth
                    size="small"
                    value={formDataBP.duree}
                    onChange={(e) => handleChange("duree", e.target.value)}
                    onFocus={() => handleFieldFocus("duree")}
                    error={!!errors.duree}
                    helperText={errors.duree}
                    required
                />
              </>
            </div>
        );
      case 6:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8 dark:text-white">
              <Typography
                  className="text-center mb-8 dark:text-white"
                  variant="h5"
              >
                Voulez-vous vraiment soumettre votre demande ?
              </Typography>
            </div>
        );
      default:
        return "Unknown step";
    }
  };

  return (
      <NavbarSidebarLayout>
        <div
            className="block items-center justify-center border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
          <div className="mb-1 w-full">
            <div className="mb-4">
              <Breadcrumb className="mb-4">
                <Breadcrumb.Item href="/dashboard">
                  <div className="flex items-center gap-x-3">
                    <HiHome className="text-xl" />
                    <span className="dark:text-white">Accueil</span>
                  </div>
                </Breadcrumb.Item>
                <Breadcrumb.Item href="/list-des-demandes">Liste des services </Breadcrumb.Item>
                <Breadcrumb.Item>Branchement provisoire</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className={`my-8`}>
              <div className="text-center text-lg md:text-3xl mb-8">
                Branchement provisoire
              </div>

              <div
                  className="flex w-full flex-col gap-y-6 lg:flex-row lg:justify-center lg:gap-y-0 md:flex-row md:justify-center md:gap-y-0">

                <div
                    className=" rounded-lg bg-white p-2 shadow-2xl dark:bg-gray-800  xl:p-8 lg:w-1/2 border-2 lg:p-5">
                  <div className="invisible md:visible">
                  <Stepper activeStep={activeStepBP} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel></StepLabel>
                        </Step>
                    ))}
                  </Stepper>

                  </div>
                  <div>
                    <Typography
                        className="flex flex-col text-sm text-center mb-8 dark:text-white"
                        variant="h6"
                    >
                      {steps[activeStepBP]}
                    </Typography>
                    {getStepContent(activeStepBP)}

                    <div className="flex flex-row justify-between pt-18">
                      <Button
                          color="success"
                          variant="outlined"
                          disabled={activeStepBP === 0}
                          onClick={handleBackBranchementProvisoire}
                          sx={{mr: 1}}
                      >
                        Précédent
                      </Button>
                      <div className="flex 1 1 auto"/>
                      {activeStepBP === steps.length - 1 && (
                          <>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={handleSendFile}
                                sx={{mr: 1}}
                                disabled={stateSend}
                            >
                              {loadingBP ?
                                  <>
                                    <CircularProgress color="secondary" size="1rem" /> En cours ...
                                  </>
                                  :
                                  <>
                                    Soumettre
                                  </>
                              }
                            </Button>
                          </>
                      )}
                      {activeStepBP !== steps.length - 1 && (
                          <Button
                              onClick={handleNextBranchementProvisoire}
                              variant="contained"
                              color="success"
                          >
                            Suivant
                          </Button>
                      )}
                    </div>
                  </div>
                  <ToastContainer/>
                  <div className="bg-green-100 mt-12">
                    {stateSend && (
                        <>
                          <Link
                              to="/dashboard"
                              className="text-justify text-white dark:text-white"
                          >
                            <Button>Voir mes demandes</Button>
                          </Link>
                        </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavbarSidebarLayout>
  );
};

export default BranchementProvisoire;
