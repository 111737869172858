import React from "react";
import {Navigate} from "react-router-dom";
import PayementDevis from "../../Payement/PayementDevis";
import DashBoardPage from "../../Dashboard_Page_Acceuil/DashBoardPage";

const PrivateRoutePayment = () => {
    const isLog = localStorage.getItem('isConnected') && localStorage.getItem('accessToken');
    const havePermission = localStorage.getItem('demandeId') && localStorage.getItem('devisId') && localStorage.getItem('type_service');
    // On verifie qu'il est authentifier
    if (isLog) {
        // On verifie qu'il dispose de tous les droits pour continuer
        if (havePermission){
            // On retourne la vue de paiement
            return <PayementDevis/>;
        }
        // Dans le cas contraire on le redirige vers la page principale
        return <DashBoardPage/>
    }
    // Oubien il retourne se logger
    return <Navigate to="/login"/>
}

export default PrivateRoutePayment;
