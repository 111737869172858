import React from "react";
import {Navigate} from "react-router-dom";
import EditPersonnePhysique from "../../../EditRequest/Personne_physique/EditPersonnePhysique";

const PrivateRouteEditPersonnePhysique = () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <EditPersonnePhysique/>;
    }
    return <Navigate to="/login"/>
}

export default PrivateRouteEditPersonnePhysique;
