export function PieceValidation(pieceDate) {

    //On délimite la date en ses composant mois,jour,année
    const pieceDateParts = pieceDate.split("/");
    const year = parseInt(pieceDateParts[0], 10); //On Convertit l'année en nombre entier
    const month = parseInt(pieceDateParts[1], 10) - 1; //On Convertit le mois en nombre entier (soustraire 1 car les mois dans JavaScript sont indexés à partir de 0)
    const day = parseInt(pieceDateParts[2], 10); //On Convertit le jour en nombre entier

    //On créer notre nouvelle date au format de l'objet Date
    const pieceDateObj = new Date(year, month, day);


    //On recupère la date d'aujourd'hui
    const currentDate = new Date();

    //On fait la comparaison
    return pieceDateObj <= currentDate;

}
