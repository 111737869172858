import React from "react";
//import { Button } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LogoSonabel from "../../assets/Logo-site-SONABEL-def.png";

// import IosImage from "../../assets/apple_store.png";
import GoogleImage from "../../assets/google_play.png";
import HomeIcon from '@mui/icons-material/Home';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
//import { Phone } from "@mui/icons-material";

const FooterComponent = (props) => {
  return (
    <footer className="border-3 border-red-400" style={{ backgroundColor: "#00992F" }} >
    <div className=" p-2">
    </div>

       <div className="bg-yellow-500  w-full" style={{ height: "2px" }}></div>

       <div className="flex  flex-col  md:flex-row md:justify-around p-2 mb-2 space-y-2 md:space-y-0">

         <div className="flex flex-col justify-center items-center">
           <img src={LogoSonabel} width={168} alt="logo sonabel" />
           <p className="text-yellow-300 font-bold">
             La Société Nationale d’Electricité du Burkina (SONABEL)
           </p>
         </div>
         <div className="text-yellow-300 ">
           <p className="uppercase text-white mb-1 font-bold text-center text-sm md:text-lg">liens utiles</p>
           <ul className="text-sm space-y-1 font-bold">
             <li> <a href="https://www.sonabel.bf/"  rel="noopener noreferrer"   target="_blank" className="flex flex-row items-center "> <ChevronRightIcon/> <span>Nos agences</span></a></li>
             <li> <a href="https://www.sonabel.bf/"  rel="noopener noreferrer" target="_blank" className="flex flex-row items-center "> <ChevronRightIcon/> <span>Centre D'appel</span></a></li>
             <li> <a href="https://www.sonabel.bf/" rel="noopener noreferrer" target="_blank"  className="flex flex-row items-center "> <ChevronRightIcon/> <span>Communiqué</span></a>   </li>
             <li> <a href="/clause-juridique" className="flex flex-row items-center "> <ChevronRightIcon/> <span>Clause Juridique</span></a>   </li>


           </ul>
         </div>
         <div className="text-yellow-300 font-bold">
           <p className="uppercase text-center text-white mb-1 font-bold text-sm md:text-lg">
             contacts
           </p>
           <ul className="text-sm space-y-1">
             <li className=" flex flex-row items-center justify-center"><HomeIcon /> <span>55, Avenue de la nation Ouagadougou, Burkina Faso</span></li>

             <li className=" flex flex-row items-center space-x-1"> <LocalPhoneIcon/> <span> (+226) 25 30 61 00 / 02 / 03 / 04  </span></li>
             <li className=" flex flex-row items-center space-x-1"> <MailOutlineIcon/> <span>info@sonabel.bf </span> </li>
             <li className="bg-white text-red-500 text-center font-bold">N° vert: 80 00 11 30 </li>
           </ul>
         </div>
         <div className="text-center">
           <p className="uppercase text-white font-bold  md:mb-1 mb-2  text-sm md:text-lg">telechargement</p>
           <div className="flex flex-row md:flex-col md:space-y-3 justify-between">
             {/*<a href="https://play.google.com/store/apps/details?id=bf.sonabel.e_service" rel="noopener noreferrer"  target="_blank"><img src={IosImage}  alt="ios"/> </a>*/}
             <a href="https://play.google.com/store/apps/details?id=bf.sonabel.e_service"  rel="noopener noreferrer"  target="_blank"><img src={GoogleImage}  alt="android"/> </a>
           </div>
         </div>
       </div>
       <div className="text-center text-yellow-500 bg-green-800 mb-0 font-small text-sm">
         <p> DIRECTION DU SYSTÈME D'INFORMATION</p>
         <p className="text-sm font-light text-white">Copyright © 2024 Tous droits réservés.</p>
       </div>
     </footer>
  );
}
export default FooterComponent;
