import React from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import {Typography} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";

const GrilleTarifaire = () => {

    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <p className="text-red-700 text-xl font-bold">Télécharger la grille tarifaire</p>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        <div className="text-center">
                            <p className="text-xl font-bold">Télécharger la grille tarifaire ici !</p>
                            <div className="mt-4">
                                <a
                                    href="https://www.sonabel.bf/wp-content/uploads/2023/12/Grille-Tarifaire.pdf"
                                >
                                    <FontAwesomeIcon icon={faFilePdf} size="6x" color="red"/>
                                </a>

                            </div>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
    );
}
export default GrilleTarifaire;