import React from "react";
import {Navigate} from "react-router-dom";
import Impaye from "../../Impaye/Impaye";

const PrivateRouteIndex = () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <Impaye/>;
    }
    return <Navigate to="/login"/>
}

export default PrivateRouteIndex;
