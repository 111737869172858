// PrivateRoute.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { api } from "../../config/config";

const clearLocalStorage = () => {
  localStorage.clear();
};

const PrivateRoute = ({ renderItem }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [serverStatus, setServerStatus] = useState("checking");
  const [serverOffline, setServerOffline] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      const isConnected = localStorage.getItem("isConnected");
      const accessToken = localStorage.getItem("accessToken");

      if (isConnected && accessToken) {
        setIsAuthenticated(true);
        await checkServerStatus(); // Attendre la vérification du statut du serveur
      } else {
        clearLocalStorage();
        setIsAuthenticated(false);
        navigate("/login"); // Rediriger immédiatement si non authentifié
      }
    };

    const checkServerStatus = async () => {
      try {
        const response = await api.get("/status");
        if (response.status === 200) {
          setServerStatus("online");
        } else {
          setServerStatus("offline");
          setServerOffline(true); // Activer l'état pour afficher le message
        }
      } catch (error) {
        setServerStatus("offline");
        setServerOffline(true); // Activer l'état pour afficher le message
      }
    };

    checkAuthentication();
  }, [navigate]); // Ajouter navigate comme dépendance pour s'assurer de sa mise à jour

  if (serverStatus === "checking" || isAuthenticated === null) {
    return <LoadingSpinner />;
  }

  if (!isAuthenticated) {
    return null; // Ne rien afficher si non authentifié, la redirection est déjà gérée
  }

  // renderItem est exécuté ici avec { serverOffline }
  return renderItem({ serverOffline });
};

export default PrivateRoute;
