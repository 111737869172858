import React, { useReducer, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { api } from "../../../config/config";
import { ToastContainer, toast } from "react-toastify";

//initial state for the form
const initialState = {
  name: "",
  email: "",
  exploitation: "",
  police:"",
  numeroAbonne:"",
  compteur:"",
  objet: "",
  message: "",
};

// Reducer function to handle state updates
function formReducer(state, action) {
  switch (action.type) {
    case "SET_FIELD": {
      return { ...state, [action.field]: action.value };
    }
    case "RESET_FORM": {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

function ContactUs() {
  const [state, dispatch] = useReducer(formReducer, initialState);
  const [loading, setLoading] = useState(false); // State to manage loading
  const [errors, setErrors] = useState({}); // State to manage form errors

  // Set the field name and value
  const handleChange = (e) => {
    dispatch({
      type: "SET_FIELD",
      field: e.target.name,
      value: e.target.value,
    });
  };

  /**
   * Verifier que les champs
   * ne sont pas vide.
   * @returns boolean (true or false)
   */
  const validateForm = () => {
    let formErrors = {};

    if (!state.name.trim()) formErrors.name = "Le nom complet est requis";
    if (!state.email.trim()) formErrors.email = "L'email est requis";
    if (!state.objet.trim()) formErrors.objet = "L'objet est requis";
    if (!state.message.trim()) formErrors.message = "Le message est requis";
    setErrors(formErrors);

    // Return true if no errors
    return Object.keys(formErrors).length === 0;
  };

  // Send form Data to the backend
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // If form is invalid, do not proceed with submission
    }
    setLoading(true); //Start loading

    try {
      const response = await api.post("/contact", state);
      if (response.status === 200) {
        toast.success("Message envoyé avec succès !");
        dispatch({
          type: "RESET_FORM", // Reset the form after successfull submission
        });
      } else {
        toast.error("Une erreur est survenue. Veuillez réessayer.");
      }
    } catch (error) {
      toast.error("Une erreur est survenue. Veuillez réessayer.");
    } finally {
      setLoading(false); // stop loading
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <ToastContainer />
      <Box mt={3}>
        <Typography variant="body1">
          <strong>Formulaire de contact :</strong>
          <br />
          Si vous avez des questions ou des préoccupations, veuillez remplir le
          formulaire ci-dessous et nous vous répondrons dès que possible.
        </Typography>
      </Box>
      <Box component="form" mt={3} onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Nom Complet"
          variant="outlined"
          margin="normal"
          name="name"
          value={state.name}
          onChange={handleChange}
          disabled={loading} // Disable input when loading
          error={!!errors.name}
          helperText={errors.name}
        />

        <TextField
          fullWidth
          label="Email"
          type="email"
          variant="outlined"
          margin="normal"
          name="email"
          value={state.email}
          onChange={handleChange}
          disabled={loading} // Disable input when loading
          error={!!errors.email}
          helperText={errors.email}
        />
          <TextField
          fullWidth
          label="N° Exploitation"
          variant="outlined"
          margin="normal"
          name="exploitation"
          value={state.exploitation}
          onChange={handleChange}
          placeholder="302"
          disabled={loading}
        />
          <TextField
          fullWidth
          label="N° Police"
          variant="outlined"
          margin="normal"
          name="police"
          value={state.police}
          onChange={handleChange}
          disabled={loading}
        />
        <TextField
          fullWidth
          label="N° Compteur"
          variant="outlined"
          margin="normal"
          name="compteur"
          value={state.compteur}
          onChange={handleChange}
          disabled={loading}
     
        />
        <TextField
          fullWidth
          label="N° Abonné"
          variant="outlined"
          margin="normal"
          name="numeroAbonne"
          value={state.numeroAbonne}
          onChange={handleChange}
          disabled={loading}
        /*   disabled={loading} // Disable input when loading
          error={!!errors.name}
          helperText={errors.name} */
        />
        <TextField
          fullWidth
          label="Objet"
          variant="outlined"
          margin="normal"
          name="objet"
          value={state.objet}
          onChange={handleChange}
          disabled={loading} // Disable input when loading
          error={!!errors.objet}
          helperText={errors.objet}
        />
        
        <TextField
          fullWidth
          label="Message"
          variant="outlined"
          margin="normal"
          name="message"
          multiline
          rows={4}
          value={state.message}
          onChange={handleChange}
          disabled={loading} // Disable input when loading
          error={!!errors.message}
          helperText={errors.message}
        />
        <Button
          variant="contained"
          color="success"
          type="submit"
          sx={{ mt: 2 }}
          endIcon={loading ? <CircularProgress size={20} /> : <SendIcon />}
          disabled={loading} // Disable input when loading
        >
          {loading ? "Envoi en cours..." : "Envoyer"}
        </Button>
      </Box>
    </Box>
  );
}

export default ContactUs;
