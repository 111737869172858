import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";

import {
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
  CircularProgress,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import NavbarSidebarLayout from "../../../components/Dashboard/Layouts/NavbarSidebar";

import { api, apiUrl } from "../../../config/config";
import TypePiece from "../../../shared/TypePiece";
import { MuiTelInput } from "mui-tel-input";
import { EmailRegex } from "../../../components/Validation/EmailRegex";
import { PieceValidation } from "../../../components/Validation/PieceValidation";
import { CloudUpload, FileDownload } from "@mui/icons-material";
import { Menu } from "../../../components/Menu/Menu";

const PuissanceMonophase = [
  { key: "3A", value: "3A",label: '3A' },
  { key: "5A - 15A", value: "5A - 15A",label: '5A - 15A' },
  { key: "10A - 30A", value: "10A - 30A",label: '10A - 30A'},
];

const PuissanceTriphase = [
  { key: "10A - 30A", value: "10A - 30A" ,label: '10A - 30A'},
  { key: "DT", value: "DT" ,label: 'Double tarif (> 30A)'},
];

const EditBranchSansAbonnement = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const [activeStep, setActiveStep] = useState(0);

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  // La liste de professions
  const [professions, setProfessions] = useState([]);
  // liste de villes
  const [villes, setVilles] = useState([]);
  // liste des villages
  const [villages, setVillages] = useState([]);

  // deactiver le bouton submit lorsqu'on actionne le bouton soumettre
  const [stateSend, setStateSend] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [isContentVillage, setIsContentVillage] = useState(false);

  // upload des nouveaux fichiers
  const [newFile, setNewFile] = useState({
    file: null,
    document_parcelle: null,
  });

  const filesDelete = useState({
    cnib: false,
    parcelle: false,
  });

  const files = useState({
    cnib: "",
    parcelle: "",
  });

  //Controler la selction de la puissance
  const [showAlert, setShowAlert] = useState(false);
  const [puissances, setPuissances] = useState([]);
  //Récuperer la demande en cours
  useEffect(() => {
    // Chercher une demande
    try {
      api
          .get(`/demande/${location.state.branchementSansAbonId}`)
          .then((response) => {
            if (response.status === 200) {
              setFormData(response?.data?.content);
            }
            
          })
          .catch(() => {
            toast.warning("Erreur serveur.");
          });
    } catch (e) {}
  }, [location.state.branchementSansAbonId]);

  useEffect(() => {
    // Récupération des villes
    api
        .get("/ville/cd")
        .then((response) => {
          if (response.status === 200) {
            const trimmedVilles = response.data.content.map((ville) => ({
              id: ville.id,
              name: ville.agence.trim(),
            }));
            setVilles(trimmedVilles);
          }
        })
        .catch(() => {});
  }, []);

  useEffect(() => {
    //Village
    if (formData.ville_id) {
      api
          .get(`village/ville/${formData.ville_id}`)
          .then((response) => {
            if (response.status === 200) {
              setVillages(response?.data?.content);
              setIsContentVillage(response?.data?.content.length > 0);
            }
          })
          .catch(() => {
            errors.village = "Problème de réseau.";
          });
    }
  }, [errors, formData.village_id, formData.ville_id]);

  useEffect(() => {
    // Récupération des professions
    api
        .get("/profession/all")
        .then((response) => {
          if (response.status === 200) {
            setProfessions(response.data.content);
          }
        })
        .catch(() => {});
  }, []);

  //Gérer les puissances en fonction du types d'ampérange
  useEffect(() => {
    const updatePuissances = () => {
      if (formData.type_amperage === "monophasé") {
        setPuissances(PuissanceMonophase);
        // Si la puisssance n'est pas dans liste fournir une puissance par defaut
        if (!PuissanceMonophase.some(p => p.key === formData.puissance)) {
          setFormData(prevData => ({ ...prevData, puissance: PuissanceMonophase[0].key }));
        }
      } else {
        setPuissances(PuissanceTriphase);
        // Si la puisssance n'est pas dans liste fournir une puissance par defaut
        if (!PuissanceTriphase.some(p => p.key === formData.puissance)) {
          setFormData(prevData => ({ ...prevData, puissance: PuissanceTriphase[0].key }));
        }
      }
    };

    updatePuissances();
  }, [formData.type_amperage,formData.puissance]);

  const steps = [
    "Type de client",
    "Informations du client",
    "Informations d'identité",
    "Localisation géographique",
    "Informations de parcelle",
    "Information sur le branchement",
    "Validation"
  ];

  const handleNext = () => {
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setErrors(validationErrors);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setStateSend(false);
  };

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleChangePhone = (value) => {
    setFormData((prevData) => ({ ...prevData, tel2: value }));
  };

  const handleChangePhoneWhatsapp = (value) => {
    setFormData((prevData) => ({ ...prevData, tel1_whatsapp: value }));
  };

  const handleChangeVille = async (event, value) => {
    setVillages([]); // met la liste des village a zero
    let valeur = value === "undefined" || value === null ? "" : value?.id;
    //setFormData((prevData) => ({ ...prevData, ville: value?.id }));
    setFormData((prevData) => ({ ...prevData, ville_id: valeur }));
    if (isContentVillage && formData.ville_id ) {
      await api
          .get(`/village/ville/${formData?.ville_id}`)

          .then((response) => {
            if (response.status === 200) {
              const trimmedVillages = response.data.content.map((village) => ({
                id: village?.id,
                name: village?.localite.trim(),
              }));
              setVillages(trimmedVillages);
            }
          })
          .catch(() => {});
    }
  };

  const handleChangeProfession = (event, value) => {
    let valeur = value === "undefined" || value === null ? "" : value?.id;
    setFormData((prevData) => ({ ...prevData, profession_id: valeur }));
  };

  const handleChangePiece = (event, value) => {
    setFormData((prevData) => ({ ...prevData, piece: value?.name }));
  };

  const handleChangeVillage = async (event, value) => {
    let valeur = value === "undefined" || value === null ? "" : value?.id;
    setFormData((prevData) => ({ ...prevData, village_id: valeur }));
  };

  const handleFieldFocus = (field) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  };

  // Validation des champs du formulaire
  const validateForm = (formData) => {
    const errors = {};
    if (activeStep === 1) {
      if (!formData.nom.trim()) {
        errors.nom = "Nom obligatoire";
      }
      if (!formData.prenom.trim()) {
        errors.prenom = "Prenom obligatoire";
      }
      // Validation de l'email
      if (!formData.email.trim()) {
        errors.email = "Email obligatoire";
      } else if (!EmailRegex(formData.email.trim())) {
        errors.email = "Format d'email invalide";
      }
      if (!formData.tel2.trim() && formData.tel2.length < 8) {
        errors.tel2 = "Telephone obligatoire";
      }
      // else if(!PhoneRegex(formData.tel2)){
      //     errors.tel2 = "Le numéro de téléphone doit être au format correct.";
      // }
      if (!formData.tel1_whatsapp.trim() && formData.tel1_whatsapp.length < 8) {
        errors.tel1_whatsapp = "Telephone obligatoire";
      }

      if (!formData.profession_id) {
        errors.profession_id = "Vueillez choisir une profession";
      }
      if (formData.numero_rccm && !formData.numero_rccm.trim()) {
        errors.numero_rccm = "RCCM obligatoire";
      }
      if (formData.nom_societe && !formData.nom_societe.trim()) {
        errors.nom_societe = "Nom Entreprise obligatoire";
      }
      if (formData.numero_ifu && !formData.numero_ifu.trim()) {
        errors.numero_ifu = "Numero IFU obligatoire";
      }
    } else if (activeStep === 2) {
      if (!formData.numero_piece.trim() && formData.numero_piece.length < 8) {
        errors.numero_piece = "Numéro piece obligatoire";
      }
      if (!formData.piece) {
        errors.piece = "Type piece obligatoire";
      }
      if (!formData.date_etablissement.trim()) {
        errors.date_etablissement = "La date d'etablissement est  obligatoire";
        setErrorDate(true);
      } else if (
          PieceValidation(formData.date_etablissement.replace(/-/g, "/")) ===
          false
      ) {
        errors.date_etablissement = "Cette date ne peut pas être acceptée.";
        setErrorDate(true);
      }
      if (!formData.lieu_etablissement.trim()) {
        errors.lieu_etablissement =
            " Lieu d'etablissement de la piece obligatoire";
      }
    } else if (activeStep === 3) {
      if (!formData.ville_id) {
        errors.ville_id = "La ville est obligatoire";
      }
      if (!formData.village_id) {
        errors.village_id = "Le village est obligatoire";
      }
    } else if (activeStep === 4) {
      if (!formData.lot) {
        errors.lot = "Numero lot obligatoire";
      }
      if (!formData.parcelle) {
        errors.parcelle = "Numero parcelle Obligatoire";
      }
      if (!formData.section.trim()) {
        errors.section = "Numero section Obligatoire";
      }
      if (!formData.document_parcelle && !newFile.document_parcelle) {
        errors.document_parcelle = "Le document de la parcelle est obligatoire";
      } else if (newFile.document_parcelle && newFile.document_parcelle.size > 2 * 1024 * 1024) {
        errors.document_parcelle = "Le fichier sélectionné est volumineux."
      }
    } else if (activeStep === 5) {
      if (!formData.puissance) {
        errors.puissance = "Ce champ est obligatoire";
        setShowAlert(true);
      }
    }
    return errors;
  };

  // Supprimer la cnib
  const handleDeleteFile = async () => {
    try {
      setIsLoading(true);
      if (newFile.file && formData.photo_piece) {
        await api
            .delete(`/storage/${formData.photo_piece}`)
            .then((response) => {
              if (response.status === 201) {
                filesDelete.cnib = true;
              }
            })
            .catch(() => {
              setIsLoading(false);
              filesDelete.cnib = false;
              toast.error("Le fichier n'existe pas.");
            });
      } else {
        await handleSendFile();
      }
    } catch (e) {}
  };

  // Supprimer le document de la parcelle
  const handleDeleteFileDocument = async () => {
    try {
      setIsLoading(true);
      if (newFile.document_parcelle && formData.document_parcelle) {
        await api
            .delete(`/storage/${formData.document_parcelle}`)
            .then((response) => {
              if (response.status === 201) {
                filesDelete.parcelle = true;
              }
            })
            .catch(() => {
              setIsLoading(false);
              filesDelete.parcelle = false;
              toast.error("Le fichier n'existe pas.");
            });
      } else {
        await handleSendDocumentFile();
      }
    } catch (e) {}
  };

  //  envoie des fichiers avant l'envoie de la demande
  const handleSendFile = async () => {
    if (newFile.file) {
      // create a new FormData object and append the file to it
      const formDataTransform = new FormData();
      formDataTransform.append("file", newFile.file);

      try {
        setIsLoading(true);
        setStateSend(true);
        await api
            .post("/storage/upload", formDataTransform, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              if (response.status === 201) {
                // handleDemande(response.data.content);
                files.cnib = response.data.content;
              }
            })
            .catch(() => {
              //S'il n'a pas pu enregistrer les données on arrête le fonctionnement du spinner
              setIsLoading(false);
              setStateSend(false);
              //On envoie une notification disant qu'il y a eu echec
              toast.error("Demande non enregistré");
            });
      } catch (e) {}
    }
  };

  // Envoyer le document de la parcelle
  const handleSendDocumentFile = async () => {
    if (newFile.document_parcelle) {
      // create a new FormData object and append the file to it
      const formDataTransformDocument = new FormData();
      formDataTransformDocument.append("file", newFile.document_parcelle);

      try {
        setIsLoading(true);
        setStateSend(true);
        return await api
            .post("/storage/upload", formDataTransformDocument, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              if (response.status === 201) {
                // handleDemande(response.data.content);
                files.parcelle = response.data.content;
              }
            })
            .catch(() => {
              // (err);
              //S'il n'a pas pu enregistrer les données on arrête le fonctionnement du spinner
              setIsLoading(false);
              setStateSend(false);
              //On envoie une notification disant qu'il y a eu echec
              toast.error("Demande non enregistré");
            });
      } catch (e) {}
    }
  };

  const handleDemande = async (cnib, document) => {
    if (cnib || document) {
      // on crée une copie de l'objet formDataBP
      formData.photo_piece = cnib;
      formData.document_parcelle = document;
    }

    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length === 0) {
      setErrors({});

      // On retire les champs qui n'ont pas été soumis pour validation
      const filteredDataSansAbonne = Object.fromEntries(
          Object.entries(formData).filter(([key, value]) => value !== null)
      );

      // Soumettre la demande du client
      try {
        setIsLoading(true);
        await api
            .put("/demande", filteredDataSansAbonne)
            .then((response) => {
              if (response.status === 201) {
                //(response.data);
                setFormData({
                  typeClient: "particulier",
                  //Initialiser avec les valeurs par défaut du formulaire
                  service: "",
                  exploitation: "",
                  section: "",
                  lot: "",
                  parcelle: "",
                  rang: "",
                  nom: "",
                  prenom: "",
                  email: "",
                  telephone: "",
                  whatsApp: "",
                  rccm: "",
                  ifu: "",
                  pb: "",
                  profession: "",
                  piece: "CNI",
                  lieu_piece: "",
                  date_piece: "",
                  numero_piece: "",
                  latitude: "",
                  longitude: "",
                  puissance: "3A",
                  compteur: "",
                  type_amperage: "monophasé", // triphasé
                  police: "",
                  region: "",
                  province: "",
                  ville: "",
                  village: "",
                  nom_entreprise: "",
                });
                //("debut");
                toast.success("Demande modifiée avec succès.");
                setIsLoading(false);
                setStateSend(true); // désactiver le bouton
                setTimeout(() => {
                  navigate("/dashboard");
                }, 3000);
              }
            })
            .catch(() => {
              toast.error("Demande non modifier");
              setIsLoading(false);
            });
      } catch (erreur) {}
    } else {
      setErrors(validationErrors);
    }
  };

  const handleFileChange = (event) => {
    setNewFile((prevData) => ({ ...prevData, file: event.target.files[0] }));
  };

  const handleFileDocumentChange = (event) => {
    setNewFile((prevData) => ({
      ...prevData,
      document_parcelle: event.target.files[0],
    }));
  };

  const handleManageSendDataEdit = async () => {
    if (newFile.file) {
      await handleDeleteFile();
      // await handleSendFile();
    }
    if (newFile.document_parcelle) {
      await handleDeleteFileDocument();
      // await handleSendDocumentFile();
    }

    if (filesDelete.cnib) {
      // Envoi de CNIB
      await handleSendFile();
    }
    if (filesDelete.parcelle) {
      // Envoi du document de la parcelle
      await handleSendDocumentFile();
    }

    // S'il y a de nouveau document à uploader
    if (files.cnib || files.parcelle) {
      await handleDemande(files.cnib, files.parcelle);
    } else {
      // Dans le cas contraire on renvoie les existants
      await handleDemande(null, null);
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
            <div className="flex justify-center h-32 items-center">
              <FormControl>
                <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="mb-5"
                >
                  {/* Type de client */}
                </FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="type-client"
                    value={formData.typeClient}
                >
                  {formData.numero_rccm && formData.numero_ifu ? (
                      <FormControlLabel
                          value="entreprise"
                          control={<Radio />}
                          label="Entreprise"
                          checked
                          // onChange={(e) => handleChange("typeClient", e.target.value)}
                      />
                  ) : (
                      <FormControlLabel
                          value="particulier"
                          control={<Radio />}
                          label="Particulier"
                          checked
                          // onChange={(e) => handleChange("typeClient", e.target.value)}
                      />
                  )}
                </RadioGroup>
              </FormControl>
            </div>
        );
      case 1:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              <TextField
                  label="Nom du Demandeur"
                  fullWidth
                  size="small"
                  value={formData.nom}
                  onChange={(e) => handleChange("nom", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  onFocus={() => handleFieldFocus("nom")}
                  error={!!errors.nom}
                  helperText={errors.nom}
                  required
              />
              <TextField
                  label="Prenom du demandeur"
                  fullWidth
                  size="small"
                  value={formData.prenom}
                  onChange={(e) => handleChange("prenom", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  onFocus={() => handleFieldFocus("prenom")}
                  error={!!errors.prenom}
                  helperText={errors.prenom}
                  required
              />
              <TextField
                  label="Email"
                  fullWidth
                  size="small"
                  value={formData.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  onFocus={() => handleFieldFocus("email")}
                  error={!!errors.email}
                  helperText={errors.email}
                  required
                  type="email"
              />
              <MuiTelInput
                  label="Telephone"
                  fullWidth
                  size="small"
                  value={formData.tel2}
                  onChange={handleChangePhone}
                  onFocus={() => handleFieldFocus("telephone")}
                  error={!!errors.tel2}
                  helperText={errors.tel2}
                  required
                  className="dark:text-white"
              />

              <MuiTelInput
                  label="Telephone"
                  fullWidth
                  size="small"
                  value={formData.tel1_whatsapp}
                  onChange={handleChangePhoneWhatsapp}
                  onFocus={() => handleFieldFocus("telephone")}
                  error={!!errors.tel1_whatsapp}
                  helperText={errors.tel1_whatsapp}
                  required
                  className="dark:text-white"
              />
              <Autocomplete
                  // disablePortal
                  autoHighlight
                  id="profession"
                  options={professions}
                  size="small"
                  sx={{ width: "100%" }}
                  onChange={handleChangeProfession}
                  getOptionLabel={(option) => option.name}
                  value={
                    formData.profession_id
                        ? (professions &&
                            professions?.find(
                                (option) => option?.id === formData.profession_id
                            )) ||
                        null
                        : null
                  }
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          label="Profession *"
                          error={!!errors.profession_id}
                          helperText={errors.profession_id}
                      />
                  )}
              />

              {/**
               * Si c'est  le client est une entreprise
               * on affiche rccm et ifu
               *
               */}

              {formData.numero_ifu && formData.numero_rccm && (
                  <>
                    <TextField
                        label="Nom Entreprise"
                        fullWidth
                        size="small"
                        value={formData.nom_entreprise}
                        onChange={(e) =>
                            handleChange("nom_entreprise", e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                        onFocus={() => handleFieldFocus("nom_entreprise")}
                        error={!!errors.nom_entreprise}
                        helperText={errors.nom_entreprise}
                        required
                    />
                    <TextField
                        label="Numero IFU"
                        fullWidth
                        size="small"
                        value={formData.numero_ifu}
                        onChange={(e) => handleChange("numero_ifu", e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        onFocus={() => handleFieldFocus("numero_ifu")}
                        error={!!errors.numero_ifu}
                        helperText={errors.numero_ifu}
                        required
                    />

                    <TextField
                        label="RCCM"
                        fullWidth
                        size="small"
                        value={formData.numero_rccm}
                        onChange={(e) => handleChange("numero_rccm", e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        onFocus={() => handleFieldFocus("rccm")}
                        error={!!errors.numero_rccm}
                        helperText={errors.numero_rccm}
                        required
                    />
                  </>
              )}
            </div>
        );
      case 2:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              <Autocomplete
                  // disablePortal
                  autoHighlight
                  id="typepiece"
                  options={TypePiece}
                  size="small"
                  sx={{ width: "100%" }}
                  onChange={handleChangePiece}
                  value={
                    formData.piece
                        ? TypePiece.find(
                        (option) => option.name === formData.piece
                    ) || null
                        : null
                  }
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          label="Type de piece"
                          error={!!errors.piece}
                          helperText={errors.piece}
                      />
                  )}
              />

              <TextField
                  label="Numero de la piece"
                  fullWidth
                  size="small"
                  value={formData.numero_piece}
                  onChange={(e) => handleChange("numero_piece", e.target.value)}
                  onFocus={() => handleFieldFocus("numero_piece")}
                  error={!!errors.numero_piece}
                  helperText={errors.numero_piece}
                  required
              />
              <div className="w-full">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                      sx={{ width: "100%" }}
                      label="Date Etablissement"
                      format="DD/MM/YYYY"
                      value={dayjs(formData.date_etablissement)}
                      onChange={(value) => {
                        // Formater la date au format "yyyy/mm/dd" avec Dayjs
                        const formattedDate = dayjs(value).format("YYYY/MM/DD");
                        // Appeler la fonction handleChange avec la date formatée
                        handleChange("date_etablissement", formattedDate);
                        setErrorDate(false);
                      }}
                      slotProps={{
                        textField: {
                          error: errorDate,
                          helperText: errors.date_etablissement,
                        },
                      }}
                  />
                </LocalizationProvider>
              </div>

              <TextField
                  label="Lieu d'etablissement piece"
                  fullWidth
                  size="small"
                  value={formData.lieu_etablissement}
                  onChange={(e) =>
                      handleChange("lieu_etablissement", e.target.value)
                  }
                  onFocus={() => handleFieldFocus("lieu_etablissement")}
                  error={!!errors.lieu_etablissement}
                  helperText={errors.lieu_etablissement}
                  required
              />
              <>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUpload />}
                >
                  Modifier votre pièce
                  <input
                      type="file"
                      accept=".pdf"
                      hidden
                      onChange={(event) => handleFileChange(event)}
                  />
                </Button>
                <p className="mt-1 text-sm font-bold">
                  Le fichier ne doit pas dépasser 5 Mo.
                </p>
                {newFile.file && (
                    <>
                      <strong>Nouveau fichier:</strong>
                      <div className={`text-blue-800`}>{newFile.file.name}</div>
                    </>
                )}
                {formData.photo_piece && (
                    <>
                      <a href={apiUrl + `/storage/${formData.photo_piece}`} target="_blank" rel="noreferrer">
                        <FileDownload color={`primary`} sx={{ fontSize: 40 }} />
                      </a>
                      {formData.photo_piece}
                    </>
                )}
                {errors.photo_piece && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      {errors.file}
                    </p>
                )}
              </>
            </div>
        );
      case 3:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              <Autocomplete
                  // disablePortal
                  autoHighlight
                  id="ville"
                  options={villes}
                  size="small"
                  sx={{width: "100%"}}
                  value={formData?.ville_id ? villes?.find((option) => option?.id === formData?.ville_id) || null : null}
                  onChange={handleChangeVille}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          label="Ville"
                          error={!!errors.ville_id}
                          helperText={errors.ville_id}
                      />
                  )}
              />
              {(isContentVillage && formData.ville_id) && (
                  <Autocomplete
                      // disablePortal
                      autoHighlight
                      id="Village"
                      options={villages}
                      size="small"
                      sx={{width: "100%"}}
                      onChange={handleChangeVillage}
                      value={
                        formData.village_id
                            ? (villages &&
                                villages.find(
                                    (option) => option?.id === formData.village_id
                                )) ||
                            null
                            : null
                      }
                      getOptionLabel={(option) => option.localite || ''}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              label="localité"
                              error={!!errors.village_id}
                              helperText={errors.village_id}
                          />
                      )}
                      renderOption={(props, option) => (
                          <li {...props} key={`${option.id}-${option.localite}`}>
                            {option.localite}
                          </li>
                      )}
                  />
              )}
            </div>
        );
      case 4: //  Inos sur la parcelle
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
              <TextField
                  label="Nom du propriétaire de la parcelle"
                  fullWidth
                  size="small"
                  value={formData.prop_nom}
                  onChange={(e) => handleChange("prop_nom", e.target.value)}
                  onFocus={() => handleFieldFocus("prop_nom")}
                  error={!!errors.prop_nom}
                  helperText={errors.prop_nom}
                  required
              />
              <TextField
                  label="Prenom du propriétaire de la parcelle"
                  fullWidth
                  size="small"
                  value={formData.prop_prenom}
                  onChange={(e) => handleChange("prop_prenom", e.target.value)}
                  onFocus={() => handleFieldFocus("prop_prenom")}
                  error={!!errors.prop_prenom}
                  helperText={errors.prop_prenom}
                  required
              />
              <TextField
                  label="Section"
                  fullWidth
                  size="small"
                  value={formData.section}
                  onChange={(e) =>
                      handleChange("section", e.target.value.toUpperCase())
                  }
                  onFocus={() => handleFieldFocus("section")}
                  error={!!errors.section}
                  helperText={errors.section}
                  required
              />
              <TextField
                  label="Lot"
                  fullWidth
                  size="small"
                  value={formData.lot}
                  onChange={(e) => handleChange("lot", e.target.value)}
                  onFocus={() => handleFieldFocus("lot")}
                  error={!!errors.lot}
                  helperText={errors.lot}
                  required
              />
              <TextField
                  label="parcelle"
                  fullWidth
                  size="small"
                  value={formData.parcelle}
                  onChange={(e) => handleChange("parcelle", e.target.value)}
                  onFocus={() => handleFieldFocus("parcelle")}
                  error={!!errors.parcelle}
                  helperText={errors.parcelle}
                  required
              />
              <>
                <p className="mt-1 text-sm font-bold text-red-700">
                  Le fichier ne doit pas dépasser 5 Mo.
                </p>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUpload />}
                >
                  Modifier le Document de votre parcelle
                  <input
                      type="file"
                      accept=".pdf"
                      hidden
                      onChange={(event) => handleFileDocumentChange(event)}
                  />
                </Button>
                {newFile.document_parcelle && (
                    <>
                      <strong>Nouveau fichier:</strong>
                      <div className={`text-blue-800`}>
                        {newFile.document_parcelle.name}
                      </div>
                    </>
                )}

                {formData.document_parcelle && (
                    <>
                      <a href={apiUrl + `/storage/${formData.document_parcelle}`} target="_blank" rel="noreferrer">
                        <FileDownload color={`primary`} sx={{ fontSize: 40 }} />
                      </a>
                      {formData.document_parcelle}
                    </>
                )}
                {errors.document_parcelle && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      {errors.document_parcelle}
                    </p>
                )}
              </>
            </div>
        );
      case 5:
        return (
            <div className=" space-y-5 mt-8 mb-8">
              <InputLabel id="demo-select-small-label">
                Type de branchement
              </InputLabel>
              <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={formData.type_amperage}
                  label="type_amperage"
                  size="small"
                  sx={{ width: "100%" }}
                  onChange={(e) => handleChange("type_amperage", e.target.value)}
              >
                <MenuItem value="monophasé">Monophasé</MenuItem>
                <MenuItem value="triphasé">Triphasé</MenuItem>
              </Select>
              <div
                  className={`flex md:justify-between md:flex-row sm:flex-row space-x-8`}
              >
                <div>
                  Monophasé (02 fils)
                  <p>
                    Pour les applications domestiques et les petits appareils.
                  </p>
                </div>
                <div>
                  Triphasé ( 04 fils )
                  <p>
                    Pour les grandes installations industrielles et commerciales.
                  </p>
                </div>
              </div>
              <InputLabel id="puissance">Ampérage souhaité</InputLabel>
              <Select
                  labelId="puissance"
                  id="puissance"
                  fullWidth
                  size="small"
                  value={formData.puissance}
                  onChange={(event) => {
                    handleChange("puissance", event.target.value);
                  }}
                  //displayEmpty
                  /*  inputProps={{ "aria-label": "Without label" }} */
                  required
              >
                {puissances.map((k) => (
                    <MenuItem key={k.key} value={k.key}>
                      {k.label}
                    </MenuItem>
                ))}
              </Select>
              {showAlert && (
                  <p style={{ color: "red" }}>
                    Veuillez sélectionner une puissance avant de soumettre le
                    formulaire.
                  </p>
              )}
            </div>
        );
      case 6:
        return (
            <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8 dark:text-white">
              <Typography
                  className="text-center mb-8 dark:text-white"
                  variant="h5"
              >
                Voulez-vous vraiment modifier votre demande ?
              </Typography>
            </div>
        );

      default:
        return "Unknown step";
    }
  };

  return (
      <NavbarSidebarLayout>
        <div className="block items-center justify-center border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
          <div className="mb-1 w-full">
            <Menu titre="Modification demande de branchement sans abonnement" />
            <div className={`my-8`}>
              <div className="text-center  text-3xl mb-8">
                Modification de la demande de branchement sans Abonnement
              </div>

              <div className="flex w-full flex-col gap-y-6 lg:flex-row lg:justify-center lg:gap-y-0 md:flex-row md:justify-center md:gap-y-0">
                <div className=" rounded-lg bg-white p-2 shadow-2xl dark:bg-gray-800  xl:p-8 lg:w-1/2 border-2 lg:p-5">
                  <div className="invisible md:visible">
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{/*label*/}</StepLabel>
                          </Step>
                      ))}
                    </Stepper>
                  </div>
                  <div>
                    <Typography className="text-center mb-8" variant="h4">
                      {steps[activeStep]}
                    </Typography>
                    {getStepContent(activeStep)}
                    <div className="flex flex-row justify-between pt-18">
                      <Button
                          color="success"
                          variant="outlined"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                      >
                        Précédent
                      </Button>

                      <div className="flex 1 1 auto" />
                      {activeStep === steps.length - 1 && (
                          <>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={handleManageSendDataEdit}
                                sx={{ mr: 1 }}
                                disabled={stateSend}
                            >
                              {isLoading ? (
                                  <>
                                    <CircularProgress color="primary" size="1rem" />{" "}
                                    En cours ...
                                  </>
                              ) : (
                                  <>Modifier</>
                              )}
                            </Button>
                          </>
                      )}
                      {activeStep !== steps.length - 1 && (
                          <Button
                              onClick={handleNext}
                              variant="contained"
                              color="success"
                          >
                            Suivant
                          </Button>
                      )}
                    </div>
                    <ToastContainer />
                    {stateSend && (
                        <div className="bg-green-100 mt-12">
                          <>
                            <Link
                                to="/dashboard"
                                className="text-center text-white dark:text-white"
                            >
                              <Button>Voir mes demandes</Button>
                            </Link>
                          </>
                        </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavbarSidebarLayout>
  );

};

export default EditBranchSansAbonnement;
