import React from "react";
import {Navigate} from "react-router-dom";
import DashBoardPage from "../../Dashboard_Page_Acceuil/DashBoardPage";

const PrivateRouteDashboard = () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <DashBoardPage/>
    }
    return <Navigate to="/login"/>
}

export default PrivateRouteDashboard;
