import React from "react";
import { useNavigate } from "react-router-dom";

import { Button, CircularProgress } from "@mui/material";

const StepActionComponent = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex flex-row justify-between pt-18">
        {props.activeStep === 0 ? (
          <Button
            color="success"
            variant="outlined"
            onClick={() => {
              navigate("/list-des-demandes");
            }}
            sx={{ mr: 1 }}
          >
            Refuser
          </Button>
        ) : (
          <Button
            color="success"
            variant="outlined"
            onClick={() => {props.handleBack()}}
            sx={{ mr: 1 }}
          >
            Précédent
          </Button>
        )}

        <div className="flex 1 1 auto" />
        {props.activeStep === props.steps.length - 1 && (
          <>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => { props.handleManageDataSend() }}
              sx={{ mr: 1 }}
              disabled={props.stateSend}
            >
              {props.isLoading && (
                <CircularProgress color="secondary" size="1rem" />
              )}
              <span className="ml-1">Soumettre</span>
            </Button>
            {/*  <div className="bg-green-500 mt-12">  <Link to="/register"> Mes demandes</Link> </div> */}
          </>
        )}
        {props.activeStep !== props.steps.length - 1 && (
          <Button
            onClick={() =>  {props.handleNext() }}
            variant="contained"
            color="success"
            disabled={props.serverOffline}
          >
            {props.activeStep === 0 ? "Accepter" : "Suivant "}
          </Button>
        )}
      </div>

     {/*  {props.stateSend && (
        <div className="bg-green-100 mt-12">
          {" "}
          <Link to="/dashboard"> Voir mes demandes</Link>{" "}
        </div>
      )} */}
    </div>
  );
};

export default StepActionComponent;
