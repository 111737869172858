import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import LogoSonabel from "../../assets/logoSonabel1.jpeg"; // Mettez à jour le chemin si nécessaire
import { api } from "../../config/config";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HashLink } from 'react-router-hash-link';

const pages = [
  {
    id: "1",
    title: "Accueil",
    link: "/",
  },
  {
    id: "2",
    title: "Nos Services",
    link: "/#service-en-ligne",
  },
  {
    id: "3",
    title: "Nos Partenaires",
    link: "/#nos-partenaires",
  },
  {
    id: "4",
    title: "Infos Utiles",
    link: "/informations",
  },
  {
    id: "5",
    title: "Contact",
    link: "/contact",
  },
  {
    id: "6",
    title: "Dénonciation",
    link: "/denonciation",
  },
];
/* const activeLinkStyle = {
  backgroundColor: "#009E49", // Vert du logo
  color: "white",
  fontWeight: "bold",
}; */


/* const commonStyles = {
  my: 2,
  color: "#000000", // Noir
  display: "block",
  fontWeight: "bold",
  textTransform: "none",
}; */
const commonStyles = {
  my: 2,
  color: "rgb(1,58,81)",
  fontSize: "14px",
  fontWeight: "bold",
  display: "block",
  fontFamily: "inherit",
  textTransform: "none",
};

function ResponsiveAppBar() {
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    const checkLoginStatus = () => {
      if (localStorage.getItem("isConnected") && localStorage.getItem("accessToken")) {
        setIsLogin(true);
      }
    };

    checkLoginStatus();
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    const id = localStorage.getItem("userId");
    api.get(`/account/logout/${id}`)
        .then((response) => {
          if (response.status === 200) {
            localStorage.clear();
            window.location.href = "/";
          }
        })
        .catch((error) => {
          if (!error.response) {
            toast.warning("Pas de réponse du serveur");
          } else if (error.response.status === 401) {
            toast.warning("Vous êtes déjà déconnecté.");
          } else {
            toast.warning("Problème survenu.");
          }
        });
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const isActive = (link) => {
    return location.pathname === link || location.hash === link;
  };

  return (
      <AppBar position="fixed" sx={{ backgroundColor: "white", color: "black" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
                variant="h6"
                noWrap
                component="a"
                href="#app-bar-with-responsive-menu"
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
            >
              <img src={LogoSonabel} width={80} alt="Logo Sonabel" />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
              >
                {pages.map((page) => (
                    <MenuItem
                        key={page.id}
                        onClick={handleCloseNavMenu}
                        sx={{ ...commonStyles,backgroundColor: isActive(page.link) ? "#d3d3d3" : "transparent"}}
                    >
                      {/* <Link to={page.link} style={{ textDecoration: "none", color: isActive(page.link) ? "#24913a" : "inherit" }}>{page.title}</Link> */}
                      <HashLink smooth to={page.link}  style={{ textDecoration: "none", color: isActive(page.link) ? "#24913a" : "inherit" }}>{page.title} </HashLink>
                    </MenuItem>
                ))}
              </Menu>
            </Box>

            <Typography
                variant="h5"
                noWrap
                component="a"
                href="#app-bar-with-responsive-menu"
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
            >
              <img src={LogoSonabel} width={80} alt="Logo Sonabel" />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                  <Button
                      key={page.id}
                      onClick={handleCloseNavMenu}
                      sx={{
                        ...commonStyles,
                        my: 2,
                        color: isActive(page.link) ? "#24913a" : "rgb(1,58,81)",
                        display: "block",
                        fontWeight: isActive(page.link) ? "bold" : "normal",
                        backgroundColor: isActive(page.link) ? "#d3d3d3" : "transparent",
                      }}
                  >
                    {/* <Link to={page.link} style={{ textDecoration: "none", color: isActive(page.link) ? "#24913a" : "inherit" }}>{page.title}</Link> */}

                    <HashLink smooth to={page.link}  style={{ textDecoration: "none", color: isActive(page.link) ? "#24913a" : "inherit" }}>{page.title} </HashLink>
                  </Button>
              ))}
            </Box>

            {isLogin ? (
                <>
                  <Button
                      variant="outlined"
                      color="success"
                      sx={{  display: { xs: 'none', md: 'block'}, fontWeight: "bold", textTransform: "none", mx: 2 }}

                      component={Link}
                      to="/dashboard"
                  >
                    Mon tableau de bord
                  </Button>
                  <Box sx={{ display: { xs: 'none', md: 'block'}, flexGrow: 0 ,mr:'8px'}}>
                    <Typography variant="subtitle1" className="mr-2">
                      Bienvenue, {localStorage.getItem("username") ? localStorage.getItem("username").split(' ')[1] : ''}
                    </Typography>
                  </Box>
                  <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Ouvrir les paramètres">
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar
                            alt={localStorage.getItem("username")}
                            src="/static/images/avatar/2.jpg"
                        />

                      </IconButton>
                    </Tooltip>
                    <ExpandMoreIcon onClick={handleOpenUserMenu} />
                    <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                      <MenuItem onClick={handleCloseUserMenu}>
                        <Link to="/dashboard" style={{ textDecoration: "none", color: "inherit" }}>Tableau de bord</Link>
                      </MenuItem>
                      <MenuItem onClick={handleLogout}>
                        <Typography textAlign="center">Se déconnecter</Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                </>
            ) : (
                <Box>
                  <Button variant="contained" sx={{ color: "white", backgroundColor: "#d3212b", fontWeight: "bold", textTransform: "none", mx: 2 }}>
                    <Link to="/login" style={{ textDecoration: "none", color: "white" }}>Se Connecter</Link>
                  </Button>
                  <Button variant="outlined" sx={{ color: "#24913a", borderColor: "#24913a", fontWeight: "bold", textTransform: "none", mx: 2 ,display: { xs: "none", md: "inline-block" }}}>
                    <Link to="/register" style={{ textDecoration: "none", color: "#24913a" }}>S'inscrire</Link>
                  </Button>
                </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
  );
}

export default ResponsiveAppBar;
