import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import {Typography} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import React from "react";

export function Abonnement() {
    return(
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <div className="text-red-700 text-xl font-bold">Abonnement</div>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1" component="div">
                        <div className="text-lg mb-2">
                            L’abonnement est un contrat conclu entre le client et la SONABEL pour la fourniture et l’utilisation de l’électricité.
                        </div>
                        <div className="text-lg mb-2">
                            La souscription à la police d’abonnement sera possible lorsque vos travaux de branchement au réseau électrique sont achevés. À l’issue de cette procédure, un compteur vous sera posé pour l’alimentation de vos installations électriques.
                        </div>

                        {/* Moved <ol> outside of <Typography> */}
                        <ol className="list-decimal pl-4 space-y-4">
                            <li>
                                <strong>Conditions à remplir :</strong>
                                <Typography component="div" className="italic font-medium">
                                    Cas d’un abonnement pour un nouveau branchement : <span className="text-orange-700">Votre branchement est déjà exécuté.</span>
                                </Typography>
                                <Typography component="div" className="italic font-medium">
                                    Cas d’un abonnement sur branchement déjà existant : <span className="text-orange-700">Il ne doit subsister aucun impayé lié au dit panneau pour permettre d’autres abonnements au même point de raccordement.</span>
                                </Typography>
                            </li>
                            <li>
                                <strong>Documents à fournir :</strong>
                                <ul className="list-disc ml-4">
                                    <li>Reçu de branchement</li>
                                    <li>Une copie d’un document d’identité en cours de validité</li>
                                    <li>Reçu de branchement ou ancienne facture ou relevé de compte (Pour le compteur post-payé)</li>
                                    <li>Reçu de branchement ou numéro de l’ancien compteur prépayé (Pour le compteur prépayé)</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Délai :</strong>
                                <Typography component="div" className="text-red-700 font-bold">
                                    Votre compteur sera posé dans un délai de 72 heures.
                                </Typography>
                            </li>
                        </ol>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
    )
}