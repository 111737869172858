import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import {Typography} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import React from "react";

export function Transport() {
    return(
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <p className="text-red-700 text-xl font-bold">Transport/Importation</p>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        <p className="text-lg mb-4">
                            {" "}
                            Le transport de l'énergie électrique est un processus essentiel qui comprend les éléments suivants :
                            {" "}
                        </p>
                        <div>
                            <ol className="space-x-8 space-y-4 text-gray-500 list-decimal list-inside">
                                <div></div>
                                <li>
                                    <strong>Transport sur les Lignes à Haute Tension :</strong>
                                    <ul className="ps-5 mt-2 space-y-1 list-disc list-inside">
                                        <li>
                                            L'acheminement de l'électricité se fait à travers des lignes à haute
                                            tension,
                                            notamment à 225 kV, 132 kV et 90 kV, couvrant près de 1500 km de lignes.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Équilibrage en Temps Réel de l'Offre et de la Demande :</strong>
                                    <ul className="ps-5 mt-2 space-y-1 list-disc list-inside">
                                        <li>Un équilibre constant entre l'offre et la demande d'électricité est maintenu
                                            en temps réel à partir du centre de conduite, également appelé Dispatching.
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                        <div className="mt-4">
                            <div className="text-xl font-bold">Composition du Réseau de Transport :</div>
                            <div className="mt-2">
                                <p>Le réseau de transport se compose des éléments suivants :</p>
                                <ol className="space-x-8 space-y-4 text-gray-500 list-decimal list-inside">
                                    <div></div>
                                    <li>
                                        <strong>Lignes à Structure Radiale :</strong>
                                        <ul className="ps-5 mt-2 space-y-1 list-disc list-inside">
                                            <li>
                                                Environ 1500 km de lignes en structure radiale traversent le territoire
                                                d'Est en Ouest, passant par le centre du pays.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Interconnexions en 225 kV :</strong>
                                        <ul className="ps-5 mt-2 space-y-1 list-disc list-inside">
                                            <li>
                                                Deux lignes d'interconnexion en 225 kV sont établies avec la Côte
                                                d'Ivoire et le Ghana, permettant d'approvisionner électriquement le pays
                                                à hauteur de plus de 60%.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Postes de Transformation :</strong>
                                        <ul className="ps-5 mt-2 space-y-1 list-disc list-inside">
                                            <li>
                                                23 postes Haute Tension (HTB) de transformation électrique assurent la
                                                répartition de l'électricité jusqu'aux réseaux de distribution Haute
                                                Tension (HTA) à 15 kV, 20 kV, et 33 kV, ainsi qu'aux gros clients tels
                                                que les usines minières, les cimenteries, les fonderies, etc.
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="text-xl font-bold">Travaux Sous Tension en Haute Tension (HTB) :</div>
                            <p className="mt-1">
                                L'entretien du réseau de transport par la maintenance préventive est réalisé à travers
                                des travaux sous tension, permettant ainsi d'effectuer des interventions sans
                                interruption de l'alimentation électrique.
                            </p>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
    );
}