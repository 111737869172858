import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import ErrorPage from "./pages/ErrorPage";
import EServicePage from "./pages/EServicePage";
import ForgetPassword from "./pages/user/ForgetPassword";
import Particulier from "./pages/Particulier";
import Entreprise from "./pages/Entreprise";
import PrivateRouteProfile from "./pages/PrivateRoute/Profile/PrivateRouteProfile";
import PrivateRouteDashboard from "./pages/PrivateRoute/Dashbord/PrivateRouteDashboard";
import ResetPassword from "./pages/auth/ForgetPassword/email/ResetPassword.jsx";
import AddCodeReset from "./pages/auth/ForgetPassword/email/AddCodeReset";
import PwdReset from "./pages/auth/ForgetPassword/email/PwdReset";
import UserCompte from "./pages/user/UserCompte";
import PrivateRouteResiliationMorale from "./pages/PrivateRoute/Demande/PrivateRouteResiliationMorale";
import PrivateRouteRequestList from "./pages/PrivateRoute/Demande/PrivateRouteRequestList";
import PrivateRouteBranchementProvisoire from "./pages/PrivateRoute/Demande/PrivateRouteBranchementProvisoire";
import PrivateRouteEditBranchementProvisoire from "./pages/PrivateRoute/EditRequestRoute/DemndeProvisoire/PrivateRouteEditBranchementProvisoire";
import PrivateRouteEditTravauxDivers from "./pages/PrivateRoute/EditRequestRoute/TravauxDivers/PrivateTravauxDivers";
import PrivateRouteEditSupervisionTravaux from "./pages/PrivateRoute/EditRequestRoute/Supervision des travaux/EditSupervisionTravaux";
import PrivateRouteEditSupervisionGenieCivil from "./pages/PrivateRoute/EditRequestRoute/SupervisionGenieCivil/EditSupervisionGenieCivil";
import PrivateRouteEditBranchAbonnement from "./pages/PrivateRoute/EditRequestRoute/BranchementAvecAbonnement/PrivateRouteEditBranchAbonnement";
import PrivateRouteBranchSansAbonnement from "./pages/PrivateRoute/EditRequestRoute/BranchementSansAbonnement/PrivateRouteEditeBranchSansAbonnement";
import PrivateRouteEditEtalonnage from "./pages/PrivateRoute/EditRequestRoute/Etalonnage/EditEtalonnage";
import PrivateRouteEditIndex from "./pages/PrivateRoute/EditRequestRoute/SaisieIndex/PrivateRouteEditIndex";
import PrivateRouteEditModificationAmperage from "./pages/PrivateRoute/EditRequestRoute/Amperage/EditModificationAmperage";
import PrivateRouteEditPersonneMorale from "./pages/PrivateRoute/EditRequestRoute/ResiliationPersonneMorale/PrivateRouteEditPersonneMorale";
import PrivateRouteEditPersonnePhysque from "./pages/PrivateRoute/EditRequestRoute/ResiliationPersonnePhysique/PrivateRouteEditPersonnePhysque";
import PrivateRouteEditDisjoncteur from "./pages/PrivateRoute/EditRequestRoute/RemplacementDisjoncteur/PrivateRouteEditDisjoncteur";
import InfosUtile from "./pages/infos/infosUtile";
import ClauseJurique from "./pages/clause_juridique/ClauseJuridique";
import PrivateRouteLogin from "./pages/PrivateRoute/Login/PrivateRouteLogin";
import PrivateRouteRegister from "./pages/PrivateRoute/Register/PrivateRouteRegister";
import { NotFound } from "./pages/NotFound/NotFound";
import SuccessPage from "./pages/Payement/SuccessPage";
import EchecPage from "./pages/Payement/EchecPage";
import PrivateRouteDetail from "./pages/PrivateRoute/Dashbord/PrivateRouteDetail";
import PrivateRoute from "./pages/PrivateRoute/PrivateRoute";
import PrivateRouteEditAbonnement from "./pages/PrivateRoute/EditRequestRoute/Abonnement/EditAbonnement";
import BranchementAbon from "./pages/demande/BranchementAbonnement/BrancheAbon";
import BrancheSanAbon from "./pages/demande/BranchementSansAbonnement/BrancheSanAbon";
import Abonnement from "./pages/demande/AbonnementSimple/Abonnement";
import EtalonnageCompteur from "./pages/demande/EtallonnageCompteur/EtalonnageCompteur";
import RemplacementDijoncteur from "./pages/demande/RemplacementDisjoncteur/RemplacementDijoncteur";
import ModificationAmperage from "./pages/demande/ModificationAmperage/ModificationAmperage";
import ResiliationPhysique from "./pages/demande/Resiliations/ResiliationPhysique";
import SaisiIndex from "./pages/demande/SaisieIndex/SaisiIndex";
import TravauxDivers from "./pages/demande/Demande_travaux_divers/TravauxDivers";
import SupervisionGenieCivil from "./pages/demande/Supervision_genie_civil/SupervisionGenieCivil";
import SupervisionTravaux from "./pages/demande/Supervision_des_travaux/SupervisionTravaux";
import PrivateRoutePayment from "./pages/PrivateRoute/Payment/PrivateRoutePayment";
import PrivateRouteImpaye from "./pages/PrivateRoute/Impaye/PrivateRouteImpaye";
import ContactPage from "./pages/contact/ContactPage";
import Denonciation from "./pages/Dennonciation/denonciation";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/informations",
    element: <InfosUtile />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/contact",
    element: <ContactPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/denonciation",
    element: <Denonciation/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/clause-juridique",
    element: <ClauseJurique />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/particulier",
    element: <Particulier />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/entreprise",
    element: <Entreprise />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/mes-impayes",
    element: <PrivateRouteImpaye />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/e-services",
    element: <EServicePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/brancheabon",
    element: (
      <PrivateRoute
        renderItem={({ serverOffline }) => (
          <BranchementAbon serverOffline={serverOffline} />
        )}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/branchesanabon",
    element: (
      <PrivateRoute
        renderItem={({ serverOffline }) => (
          <BrancheSanAbon serverOffline={serverOffline} />
        )}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/abonnement",
    element: (
      <PrivateRoute
        renderItem={({ serverOffline }) => (
          <Abonnement serverOffline={serverOffline} />
        )}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/etalonnagecompteur",
    element: (
      <PrivateRoute
        renderItem={({ serverOffline }) => (
          <EtalonnageCompteur serverOffline={serverOffline} />
        )}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/remplacement",
    element: (
      <PrivateRoute
        renderItem={({ serverOffline }) => (
          <RemplacementDijoncteur serverOffline={serverOffline} />
        )}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/modificationamperage",

    element: (
      <PrivateRoute
        renderItem={({ serverOffline }) => (
          <ModificationAmperage serverOffline={serverOffline} />
        )}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/resiliationphysique",
    element: (
      <PrivateRoute
        renderItem={({ serverOffline }) => (
          <ResiliationPhysique serverOffline={serverOffline} />
        )}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/resiliationmorale",
    element: <PrivateRouteResiliationMorale />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/saisindex",
    element: (
      <PrivateRoute
        renderItem={({ serverOffline }) => (
          <SaisiIndex serverOffline={serverOffline} />
        )}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <PrivateRouteLogin />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/register",
    element: <PrivateRouteRegister />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/dashboard",
    element: <PrivateRouteDashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/forget_password",
    element: <ForgetPassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/profile",
    element: <PrivateRouteProfile />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/reset_password",
    element: <ResetPassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/reset_password_code",
    element: <AddCodeReset />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/reset",
    element: <PwdReset />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/usercompte",
    element: <UserCompte />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/list-des-demandes",
    element: <PrivateRouteRequestList />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/branchement-provisoire",
    element: <PrivateRouteBranchementProvisoire />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Supervision-travaux",

    element: (
      <PrivateRoute
        renderItem={({ serverOffline }) => (
          <SupervisionTravaux serverOffline={serverOffline} />
        )}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/Supervision-genie-civil",
    element: (
      <PrivateRoute
        renderItem={({ serverOffline }) => (
          <SupervisionGenieCivil serverOffline={serverOffline} />
        )}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/Demande-de-travaux-divers",

    element: (
      <PrivateRoute
        renderItem={({ serverOffline }) => (
          <TravauxDivers serverOffline={serverOffline} />
        )}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/modifier-un-branchement-provisoire",
    element: <PrivateRouteEditBranchementProvisoire />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Modifier-Demande-de-travaux-divers",
    element: <PrivateRouteEditTravauxDivers />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Modifier-Supervision-travaux",
    element: <PrivateRouteEditSupervisionTravaux />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Modification-Supervision-genie-civil",
    element: <PrivateRouteEditSupervisionGenieCivil />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Modifier branchement avec abonnement",
    element: <PrivateRouteEditBranchAbonnement />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Modifier-branchement-sans-abonnement",
    element: <PrivateRouteBranchSansAbonnement />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Modifier-votre-index",
    element: <PrivateRouteEditIndex />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Modifier-votre-abonnement",
    element: <PrivateRouteEditAbonnement />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Modifier-etalonnage-compteur",
    element: <PrivateRouteEditEtalonnage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Modifier-modificationamperage",
    element: <PrivateRouteEditModificationAmperage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Modifier-resiliationphysique",
    element: <PrivateRouteEditPersonnePhysque />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Modifier-resiliationmorale",
    element: <PrivateRouteEditPersonneMorale />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Modifier-remplacement-disjoncteur",
    element: <PrivateRouteEditDisjoncteur />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/payement",
    element: <PrivateRoutePayment />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/details",
    element: <PrivateRouteDetail />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/successpage",
    element: <SuccessPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/echecpage",
    element: <EchecPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
