import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import SonabelLogo from "../../../assets/Sonabel_logo.svg.jpg";
import HomeIcon from "@mui/icons-material/Home";
import { api } from "../../../config/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Login = () => {
  const navigate = useNavigate();

  // utiliser useState pour sauvegarder les saisies de l'utilisateur
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      api
        .post("/account/login", { email: email, password: password })
        .then((response) => {
          if (response.data.status === 200) {
            localStorage.setItem("isConnected", true);
            localStorage.setItem(
              "accessToken",
              response?.data?.content.sessionKey
            );
            localStorage.setItem(
              "userId",
              response.data.content.user.accountId
            );
            localStorage.setItem(
                "username",
                response.data.content.user.fullName
            );
            localStorage.setItem(
                "email",
                response.data.content.user.email
            );
            setEmail("");
            setPassword("");
            setLoading(false);
            //Rédiriger vers la page d'accueil
            navigate("/dashboard");
          }
        })
        .catch((err) => {
          setLoading(false);
          if (!err?.response) {
            toast.warning("Pas de réponse du serveur");
          } else if (err.response?.status === 400) {
            toast.warning("Email ou mot de passe manquant");
          } else if (err.response?.status === 401) {
            toast.warning("Votre mot de passe ou email est incorrect.");
          } else {
            toast.warning("Echec de l'authentification");
          }
          localStorage.removeItem("isConnected");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userId");
        });
    } catch (err) {}
  };

  return (
    <main>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <Link
            to="/"
            className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
          >
            <img className="w-50 h-12 mr-2" src={SonabelLogo} alt="logo" />
            SONABEL
          </Link>
          <ToastContainer />
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Connectez-vous à votre compte
              </h1>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="votre email"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Mot de passe
                  </label>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      placeholder="••••••••"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-600"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </button>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="remember"
                        aria-describedby="remember"
                        type="checkbox"
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                        required=""
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="remember"
                        className="text-gray-500 dark:text-gray-300"
                      >
                        Remember me
                      </label>
                    </div>
                  </div>
                  <Link
                    to="/reset_password"
                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Mot de passe oublié?
                  </Link>
                </div>
                <Button
                  type="submit"
                  className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  {loading ? (
                    <>
                      <CircularProgress color="primary" size="1rem" />
                      En cours ...
                    </>
                  ) : (
                    <>Se connecter</>
                  )}
                </Button>
                <div className="flex flex-row justify-around">
                  <div>
                    <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                      Vous n'avez pas encore de compte ? {""}
                      <Link
                        to="/register"
                        className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                      >
                        Inscrivez-vous
                      </Link>
                    </p>
                  </div>
                  <div>
                    <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                      <Link
                        to="/"
                        className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                      >
                        <HomeIcon />
                      </Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Login;
