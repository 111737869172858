import NavbarDash from "../NavBar/NavbarDash";
import SidebarDash from "../Sidebar/SidebarDash";
import { Link } from "react-router-dom";

const NavbarSidebarLayout=
  function ({ children, isFooter = true }) {
    return (
      <>
        <NavbarDash />
        <div className="flex items-start">
          <SidebarDash />
          <MainContent isFooter={isFooter}>{children}</MainContent>
        </div>
      </>
    );
  };

const MainContent = function (props) {
  return (
      <main className="relative h-full w-full overflow-y-auto bg-gray-50 ml-24 lg:ml-64">
          {props.children}
          {props.isFooter && (
              <div className="mx-4 mt-4">
                  <MainContentFooter/>
              </div>
          )}
      </main>
  );
};

const MainContentFooter = function () {
    return (
        <>
            <div className="flex w-full flex-col gap-y-6 lg:flex-row lg:justify-center lg:gap-y-0">
                <p className="my-8 text-center text-sm text-gray-500 dark:text-gray-300">
                    &copy; Copyright © 2024 <Link to="https://www.sonabel.bf/">SONABEL</Link> Tous droits réservés.
                </p>
            </div>
        </>
    );
};

export default NavbarSidebarLayout;
