import React from "react";

const HeaderMessage = (props) => {
    return (
        <div className="flex flex-row justify-between rounded-lg bg-green-600 text-white p-5 mb-4">
            {props.children}
        </div>
    );
};

export default HeaderMessage;
