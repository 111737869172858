import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/css/sliderStyles.css" // Import the custom CSS

import HeroComponent from "./HeroComponent";
import ServiceAccess from "./ServiceAccess";
import PayeOnline from "./PayeOnline";
import TechnicienSlide from "./TechnicienSlide";

const SimpleSlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 700,
    /* slidesToShow: 1,
    slidesToScroll: 1, */
     adaptiveHeight: true,
     autoplay: true,
  };

  return (
    <div className="flex flex-row justify-center">
      <div className="w-[95%]">
        <Slider {...settings}>
          <div>
            <HeroComponent/>
          </div>
          <div>
            <ServiceAccess/>
          </div>
          <div>
            <PayeOnline/>
          </div>
          <div>
            <TechnicienSlide/>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default SimpleSlider;
