import React from "react";
import {Navigate} from "react-router-dom";
import BranchementProvisoire from "../../demande/Branchement_provisoire/BranchementProvisoire";

const PrivateRouteBranchementProvisoire = () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <BranchementProvisoire/>;
    }
    return <Navigate to="/login"/>
}

export default PrivateRouteBranchementProvisoire;
