import React from "react";
import {Navigate} from "react-router-dom";
import EditAmperage from "../../../EditRequest/Amperage/EditAmperage";

const PrivateRouteEditModificationAmperage = () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <EditAmperage/>;
    }
    return <Navigate to="/login"/>
}

export default PrivateRouteEditModificationAmperage;
