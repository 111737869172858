import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import {Typography} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import React from "react";

export function Resiliation() {
    return(
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <div className="text-red-700 text-xl font-bold">Résiliation</div>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1" component="div">
                        <div className="text-xl font-bold">Compteur Post-payé</div>
                        <div className="text-lg mb-2 text-justify">
                            Lors de la résiliation d'un contrat d'abonnement pour des compteurs post-payés, le client peut être admissible
                            à un remboursement partiel de l'avance sur consommation et des avances sur facture, déduction faite des
                            factures impayées. Cependant, si le montant des impayés dépasse celui de l'avance sur consommation, le client
                            sera tenu de régler la différence.
                        </div>

                        <div className="text-xl font-bold">Compteur Prépayé</div>
                        <div className="text-justify text-lg mb-2">
                            En ce qui concerne les compteurs prépayés, le client est tenu de régler toutes les charges fixes dues, y
                            compris les redevances et les primes fixes.
                        </div>

                        {/* Ensure Typography renders as div to avoid p-nesting issue */}
                        <Typography component="div">
                            <ol className="list-decimal pl-4 space-y-4">
                                <li>
                                    <strong>Conditions à remplir :</strong>
                                    <div className="pt-2 text-justify italic font-medium">
                                        Le demandeur doit être le titulaire de l'abonnement. Pour les non-titulaires de la police d'abonnement,
                                        veuillez contacter une agence de la SONABEL pour plus d'informations.
                                    </div>
                                </li>
                                <li>
                                    <strong>Documents à fournir :</strong>
                                    <div className="pt-4 text font-bold">Pour les Personnes Physiques :</div>
                                    <ul className="list-disc ml-4">
                                        <li>Contrat de police d'abonnement ou une attestation de perte</li>
                                        <li>Copie d'un document d'identité en cours de validité</li>
                                        <li>Correspondance comprenant les références du contrat de la police d'abonnement (numéro de police, références de branchement)</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Délai</strong>
                                    <div className="pt-2 text-justify text-red-700 font-bold">
                                        Le compteur sera retiré dans un délai de 72 heures après notification de la demande de résiliation.
                                    </div>
                                </li>
                            </ol>
                        </Typography>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
    );

}