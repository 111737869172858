import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import {Typography} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import React from "react";

export function SupervisionGenieCivil() {
    return(
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <div className="text-red-700 text-xl font-bold">
                        Prestations techniques & Spéciales
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1" component="div">
                        <div className="text-justify">
                            En plus de son engagement à garantir la production, le transport, la distribution,
                            la commercialisation, la vente, ainsi que l'importation et l'exportation de l'énergie
                            électrique, la SONABEL propose des prestations techniques spéciales pour soutenir les
                            acteurs économiques opérant dans divers domaines. Ces services incluent :
                        </div>

                        {/* Section: Supervision des Travaux d'Électricité */}
                        <div className="pt-4">
                            <div className="text-xl font-bold">
                                Supervision des Travaux d'Électricité
                            </div>
                            <div className="text-lg mb-2 text-justify">
                                Suivi et contrôle des travaux électriques effectués par une entreprise agréée.
                                Les frais de supervision sont à la charge du client.
                            </div>
                            <ol className="list-decimal pl-4 space-y-4">
                                <li>
                                    <strong>Conditions à remplir :</strong>
                                    <div className="pt-2 text-justify italic font-medium">
                                        Le client doit formuler une demande de supervision de travaux électriques à la SONABEL.
                                    </div>
                                </li>
                                <li>
                                    <strong>Documents à fournir :</strong>
                                    <div className="pt-2">
                                        Pour les personnes physiques :
                                        <ul className="list-disc ml-4">
                                            <li>Dossier technique préparé par une entreprise agréée.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <strong>Délai :</strong>
                                    <div className="pt-2 text-red-700 font-bold">
                                        Délai de vingt et un (21) jours pour l'établissement du devis.
                                    </div>
                                </li>
                            </ol>
                        </div>

                        {/* Section: Supervision des Travaux de Génie Civil */}
                        <div className="pt-4">
                            <div className="text-xl font-bold">
                                Supervision des Travaux de Génie Civil
                            </div>
                            <div className="text-justify text-lg mb-2">
                                Suivi et contrôle de la réalisation des travaux de génie civil dans la construction
                                des ouvrages de distribution électrique. Les frais sont à la charge du client.
                            </div>
                            <ol className="list-decimal pl-4 space-y-4">
                                <li>
                                    <strong>Conditions à remplir :</strong>
                                    <div className="pt-2 text-justify italic font-medium">
                                        Le client doit soumettre une demande de supervision génie civil à la SONABEL.
                                    </div>
                                </li>
                                <li>
                                    <strong>Documents à fournir :</strong>
                                    <div className="pt-2">
                                        Pour les personnes physiques :
                                        <ul className="list-disc ml-4">
                                            <li>Plan cadastral de la parcelle ou du domaine.</li>
                                            <li>Puissance électrique requise.</li>
                                            <li>Spécifications du type de poste à installer.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <strong>Délai :</strong>
                                    <div className="pt-2 text-red-700 font-bold">
                                        Délai de vingt et un (21) jours pour l'établissement du devis.
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
    );
}