import React from "react";
import {Navigate} from "react-router-dom";
import EditSupervisionTravaux from "../../../EditRequest/Supervision_des_travaux/EditSupervisionTravaux";

const PrivateRouteEditSupervisionTravaux = () => {
    if (localStorage.getItem('isConnected') && localStorage.getItem('accessToken')) {
        return <EditSupervisionTravaux/>;
    }
    return <Navigate to="/login"/>
}

export default PrivateRouteEditSupervisionTravaux;
