import React, { useEffect, useState } from "react";
import {api, apiUrl} from "../../../config/config";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { MuiTelInput } from "mui-tel-input";
import {
    Button,
    Stepper,
    Step,
    StepLabel,
    Typography,
    TextField, CircularProgress,
} from "@mui/material";
import NavbarSidebarLayout from "../../../components/Dashboard/Layouts/NavbarSidebar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation } from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import TypePiece from "../../../shared/TypePiece";
import InputLabel from "@mui/material/InputLabel";
import {PuissanceMonophase} from "../../demande/ListPuissance/PuissanceMonophase";
import {PuissanceTriphase} from "../../demande/ListPuissance/PuissanceTriphase";
import {PhoneRegex} from "../../../components/Validation/PhoneRegex";
import {PieceValidation} from "../../../components/Validation/PieceValidation";
import {EmailRegex} from "../../../components/Validation/EmailRegex";
import {CloudUpload, FileDownload} from "@mui/icons-material";
import {Menu} from "../../../components/Menu/Menu";


const EditBranchementProvisoire = () => {
    const location = useLocation();

    const [activeStepBP, setActiveStepBP] = useState(0);

    //Gestion des erreur
    const [errors, setErrors] = useState({});

    // Liste des professions existantes
    const [professions, setProfessions] = useState([]);
    const [villes, setVilles] = useState([]);
    const [villages, setVillages] = useState([]);
    const [isContentVillage, setIsContentVillage] = useState(false);
    const [errorDate,setErrorDate] = useState(false);
    // Variable pour controler les spinners
    const [loadingBP, setLoadingBP] = useState(false);

    // deactiver le bouton submit lorsqu'on actionne le bouton soumettre
    const [stateSend, setStateSend] = useState(false);


    // Formation des champs pour le type particulier
    const [formDataBP, setFormDataBP] = useState({});

    const [puissances, setPuissances] = useState([]);

    const [newFile, setNewFile]= useState({
        file: null,
    });

    //Gérer les puissances en fonction du types d'ampérange
    useEffect(() => {
        if (formDataBP.type_amperage ==="monophasé"){
            setFormDataBP((prevData) => ({ ...prevData, "puissance": formDataBP.puissance }));
            setPuissances(PuissanceMonophase);

        }else{
            setFormDataBP((prevData) => ({ ...prevData, "puissance": formDataBP.puissance }));
            setPuissances(PuissanceTriphase);
        }
    }, [formDataBP.puissance, formDataBP.type_amperage]);

    //Récupération des professions de façon dynamique
    useEffect(() => {
        api
            .get("/profession/all")
            .then((response) => {
                if (response.status === 200) {
                    setProfessions(response?.data?.content);
                }
            })
            .catch(() => {
            });
    }, []);

    // ville
    useEffect(() => {
        api
            .get("/ville/all")
            .then((response) => {
                if (response.status === 200) {
                    setVilles(response?.data?.content);
                }
            })
            .catch(() => {});
    }, []);

    //
    useEffect(()=>{
        // Chercher une demande
        try {
            api.get(`/demande/${location.state.branchement_provisoire_id}`).then(response => {
                if (response.status === 200){
                    setFormDataBP(response.data.content);
                }
            }).catch(() => {
                toast.warning("Erreur serveur.");
            })
        }catch (e) {

        }
    }, [location.state.branchement_provisoire_id]);

    // Village
    useEffect(() => {
        if (formDataBP.village_id){
            api
                .get(`village/ville/${formDataBP?.ville_id}`)
                .then((response) => {
                    if (response.status === 200) {
                        setVillages(response?.data?.content);
                        setIsContentVillage(response?.data?.content.length > 0);
                    }
                })
                .catch(() => {
                    errors.village = "Problème de réseau."
                });
        }
    }, [errors, formDataBP?.village_id, formDataBP?.ville_id]);

    const steps = [
        "Type de client",
        "Informations personnelles",
        "Informations sur votre pièce d'identité",
        "Zone Géographique",
        "parcelle",
        "Souscription",
        "Validation",
    ];

    const handleNextBranchementProvisoire = () => {
        const validationErrorsBranchementProvisoire = validateForm(formDataBP);
        if (Object.keys(validationErrorsBranchementProvisoire).length === 0) {
            setErrors({});
            setActiveStepBP((prevActiveStep) => prevActiveStep + 1);
        } else {
            setErrors(validationErrorsBranchementProvisoire);
        }
    };

    const handleBackBranchementProvisoire = () => {
        setActiveStepBP((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChange = (field, value) => {
        setFormDataBP((prevData) => ({ ...prevData, [field]: value }));
    };

    const handleFieldFocus = (field) => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
    };

    //function pour passer la valeur de la région dans le setRegionId
    const handleVilleId = async (_, value) => {
        setFormDataBP((prevData) => ({ ...prevData, ville_id: value?.id }));
        api
            .get(`village/ville/${value?.id}`)
            .then((response) => {
                if (response.status === 200) {
                    setVillages(response?.data?.content);
                    setIsContentVillage(response?.data?.content.length > 0);
                }
            })
            .catch(() => {
                errors.village_id = "Erreur serveur.";
            });
    };
    const handleVillageId = async (_, value) => {
        setFormDataBP((prevData) => ({ ...prevData, village_id: value?.id }));
    };
    const handleProfession = async (_, value) => {
        setFormDataBP((prevData) => ({ ...prevData, profession_id: value?.id }));
        formDataBP.profession_id = value?.id;
    };

    // Suppression du fichier
    const handleDeleteFile = async () => {
        try {
            setLoadingBP(true);
            if (newFile.file){
                api.delete(`/storage/${formDataBP.photo_piece}`).then(response => {
                    if(response.status === 201){
                        handleSendFile();
                    }
                }).catch(() => {
                    setLoadingBP(false)
                    toast.error("Demande non enregistré");
                })
            }else {
                await handleSendFile();
            }
        }catch (e) {

        }
    };
    //on enregistre le nouveau fichier
    const handleSendFile =  async () =>{
        if(newFile.file){
            // create a new FormData object and append the file to it
            const formData = new FormData();
            formData.append("file", newFile.file);

            try {
                api
                    .post("/storage/upload", formData,{ headers: {
                            "Content-Type": "multipart/form-data",
                        },})
                    .then((response) => {
                        if (response.status === 201) {

                            handleSubmitData(response.data.content)
                        }
                    })
                    .catch(() => {
                        //S'il n'a pas pu enregistrer les données on arrête le fonctionnement du spinner
                        setLoadingBP(false);

                        //On envoie une notification disant qu'il y a eu echec
                        toast.error("Demande non enregistré");
                    });
            } catch (e) {}
        }else {
            await handleSubmitData(null);
        }
    }

    // On soumets les nouvelle valeur
    const handleSubmitData = async (value) => {
        if (value){
            // on crée une copie de l'objet formDataBP
            formDataBP.photo_piece = value;
        }

        // On retire les champs qui n'ont pas été soumis pour validation
        const filteredData = Object.fromEntries(
            Object.entries(formDataBP).filter(([key, value]) => value !== null)
        );
        // On enregistre les données
        try {
            api
                .put("/demande", filteredData)
                .then((response) => {
                    if (response.status === 201) {
                        // On vide le formulaire
                        setFormDataBP({
                            //Initialiser avec les valeurs par défaut du formulaire
                            service: "",
                            exploitation: "",
                            section: "",
                            lot: "",
                            parcelle: "",
                            rang: "",
                            nom: "",
                            prenom: "",
                            email: "",
                            telephone: "+226",
                            whatsApp: "+226",
                            rccm: "",
                            ifu: "",
                            pb: "",
                            profession: "",
                            region: "",
                            province: "",
                            ville: "",
                            village: "",
                            type_amperage: "",
                            puissance_demande: "",
                            duree: "",
                            cnib: "",
                            passport: "",
                            date_delibery: "",
                            delivery_place: "",
                            amperage: "",
                            puissance: "",
                            piece: "",
                        });
                        setNewFile({
                            file: null
                        });

                        //S'il a pu enregistrer les données on arrête le fonctionnement du spinner
                        setLoadingBP(false);

                        //On envoie une notification
                        toast.success(
                            "Demande Modifiée avec succès."
                        );
                        setStateSend(true);
                    }
                })
                .catch(() => {

                    //S'il n'a pas pu enregistrer les données on arrête le fonctionnement du spinner
                    setLoadingBP(false);

                    //On envoie une notification disant qu'il y a eu echec
                    toast.error("Demande non enregistré");
                });
        } catch (e) {}
    };

    const validateForm = (formDataBP) => {
        const errors = {};
        if (activeStepBP === 1) {
            if (!formDataBP.nom.trim()) {
                errors.nom = "Nom obligatoire";
            }
            if (!formDataBP.prenom.trim()) {
                errors.prenom = "Prenom obligatoire";
            }
            // Validation de l'email
            if (!formDataBP.email.trim()) {
                errors.email = "Email obligatoire";
            } else if (!EmailRegex(formDataBP.email.trim())) {
                errors.email = "Format d'email invalide";
            }
            if (!formDataBP.tel2.trim()) {
                errors.tel2 = "Telephone obligatoire";
            }else if(!PhoneRegex(formDataBP.tel2)){
                errors.tel2 = "Le numéro de téléphone doit être au format correct.";
            }
            if(!PhoneRegex(formDataBP.tel1_whatsapp)){
                errors.tel1_whatsapp = "Le numéro de téléphone doit être au format correct.";
            }

            if (formDataBP.numero_ifu && !formDataBP.numero_ifu.trim()) {
                errors.numero_ifu = "Numéro IFU obligatoire";
            }
            if (formDataBP.numero_rccm && !formDataBP.numero_rccm.trim()) {
                errors.numero_rccm = "Numéro RCCM obligatoire";
            }
            if (formDataBP.nom_societe && !formDataBP.nom_societe.trim()) {
                errors.nom_societe = "Le nom de la société est obligatoire";
            }
            if (!formDataBP.profession_id) {
                errors.profession_id = "Choisissez votre profession. S'il n'existe pas dans la liste choisissez autre.";
            }
        }
        if (activeStepBP === 2) {
            if (!formDataBP.numero_piece.trim()) {
                errors.numero_piece = "Ce champ est obligatoire";
            }
            if (!formDataBP.date_etablissement.trim()){
                errors.date_etablissement = "Veillez entrer la date de délivrance de votre pièce.";
                setErrorDate(true);
            }else if(PieceValidation(formDataBP.date_etablissement.replace(/-/g, "/")) === false){
                errors.date_etablissement = "Cette date ne peut pas être acceptée." ;
                setErrorDate(true);
            }
            if (!formDataBP.lieu_etablissement.trim()) {
                errors.lieu_etablissement = "Ce champ est obligatoire";
            }
            if (!formDataBP.photo_piece) {
                errors.photo_piece = "Ce champ est obligatoire";
            }
        }
        if (activeStepBP === 3) {
            if (!formDataBP.ville_id) {
                errors.ville_id = "Veuillez sélectionner la ville";
            }
            if (isContentVillage && !formDataBP.village_id) {
                errors.village_id = "Veuillez sélectionner le village";
            }
        }
        if (activeStepBP === 4){
            if(!formDataBP.section.trim()){
                errors.section = "Ce champ est obligatoire."
            }
            if(!formDataBP.lot){
                errors.lot = "Ce champ est obligatoire."
            }
            if(!formDataBP.parcelle){
                errors.parcelle = "Ce champ est obligatoire."
            }
        }
        if (activeStepBP === 5) {
            if (formDataBP.type_amperage === "") {
                errors.type_amperage = "Ce champ est obligatoire";
            }
            if (!formDataBP.puissance === "") {
                errors.puissance = "Ce champ est obligatoire";
            }
            if (!formDataBP.duree.trim()) {
                errors.duree = "Ce champ est obligatoire";
            }
        }
        return errors;
    };

    const handleChangePiece = (event, value) => {
        setFormDataBP((prevData) => ({ ...prevData, piece: value.name }));
    };


    const handleFileChange = (event) => {
        setNewFile((prevData) => ({ ...prevData, "file": event.target.files[0] }));
    };


    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <div className="flex justify-center  h-32 items-center dark:text-white">

                        <FormControl>
                            <FormLabel
                                id="demo-row-radio-buttons-group-label"
                                className="mb-5"
                            >
                                {/* Type de client */}
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="type-client"
                                value={formDataBP.typeClient}
                            >
                                {(formDataBP.numero_ifu && formDataBP.numero_rccm ) ? (
                                    <FormControlLabel
                                        value="entreprise"
                                        control={<Radio />}
                                        label="Entreprise"
                                        checked
                                        onChange={(e) => handleChange("typeClient", e.target.value)}
                                    />
                                ) : (
                                    <FormControlLabel
                                        value="particulier"
                                        control={<Radio />}
                                        label="Particulier"
                                        checked
                                        onChange={(e) => handleChange("typeClient", e.target.value)}
                                    />
                                )}
                            </RadioGroup>
                        </FormControl>
                    </div>
                );
            case 1:
                return (
                    <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8 dark:text-white">
                        <>
                            <TextField
                                className="dark:text-white"
                                label="Nom"
                                fullWidth
                                size="small"
                                value={formDataBP.nom}
                                onChange={(e) => handleChange("nom", e.target.value)}
                                InputLabelProps={{shrink: true}}
                                onFocus={() => handleFieldFocus("nom")}
                                error={!!errors.nom}
                                helperText={errors.nom}
                                required
                            />
                            <TextField
                                label="Prenom"
                                fullWidth
                                size="small"
                                value={formDataBP.prenom}
                                onChange={(e) => handleChange("prenom", e.target.value)}
                                InputLabelProps={{shrink: true}}
                                onFocus={() => handleFieldFocus("prenom")}
                                error={!!errors.prenom}
                                helperText={errors.prenom}
                                required
                                className="dark:text-white"
                            />
                            <TextField
                                label="Email"
                                type="email"
                                fullWidth
                                size="small"
                                value={formDataBP.email}
                                onChange={(e) => handleChange("email", e.target.value)}
                                InputLabelProps={{shrink: true}}
                                onFocus={() => handleFieldFocus("email")}
                                error={!!errors.email}
                                helperText={errors.email}
                                required
                                className="dark:text-white"
                            />
                            <MuiTelInput
                                label="Telephone"
                                fullWidth
                                size="small"
                                value={formDataBP.tel2}
                                onChange={(e) => handleChange("tel2", e.target.value)}
                                InputLabelProps={{shrink: true}}
                                onFocus={() => handleFieldFocus("telephone")}
                                error={!!errors.tel2}
                                helperText={errors.tel2}
                                required
                                className="dark:text-white"
                            />
                            <MuiTelInput
                                label="WhatSApp"
                                fullWidth
                                size="small"
                                value={formDataBP.tel1_whatsapp}
                                onChange={(e) => handleChange("tel1_whatsapp", e.target.value)}
                                InputLabelProps={{shrink: true}}
                                onFocus={() => handleFieldFocus("whatsApp")}
                            />
                            {(formDataBP.numero_ifu && formDataBP.numero_rccm) && (
                                <>
                                    <TextField
                                        label="Nom de la société"
                                        fullWidth
                                        size="small"
                                        value={formDataBP.nom_entreprise}
                                        onChange={(e) => handleChange("nom_entreprise", e.target.value)}
                                        InputLabelProps={{shrink: true}}
                                        onFocus={() => handleFieldFocus("nom_entreprise")}
                                        error={!!errors.nom_entreprise}
                                        helperText={errors.nom_entreprise}
                                        required
                                        className="dark:text-white"
                                    />
                                    <TextField
                                        label="Numéro IFU"
                                        fullWidth
                                        size="small"
                                        value={formDataBP.numero_ifu}
                                        onChange={(e) => handleChange("numero_ifu", e.target.value)}
                                        InputLabelProps={{shrink: true}}
                                        onFocus={() => handleFieldFocus("numero_ifu")}
                                        error={!!errors.numero_ifu}
                                        helperText={errors.numero_ifu}
                                        required
                                        className="dark:text-white"
                                    />
                                    <TextField
                                        label="Numéro rccm"
                                        fullWidth
                                        size="small"
                                        value={formDataBP.numero_rccm}
                                        onChange={(e) => handleChange("numero_rccm", e.target.value)}
                                        InputLabelProps={{shrink: true}}
                                        onFocus={() => handleFieldFocus("numero_rccm")}
                                        error={!!errors.numero_rccm}
                                        helperText={errors.numero_rccm}
                                        required
                                        className="dark:text-white"
                                    />
                                </>
                            )}
                            <Autocomplete
                                // disablePortal
                                autoHighlight
                                id="profession"
                                options={professions}
                                size="small"
                                sx={{ width: "100%" }}
                                onChange={handleProfession}
                                getOptionLabel={(option) => option.name}
                                value={formDataBP?.profession_id ? professions?.find((option) => option?.id === formDataBP?.profession_id) || null : null}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Profession *"
                                        error={!!errors.profession}
                                        helperText={errors.profession}
                                    />
                                )}
                            />
                        </>
                    </div>
                );
            case 2:
                return (
                    <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
                        <>
                            <Autocomplete
                                // disablePortal
                                autoHighlight
                                id="typepiece"
                                options={TypePiece}
                                size="small"
                                sx={{ width: "100%" }}
                                onChange={handleChangePiece}
                                value={formDataBP.piece ? TypePiece?.find((option) => option?.name === formDataBP.piece) || null : null}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Type de piece"
                                        error={!!errors.piece}
                                        helperText={errors.piece}
                                    />
                                )}
                            />
                            <TextField
                                label="Référence de la pièce (CNIB, Passport, etc)"
                                fullWidth
                                size="small"
                                value={formDataBP.numero_piece}
                                onChange={(e) => handleChange("numero_piece", e.target.value)}
                                InputLabelProps={{shrink: true}}
                                onFocus={() => handleFieldFocus("numero_piece")}
                                error={!!errors.numero_piece}
                                helperText={errors.numero_piece}
                                required
                                className="dark:text-white"
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{ width: "100%" }}
                                    label="Date Etablissement"
                                    defaultValue={dayjs(formDataBP.date_etablissement)}
                                    format="DD/MM/YYYY"
                                    onChange={(value) => {
                                        // Formater la date au format "yyyy/mm/dd" avec Dayjs
                                        const formattedDate = dayjs(value).format("YYYY/MM/DD");

                                        // Appeler la fonction handleChange avec la date formatée
                                        handleChange("date_etablissement", formattedDate);
                                        setErrorDate(false)
                                    }}
                                    slotProps={{
                                        textField: {
                                            error: errorDate,
                                            helperText: errors.date_delibery,
                                        },
                                    }}
                                />
                            </LocalizationProvider>

                            <TextField
                                label="Lieu d'établissement"
                                fullWidth
                                size="small"
                                value={formDataBP.lieu_etablissement}
                                onChange={(e) => handleChange("lieu_etablissement", e.target.value)}
                                InputLabelProps={{shrink: true}}
                                onFocus={() => handleFieldFocus("lieu_etablissement")}
                                error={!!errors.lieu_etablissement}
                                helperText={errors.lieu_etablissement}
                                required
                                className="dark:text-white"
                            />
                        </>
                        <>
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUpload/>}
                            >
                                Modifiez votre pièce
                                <input
                                    type="file"
                                    hidden
                                    onChange={event => handleFileChange(event)}
                                />
                            </Button>
                            <p className="mt-1 text-sm font-bold">Le fichier ne doit pas dépasser 5 Mo.</p>
                            {newFile.file && (
                                <><strong>Nouveau fichier:</strong>
                                    <div className={`text-blue-800`}>{newFile.file.name}</div>
                                </>
                            )}
                            {formDataBP.photo_piece && (
                                <>
                                    <a href={apiUrl + `/storage/${formDataBP.photo_piece}`}>
                                        <FileDownload color={`primary`} sx={{fontSize: 40}}/>
                                    </a>
                                    {formDataBP.photo_piece}
                                </>
                            )}
                            {errors.photo_piece && (
                                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.file}</p>
                            )}
                        </>
                    </div>
                );
            case 3:
                return (
                    <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
                        <>
                            <Autocomplete
                                // disablePortal
                                autoHighlight
                                id="ville"
                                options={villes}
                                size="small"
                                sx={{ width: "100%" }}
                                value={formDataBP.ville_id ? (villes && villes.find((option) => option?.id === formDataBP.ville_id)) || null : null}
                                onChange={handleVilleId}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Ville"
                                        error={!!errors.ville_id}
                                        helperText={errors.ville_id}
                                    />
                                )}
                            />
                            {(isContentVillage || formDataBP.village_id) && (
                                <Autocomplete
                                    // disablePortal
                                    autoHighlight
                                    id="Village"
                                    options={villages}
                                    size="small"
                                    sx={{ width: "100%" }}
                                    onChange={handleVillageId}
                                    value={formDataBP.village_id ? villages?.find((option) => option?.id === formDataBP.village_id) || null : null}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Village"
                                            error={!!errors.village_id}
                                            helperText={errors.village_id
                                            }
                                        />
                                    )}
                                />
                            )}
                        </>
                    </div>
                );
            case 4:
                return (
                    <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8">
                        <TextField
                            label="Nom du propriétaire de la parcelle "
                            fullWidth
                            size="small"
                            value={formDataBP.prop_nom}
                            onChange={(e) => handleChange("prop_nom", e.target.value)}
                            InputLabelProps={{shrink: true}}
                            onFocus={() => handleFieldFocus("prop_nom")}
                            error={!!errors.prop_nom}
                            helperText={errors.prop_nom}
                            required
                        />
                        <TextField
                            label="Prénom du propriétaire de la parcelle "
                            fullWidth
                            size="small"
                            value={formDataBP.prop_prenom}
                            onChange={(e) => handleChange("prop_prenom", e.target.value)}
                            InputLabelProps={{shrink: true}}
                            onFocus={() => handleFieldFocus("prop_prenom")}
                            error={!!errors.prop_prenom}
                            helperText={errors.prop_prenom}
                            required
                        />
                        <TextField
                            label="Section"
                            fullWidth
                            size="small"
                            value={formDataBP.section}
                            onChange={(e) => handleChange("section", e.target.value.toUpperCase())}
                            InputLabelProps={{shrink: true}}
                            onFocus={() => handleFieldFocus("section")}
                            error={!!errors.section}
                            helperText={errors.section}
                            required
                        />
                        <TextField
                            label="Lot"
                            type="number"
                            fullWidth
                            size="small"
                            value={formDataBP.lot}
                            onChange={(e) => handleChange("lot", e.target.value)}
                            InputLabelProps={{shrink: true}}
                            onFocus={() => handleFieldFocus("lot")}
                            error={!!errors.lot}
                            helperText={errors.lot}
                            required
                        />
                        <TextField
                            label="Parcelle"
                            fullWidth
                            type="number"
                            size="small"
                            value={formDataBP.parcelle}
                            onChange={(e) => handleChange("parcelle", e.target.value)}
                            InputLabelProps={{shrink: true}}
                            onFocus={() => handleFieldFocus("parcelle")}
                            error={!!errors.parcelle}
                            helperText={errors.parcelle}
                            required
                        />
                    </div>
                );
            case 5:
                return (
                    <div className=" space-y-5 mt-8 mb-8">
                        <InputLabel id="demo-select-small-label">
                            Type de branchement
                        </InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={formDataBP.type_amperage}
                            label="type_amperage"
                            size="small"
                            sx={{width: "100%"}}
                            onChange={(e) => handleChange("type_amperage", e.target.value)}
                        >
                            <MenuItem value="monophasé">Monophasé</MenuItem>
                            <MenuItem value="triphasé">Triphasé</MenuItem>
                        </Select>
                        <div className={`flex md:justify-between md:flex-row sm:flex-row space-x-8`}>
                            <div>
                                Monophasé
                                <p>
                                    Pour les applications domestiques et les petits appareils.
                                </p>
                            </div>
                            <div>
                                Triphasé
                                <p>
                                    Pour les grandes installations industrielles et commerciales.
                                </p>
                            </div>
                        </div>
                        <InputLabel id="puissance">Ampérage souhaité</InputLabel>
                        <Select
                            fullWidth
                            size="small"
                            value={formDataBP.puissance}
                            onChange={(event) => {
                                handleChange("puissance", event.target.value);
                            }}
                            displayEmpty
                            inputProps={{"aria-label": "Without label"}}
                            required
                        >
                            <MenuItem value="" disabled>
                                <em>Puissance Souhaitée</em>
                            </MenuItem>
                            {puissances.map((value) => (
                                <MenuItem key={value} value={value}>{value}</MenuItem>
                            ))}
                        </Select>
                        <TextField
                            label="Durée de souscription en jour"
                            type="number"
                            placeholder="par exemple 20 jours"
                            fullWidth
                            size="small"
                            value={formDataBP.duree}
                            onChange={(e) => handleChange("duree", e.target.value)}
                            onFocus={() => handleFieldFocus("duree")}
                            InputLabelProps={{shrink: true}}
                            error={!!errors.duree}
                            helperText={errors.duree}
                            required
                        />
                    </div>
                );
            case 6:
                return (
                    <div className="flex flex-col justify-center items-center space-y-5 mt-8 mb-8 dark:text-white">
                        <Typography
                            className="text-center mb-8 dark:text-white"
                            variant="h5"
                        >
                            Voulez-vous vraiment modifier votre demande ?
                        </Typography>
                    </div>
                );
            default:
                return "Unknown step";
        }
    };

    return (
        <NavbarSidebarLayout>
            <div
                className="block items-center justify-center border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
                <div className="mb-1 w-full">
                    <Menu titre="Modification demande de branchement provisoire"/>
                    <div className={`my-8`}>
                        <div className="text-center  text-3xl mb-8">
                            Modification de la demande de branchement provisoire
                        </div>

                        <div
                            className="flex w-full flex-col gap-y-6 lg:flex-row lg:justify-center lg:gap-y-0 md:flex-row md:justify-center md:gap-y-0">
                            <div
                                className=" rounded-lg bg-white p-2 shadow-2xl dark:bg-gray-800  xl:p-8 lg:w-1/2 border-2 lg:p-5">

                                <div className="invisible md:visible">
                    <Stepper activeStep={activeStepBP} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{/*label*/}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                                <div>
                                    <Typography
                                        className="text-center mb-8 dark:text-white"
                                        variant="h4"
                                    >
                                        {steps[activeStepBP]}
                                    </Typography>
                                    {getStepContent(activeStepBP)}

                                    <div className="flex flex-row justify-between pt-18">
                                        <Button
                                            color="success"
                                            variant="outlined"
                                            disabled={activeStepBP === 0}
                                            onClick={handleBackBranchementProvisoire}
                                            sx={{mr: 1}}
                                        >
                                            Précédent
                                        </Button>
                                        <div className="flex 1 1 auto"/>
                                        {activeStepBP === steps.length - 1 && (
                                            <>
                                                <Button
                                                    color="secondary"
                                                    variant="outlined"
                                                    onClick={handleDeleteFile}
                                                    sx={{mr: 1}}
                                                >
                                                    {loadingBP ?
                                                        <>
                                                            <CircularProgress color="primary" size="1rem" /> En
                                                            cours ...
                                                        </>
                                                        :
                                                        <>
                                                            Modifier
                                                        </>
                                                    }
                                                </Button>
                                            </>
                                        )}
                                        {activeStepBP !== steps.length - 1 && (
                                            <Button
                                                onClick={handleNextBranchementProvisoire}
                                                variant="contained"
                                                color="success"
                                            >
                                                Suivant
                                            </Button>
                                        )}
                                    </div>
                                </div>
                                <ToastContainer/>
                                <div className="bg-green-100 mt-12">
                                    {stateSend && (
                                        <>
                                            <Link
                                                to="/dashboard"
                                                className="text-justify text-white dark:text-white"
                                            >
                                                <Button>Voir mes demandes</Button>
                                            </Link>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </NavbarSidebarLayout>
    );
};

export default EditBranchementProvisoire;
