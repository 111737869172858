import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import SonabelLogo from "../../../../assets/Sonabel_logo.svg.jpg";
import Button from "@mui/material/Button";
import {api} from "../../../../config/config";
import {toast, ToastContainer} from "react-toastify";
import {CircularProgress} from "@mui/material";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const PwdReset = () => {

    const [password, setPassword] = useState('');
    const [matchPwd, setMatchPwd] = useState("");

    const [validPassword, setValidPassword] = useState(false);
    const [validMatch, setValidMatch] = useState(false);

    const [passwordError, setPasswordError] = useState("");
    const [matchError, setMatchError] = useState("");

    // const [pwdFocus, setPwdFocus] = useState(false);
    // const [matchFocus, setMatchFocus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password));
        setPasswordError(
            PWD_REGEX.test(password) ? "" : "Le mot de passe doit contenir 8 caratères au minimun. " +
                "Doit comprendre des lettres majuscules et minuscules, un chiffre et un caractère spécial."
        );
        setValidMatch(password === matchPwd);
        setMatchError(
            password === matchPwd ? "" : "Les mots de passe ne correspondent pas"
        );
    }, [password, matchPwd]);

    const handleChange = (value) => {
        setPassword(value);
    }
    const handleChangeMatchPassword = (value) => {
        setMatchPwd(value);
    }

    const handleSubmitPwd = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            if(validPassword && validMatch){
                await api.post('account/lost-password', {
                    step: "last",
                    email: localStorage.getItem("resetEmail"),
                    code: localStorage.getItem("code"),
                    password: password
                }).then(response => {
                    if (response.status === 200){
                        setIsLoading(false);
                        localStorage.removeItem("resetEmail");
                        localStorage.removeItem("code");
                        localStorage.removeItem("isReset");
                        navigate("/login");
                    }
                }).catch(error => {
                    setIsLoading(false);
                    if (!error?.response) {
                        toast.warning('Pas de réponse du serveur');
                        // setErrMsg('Pas de réponse du serveur');
                    } else if (error.response?.status === 401) {
                        toast.warning(error.response.data.title);
                    } else {
                        toast.warning('Le processus de réinitialisation du mot de passe a échoué. Veuillez réessayer.');
                    }
                });
            }else{
                toast.warning("Veuillez corriger les erreurs dans le formulaire");
            }

        }catch (err) {
            if (!err?.response) {
                toast.warning('Pas de réponse du serveur');
                // setErrMsg('Pas de réponse du serveur');
            } else if (err.response?.status === 401) {

            } else {
                toast.warning('Le processus de réinitialisation du mot de passe a échoué. Veuillez réessayer.');
            }
        }
    };

    return(
        <>
            <ToastContainer/>
            <div
                className="flex flex-col items-center justify-center px-6 pt-8 mx-auto md:h-screen pt:mt-0 dark:bg-gray-900">
                <Link to="/"
                      className="flex items-center justify-center mb-8 text-2xl font-semibold lg:mb-10 dark:text-white">
                    <img src={SonabelLogo} className="mr-4 h-11" alt="FlowBite Logo"/>
                    <span>SONABEL</span>
                </Link>
                {/*// <!-- Card -->*/}
                <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
                    <div className="w-full p-6 sm:p-8">
                        <h3 className="mb-3 text-2xl font-bold text-gray-400 text-center">
                            Etape 3
                        </h3>
                        <h2 className="mb-3 text-2xl font-bold text-gray-900 text-center">
                            Réinitialisez votre mot de passe.
                        </h2>
                        <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                            Vous pouvez maintenant modifier votre mot de passe.
                        </p>
                        <form className="mt-8 space-y-6" method="post">

                            {/*Password*/}
                            <div>
                                <label
                                    htmlFor="password"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Mot de passe
                                </label>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    onChange={(e) => handleChange(e.target.value)}
                                    value={password}
                                    placeholder="••••••••"
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                                        passwordError ? "border-red-500" : ""
                                    }`}
                                    required
                                    aria-invalid={validPassword ? "false" : "true"}
                                    aria-describedby="pwdnote"
                                    // onBlur={() => setPwdFocus(false)}
                                />
                                {passwordError && (
                                    <p style={{color: "red", marginTop: "0.5rem"}}>{passwordError}</p>
                                )}
                                <br/>
                            </div>
                            {/*confirm password*/}
                            <div>
                                <label
                                    htmlFor="confirm-password"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Confirmez le mot de passe
                                </label>
                                <input
                                    type="password"
                                    name="matchPwd"
                                    id="confirm-password"
                                    onChange={(e) => handleChangeMatchPassword(e.target.value)}
                                    value={matchPwd}
                                    placeholder="••••••••"
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                                        matchError ? "border-red-500" : ""
                                    }`}
                                    required
                                    aria-invalid={validMatch ? "false" : "true"}
                                    aria-describedby="confirmnote"
                                    // onBlur={() => setMatchFocus(false)}
                                />
                                {matchError && (
                                    <p style={{color: "red", marginTop: "0.5rem"}}>{matchError}</p>
                                )}
                            </div>
                            <div>
                                <div className="flex justify-end">
                                    <Button type="submit"
                                            className="w-full px-5 py-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                            onClick={handleSubmitPwd}
                                    >
                                        {isLoading ?
                                            <>
                                                <CircularProgress color="primary" size="1rem"/> En cours ...
                                            </>
                                            :
                                            <>
                                                Modifier
                                            </>
                                        }
                                    </Button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
export default PwdReset;
